import { Button, makeStyles, Menu, MenuItem } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import clsx from 'clsx';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import StockQuantityDialog from '../../components/ProductManagement/StockQuantityDialog';
import { ProductShow } from '../../state/types';
import { PATHS } from '../../utils/appPaths';
import browserHistory from '../../utils/browserhistory';
import { Product } from '../../utils/constants';

interface Props {
  item: Product;
  fullItem: ProductShow;
  products: ProductShow[];
  setProducts: (products: ProductShow[]) => void;
  index: number;
}

const MenuItemDropDown: FC<Props> = props => {
  const classes = useStyles();
  const { item,fullItem,products,setProducts,index } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [quantityDialogOpened, setQuantityDialogOpened] = useState<boolean>(false);
  const handleCloseDropdown = () => {
    setAnchorEl(null);
  };

  const handleCloseDialog = () => {
    setQuantityDialogOpened(false);
  };
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const openStockQuantityDialog = (glasses: Product) => {
    setQuantityDialogOpened(true);
    handleCloseDropdown();
  };

  const navigateTo = (route: string,state: ProductShow) => {
    // browserHistory.push(route);
    browserHistory.push({
      pathname: route,
      state: state
    });
  };

  const { t } = useTranslation();

  return (
    <div className={clsx(classes.cells, classes.dropdownButton)}>
      <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
        <ArrowDropDownIcon />
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted={true}
        open={Boolean(anchorEl)}
        onClose={handleCloseDropdown}
      >
        <MenuItem onClick={openStockQuantityDialog.bind(this, item)}>
          {t('MANAGE_STOCK')}
        </MenuItem>
        <MenuItem onClick={navigateTo.bind(this, PATHS.APP.PRODUCTS_MANAGEMENT.EDIT_PRODUCT,fullItem)}>
          {t('MODIFY_PRODUCT')}
        </MenuItem>
      </Menu>
      {quantityDialogOpened ? (
        <StockQuantityDialog
          onClose={handleCloseDialog}
          open={quantityDialogOpened}
          stock={item.stock}
          id={item.id}
          products={products}
          setProducts={setProducts}
          index={index}
        />
      ) : null}
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  cells: {
    height: '39px',
    display: 'flex',
  },
  dropdownButton: {
    display: 'grid',
    justifyItems: 'center',
  },
}));

export default MenuItemDropDown;
