import { makeStyles, MenuItem, TextField, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { setSizeProduct } from '../../state/actions';
import { InitialState, ProductShow, SizeProduct } from '../../state/types';

interface Props {
  size: {
    width: string[];
    length: string[];
    bridge: string[];
  };
  item?: ProductShow;
}

const SizeSet: FC<Props> = props => {
  const classes = useStyles();
  const { size,item } = props;
  const buttonPressed = useSelector((state: InitialState) => state.buttonPressed);
  const [width, setWidth] = useState<string>(typeof item !== 'undefined' ? item.size[0].width.toString():'');
  const [length, setLength] = useState<string>(typeof item !== 'undefined' ? item.size[0].length.toString():'');
  const [bridgeWidth, setBridgeWidth] = useState<string>(typeof item !== 'undefined' ? item.size[0].bridge.toString():'');

  const widthChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setWidth(event.target.value);
  };
  const lengthChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLength(event.target.value);
  };
  const bridgeWidthChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBridgeWidth(event.target.value);
  };

  useEffect(()=>{
    if(width!=='' && length!=='' && bridgeWidth!=='')
    {
      const size: SizeProduct = {width: width,length: length,bridge: bridgeWidth}
      setSizeProduct(size);
    }
  },[width,length,bridgeWidth])

  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Typography color="textPrimary" variant={'body1'}>
        {t('LENSES_WIDTH')}*
      </Typography>
      <Typography color="textPrimary" variant={'body1'}>
        {t('TEMPLE_LENGTH')}*
      </Typography>
      <Typography color="textPrimary" variant={'body1'}>
        {t('BRIDGE_WIDTH')}*
      </Typography>
      <TextField
        className={classes.input}
        value={width}
        onChange={widthChangeHandler}
        select={true}
        error={width === '' && buttonPressed===true}
      >
        {size.width.map(option => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        className={classes.input}
        value={length}
        onChange={lengthChangeHandler}
        select={true}
        error={length === '' && buttonPressed===true}
      >
        {size.length.map(option => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        className={classes.input}
        value={bridgeWidth}
        onChange={bridgeWidthChangeHandler}
        select={true}
        error={bridgeWidth === '' && buttonPressed===true}
      >
        {size.bridge.map(option => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </TextField>
    </React.Fragment>
  );
};

const useStyles = makeStyles(theme => ({
  input: {
    display: 'grid',
    backgroundColor: theme.palette.background.default,
    borderRadius: '5px',
    height: '34px',
    marginBottom: theme.spacing(3),
  },
  size: {
    paddingBottom: theme.spacing(2.5),
  },
}));

export default SizeSet;
