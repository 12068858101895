import { makeStyles, Typography } from '@material-ui/core';
import { FC, useEffect, useState } from 'react';
import React from 'react';
import profilePic from '../assets/profilePic.png';
import { getCompany, getUser } from '../state/commands/user';
import { showSnack } from '../state/actions';
import { SnackbarVariant } from '../utils/constants';
import i18n from '../i18n';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CustomPopover from './Popover/CustomPopover';
import { useTranslation } from 'react-i18next';
import romania from '../assets/romania.png';
import usa from '../assets/usa.png';

interface Props {
  tabName: string;
}

const Header: FC<Props> = props => {
  const classes = useStyles();
  const { tabName } = props;
  const [opticalCabinetName, setOpticalCabinetName] = useState<string>('');
  const [languagePopoverOpened, setLanguagePopoverOpened] = useState<boolean>(false);
  const [languagePopoverAnchorEl, setLanguagePopoverAnchorEl] = useState<HTMLDivElement | null>(
    null,
  );
  const { t } = useTranslation();

  const handleLanguagePopoverClose = () => {
    setLanguagePopoverOpened(false);
    setLanguagePopoverAnchorEl(null);
  };
  
  const handleLanguagePopoverOpened = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setLanguagePopoverOpened(true);
    setLanguagePopoverAnchorEl(e.currentTarget);
  };

  const changeLanguage = (locale: string) => {
    i18n.changeLanguage(locale);
  };

  useEffect(()=>{
    loadUser();
  },[])

  const loadUser = async () => {
    try {
      const userDetailsResponse = await getUser();
      const userId = userDetailsResponse.data.data.id.toString();
        
      const companyDetailsResponse = await getCompany(userId);
      let name = companyDetailsResponse.data.name.replace(/\+/g,' ')
      setOpticalCabinetName(name);
    } catch(e) {
      showSnack(e.message, SnackbarVariant.error);
    }
  };
  
  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography className={classes.lightBold} color="textPrimary" variant={'h3'}>
          {tabName}
        </Typography>
        <div className={classes.profile}>
          <Typography color="primary" variant={'h4'}>
            {opticalCabinetName}
          </Typography>
          <img src={profilePic} alt="profile" />
        </div>
        <div className={classes.countryItem}>
          <div>
          <img
                  src={i18n.language === 'ro' ? romania : usa}
                  alt="logo"
                  width={30}
                  height={30}
                  className={classes.logo}
                  onMouseEnter={handleLanguagePopoverOpened}
                />
                <ArrowDropDownIcon />
          </div>
        </div>
        <CustomPopover
        opened={languagePopoverOpened}
        anchorEl={languagePopoverAnchorEl}
        close={handleLanguagePopoverClose}
        >
        <Typography
          onClick={changeLanguage.bind(this, 'ro')}
          classes={{ root: classes.hover }}
          color="primary"
        >
          {t('ROMANA')}
        </Typography>
        <Typography
          onClick={changeLanguage.bind(this, 'en')}
          classes={{ root: classes.hover }}
          color="primary"
        >
          {t('ENGLEZA')}
        </Typography>
      </CustomPopover>
        
        
      </div>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  header: {
    display: 'grid',
    gridTemplateColumns: '1fr 0.3fr 0.3fr',
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    boxShadow: '0px 2px 15px rgba(0, 0, 0, 0.1)',
  },
  profile: {
    display: 'grid',
    gridTemplateColumns: '150px  1fr',
  },
  lightBold: {
    fontWeight: 600,
  },
  countryItem: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    alignItems: 'center',
    cursor: 'pointer',
  },
  logo: {
    cursor: 'pointer',
  },
  hover: {
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.text.secondary,
    },
  },
}));

export default Header;
