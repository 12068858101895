import { IconButton, makeStyles } from '@material-ui/core';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import clsx from 'clsx';
import { UnregisterCallback } from 'history';
import { find } from 'lodash';
import React from 'react';
import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import logoWhite from '../assets/logo-white.png';
import { PATHS, REGEX_ROUTE } from '../utils/appPaths';
import browserHistory from '../utils/browserhistory';
import { sideMenuList, SideMenuList } from '../utils/constants';

export const SideMenu: FC<{}> = () => {
  const classes = useStyles();
  const urlParamsCallback = useRef<UnregisterCallback>();
  const [toolbarItem, setToolbarItem] = useState<string>(PATHS.APP.ROOT);
  const [sideMenu, setSideMenu] = useState<SideMenuList>(sideMenuList[0]);
  const [path, setPath] = useState<string>('');
  const { t } = useTranslation();

  useEffect(()=>{
    if(window?.location?.origin === 'https://admin.eyes-aid.com')
    {
    const token = String(sessionStorage.getItem('sessionToken'));
    if (token === 'null' || token === '') {
      const env_url = 'https://eyes-aid.com/';
      window.location.replace(env_url+'login?option=OPTIC_LOGIN');
    }
    }
  
  })

  useEffect(() => {
    getActiveToolbarItem();
    urlParamsCallback.current = browserHistory.listen(() => {
      getActiveToolbarItem();
    });
    return () => {
      if (urlParamsCallback.current) {
        urlParamsCallback.current();
      }
    };
  }, []);

  const getActiveToolbarItem = () => {
    const url = browserHistory.location.pathname;
    setPath(url);
    if (REGEX_ROUTE.PRODUCTS_MANAGEMENT.test(url)) {
      setToolbarItem(PATHS.APP.PRODUCTS_MANAGEMENT.ROOT);
      return;
    }
    if (REGEX_ROUTE.ACCOUNT.test(url)) {
      setToolbarItem(PATHS.APP.ACCOUNT.ROOT);
      return;
    }
    if (REGEX_ROUTE.ORDERS.test(url)) {
      setToolbarItem(PATHS.APP.ORDERS.ROOT);
      return;
    }
    setToolbarItem(PATHS.APP.ROOT);
  };

  useEffect(() => {
    switch (toolbarItem) {
      case PATHS.APP.ROOT: {
        const item = find(sideMenuList, { toolbarItem: PATHS.APP.ROOT });
        if (item) {
          setSideMenu(item);
        }
        break;
      }
      case PATHS.APP.PRODUCTS_MANAGEMENT.ROOT: {
        const item = find(sideMenuList, { toolbarItem: PATHS.APP.PRODUCTS_MANAGEMENT.ROOT });
        if (item) {
          setSideMenu(item);
        }
        break;
      }
      case PATHS.APP.ORDERS.ROOT: {
        const item = find(sideMenuList, { toolbarItem: PATHS.APP.ORDERS.ROOT });
        if (item) {
          setSideMenu(item);
        }
        break;
      }
      case PATHS.APP.ACCOUNT.ROOT: {
        const item = find(sideMenuList, { toolbarItem: PATHS.APP.ACCOUNT.ROOT });
        if (item) {
          setSideMenu(item);
        }
        break;
      }
    }
  }, [toolbarItem]);

  const navigateTo = (route: string) => {
    browserHistory.push(route);
  };

  return (
    <div className={classes.sideMenu}>
      <div className={classes.toolbar}>
        <div className={classes.centeredGrid}>
          <img style={{ width: '90px', height: '90px' }} src={logoWhite} alt="alt1" />
        </div>
        <div className={clsx(classes.centeredGrid, classes.paddingTop)}>
          <IconButton
            onClick={navigateTo.bind(this, PATHS.APP.ROOT)}
            classes={{
              root:
                toolbarItem === PATHS.APP.ROOT
                  ? classes.selectedIconButton
                  : classes.iconButton,
            }}
          >
            <AddBoxOutlinedIcon
              classes={{
                root: toolbarItem === PATHS.APP.ROOT ? classes.selectedLogo : classes.logo,
              }}
            />
          </IconButton>
        </div>
        <div className={clsx(classes.centeredGrid)}>
          <IconButton
            onClick={navigateTo.bind(this, PATHS.APP.PRODUCTS_MANAGEMENT.ROOT)}
            classes={{
              root:
                toolbarItem === PATHS.APP.PRODUCTS_MANAGEMENT.ROOT
                  ? classes.selectedIconButton
                  : classes.iconButton,
            }}
          >
            <AssignmentOutlinedIcon
              classes={{
                root:
                  toolbarItem === PATHS.APP.PRODUCTS_MANAGEMENT.ROOT
                    ? classes.selectedLogo
                    : classes.logo,
              }}
            />
          </IconButton>
        </div>
        <div className={clsx(classes.centeredGrid)}>
          <IconButton
            onClick={navigateTo.bind(this, PATHS.APP.ORDERS.ROOT)}
            classes={{
              root:
                toolbarItem === PATHS.APP.ORDERS.ROOT
                  ? classes.selectedIconButton
                  : classes.iconButton,
            }}
          >
            <CalendarTodayOutlinedIcon
              classes={{
                root:
                  toolbarItem === PATHS.APP.ORDERS.ROOT ? classes.selectedLogo : classes.logo,
              }}
            />
          </IconButton>
        </div>
        <div
          onClick={navigateTo.bind(this, PATHS.APP.ACCOUNT.ROOT)}
          className={clsx(classes.centeredGrid)}
        >
          <IconButton
            classes={{
              root:
                toolbarItem === PATHS.APP.ACCOUNT.ROOT
                  ? classes.selectedIconButton
                  : classes.iconButton,
            }}
          >
            <SettingsOutlinedIcon
              classes={{
                root:
                  toolbarItem === PATHS.APP.ACCOUNT.ROOT ? classes.selectedLogo : classes.logo,
              }}
            />
          </IconButton>
        </div>
      </div>
      {sideMenu.menuItems.length ? (
        <div className={classes.menu}>
          <div className={classes.title}>
            <span>{t(sideMenu.name)}</span>
          </div>
          <div className={classes.spacer} />
          <div className={classes.listItems}>
            {sideMenu.menuItems.map((item,index) => {
              return (
                <span
                  key={item.name}
                  className={clsx(classes.listItem, path === item.route && classes.selected)}
                  onClick={navigateTo.bind(this, item.route)}
                >
                  {/* {(path === PATHS.APP.ADD_PRODUCT.EYESIGHT_LENSES || path === PATHS.APP.ADD_PRODUCT.COSMETIC_LENSES) &&  index === 4  ? t(sideMenu.menuItems[index].name) : null} */}
                  {/* {(path === PATHS.APP.ADD_PRODUCT.EYESIGHT_LENSES) &&  index === 4  ? t(sideMenu.menuItems[index].name) : null} */}
                  {/* {index !== 4 ? t(item.name) : null} */}
                  {t(item.name)}
                </span>
              );
            })}
          </div>
        </div>
      ) : null}
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  sideMenu: {
    display: 'flex',
    flexDirection: 'row',
  },
  toolbar: {
    width: '120px',
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    flexDirection: 'column',
  },
  menu: {
    width: '240px',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.default,
    borderRight: `2px solid ${theme.palette.grey[300]}`,
  },
  title: {
    fontSize: theme.typography.h4.fontSize,
    color: theme.palette.grey[200],
    padding: theme.spacing(2),
  },
  centeredGrid: {
    display: 'grid',
    justifyContent: 'center',
    paddingTop: theme.spacing(0.5),
  },
  iconButton: {
    borderRadius: 'unset',
    width: '120px',
    height: '90px',
  },
  logo: {
    width: '2.2em',
    height: '2.2em',
    fill: '#BDBDBD',
  },
  paddingTop: {
    paddingTop: theme.spacing(3),
  },
  selectedIconButton: {
    backgroundColor: theme.palette.background.default,
    borderRadius: 'unset',
    width: '120px',
    height: '90px',
  },
  selectedLogo: {
    fill: theme.palette.primary.main,
    width: '2.2em',
    height: '2.2em',
  },
  spacer: {
    backgroundColor: theme.palette.grey[300],
    height: '1px',
    width: '90%',
    marginLeft: '12px',
  },
  listItems: {
    display: 'grid',
    paddingTop: theme.spacing(4),
    gridGap: theme.spacing(5),
    padding: theme.spacing(2),
  },
  listItem: {
    cursor: 'pointer',
    fontSize: theme.typography.subtitle2.fontSize,
  },
  selected: {
    color: theme.palette.primary.main,
  },
}));
