import { Checkbox, FormControlLabel, InputAdornment, makeStyles, TextField, Typography } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { setDioptersProduct } from '../../state/actions';
import { InitialState, LenseDiopter, ProductShow, SphCylLense } from '../../state/types';
import HeightIcon from '@material-ui/icons/Height';

enum CATEGORY {
  COSMETIC = 'cosmetic',
  EYESIGHT = 'eyesight'
}

interface Props {
  item?: ProductShow;
  index?: number;
  category?: string;
}

const Parameters: FC<Props> = props => {
  const { item,index,category } = props;
  const classes = useStyles();
  const [sphereFrom, setSphereFrom] = React.useState<number>(-10);
  const [sphereTo, setSphereTo] = React.useState<number>(10);
  const [cylinderFrom, setCylinderFrom] = React.useState<number>(-10);
  const [cylinderTo, setCylinderTo] = React.useState<number>(10);
  const [radius, setRadius] = React.useState<string>('0');
  const [diameter, setDiameter] = React.useState<string>('0');
  const { t } = useTranslation();

  useEffect(()=>{
    if(typeof item !== 'undefined')
    {
      if(typeof index !== 'undefined')
      {
        if(typeof item.lenses.diopter[index].diopters !== 'undefined')
          {

            for(let i=0;i<item.lenses.diopter[index].diopters?.sph.length!;i++)
            {
              if(item.lenses.diopter[index].diopters?.sph[i].min! === -12 && item.lenses.diopter[index].diopters?.sph[i].max! === -10.5)
              {
                if(item.category.id === 4)
                {
                  setEyesightRange1(true);
                }
              }
              if(item.lenses.diopter[index].diopters?.sph[i].min! === -10 && item.lenses.diopter[index].diopters?.sph[i].max! === -8.5)
              {
                if(item.category.id === 4)
                {
                  setEyesightRange2(true);
                }
              }
              if(item.lenses.diopter[index].diopters?.sph[i].min! === -8.25 && item.lenses.diopter[index].diopters?.sph[i].max! === -6.25)
              {
                if(item.category.id === 4)
                {
                  setEyesightRange3(true);
                }
              }
              if(item.lenses.diopter[index].diopters?.sph[i].min! === -6.25 && item.lenses.diopter[index].diopters?.sph[i].max! === -0.25)
              {
                if(item.category.id === 4)
                {
                  setEyesightRange4(true);
                }
              }
              if(item.lenses.diopter[index].diopters?.sph[i].min! === 0.25 && item.lenses.diopter[index].diopters?.sph[i].max! === 6)
              {
                if(item.category.id === 4)
                {
                  setEyesightRange5(true);
                }
              }
              if(item.lenses.diopter[index].diopters?.sph[i].min! === 6.25 && item.lenses.diopter[index].diopters?.sph[i].max! === 8)
              {
                if(item.category.id === 4)
                {
                  setEyesightRange6(true);
                }
              }
            }
              // setSphereFrom(item.lenses.diopter[index].diopters?.sph[0].min!)
              // setSphereTo(item.lenses.diopter[index].diopters?.sph[0].max!)

              // setCylinderFrom(item.lenses.diopter[index].diopters?.cyl[0].min!)
              // setCylinderTo(item.lenses.diopter[index].diopters?.cyl[0].max!)
          }
        setRadius(item.configurator.diopter[0].radius.toString());
        setDiameter(item.configurator.diopter[0].diameter.toString());
      }
      else{
        if(item.lenses.diopter.length!==0)
        {
          if(typeof item.lenses.diopter[0].diopters !== 'undefined')
          {
              // setSphereFrom(item.lenses.diopter[0].diopters?.sph[0].min)
              // setSphereTo(item.lenses.diopter[0].diopters?.sph[0].max)

              // setCylinderFrom(item.lenses.diopter[0].diopters?.cyl[0].min)
              // setCylinderTo(item.lenses.diopter[0].diopters?.cyl[0].max)
            if(item.lenses.diopter[0].id === 2)
            {
              for(let i=0;i<item.lenses.diopter[0].diopters?.sph.length!;i++)
              {
                if(item.lenses.diopter[0].diopters?.sph[i].min! === -12 && item.lenses.diopter[0].diopters?.sph[i].max! === -10.5)
                {
                  if(item.category.id === 4)
                  {
                    setEyesightRange1(true);
                  }
                }
                if(item.lenses.diopter[0].diopters?.sph[i].min! === -10 && item.lenses.diopter[0].diopters?.sph[i].max! === -8.5)
                {
                  if(item.category.id === 4)
                  {
                    setEyesightRange2(true);
                  }
                }
                if(item.lenses.diopter[0].diopters?.sph[i].min! === -8.25 && item.lenses.diopter[0].diopters?.sph[i].max! === -6.25)
                {
                  if(item.category.id === 4)
                  {
                    setEyesightRange3(true);
                  }
                }
                if(item.lenses.diopter[0].diopters?.sph[i].min! === -6.25 && item.lenses.diopter[0].diopters?.sph[i].max! === -0.25)
                {
                  if(item.category.id === 4)
                  {
                    setEyesightRange4(true);
                  }
                }
                if(item.lenses.diopter[0].diopters?.sph[i].min! === 0.25 && item.lenses.diopter[0].diopters?.sph[i].max! === 6)
                {
                  if(item.category.id === 4)
                  {
                    setEyesightRange5(true);
                  }
                }
                if(item.lenses.diopter[0].diopters?.sph[i].min! === 6.25 && item.lenses.diopter[0].diopters?.sph[i].max! === 8)
                {
                  if(item.category.id === 4)
                  {
                    setEyesightRange6(true);
                  }
                }
                if(item.lenses.diopter[0].diopters?.sph[i].min! === -16 && item.lenses.diopter[0].diopters?.sph[i].max! === -12.5)
                {
                  if(item.category.id === 5)
                  {
                    setCosmeticRange1(true);
                  }
                }
                if(item.lenses.diopter[0].diopters?.sph[i].min! === -12 && item.lenses.diopter[0].diopters?.sph[i].max! === -8.5)
                {
                  if(item.category.id === 5)
                  {
                    setCosmeticRange2(true);
                  }
                }
                if(item.lenses.diopter[0].diopters?.sph[i].min! === -8 && item.lenses.diopter[0].diopters?.sph[i].max! === -5.5)
                {
                  if(item.category.id === 5)
                  {
                    setCosmeticRange3(true);
                  }
                }
                if(item.lenses.diopter[0].diopters?.sph[i].min! === -5.25 && item.lenses.diopter[0].diopters?.sph[i].max! === -0.5)
                {
                  if(item.category.id === 5)
                  {
                    setCosmeticRange4(true);
                  }
                }
                if(item.lenses.diopter[0].diopters?.sph[i].min! === 8.5 && item.lenses.diopter[0].diopters?.sph[i].max! === 10)
                {
                  if(item.category.id === 5)
                  {
                    setCosmeticRange5(true);
                  }
                }
              }
            }
            if(item.lenses.diopter[0].id === 1)
            {
              if(item.category.id === 5)
              {
                setCosmeticRange6(true);
              }
            }
            if(item.lenses.diopter.length>1)
            {
              if(item.lenses.diopter[1].id === 2 && item.lenses.diopter[1].diopters?.sph)
              {
                for(let i=0;i<item.lenses.diopter[1].diopters?.sph.length!;i++)
                {
                  if(item.lenses.diopter[1].diopters?.sph[i].min! === -16 && item.lenses.diopter[1].diopters?.sph[i].max! === -12.5)
                  {
                    if(item.category.id === 5)
                    {
                      setCosmeticRange1(true);
                    }
                  }
                  if(item.lenses.diopter[1].diopters?.sph[i].min! === -12 && item.lenses.diopter[1].diopters?.sph[i].max! === -8.5)
                  {
                    if(item.category.id === 5)
                    {
                      setCosmeticRange2(true);
                    }
                  }
                  if(item.lenses.diopter[1].diopters?.sph[i].min! === -8 && item.lenses.diopter[1].diopters?.sph[i].max! === -5.5)
                  {
                    if(item.category.id === 5)
                    {
                      setCosmeticRange3(true);
                    }
                  }
                  if(item.lenses.diopter[1].diopters?.sph[i].min! === -5.25 && item.lenses.diopter[1].diopters?.sph[i].max! === -0.5)
                  {
                    if(item.category.id === 5)
                    {
                      setCosmeticRange4(true);
                    }
                  }
                  if(item.lenses.diopter[1].diopters?.sph[i].min! === 8.5 && item.lenses.diopter[1].diopters?.sph[i].max! === 10)
                  {
                    if(item.category.id === 5)
                    {
                      setCosmeticRange5(true);
                    }
                  }
                }
              }
            }
            
          }
          setRadius(item.configurator.diopter[0].radius.toString());
          setDiameter(item.configurator.diopter[0].diameter.toString());
        }
      }
    }
    else{
      setCylinderFrom(0);
      setCylinderTo(0);
    }
  },[])

  const sphereFromChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSphereFrom(Number(event.target.value));
  };

  const sphereToChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSphereTo(Number(event.target.value));
  };

  const cylinderFromChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCylinderFrom(Number(event.target.value));
  };

  const cylinderToChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCylinderTo(Number(event.target.value));
  };

  const radiusChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRadius(event.target.value);
  };

  const diameterChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDiameter(event.target.value);
  };

  const lenseDiopterStore = useSelector((state: InitialState) => state.product.lense_diopter);
  
  const [cosmeticRange1, setCosmeticRange1] = useState<boolean>(false);
  const [cosmeticRange2, setCosmeticRange2] = useState<boolean>(false);
  const [cosmeticRange3, setCosmeticRange3] = useState<boolean>(false);
  const [cosmeticRange4, setCosmeticRange4] = useState<boolean>(false);
  const [cosmeticRange5, setCosmeticRange5] = useState<boolean>(false);
  const [cosmeticRange6, setCosmeticRange6] = useState<boolean>(false);

  const toggleCosmeticRange1 = () => {
    setCosmeticRange1(!cosmeticRange1);
  }

  const toggleCosmeticRange2 = () => {
    setCosmeticRange2(!cosmeticRange2);
  }

  const toggleCosmeticRange3 = () => {
    setCosmeticRange3(!cosmeticRange3);
  }

  const toggleCosmeticRange4 = () => {
    setCosmeticRange4(!cosmeticRange4);
  }

  const toggleCosmeticRange5 = () => {
    setCosmeticRange5(!cosmeticRange5);
  }

  const toggleCosmeticRange6 = () => {
    setCosmeticRange6(!cosmeticRange6);
  }

  const [eyesightRange1, setEyesightRange1] = useState<boolean>(false);
  const [eyesightRange2, setEyesightRange2] = useState<boolean>(false);
  const [eyesightRange3, setEyesightRange3] = useState<boolean>(false);
  const [eyesightRange4, setEyesightRange4] = useState<boolean>(false);
  const [eyesightRange5, setEyesightRange5] = useState<boolean>(false);
  const [eyesightRange6, setEyesightRange6] = useState<boolean>(false);

  const toggleEyesightRange1 = () => {
    setEyesightRange1(!eyesightRange1);
  }

  const toggleEyesightRange2 = () => {
    setEyesightRange2(!eyesightRange2);
  }

  const toggleEyesightRange3 = () => {
    setEyesightRange3(!eyesightRange3);
  }

  const toggleEyesightRange4 = () => {
    setEyesightRange4(!eyesightRange4);
  }

  const toggleEyesightRange5 = () => {
    setEyesightRange5(!eyesightRange5);
  }

  const toggleEyesightRange6 = () => {
    setEyesightRange6(!eyesightRange6);
  }

  useEffect(()=>{
    const lenseDiopter: LenseDiopter[] = [];
    const sph: SphCylLense[] = [];
    const cyl: SphCylLense[] = [];
    if(category === CATEGORY.EYESIGHT)
    {
      if(eyesightRange1)
      {
        sph.push({min: '-12',
                  max: '-10.5'})
      }
      if(eyesightRange2)
      {
        sph.push({min: '-10',
                  max: '-8.5'})
      }
      if(eyesightRange3)
      {
        sph.push({min: '-8.25',
                  max: '-6.25'})
      }
      if(eyesightRange4)
      {
        sph.push({min: '-6.25',
                  max: '-0.25'})
      }
      if(eyesightRange5)
      {
        sph.push({min: '0.25',
                  max: '6'})
      }
      if(eyesightRange6)
      {
        sph.push({min: '6.25',
                  max: '8'})
      }

      cyl[0]={
        min: '0',
        max: '0',
      }
      lenseDiopter.push({
        id: '2',
        radius: radius.toString(),
        diameter: diameter.toString(),
        sph: sph,
        cyl: cyl,
      })
    }
    if(category === CATEGORY.COSMETIC)
    {
      if(cosmeticRange1)
      {
        sph.push({min: '-16',
                  max: '-12.5'})
      }
      if(cosmeticRange2)
      {
        sph.push({min: '-12',
                  max: '-8.5'})
      }
      if(cosmeticRange3)
      {
        sph.push({min: '-8',
                  max: '-5.5'})
        sph.push({min: '5.5',
                  max: '8'})
      }
      if(cosmeticRange4)
      {
        sph.push({min: '-5.25',
                  max: '-0.5'})
        sph.push({min: '0.5',
                  max: '5.25'})
      }
      if(cosmeticRange5)
      {
        sph.push({min: '8.5',
                  max: '10'})
      }
      if(cosmeticRange6)
      {
        const sphZero: SphCylLense[] = [];
        const cylZero: SphCylLense[] = [];
        sphZero[0]={
          min: '0',
          max: '0',
        }
        cylZero[0]={
          min: '0',
          max: '0',
        }
        lenseDiopter.push({
          id: '1',
          radius: radius.toString(),
          diameter: diameter.toString(),
          sph: sphZero,
          cyl: cylZero,
        })
      }

      cyl[0]={
        min: '0',
        max: '0',
      }
      lenseDiopter.push({
        id: '2',
        radius: radius.toString(),
        diameter: diameter.toString(),
        sph: sph,
        cyl: cyl,
      })
    }
    // sph[0]={
    //   min: sphereFrom.toString(),
    //   max: sphereTo.toString(),
    // }
    // cyl[0]={
    //   min: cylinderFrom.toString(),
    //   max: cylinderTo.toString(),
    // }
    // lenseDiopter.push({
    //   id: '2',
    //   radius: radius.toString(),
    //   diameter: diameter.toString(),
    //   sph: sph,
    //   cyl: cyl,
    // })
    // console.log(lenseDiopter);
    setDioptersProduct(lenseDiopter);
  },[eyesightRange1,eyesightRange2,eyesightRange3,eyesightRange4,eyesightRange5,eyesightRange6,cosmeticRange1,cosmeticRange2,cosmeticRange3,cosmeticRange4,cosmeticRange5,cosmeticRange6,radius,diameter])

  return (
    <div className={classes.productInfo}>
      <div>
        <Typography color="textPrimary" variant={'body1'} className={classes.paddingBot}>
          {t('DIOPTERS')}
        </Typography>
        {/* <div className={classes.columns}>
          <div className={classes.columns2}>
            <div>
              <Typography color="textPrimary" variant={'body1'} className={classes.paddingBot}>
                {t('SPHERE')}
              </Typography>
              <div className={classes.flex}>
                <Typography color="textPrimary" variant={'body1'}>
                  {t('FROM')}
                </Typography>
                <TextField
                  className={classes.input}
                  type="number"
                  value={sphereFrom}
                  onChange={sphereFromChangeHandler}
                />
                <Typography color="textPrimary" variant={'body1'}>
                  {t('TO')}
                </Typography>
                <TextField
                  className={classes.input}
                  type="number"
                  value={sphereTo}
                  onChange={sphereToChangeHandler}
                />
              </div>
            </div>
            
            {/* <div>
              <Typography color="textPrimary" variant={'body1'} className={classes.paddingBot}>
                {t('CYL')}
              </Typography>
              <div className={classes.flex}>
                <Typography color="textPrimary" variant={'body1'}>
                  {t('FROM')}
                </Typography>
                <TextField
                  className={classes.input}
                  type="number"
                  value={cylinderFrom}
                  onChange={cylinderFromChangeHandler}
                />
                <Typography color="textPrimary" variant={'body1'}>
                  {t('TO')}
                </Typography>
                <TextField
                  className={classes.input}
                  type="number"
                  value={cylinderTo}
                  onChange={cylinderToChangeHandler}
                />
              </div>
            </div> */}
          {/* </div>
        </div>  */}

{/* cosmetic */}
        {category === CATEGORY.COSMETIC ? (
          <div className={classes.prescriptionPricing}>
            <div className={classes.sphereAndCyl}>
              <div/>
              <div/>
              <div className={classes.sphereContainer}>
                <div className={classes.rangeAndPrice}>
                <div>
                    <div className={classes.inlineRange}>
                      {'-12,50'} <HeightIcon className={classes.arrow} />
                      {'-16'}
                    </div>
                  </div>
                  <FormControlLabel
                    className={classes.checkbox}
                    value="prescription"
                    control={<Checkbox color="primary" className={classes.radio}/>}
                    label={t('AVAILABLE')}
                    labelPlacement="end"
                    checked={cosmeticRange1}
                    onClick={toggleCosmeticRange1}
                  />
                  <div>
                    <div className={classes.inlineRange}>
                      {'-8,50'} <HeightIcon className={classes.arrow} />
                      {'-12'}
                    </div>
                  </div>
                  <FormControlLabel
                    className={classes.checkbox}
                    value="prescription"
                    control={<Checkbox color="primary" className={classes.radio}/>}
                    label={t('AVAILABLE')}
                    labelPlacement="end"
                    checked={cosmeticRange2}
                    onClick={toggleCosmeticRange2}
                  />
                  <div>
                    <div className={classes.inlineRange}>
                      {'-5,50'} <HeightIcon className={classes.arrow} />
                      {'-8'}
                    </div>
                    <div className={classes.inlineRange}>
                      {'+5,50'} <HeightIcon className={classes.arrow} />
                      {'+8'}
                    </div>
                  </div>
                  <FormControlLabel
                    className={classes.checkbox}
                    value="prescription"
                    control={<Checkbox color="primary" className={classes.radio}/>}
                    label={t('AVAILABLE')}
                    labelPlacement="end"
                    checked={cosmeticRange3}
                    onClick={toggleCosmeticRange3}
                  />
                  <div>
                    <div className={classes.inlineRange}>
                      {'-0,50'} <HeightIcon className={classes.arrow} />
                      {'-5,25'}
                    </div>
                    <div className={classes.inlineRange}>
                      {'+0,50'} <HeightIcon className={classes.arrow} />
                      {'+5,25'}
                    </div>
                  </div>
                  <FormControlLabel
                    className={classes.checkbox}
                    value="prescription"
                    control={<Checkbox color="primary" className={classes.radio}/>}
                    label={t('AVAILABLE')}
                    labelPlacement="end"
                    checked={cosmeticRange4}
                    onClick={toggleCosmeticRange4}
                  />
                  <div>
                    <div className={classes.inlineRange}>
                      {'+8,50'} <HeightIcon className={classes.arrow} />
                      {'+10'}
                    </div>
                  </div>
                  <FormControlLabel
                    className={classes.checkbox}
                    value="prescription"
                    control={<Checkbox color="primary" className={classes.radio}/>}
                    label={t('AVAILABLE')}
                    labelPlacement="end"
                    checked={cosmeticRange5}
                    onClick={toggleCosmeticRange5}
                  />
                  <div>
                    <div className={classes.withoutDiopter}>
                      {t('WITHOUT_DIOPTER')}
                    </div>
                  </div>
                  <FormControlLabel
                    className={classes.checkbox}
                    value="prescription"
                    control={<Checkbox color="primary" className={classes.radio}/>}
                    label={t('AVAILABLE')}
                    labelPlacement="end"
                    checked={cosmeticRange6}
                    onClick={toggleCosmeticRange6}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : null}

{category === CATEGORY.EYESIGHT ? (
          <div className={classes.prescriptionPricing}>
            <div className={classes.sphereAndCyl}>
              <div/>
              <div/>
              <div className={classes.sphereContainer2}>
                <div className={classes.rangeAndPrice}>
                <div>
                    <div className={classes.inlineRange}>
                      {'-10,50'} <HeightIcon className={classes.arrow} />
                      {'-12'}
                    </div>
                  </div>
                  <FormControlLabel
                    className={classes.checkbox}
                    value="prescription"
                    control={<Checkbox color="primary" className={classes.radio}/>}
                    label={t('AVAILABLE')}
                    labelPlacement="end"
                    checked={eyesightRange1}
                    onClick={toggleEyesightRange1}
                  />
                  <div>
                    <div className={classes.inlineRange}>
                      {'-8,50'} <HeightIcon className={classes.arrow} />
                      {'-10'}
                    </div>
                  </div>
                  <FormControlLabel
                    className={classes.checkbox}
                    value="prescription"
                    control={<Checkbox color="primary" className={classes.radio}/>}
                    label={t('AVAILABLE')}
                    labelPlacement="end"
                    checked={eyesightRange2}
                    onClick={toggleEyesightRange2}
                  />
                  <div>
                    <div className={classes.inlineRange}>
                      {'-6,25'} <HeightIcon className={classes.arrow} />
                      {'-8,25'}
                    </div>
                  </div>
                  <FormControlLabel
                    className={classes.checkbox}
                    value="prescription"
                    control={<Checkbox color="primary" className={classes.radio}/>}
                    label={t('AVAILABLE')}
                    labelPlacement="end"
                    checked={eyesightRange3}
                    onClick={toggleEyesightRange3}
                  />
                  <div>
                    <div className={classes.inlineRange}>
                      {'-0,25'} <HeightIcon className={classes.arrow} />
                      {'-6,25'}
                    </div>
                  </div>
                  <FormControlLabel
                    className={classes.checkbox}
                    value="prescription"
                    control={<Checkbox color="primary" className={classes.radio}/>}
                    label={t('AVAILABLE')}
                    labelPlacement="end"
                    checked={eyesightRange4}
                    onClick={toggleEyesightRange4}
                  />
                  <div>
                    <div className={classes.inlineRange}>
                      {'+0,25'} <HeightIcon className={classes.arrow} />
                      {'+6'}
                    </div>
                  </div>
                  <FormControlLabel
                    className={classes.checkbox}
                    value="prescription"
                    control={<Checkbox color="primary" className={classes.radio}/>}
                    label={t('AVAILABLE')}
                    labelPlacement="end"
                    checked={eyesightRange5}
                    onClick={toggleEyesightRange5}
                  />
                  <div>
                    <div className={classes.inlineRange}>
                      {'+6,25'} <HeightIcon className={classes.arrow} />
                      {'+8'}
                    </div>
                  </div>
                  <FormControlLabel
                    className={classes.checkbox}
                    value="prescription"
                    control={<Checkbox color="primary" className={classes.radio}/>}
                    label={t('AVAILABLE')}
                    labelPlacement="end"
                    checked={eyesightRange6}
                    onClick={toggleEyesightRange6}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : null}

        <div className={classes.radiusAndDiameter}>
          <div className={classes.columns2}>
            <div>
              <Typography color="textPrimary" variant={'body1'}>
                {t('RADIUS')}
              </Typography>
              <TextField
                className={classes.input}
                // type="number"
                value={radius}
                onChange={radiusChangeHandler}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Typography color="textPrimary" variant={'body1'}>
                        mm
                      </Typography>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div>
              <Typography color="textPrimary" variant={'body1'}>
                {t('DIAMETER')}
              </Typography>
              <TextField
                className={classes.input}
                // type="number"
                value={diameter}
                onChange={diameterChangeHandler}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Typography color="textPrimary" variant={'body1'}>
                        mm
                      </Typography>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  productInfo: {
    padding: theme.spacing(3),
    background: theme.palette.primary.light,
  },
  bold: {
    fontWeight: 700,
    paddingBottom: theme.spacing(1.5),
    paddingTop: theme.spacing(3),
  },
  columns: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 0.75fr))',
    paddingBottom: theme.spacing(4),
  },
  columns2: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 0.50fr))',
    gridGap: theme.spacing(4),
  },
  paddingBot: {
    paddingBottom: theme.spacing(1.5),
  },
  flex: {
    display: 'flex',
    gridGap: theme.spacing(1),
    alignItems: 'baseline',
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(1),
  },
  input: {
    display: 'grid',
    backgroundColor: theme.palette.background.default,
    borderRadius: '5px',
    height: '34px',
    border: `1px solid ${theme.palette.grey[300]}`,
  },
  radiusAndDiameter: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 0.50fr))',
    paddingBottom: theme.spacing(4),
  },
  checkbox: {
    margin: '0',
  },
  prescriptionPricing: {
    padding: theme.spacing(0),
  },
  sphereAndCyl: {
    display: 'grid',
    gridTemplateColumns: '0.2fr 0.2fr',
    paddingBottom: theme.spacing(2),
  },
  arrow: {
    transform: 'rotate(90deg)',
  },
  sphereContainer: {
    width: '242px',
    height: '340px',
    background: theme.palette.background.default,
    borderRadius: '5px',
  },
  sphereContainer2: {
    width: '242px',
    height: '310px',
    background: theme.palette.background.default,
    borderRadius: '5px',
  },
  cylContainer: {
    width: '230px',
    height: '120px',
    background: theme.palette.background.default,
    borderRadius: '5px',
  },
  rangeAndPrice: {
    display: 'grid',
    gridTemplateColumns: '0.5fr 0.5fr',
    rowGap: theme.spacing(1),
    padding: theme.spacing(1),
  },
  inlineRange: {
    display: 'grid',
    gridTemplateColumns: '0.4fr 0.2fr 0.4fr',
    alignItems: 'center',
    marginTop: '7px'
  },
  radio: {
    color: theme.palette.primary.dark,
  },
  withoutDiopter: {
    marginTop: '7px'
  }
}));

export default Parameters;
