import { makeStyles, TextField, Typography } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Colors from '../../components/AddProduct/Colors';
import Add3DImage from '../../components/AddProduct/Images';
import PriceAndDescription from '../../components/AddProduct/ProductPriceAndDescription';
import ShapeAndSize from '../../components/AddProduct/ProductShapeAndSize';
import PublishOrSave from '../../components/AddProduct/PublishOrSave';
import Header from '../../components/Header';
import { resetProduct, setButtonPressed, setCategoryProduct } from '../../state/actions';
import { getProductInfo } from '../../state/commands/product';
import { createProductInitial } from '../../state/initialStore';
import { InitialState, ProductShow, Size } from '../../state/types';
import { initialSunglassesInfo, Product, ProductCategory, SunGlassesInfo, Taxonomy } from '../../utils/constants';

interface Props {
  item?: ProductShow;
}

const size: Size = {width: [],length: [],bridge: []};

const AddSunglassesContainer: FC<Props> = props => {
  const classes = useStyles();
  const { item } = props;
  const [sunglassesInfo, setSunglassesInfo] = useState<SunGlassesInfo>(initialSunglassesInfo);
  const { t } = useTranslation();
  const product = useSelector((state: InitialState) => state.product);

  useEffect(() => {
    setCategoryProduct(ProductCategory.SUNGLASSES);
    getSunglassesDetails();
    generateSize();
  }, []);

  useEffect(()=>{
    if(typeof item === 'undefined')
    {
      if(product.lense_thickness.length===0)
      {
        resetProduct(createProductInitial);
      }
      setCategoryProduct(ProductCategory.SUNGLASSES);
      setButtonPressed(false);
    }
  },[])

  const getSunglassesDetails = async () => {
    try {
      const newSunglassesInfo = await getProductInfo(ProductCategory.SUNGLASSES);
      setSunglassesInfo(newSunglassesInfo.data);
    } catch (e) {
      console.error(e.message);
    }
  };

  useEffect(()=>{
    if(sunglassesInfo.brands.length!==0)
    {
      let auxArray: Taxonomy[]= [...sunglassesInfo.brands];
      let aux;
      let index = -1;
      for(let i=0;i<auxArray.length;i++)
      {
        if(auxArray[i].name==='Altele')
        {
          index = i;
        }
        if(i>0 && auxArray[i].name === auxArray[i-1].name)
        {
          auxArray.splice(i, 1);
        }
      }
      if (index > -1) {
        aux = auxArray[index];
        auxArray.splice(index, 1);
        auxArray.push(aux);
        setBrands(auxArray);
      }      
    }
  },[sunglassesInfo])

  const [brands, setBrands] = useState<Taxonomy[]>([]);

  const generateSize = () => {
    for (let i = 35; i <= 70; i+=0.5) {
      size.width.push(i.toString());
    }
    for (let i = 90; i <= 155; i+=0.5) {
      size.length.push(i.toString());
    }
    for (let i = 9; i <= 26; i+=0.5) {
      size.bridge.push(i.toString());
    }
  }

  return (
    <div className={classes.root}>
      {typeof item === 'undefined' ? <Header tabName={t('ADD_SUNGLASSES')} /> : null}
      <div className={classes.productInfo}>
        <PriceAndDescription brands={brands} item={item}/>
        <ShapeAndSize taxonomies={sunglassesInfo.taxonomies} size={size} item={item} category="sunglasses"/>
        <Typography className={classes.colorTitle} color="textPrimary" variant={'body1'}>
          {t('FRAME_COLOR')}*
        </Typography>
        <Colors frameColor={sunglassesInfo.taxonomies['frame-color']} color={item?.color.frame !== null ? item?.color.frame : undefined}/>
        <Typography className={classes.colorTitle} color="textPrimary" variant={'body1'}>
          {t('LENSES_COLOR')}*
        </Typography>
        <Colors lensesColor={sunglassesInfo.taxonomies["lenses-color"]} color={item?.color.lense !== null ?  item?.color.lense : undefined}/>
        <Add3DImage item={item}/>
        <PublishOrSave item={item}/>
      </div>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  productInfo: {
    padding: `${theme.spacing(4)}px ${theme.spacing(2)}px ${theme.spacing(6)}px ${theme.spacing(
      2,
    )}px`,
  },
  colorTitle: {
    background: theme.palette.primary.light,
    paddingLeft: theme.spacing(3),
    paddingBottom: theme.spacing(1),
  },
  lensesColor: {
    display: 'grid',
    backgroundColor: theme.palette.primary.light,
  },
  input: {
    display: 'grid',
    backgroundColor: theme.palette.background.default,
    borderRadius: '5px',
    height: '34px',
    width: '40%',
    marginLeft: '24px'
  },
}));

export default AddSunglassesContainer;
