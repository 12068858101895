import axios from 'axios';
import { env } from '../../config';
import { getAxiosConfig } from '../../utils/constants';
import { UserPasswords, UserProfile } from '../types';
// const env =  'https://api.eyes-aid.com/';

const GET_USER = `${env}api/user/profile`;
const UPDATE_PROFILE_API = `${env}api/user-company/`;
const CHANGE_PASSWORD_API = `${env}api/user/change-password`;
const GET_BANK_DETAILS = `${env}api/dashboard/account-details`;
const UPDATE_BANK_DETAILS = `${env}api/dashboard/account-details/`;
const CREATE_BANK_DETAILS = `${env}api/dashboard/account-details`;
const GET_COMPANY_DETAILS = `${env}api/user-company/`;
const UPDATE_USER_PROFILE_API = `${env}api/user/profile`;

export const getUser = async (): Promise<{
    data: { data: { name: string; email: string; id: number } };
  }> => {
    return axios
      .post(GET_USER, {}, getAxiosConfig())
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw new Error(err.message);
      });
  };

export const updateCompanyProfile = async (userProfile: UserProfile) => {
    const bodyParameters = {
      name: userProfile.name,
      email: userProfile.email,
      trade_register_number: userProfile.tradeRegNr,
      company_register_number: userProfile.companyRegNr
    };
    return axios
      .put(UPDATE_PROFILE_API+userProfile.userId, bodyParameters, getAxiosConfig())
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw new Error(err.message);
      });
  };

export const changeAccountPassword = async (userPasswords: UserPasswords) => {
    const body = {
      current_password: userPasswords.current_password,
      new_password: userPasswords.new_password,
      new_password_confirmation: userPasswords.new_password_confirmation,
    };
    return axios
      .post(CHANGE_PASSWORD_API, body, getAxiosConfig())
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw new Error(err.message);
      });
  };

  export const getBankDetails = async () => {

    return axios
      .get(GET_BANK_DETAILS, getAxiosConfig())
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.message);
      });
  };

  export const getBankDetailsById = async (id: string) => {

    return axios
      .get(GET_BANK_DETAILS+'/'+id, getAxiosConfig())
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.message);
      });
  };

  export const updateBankDetailsById = async (id: string,name: string, iban: string, bank: string) => {
    const body = {
      name: name,
      iban: iban,
      bank: bank,
    };
    return axios
      .put(UPDATE_BANK_DETAILS+id, body,getAxiosConfig())
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.message);
      });
  };

  export const createBankDetails = async (id: number,name: string, iban: string, bank: string) => {
    const body = {
      company_id: id.toString(),
      name: name,
      iban: iban,
      bank: bank,
    };
    return axios
      .post(CREATE_BANK_DETAILS, body, getAxiosConfig())
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw new Error(err.message);
      });
  };

  export const getCompany = async (userId: string) => {

    return axios
      .get(GET_COMPANY_DETAILS+userId, getAxiosConfig())
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.message);
      });
  };

  export const updateUserProfile = async (userProfile: UserProfile) => {
    const bodyParameters = {
      name: userProfile.name,
      email: userProfile.email,
    };
    return axios
      .put(UPDATE_USER_PROFILE_API, bodyParameters, getAxiosConfig())
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw new Error(err.message);
      });
  };
