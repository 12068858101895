import { InputAdornment, makeStyles, MenuItem, TextField, Typography } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import PriceAndDescription from '../../components/AddProduct/ProductPriceAndDescription';
import Parameters from '../../components/AddProduct/Parameters';
import PublishOrSave from '../../components/AddProduct/PublishOrSave';
import Add3DImage from '../../components/AddProduct/Images';
import Header from '../../components/Header';
import { resetProduct, setBrandProduct, setButtonPressed, setCategoryProduct, setCodeProduct, setDescriptionProduct, setDiscountProduct, setForProduct, setFullPriceProduct, setMethodOfWearingProduct, setModelProduct, setPeriodOfUseProduct, setStockProduct } from '../../state/actions';
import { getProductInfo } from '../../state/commands/product';
import { EyeGlassesInfo, EyeSightLensesInfo, initialEyeglassesInfo, initialEyesightlensesInfo, Product, ProductCategory, Taxonomy } from '../../utils/constants';
import { InitialState, ProductShow } from '../../state/types';
import { createProductInitial } from '../../state/initialStore';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Autocomplete, AutocompleteRenderInputParams } from '@material-ui/lab';

interface Props {
  item?: ProductShow;
}

const AddOthersContainer: FC<Props> = props => {
  const { item } = props;
  const classes = useStyles();
  const buttonPressed = useSelector((state: InitialState) => state.buttonPressed);
  const { t } = useTranslation();
  const [othersInfo, setOthersInfo] = useState<EyeGlassesInfo>(initialEyeglassesInfo);

  useEffect(() => {
    setCategoryProduct(ProductCategory.OTHER);
    getOthersDetails();
  }, []);

  useEffect(()=>{
    if(typeof item === 'undefined')
    {
      resetProduct(createProductInitial);
      // setCategoryProduct(ProductCategory.OTHER);
      setButtonPressed(false);
    }
  },[])

  useEffect(()=>{
    if(typeof item !== 'undefined')
    {
      setCategoryProduct(String(item.category.id));
      setModelProduct(item.general.model);
      setFullPriceProduct(item.price.price.replace(',',''));
      setDiscountProduct(item.price.discount.toString());
      setStockProduct(item.general.stoc.toString());
      setDescriptionProduct(item.description);
      setCodeProduct(item.code)
    }
  },[])

  const getOthersDetails = async () => {
    try {
      const newOthersInfo = await getProductInfo(ProductCategory.OTHER);
      setOthersInfo(newOthersInfo.data);
    } catch (e) {
      console.error(e.message);
    }
  };

  return (
    <div className={classes.root}>
      {typeof item === 'undefined' ? <Header tabName={t('ADD_OTHERS')} /> : null}
      <div className={classes.productInfo}>
        <PriceAndDescription category='other' brands={othersInfo.brands} item={item}/>
        <Add3DImage category='other' item={item}/>
        <PublishOrSave item={item}/>
      </div>
        
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  productInfo: {
    padding: `${theme.spacing(4)}px ${theme.spacing(2)}px ${theme.spacing(6)}px ${theme.spacing(
      2,
    )}px`,
  },
  lensesDetails: {
    display: 'grid',
    // gridTemplateColumns: 'minmax(100px, 0.33fr) minmax(100px, 0.33fr) minmax(100px, 0.33fr)',
    // columnGap: theme.spacing(2),
    padding: `${theme.spacing(3)}px ${theme.spacing(3)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
    background: theme.palette.primary.light,

    gridTemplateColumns: '0.5fr 0.5fr',
  },
  input: {
    display: 'grid',
    backgroundColor: theme.palette.background.default,
    borderRadius: '5px',
    height: '34px',
    marginBottom: theme.spacing(3),
  },
  bold: {
    fontWeight: 700,
    paddingBottom: theme.spacing(1.5),
    paddingTop: theme.spacing(3),
  },
  description: {
    display: 'grid',
    rowGap: theme.spacing(4),
    padding: `${theme.spacing(3)}px ${theme.spacing(3)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
    background: theme.palette.primary.light,

  },
  multiLineInput: {
    width: '100%',
    backgroundColor: theme.palette.background.default,
  },
  stock: {
    display: 'grid',
    gridTemplateColumns: 'minmax(180px, 0.25fr) minmax(100px, 0.25fr)',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    columnGap: theme.spacing(5)
  },
  inputStock: {
    marginTop: '25px',
    display: 'grid',
    backgroundColor: theme.palette.background.default,
    borderRadius: '5px',
    height: '34px',
    marginBottom: theme.spacing(3),
  },
  pricing: {
    background: theme.palette.primary.light,
    padding: `${theme.spacing(3)}px ${theme.spacing(3)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
    display: 'grid',
    gridTemplateColumns: 'minmax(100px, 0.2fr) minmax(120px, 0.2fr) minmax(160px, 0.2fr)',
    columnGap: theme.spacing(9.5),
    paddingBottom: theme.spacing(3.5),
    '& input[type=number]': {
      '-moz-appearance': 'textfield'
    },
    '& input[type=number]::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0
    },
    '& input[type=number]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0
    }
  },
  autocomplete: {
    background: theme.palette.background.default,
  },
}));

export default AddOthersContainer;
