import {
  Checkbox,
  FormControlLabel,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { setLenseImprovementProduct } from '../../state/actions';
import { configuratorImprovement, INEXISTENT_ID, INEXISTENT_VALUE, InitialState, LenseImprovement, LensesImprovementsProductShow } from '../../state/types';

enum Upgrades {
  NO_UPGRADES = 1,
  FOR_COMPUTER = 2,
  SHOCK_RESISTANT = 3,
  PHOTOCHROMATIC = 4,
  FOR_SUN_WITH_DIOPTERS = 5,
  PROGRESSIVE = 6,
  PHOTOCHROMATIC_BROWN = 8,
  PHOTOCHROMATIC_GREY = 9,
}

enum Thickness {
  STANDARD = 1,
  THIN = 2,
  ULTRA_THIN = 3,
}

const upgradesChoosen: LenseImprovement[] = [];

interface Props {
  improvements?: configuratorImprovement[];
}

const UpgradesPrescriptionThickness: FC<Props> = props => {
  const{ improvements } = props;
  const classes = useStyles();
  const buttonPressed = useSelector((state: InitialState) => state.buttonPressed);
  const [computer, setComputer] = useState<boolean>(false);
  const [shockResistant, setShockResistant] = useState<boolean>(false);
  const [photochromatic, setPhotochromatic] = useState<boolean>(false);
  const [sunWithDiopters, setSunWithDiopters] = useState<boolean>(false);
  const [progressive, setProgressive] = useState<boolean>(false);

  const product = useSelector((state: InitialState) => state.product);
  const [computerStandardPrice, setComputerStandardPrice] = useState<string>('');
  const [computerThinPrice, setComputerThinPrice] = useState<string>('');
  const [computerUltraThinPrice, setComputerUltraThinPrice] = useState<string>('');
  const [shockResistantPrice, setShockResistantPrice] = useState<string>('');
  const [photoChromaticPrice, setPhotochromaticPrice] = useState<string>('0');
  const [photoChromaticBrownStandardPrice, setPhotoChromaticBrownStandardPrice] = useState<string>('');
  const [photoChromaticBrownThinPrice, setPhotoChromaticBrownThinPrice] = useState<string>('');
  const [photoChromaticBrownUltraThinPrice, setPhotoChromaticBrownUltraThinPrice] = useState<string>('');
  const [photoChromaticGreyStandardPrice, setPhotoChromaticGreyStandardPrice] = useState<string>('');
  const [photoChromaticGreyThinPrice, setPhotoChromaticGreyThinPrice] = useState<string>('');
  const [photoChromaticGreyUltraThinPrice, setPhotoChromaticGreyUltraThinPrice] = useState<string>('');
  const [sunWithDioptersPrice, setSunWithDioptersPrice] = useState<string>('');
  const [progressivePrice, setProgressivePrice] = useState<string>('');

  const { t } = useTranslation();

  useEffect(()=>{
    if(typeof improvements !== 'undefined')
    {
      for(let i=0;i<improvements.length;i++)
      {
        if(improvements[i].id===Upgrades.FOR_COMPUTER)
        {
          setComputer(true);
        }
        if(improvements[i].id===Upgrades.FOR_COMPUTER && improvements[i].thickness_id===Thickness.STANDARD)
        {
          setComputerStandardPrice(improvements[i].price.toString())
        }
        if(improvements[i].id===Upgrades.FOR_COMPUTER && improvements[i].thickness_id===Thickness.THIN)
        {
          setComputerThinPrice(improvements[i].price.toString())
        }
        if(improvements[i].id===Upgrades.FOR_COMPUTER && improvements[i].thickness_id===Thickness.ULTRA_THIN)
        {
          setComputerUltraThinPrice(improvements[i].price.toString())
        }
        if(improvements[i].id===Upgrades.SHOCK_RESISTANT)
        {
          setShockResistant(true);
          setShockResistantPrice(improvements[i].price.toString())
        }
        if(improvements[i].id===Upgrades.PHOTOCHROMATIC)
        {
          setPhotochromatic(true);
        }
        if(improvements[i].id===Upgrades.FOR_SUN_WITH_DIOPTERS)
        {
          setSunWithDiopters(true);
          setSunWithDioptersPrice(improvements[i].price.toString())
        }
        if(improvements[i].id===Upgrades.PROGRESSIVE)
        {
          setProgressive(true);
          setProgressivePrice(improvements[i].price.toString())
        }
        if(improvements[i].id===Upgrades.PHOTOCHROMATIC_BROWN && improvements[i].thickness_id===Thickness.STANDARD)
        {
          setPhotoChromaticBrownStandardPrice(improvements[i].price.toString())
        }
        if(improvements[i].id===Upgrades.PHOTOCHROMATIC_BROWN && improvements[i].thickness_id===Thickness.THIN)
        {
          setPhotoChromaticBrownThinPrice(improvements[i].price.toString())
        }
        if(improvements[i].id===Upgrades.PHOTOCHROMATIC_BROWN && improvements[i].thickness_id===Thickness.ULTRA_THIN)
        {
          setPhotoChromaticBrownUltraThinPrice(improvements[i].price.toString())
        }
        if(improvements[i].id===Upgrades.PHOTOCHROMATIC_GREY && improvements[i].thickness_id===Thickness.STANDARD)
        {
          setPhotoChromaticGreyStandardPrice(improvements[i].price.toString())
        }
        if(improvements[i].id===Upgrades.PHOTOCHROMATIC_GREY && improvements[i].thickness_id===Thickness.THIN)
        {
          setPhotoChromaticGreyThinPrice(improvements[i].price.toString())
        }
        if(improvements[i].id===Upgrades.PHOTOCHROMATIC_GREY && improvements[i].thickness_id===Thickness.ULTRA_THIN)
        {
          setPhotoChromaticGreyUltraThinPrice(improvements[i].price.toString())
        }
      }
    }
  },[])

  useEffect(()=>{
    for(let i=0;i<product.lense_improvement.length;i++)
      {
        if(Number(product.lense_improvement[i].id)===Upgrades.FOR_COMPUTER && Number(product.lense_improvement[i].product_depth_id)===Thickness.STANDARD)
        {
          setComputerStandardPrice(product.lense_improvement[i].price.toString())
        }
        if(Number(product.lense_improvement[i].id)===Upgrades.FOR_COMPUTER && Number(product.lense_improvement[i].product_depth_id)===Thickness.THIN)
        {
          setComputerThinPrice(product.lense_improvement[i].price.toString())
        }
        if(Number(product.lense_improvement[i].id)===Upgrades.FOR_COMPUTER && Number(product.lense_improvement[i].product_depth_id)===Thickness.ULTRA_THIN)
        {
          setComputerUltraThinPrice(product.lense_improvement[i].price.toString())
        }
        if(Number(product.lense_improvement[i].id)===Upgrades.PHOTOCHROMATIC_BROWN && Number(product.lense_improvement[i].product_depth_id)===Thickness.STANDARD)
        {
          setPhotoChromaticBrownStandardPrice(product.lense_improvement[i].price.toString())
        }
        if(Number(product.lense_improvement[i].id)===Upgrades.PHOTOCHROMATIC_BROWN && Number(product.lense_improvement[i].product_depth_id)===Thickness.THIN)
        {
          setPhotoChromaticBrownThinPrice(product.lense_improvement[i].price.toString())
        }
        if(Number(product.lense_improvement[i].id)===Upgrades.PHOTOCHROMATIC_BROWN && Number(product.lense_improvement[i].product_depth_id)===Thickness.ULTRA_THIN)
        {
          setPhotoChromaticBrownUltraThinPrice(product.lense_improvement[i].price.toString())
        }
        if(Number(product.lense_improvement[i].id)===Upgrades.PHOTOCHROMATIC_GREY && Number(product.lense_improvement[i].product_depth_id)===Thickness.STANDARD)
        {
          setPhotoChromaticGreyStandardPrice(product.lense_improvement[i].price.toString())
        }
        if(Number(product.lense_improvement[i].id)===Upgrades.PHOTOCHROMATIC_GREY && Number(product.lense_improvement[i].product_depth_id)===Thickness.THIN)
        {
          setPhotoChromaticGreyThinPrice(product.lense_improvement[i].price.toString())
        }
        if(Number(product.lense_improvement[i].id)===Upgrades.PHOTOCHROMATIC_GREY && Number(product.lense_improvement[i].product_depth_id)===Thickness.ULTRA_THIN)
        {
          setPhotoChromaticGreyUltraThinPrice(product.lense_improvement[i].price.toString())
        }
      }
  },[])

  const computerChangeHandler = () => {
    setComputer(!computer);
  };
  const waterResistantChangeHandler = () => {
    setShockResistant(!shockResistant);
  };
  const photochromaticChangeHandler = () => {
    setPhotochromatic(!photochromatic);
  };
  const sunWithDioptersChangeHandler = () => {
    setSunWithDiopters(!sunWithDiopters);
  };
  const progressiveChangeHandler = () => {
    setProgressive(!progressive);
  };

  const computerStandardPriceChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if(Number(event.target.value)>=0)
      setComputerStandardPrice(event.target.value);
  };
  const shockResistantPriceChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if(Number(event.target.value)>=0)
      setShockResistantPrice(event.target.value);
  };
  const photoChromaticPriceChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if(Number(event.target.value)>=0)
      setPhotochromaticPrice(event.target.value);
  };
  const photoChromaticBrownPriceChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if(Number(event.target.value)>=0)
      setPhotoChromaticBrownStandardPrice(event.target.value);
  };
  const photoChromaticBrownPriceChangeHandlerThin = (event: React.ChangeEvent<HTMLInputElement>) => {
    if(Number(event.target.value)>=0)
      setPhotoChromaticBrownThinPrice(event.target.value);
  };
  const photoChromaticBrownPriceChangeHandlerUltraThin = (event: React.ChangeEvent<HTMLInputElement>) => {
    if(Number(event.target.value)>=0)
      setPhotoChromaticBrownUltraThinPrice(event.target.value);
  };
  const photoChromaticGreyPriceChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if(Number(event.target.value)>=0)
      setPhotoChromaticGreyStandardPrice(event.target.value);
  };
  const photoChromaticGreyPriceChangeHandlerThin = (event: React.ChangeEvent<HTMLInputElement>) => {
    if(Number(event.target.value)>=0)
      setPhotoChromaticGreyThinPrice(event.target.value);
  };
  const photoChromaticGreyPriceChangeHandlerUltraThin = (event: React.ChangeEvent<HTMLInputElement>) => {
    if(Number(event.target.value)>=0)
      setPhotoChromaticGreyUltraThinPrice(event.target.value);
  };
  const computerThinPriceChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if(Number(event.target.value)>=0)
      setComputerThinPrice(event.target.value);
  };
  const computerUltraThinPriceChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if(Number(event.target.value)>=0)
      setComputerUltraThinPrice(event.target.value);
  };
  const sunWithDioptersPriceChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if(Number(event.target.value)>=0)
      setSunWithDioptersPrice(event.target.value);
  };
  const progressivePriceChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if(Number(event.target.value)>=0)
      setProgressivePrice(event.target.value);
  };

  const filtered = (upgrades: LenseImprovement[]) => upgrades.filter((item=>{
    return item!==null && item.id!==INEXISTENT_ID
  }));

  useEffect(()=>{
    if((computer===true && computerStandardPrice!==''))
    {
      upgradesChoosen[0]={
        id: Upgrades.FOR_COMPUTER.toString(),
        price: computerStandardPrice,
        product_depth_id: 1,
      };
    }
    if((shockResistant===true && shockResistantPrice!==''))
    {
      upgradesChoosen[1]={
        id: Upgrades.SHOCK_RESISTANT.toString(),
        price: shockResistantPrice,
      };
    }
    if((photochromatic===true && photoChromaticPrice!==''))
    {
      upgradesChoosen[2]={
        id: Upgrades.PHOTOCHROMATIC.toString(),
        price: photoChromaticPrice,
      };
    }
    if((sunWithDiopters===true && sunWithDioptersPrice!==''))
    {
      upgradesChoosen[3]={
        id: Upgrades.FOR_SUN_WITH_DIOPTERS.toString(),
        price: sunWithDioptersPrice,
      };
    }
    if((progressive===true && progressivePrice!==''))
    {
      upgradesChoosen[4]={
        id: Upgrades.PROGRESSIVE.toString(),
        price: progressivePrice,
      };
    }
    if((photochromatic===true && photoChromaticBrownStandardPrice!==''))
    {
      upgradesChoosen[8]={
        id: Upgrades.PHOTOCHROMATIC_BROWN.toString(),
        price: photoChromaticBrownStandardPrice,
        product_depth_id: 1,
      };
    }
    if((photochromatic===true && photoChromaticGreyStandardPrice!==''))
    {
      upgradesChoosen[9]={
        id: Upgrades.PHOTOCHROMATIC_GREY.toString(),
        price: photoChromaticGreyStandardPrice,
        product_depth_id: 1,
      };
    }
    if((photochromatic===true && photoChromaticBrownThinPrice!==''))
    {
      upgradesChoosen[10]={
        id: Upgrades.PHOTOCHROMATIC_BROWN.toString(),
        price: photoChromaticBrownThinPrice,
        product_depth_id: 2,
      };
    }
    if((photochromatic===true && photoChromaticGreyThinPrice!==''))
    {
      upgradesChoosen[11]={
        id: Upgrades.PHOTOCHROMATIC_GREY.toString(),
        price: photoChromaticGreyThinPrice,
        product_depth_id: 2,
      };
    }
    if((photochromatic===true && photoChromaticBrownUltraThinPrice!==''))
    {
      upgradesChoosen[12]={
        id: Upgrades.PHOTOCHROMATIC_BROWN.toString(),
        price: photoChromaticBrownUltraThinPrice,
        product_depth_id: 3,
      };
    }
    if((photochromatic===true && photoChromaticGreyUltraThinPrice!==''))
    {
      upgradesChoosen[13]={
        id: Upgrades.PHOTOCHROMATIC_GREY.toString(),
        price: photoChromaticGreyUltraThinPrice,
        product_depth_id: 3,
      };
    }
    if((computer===true && computerThinPrice!==''))
    {
      upgradesChoosen[14]={
        id: Upgrades.FOR_COMPUTER.toString(),
        price: computerThinPrice,
        product_depth_id: 2,
      };
    }
    if((computer===true && computerUltraThinPrice!==''))
    {
      upgradesChoosen[15]={
        id: Upgrades.FOR_COMPUTER.toString(),
        price: computerUltraThinPrice,
        product_depth_id: 3,
      };
    }

    if(computer===false)
    {
      upgradesChoosen[0]={
        id: INEXISTENT_ID,
        price: INEXISTENT_VALUE,
      };
      upgradesChoosen[14]={
        id: INEXISTENT_ID,
        price: INEXISTENT_VALUE,
      };
      upgradesChoosen[15]={
        id: INEXISTENT_ID,
        price: INEXISTENT_VALUE,
      };
    }
    if(shockResistant===false)
    {
      upgradesChoosen[1]={
        id: INEXISTENT_ID,
        price: INEXISTENT_VALUE,
      };
    }
    if(photochromatic===false)
    {
      upgradesChoosen[2]={
        id: INEXISTENT_ID,
        price: INEXISTENT_VALUE,
      };
      upgradesChoosen[8]={
        id: INEXISTENT_ID,
        price: INEXISTENT_VALUE,
      };
      upgradesChoosen[9]={
        id: INEXISTENT_ID,
        price: INEXISTENT_VALUE,
      };
      upgradesChoosen[10]={
        id: INEXISTENT_ID,
        price: INEXISTENT_VALUE,
      };
      upgradesChoosen[11]={
        id: INEXISTENT_ID,
        price: INEXISTENT_VALUE,
      };
      upgradesChoosen[12]={
        id: INEXISTENT_ID,
        price: INEXISTENT_VALUE,
      };
      upgradesChoosen[13]={
        id: INEXISTENT_ID,
        price: INEXISTENT_VALUE,
      };
    }
    if(sunWithDiopters===false)
    {
      upgradesChoosen[3]={
        id: INEXISTENT_ID,
        price: INEXISTENT_VALUE,
      };
    }
    if(progressive===false)
    {
      upgradesChoosen[4]={
        id: INEXISTENT_ID,
        price: INEXISTENT_VALUE,
      };
    }
    // console.log(upgradesChoosen)
    setLenseImprovementProduct(filtered(upgradesChoosen));
  },[computer,shockResistant,photochromatic,sunWithDiopters,progressive,computerStandardPrice,shockResistantPrice,photoChromaticPrice,sunWithDioptersPrice,progressivePrice,photoChromaticBrownStandardPrice,photoChromaticGreyStandardPrice,photoChromaticBrownThinPrice,photoChromaticBrownUltraThinPrice,photoChromaticGreyThinPrice,photoChromaticGreyUltraThinPrice])

  return (
    <div>      
      <Typography
        className={clsx(classes.bold, classes.title)}
        color="textPrimary"
        variant={'body1'}
      >
        {t('AVAILABLE_ENHANCEMENTS')}
      </Typography>
      <div className={classes.textFields}>
        <div>
          <FormControlLabel
            control={
              <Checkbox
                className={classes.radio}
                name="computer"
                color="primary"
                onClick={computerChangeHandler}
                checked={computer}
              />
            }
            label={t('COMPUTER')}
          />
          {computer === true ? <Typography color="textPrimary" variant={'body1'}>
              {'1.5 index'}
            </Typography> : null}
          <TextField
            className={classes.input}
            type="number"
            placeholder={t('PRICE')}
            disabled={!computer}
            value={computerStandardPrice}
            onChange={computerStandardPriceChangeHandler}
            error={computerStandardPrice === '' && buttonPressed===true && computer}
          />
          {computer === true ? <div>
            <Typography color="textPrimary" variant={'body1'}>
              {'1.6 index'}
            </Typography>
            <TextField
              className={classes.input}
              type="number"
              placeholder={t('PRICE')}
              disabled={!computer}
              value={computerThinPrice}
              onChange={computerThinPriceChangeHandler}
              error={computerThinPrice === '' && buttonPressed===true && computer}
            />
            <Typography color="textPrimary" variant={'body1'}>
                {'1.67 index'}
            </Typography>
            <TextField
              className={classes.input}
              type="number"
              placeholder={t('PRICE')}
              disabled={!computer}
              value={computerUltraThinPrice}
              onChange={computerUltraThinPriceChangeHandler}
              error={computerUltraThinPrice === '' && buttonPressed===true && computer}
            />
          </div> : null }
        </div>
        {/* <div>
          <FormControlLabel
            control={
              <Checkbox
                onClick={waterResistantChangeHandler}
                className={classes.radio}
                name="shockRezist"
                color="primary"
                checked={shockResistant}
              />
            }
            label={t('SHOCK_RESISTANT')}
          />
          <TextField
            className={classes.input}
            type="number"
            placeholder={t('PRICE')}
            disabled={!shockResistant}
            value={shockResistantPrice}
            onChange={shockResistantPriceChangeHandler}
            error={shockResistantPrice === '' && buttonPressed===true && shockResistant}
          />
        </div> */}
        <div>
          <FormControlLabel
            control={
              <Checkbox
                onClick={photochromaticChangeHandler}
                className={classes.radio}
                name="photochrome"
                color="primary"
                checked={photochromatic}
              />
            }
            label={t('PHOTOCHROMATIC')}
          />
          {photochromatic === true ? <Typography color="textPrimary" variant={'body1'}>
              {t('BROWN') +' 1.50 index'}
            </Typography> : null}
          <TextField
            className={classes.input}
            type="number"
            placeholder={t('PRICE')}
            disabled={!photochromatic}
            value={photoChromaticBrownStandardPrice}
            onChange={photoChromaticBrownPriceChangeHandler}
            error={photoChromaticBrownStandardPrice === '' && buttonPressed===true && photochromatic}
          />
          {photochromatic === true ? <div>
            <Typography color="textPrimary" variant={'body1'}>
              {t('BROWN') + ' 1.6 index'}
            </Typography>
            <TextField
              className={classes.input}
              type="number"
              placeholder={t('PRICE')}
              disabled={!photochromatic}
              value={photoChromaticBrownThinPrice}
              onChange={photoChromaticBrownPriceChangeHandlerThin}
              error={photoChromaticBrownThinPrice === '' && buttonPressed===true && photochromatic}
            />
            <Typography color="textPrimary" variant={'body1'}>
                {t('BROWN') + ' 1.67 index'}
            </Typography>
            <TextField
              className={classes.input}
              type="number"
              placeholder={t('PRICE')}
              disabled={!photochromatic}
              value={photoChromaticBrownUltraThinPrice}
              onChange={photoChromaticBrownPriceChangeHandlerUltraThin}
              error={photoChromaticBrownUltraThinPrice === '' && buttonPressed===true && photochromatic}
            />
            <Typography color="textPrimary" variant={'body1'}>
                {t('GREY') + ' 1.5 index'}
            </Typography>
            <TextField
              className={classes.input}
              type="number"
              placeholder={t('PRICE')}
              disabled={!photochromatic}
              value={photoChromaticGreyStandardPrice}
              onChange={photoChromaticGreyPriceChangeHandler}
              error={photoChromaticGreyStandardPrice === '' && buttonPressed===true && photochromatic}
            />
            <Typography color="textPrimary" variant={'body1'}>
                {t('GREY') + ' 1.6 index'}
            </Typography>
            <TextField
              className={classes.input}
              type="number"
              placeholder={t('PRICE')}
              disabled={!photochromatic}
              value={photoChromaticGreyThinPrice}
              onChange={photoChromaticGreyPriceChangeHandlerThin}
              error={photoChromaticGreyThinPrice === '' && buttonPressed===true && photochromatic}
            />
            <Typography color="textPrimary" variant={'body1'}>
                {t('GREY') + ' 1.67 index'}
            </Typography>
            <TextField
              className={classes.input}
              type="number"
              placeholder={t('PRICE')}
              disabled={!photochromatic}
              value={photoChromaticGreyUltraThinPrice}
              onChange={photoChromaticGreyPriceChangeHandlerUltraThin}
              error={photoChromaticGreyUltraThinPrice === '' && buttonPressed===true && photochromatic}
            />
          </div> : null }
        </div>
        {/* <div>
          <FormControlLabel
            control={
              <Checkbox
                onClick={sunWithDioptersChangeHandler}
                className={classes.radio}
                name="sunglassesWithDiopter"
                color="primary"
                checked={sunWithDiopters}
              />
            }
            label={t('SUN_DIOPTERS')}
          />
          <TextField
            className={classes.input}
            type="number"
            placeholder={t('PRICE')}
            disabled={!sunWithDiopters}
            value={sunWithDioptersPrice}
            onChange={sunWithDioptersPriceChangeHandler}
            error={sunWithDioptersPrice === '' && buttonPressed===true && sunWithDiopters}
          />
        </div> */}
        {/* <div>
          <FormControlLabel
            control={
              <Checkbox
                onClick={progressiveChangeHandler}
                className={classes.radio}
                name="progressive"
                color="primary"
                checked={progressive}
              />
            }
            label={t('PROGRESSIVE')}
          />
          <TextField
            className={classes.input}
            type="number"
            placeholder={t('PRICE')}
            disabled={!progressive}
            value={progressivePrice}
            onChange={progressivePriceChangeHandler}
            error={progressivePrice === '' && buttonPressed===true && progressive}
          />
        </div> */}
      </div>
      <div className={classes.info}>
        <Typography variant="body1" color="textPrimary">
          {t('ENHANCEMENTS_MANDATORY')}
        </Typography>
      </div>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  bold: {
    fontWeight: 700,
  },
  info: {
    padding: theme.spacing(3),
    background: theme.palette.primary.light,
  },
  textFields: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit,minmax(170px,0.2fr))',
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    background: theme.palette.primary.light,
    gridGap: theme.spacing(3),
  },
  title: {
    paddingTop: theme.spacing(3.5),
    paddingBottom: theme.spacing(2),
  },
  radio: {
    color: theme.palette.primary.dark,
  },
  checkbox: {
    margin: '0',
  },
  prescription: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    padding: theme.spacing(3),
    background: theme.palette.primary.light,
  },
  thickness: {
    display: 'grid',
    gridTemplateColumns: '0.19fr 0.19fr 0.38fr 0.27fr',
    padding: theme.spacing(3),
    background: theme.palette.primary.light,
  },
  prescriptionValuesContainer: {
    display: 'grid',
    gridTemplateColumns: '0.1fr 0.2fr 0.1fr 0.2fr 0.2fr 0.2fr',
    alignItems: 'start',
    columnGap: theme.spacing(0.5),
  },
  scrollSelect: {
    backgroundColor: theme.palette.background.default,
  },
  prescriptionValues: {
    display: 'grid',
    gridTemplateColumns: '0.2fr 0.8fr',
  },
  selectOption: {
    justifySelf: 'center',
  },
  input: {
    display: 'grid',
    backgroundColor: theme.palette.background.default,
    borderRadius: '5px',
    height: '28px',
    width: '150px',
    '& input[type=number]': {
      '-moz-appearance': 'textfield'
    },
    '& input[type=number]::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0
    },
    '& input[type=number]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0
    }
  },
}));

export default UpgradesPrescriptionThickness;
