import { cloneDeep } from "lodash";
import { SnackbarVariant } from "../utils/constants";
import { store } from "./store";
import { ProductCreate, SET_CREATE_PRODUCT, SET_PRODUCT_BRAND, SET_PRODUCT_CHARACTERISTICS, SET_PRODUCT_DESCRIPTION, SET_PRODUCT_DISCOUNT, SET_PRODUCT_FACE_SHAPE, SET_PRODUCT_FOR, SET_PRODUCT_FRAME_MATERIALS, SET_PRODUCT_FRAME_SHAPE, SET_PRODUCT_FRAME_TYPE, SET_PRODUCT_FULLPRICE, SET_PRODUCT_SIZE, SET_PRODUCT_LENS_TYPES, SET_PRODUCT_MODEL, SET_PRODUCT_STOCK, SizeProduct, SET_PRODUCT_FRAME_COLOR, SET_PRODUCT_LENSE_COLOR, LenseImprovement, SET_PRODUCT_LENSE_IMPROVEMENTS, LenseThickness, SET_PRODUCT_LENSE_THICKNESS, LenseType, SET_PRODUCT_LENSE_TYPE, SET_PRODUCT_CATEGORY, SET_PRODUCT_IMAGE3D, SET_PRODUCT_IMAGES, SET_PRODUCT_DIOPTERS, LenseDiopter, SET_PRODUCT_PERIOD_OF_USE, SET_PRODUCT_METHOD_OF_WEARING, SET_ORDER_BY, SET_ORDER_PRODUCT_CATEGORY, RESET_PRODUCT, SHOW_SNACKBAR, HIDE_SNACKBAR, SET_BUTTON_PRESSED, SET_PRODUCT_CODE } from "./types";

export const setCreateProduct = (product: ProductCreate) => {
    store.dispatch({
      type: SET_CREATE_PRODUCT,
      meta: cloneDeep(product),
    });
  };

export const setBrandProduct = (brandId: string) => {
    store.dispatch({
      type: SET_PRODUCT_BRAND,
      meta: brandId,
    });
  };

export const setModelProduct = (model: string) => {
    store.dispatch({
      type: SET_PRODUCT_MODEL,
      meta: model,
    });
  };

export const setStockProduct = (stock: string) => {
    store.dispatch({
      type: SET_PRODUCT_STOCK,
      meta: stock,
    });
  };

export const setFullPriceProduct = (fullprice: string) => {
    store.dispatch({
      type: SET_PRODUCT_FULLPRICE,
      meta: fullprice,
    });
  };

export const setDiscountProduct = (discount: string) => {
    store.dispatch({
      type: SET_PRODUCT_DISCOUNT,
      meta: discount,
    });
  };

export const setDescriptionProduct = (description: string) => {
    store.dispatch({
      type: SET_PRODUCT_DESCRIPTION,
      meta: description,
    });
  };

export const setFaceShapeProduct = (faceShapeId: string) => {
    store.dispatch({
      type: SET_PRODUCT_FACE_SHAPE,
      meta: faceShapeId,
    });
  };

export const setFrameShapeProduct = (frameShapeId: string) => {
    store.dispatch({
      type: SET_PRODUCT_FRAME_SHAPE,
      meta: frameShapeId,
    });
  };

export const setFrameTypeProduct = (frameTypeId: string) => {
    store.dispatch({
      type: SET_PRODUCT_FRAME_TYPE,
      meta: frameTypeId,
    });
  };

export const setFrameMaterialsProduct = (frameMaterialId: string) => {
    store.dispatch({
      type: SET_PRODUCT_FRAME_MATERIALS,
      meta: frameMaterialId,
    });
  };

export const setLensTypesProduct = (lensTypeId: string) => {
    store.dispatch({
      type: SET_PRODUCT_LENS_TYPES,
      meta: lensTypeId,
    });
  };

export const setCharacteristicsProduct = (characteristicsId: string) => {
    store.dispatch({
      type: SET_PRODUCT_CHARACTERISTICS,
      meta: characteristicsId,
    });
  };

export const setForProduct = (forId: string) => {
    store.dispatch({
      type: SET_PRODUCT_FOR,
      meta: forId,
    });
  }; 

export const setSizeProduct = (size: SizeProduct) => {
    store.dispatch({
      type: SET_PRODUCT_SIZE,
      meta: cloneDeep(size),
    });
  }; 

export const setFrameColorProduct = (frameColorId: string[]) => {
    store.dispatch({
      type: SET_PRODUCT_FRAME_COLOR,
      meta: frameColorId,
    });
  };

export const setLensesColorProduct = (lensesColorId: string) => {
    store.dispatch({
      type: SET_PRODUCT_LENSE_COLOR,
      meta: lensesColorId,
    });
  };


export const setLenseImprovementProduct = (lenseImprovement: LenseImprovement[]) => {
    store.dispatch({
      type: SET_PRODUCT_LENSE_IMPROVEMENTS,
      meta: lenseImprovement,
    });
  };

export const setLenseThicknessProduct = (lenseThickness: LenseThickness[]) => {
    store.dispatch({
      type: SET_PRODUCT_LENSE_THICKNESS,
      meta: lenseThickness,
    });
  };

export const setLenseTypeProduct = (lenseType: LenseType[]) => {
    store.dispatch({
      type: SET_PRODUCT_LENSE_TYPE,
      meta: lenseType,
    });
  };

export const setCategoryProduct = (categoryId: string) => {
    store.dispatch({
      type: SET_PRODUCT_CATEGORY,
      meta: categoryId,
    });
  };

export const setImage3DProduct = (image3DId: string) => {
    store.dispatch({
      type: SET_PRODUCT_IMAGE3D,
      meta: image3DId,
    });
  };

export const setImagesProduct = (imagesDId: string[]) => {
    store.dispatch({
      type: SET_PRODUCT_IMAGES,
      meta: imagesDId,
    });
  };

export const setDioptersProduct = (lenseDiopter: LenseDiopter[]) => {
    store.dispatch({
      type: SET_PRODUCT_DIOPTERS,
      meta: lenseDiopter,
    });
  };

export const setPeriodOfUseProduct = (periodId: string) => {
    store.dispatch({
      type: SET_PRODUCT_PERIOD_OF_USE,
      meta: periodId,
    });
  };

export const setMethodOfWearingProduct = (methodId: string) => {
    store.dispatch({
      type: SET_PRODUCT_METHOD_OF_WEARING,
      meta: methodId,
    });
  };

export const setOrderBy = (orderBy: string) => {
    store.dispatch({
      type: SET_ORDER_BY,
      meta: orderBy,
    });
  };

export const setOrderProductCategory = (categoryId: string) => {
    store.dispatch({
      type: SET_ORDER_PRODUCT_CATEGORY,
      meta: categoryId,
    });
  };

export const resetProduct = (initialProduct: ProductCreate) => {
    store.dispatch({
      type: RESET_PRODUCT,
      meta: initialProduct,
    });
  };

export const showSnack = (message: string, variant: SnackbarVariant) => {
    store.dispatch({
      type: SHOW_SNACKBAR,
      meta: {
        message,
        variant,
      },
    });
  };
  
export const hideSnack = () => {
    store.dispatch({
      type: HIDE_SNACKBAR,
    });
  };

export const setButtonPressed = (pressedStatus: boolean) => {
    store.dispatch({
      type: SET_BUTTON_PRESSED,
      meta: pressedStatus,
    });
  };

  export const setCodeProduct = (code: string) => {
    store.dispatch({
      type: SET_PRODUCT_CODE,
      meta: code,
    });
  };