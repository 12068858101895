import { Dialog, makeStyles, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { actionType, PickupLocationGet, PickupLocationPost } from '../../utils/constants';
import PickupLocationDialog from './PickupDialog/PickupDialog';

interface Props {
  data: PickupLocationGet;
}

const PickupLocationData: FC<Props> = props => {
  const classes = useStyles();
  const { data } = props;
  const [locationDialogOpen, setLocationDialogOpen] = React.useState<boolean>(false);

  const { t } = useTranslation();

  const updateLatAndLong = async () => {
    const newLocation: PickupLocationPost= {
        email: "",
        name: "",
        county_id: 1,
        county_name: "",
        locality_name: "",
        street_name: "",
        building_number: "",
        address_text: "",
        contact_person: "",
        phone_number: "",
        postal_code: "",
        street_id: 0,
        locality_id: 1,
        location_id: 1,
        automatic_eod: "",
        country_id: 1,
        company_id: 1
    };
      try {
        // await updateLocation(data.id, newLocation);
        // update();
      } catch (e) {
        console.error('Nu am putut actualiza coordonatele!');
      }

  };

  const handleUpdateLocation = () => {
    setLocationDialogOpen(true);
  };

  const closeLocationDialog = () => {
    setLocationDialogOpen(false);
  };

  const handleDeleteLocation = async () => {
    // await deleteLocation(data.id);
    // update();
  };

  return (
    <div className={classes.root}>
      <div className={classes.topPart}>
        <div className={classes.info}>
          <Typography className={classes.bold} color="textPrimary" variant={'h4'}>
            {t('LOCATION_DETAILS')}
          </Typography>
          <div className={classes.infoColumns}>
            <div className={classes.columns}>
              <div className={classes.rows}>
                <Typography className={classes.grey} color="textPrimary" variant={'subtitle2'}>
                  {t('COUNTY')}
                </Typography>
                <Typography className={classes.bold} color="textPrimary" variant={'subtitle2'}>
                  {data.county_name}
                </Typography>
              </div>
              <div className={classes.rows}>
                <Typography className={classes.grey} color="textPrimary" variant={'subtitle2'}>
                  {t('TOWN')}
                </Typography>
                <Typography className={classes.bold} color="textPrimary" variant={'subtitle2'}>
                  {data.locality_name}
                </Typography>
              </div>
              <div className={classes.rows}>
                <Typography className={classes.grey} color="textPrimary" variant={'subtitle2'}>
                  {t('STREET')}
                </Typography>
                <Typography className={classes.bold} color="textPrimary" variant={'subtitle2'}>
                  {data.street_name}
                </Typography>
              </div>
            </div>
            <div className={classes.columns}>
              <div className={classes.rows}>
                <Typography className={classes.grey} color="textPrimary" variant={'subtitle2'}>
                  {t('NUMBER')}
                </Typography>
                <Typography className={classes.bold} color="textPrimary" variant={'subtitle2'}>
                  {data.building_number}
                </Typography>
              </div>
              <div className={classes.rows}>
                <Typography className={classes.grey} color="textPrimary" variant={'subtitle2'}>
                  {t('ZIP')}
                </Typography>
                <Typography className={classes.bold} color="textPrimary" variant={'subtitle2'}>
                  {data.postal_code}
                </Typography>
              </div>
              <div className={classes.rows}>
                <Typography className={classes.grey} color="textPrimary" variant={'subtitle2'}>
                  {t('CONTACT_PERSON')}
                </Typography>
                <Typography className={classes.bold} color="textPrimary" variant={'subtitle2'}>
                  {data.contact_person}
                </Typography>
              </div>
              <div className={classes.rows}>
                <Typography className={classes.grey} color="textPrimary" variant={'subtitle2'}>
                  {t('PHONE_NUMBER')}
                </Typography>
                <Typography className={classes.bold} color="textPrimary" variant={'subtitle2'}>
                  {data.phone_number}
                </Typography>
              </div>
              <div className={classes.rows}>
                <Typography className={classes.grey} color="textPrimary" variant={'subtitle2'}>
                  {t('EMAIL')}
                </Typography>
                <Typography className={classes.bold} color="textPrimary" variant={'subtitle2'}>
                  {data.email}
                </Typography>
              </div>
              <div className={classes.rows}>
                <Typography className={classes.grey} color="textPrimary" variant={'subtitle2'}>
                  {t('NAME')}
                </Typography>
                <Typography className={classes.bold} color="textPrimary" variant={'subtitle2'}>
                  {data.name}
                </Typography>
              </div>
              <div className={classes.rows}>
                <Typography className={classes.grey} color="textPrimary" variant={'subtitle2'}>
                  {t('END_OF_DAY')}
                </Typography>
                <Typography className={classes.bold} color="textPrimary" variant={'subtitle2'}>
                  {data.automatic_eod}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.buttons}>
        <Typography
          color="primary"
          className={classes.pointer}
          variant={'subtitle2'}
          onClick={handleUpdateLocation}
        >
          {t('EDIT_LOCATION')}
        </Typography>
        {/* <div className={classes.end}>
          <Typography
            color="secondary"
            className={classes.pointer}
            variant={'subtitle2'}
            onClick={handleDeleteLocation}
          >
            {t('DELETE_LOCATION')}
          </Typography>
        </div> */}
      </div>
      <Dialog
        onClose={closeLocationDialog}
        open={locationDialogOpen}
        fullWidth={true}
        maxWidth="md"
      >
        <PickupLocationDialog
          onClose={closeLocationDialog}
          data={data}
          action={actionType.update}
        />
      </Dialog>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: '0px 2px 15px rgba(0, 0, 0, 0.1)',
    marginRight: theme.spacing(6),
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  topPart: {
    display: 'grid',
    gridTemplateColumns: '1fr 0.25fr',
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
  bold: {
    fontWeight: 700,
  },
  info: {
    display: 'grid',
  },
  map: {
    width: '258px',
    height: '159px',
    position: 'relative',
  },
  infoColumns: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 0.5fr))',
  },
  columns: {
    display: 'grid',
  },
  rows: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(100px, 0.5fr))',
  },
  grey: {
    color: 'grey',
  },
  buttons: {
    textAlign: 'center',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
    gridGap: theme.spacing(5),
  },
  pointer: {
    cursor: 'pointer',
  },
  end: {
    justifyContent: 'end',
  },
}));

export default PickupLocationData;
