import { Button, Dialog, makeStyles } from '@material-ui/core';
import { FC, useEffect } from 'react';
import React from 'react';
import Header from '../../components/Header';
import { getPickupPoint } from '../../state/commands/location';
import { actionType, Location, PickupLocationGet } from '../../utils/constants';
import { useTranslation } from 'react-i18next';
import PickupLocationDialog from '../../components/LocationData/PickupDialog/PickupDialog';
import PickupLocationData from '../../components/LocationData/PickupLocationData';

const AccountPickupLocationContainer: FC<{}> = () => {
  const classes = useStyles();
  const displayLocation = Number(sessionStorage.getItem('pickupPoint'));
  const [locationDialogOpen, setLocationDialogOpen] = React.useState<boolean>(false);
  const location: Location = {
    id: 0,
    country: '',
    state: '',
    city: '',
    street: '',
    street_number: '',
    zipcode: '',
    latitude: '',
    longitude: '',
    description: '',
    phone: '',
    scheduler_customer_server: '',
    main: '',
    email: '',
  };
  const emptyPickup = {
    id: 0,
    created_at: "",
    updated_at: "",
    automatic_eod: "",
    location_id: 0,
    name: "",
    county_id: 0,
    county_name: "",
    locality_id: 0,
    locality_name: "",
    street_id: 0,
    street_name: "",
    building_number: "",
    address_text: "",
    contact_person: "",
    phone_number: "",
    email: "",
    cod_postal: "",
    postal_code: "",
    country_id: 0,
    company_id: 0,
    user_id: 0,
    cargus_id: ""};
  const [pickupPoints, setPickupPoints] = React.useState<PickupLocationGet[]>([
      {
    id: 0,
    created_at: "",
    updated_at: "",
    automatic_eod: "",
    location_id: 0,
    name: "",
    county_id: 0,
    county_name: "",
    locality_id: 0,
    locality_name: "",
    street_id: 0,
    street_name: "",
    building_number: "",
    address_text: "",
    contact_person: "",
    phone_number: "",
    email: "",
    cod_postal: "",
    postal_code: "",
    country_id: 0,
    company_id: 0,
    user_id: 0,
    cargus_id: ""}]);
  const { t } = useTranslation();

  useEffect(() => {
    getUserLocation();
  }, []);

  const getUserLocation = async () => {
    try {
      const pickupPoint = await getPickupPoint();
      setPickupPoints(pickupPoint);
    } catch (e) {
      console.error('Nu am putut incarca locatia de ridicare!');
    }
  };

  const openLocationModal = () => {
    setLocationDialogOpen(true);
  };
  const closeLocationModal = () => {
    setLocationDialogOpen(false);
  };

  const locations = 
      <div key={pickupPoints.length!==0 ? pickupPoints[pickupPoints.length-1].address_text: (Math.random() + 1).toString(36).substring(7)}>
        <PickupLocationData data={pickupPoints.length!==0 ? pickupPoints[pickupPoints.length-1]: emptyPickup} />
      </div>;
  

  const locationContainer = (
    <div className={classes.root}>
      <Header tabName={t('PICKUP_LOCATION')} />
      {displayLocation!==0 ? locations : null}
      {pickupPoints.length<1 ? <div className={classes.addButton}>
          {/* <= !== */}
        <Button onClick={openLocationModal} size="large" variant="contained" color="primary">
          {t('ADD_LOCATION')}
        </Button>
      </div> : null}
    </div>
  );

  return (
    <div className={classes.root}>
      {locationContainer}
      <Dialog
        onClose={closeLocationModal}
        open={locationDialogOpen}
        fullWidth={true}
        maxWidth="md"
      >
        <PickupLocationDialog
          onClose={closeLocationModal}
          data={emptyPickup}
          action={actionType.insert}
        />
      </Dialog>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  header: {
    display: 'grid',
    gridTemplateColumns: '1fr 0.3fr',
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    boxShadow: '0px 2px 15px rgba(0, 0, 0, 0.1)',
  },
  profile: {
    display: 'grid',
    gridTemplateColumns: '150px  1fr',
  },
  lightBold: {
    fontWeight: 600,
  },
  addButton: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
  },
}));

export default AccountPickupLocationContainer;
