import { makeStyles } from '@material-ui/core';
import React from 'react';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Route, Router, Switch } from 'react-router';
import { SideMenu } from './components/SideMenu';
import { SnackbarMessage } from './components/Snackbar/SnackbarMessage';
import AccountDataContainer from './containers/Account/AccountDataContainer';
import AccountLocationContainer from './containers/Account/AccountLocationContainer';
import AccountPickupLocationContainer from './containers/Account/AccountPickupLocationContainer';
import AddComputerglassesContainer from './containers/AddProduct/AddComputerglassesContainer';
import AddCosmeticLensesContainer from './containers/AddProduct/AddCosmeticLensesContainer';
import AddEyeglassesContainer from './containers/AddProduct/AddEyeglassesContainer';
import AddEyesightLensesContainer from './containers/AddProduct/AddEyesightLensesContainer';
import AddOthersContainer from './containers/AddProduct/AddOthersContainer';
import AddSunglassesContainer from './containers/AddProduct/AddSunglassesContainer';
import OrdersContainer from './containers/Orders/OrdersContainer';
import OrdersDetailsContainer from './containers/Orders/OrdersDetailsContainer';
import EditProduct from './containers/ProductsManagement/EditProduct';
import ProductsDetailsContainer from './containers/ProductsManagement/ProductDetailsContainer';
import ProductsManagementContainer from './containers/ProductsManagement/ProductsManagementContainer';
import { hideSnack } from './state/actions';
import { InitialState } from './state/types';
import { PATHS } from './utils/appPaths';
import browserHistory from './utils/browserhistory';

const AppContainer: FC<{}> = () => {
  const classes = useStyles();
  const snackbar = useSelector((state: InitialState) => state.snackbar);

  const handleCloseSnackbar = () => {
    hideSnack();
  };

  return (
    <div className={classes.root}>
      <SideMenu />
      <Router history={browserHistory}>
        <Switch>
          <Route path={PATHS.APP.PRODUCTS_MANAGEMENT.EDIT_PRODUCT} component={EditProduct} />
          <Route path={PATHS.APP.ORDERS.DETAILS} component={OrdersDetailsContainer} />
          <Route path={PATHS.APP.ORDERS.ROOT} component={OrdersContainer} />
          <Route path={PATHS.APP.ACCOUNT.PICKUP_LOCATION} component={AccountPickupLocationContainer} />
          <Route path={PATHS.APP.ACCOUNT.LOCATION} component={AccountLocationContainer} />
          <Route path={PATHS.APP.ACCOUNT.ROOT} component={AccountDataContainer} />
          <Route path={PATHS.APP.ADD_PRODUCT.SUNGLASSES} component={AddSunglassesContainer} />
          <Route
            path={PATHS.APP.ADD_PRODUCT.COMPUTER_GLASSES}
            component={AddComputerglassesContainer}
          />
          <Route
            path={PATHS.APP.ADD_PRODUCT.EYESIGHT_LENSES}
            component={AddEyesightLensesContainer}
          />
          <Route
            path={PATHS.APP.ADD_PRODUCT.COSMETIC_LENSES}
            component={AddCosmeticLensesContainer}
          />
          <Route
            path={PATHS.APP.ADD_PRODUCT.OTHERS}
            component={AddOthersContainer}
          />
          <Route
            path={PATHS.APP.PRODUCTS_MANAGEMENT.DETAILS}
            component={ProductsDetailsContainer}
          />
          <Route
            path={PATHS.APP.PRODUCTS_MANAGEMENT.ROOT}
            component={ProductsManagementContainer}
          />
          <Route path={PATHS.APP.ROOT} component={AddEyeglassesContainer} />
        </Switch>
      </Router>
      <SnackbarMessage
        onClose={handleCloseSnackbar}
        message={snackbar.message}
        variant={snackbar.variant}
        open={snackbar.open}
      />
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    minHeight: '100vh',
  },
}));

export default AppContainer;
