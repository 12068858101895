import { makeStyles, MenuItem, TextField, Typography } from '@material-ui/core';
// import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { setForProduct, setFrameMaterialsProduct, setFrameShapeProduct, setFrameTypeProduct, setLensTypesProduct } from '../../state/actions';
import { InitialState, ProductShow } from '../../state/types';
import { Taxonomy } from '../../utils/constants';
import SizeSet from './SizeSet';

interface Props {
  taxonomies: {
    [`face-shape`]: Taxonomy[];
    [`frame-shape`]: Taxonomy[];
    [`frame-type`]: Taxonomy[];
    [`frame-materials`]: Taxonomy[];
    [`lens-types`]: Taxonomy[];
    characteristics: Taxonomy[];
    for: Taxonomy[];
  };
  size: {
    width: string[];
    length: string[];
    bridge: string[];
  };
  item?: ProductShow;
  category?: string;
}

const ShapeAndSize: FC<Props> = props => {
  const { taxonomies, size,item,category } = props;
  const classes = useStyles();
  const buttonPressed = useSelector((state: InitialState) => state.buttonPressed);
  const [sizesCount] = useState<number>(1);
  const [frameShape, setFrameShape] = useState<string>(typeof item !== 'undefined' ? item.details['Frame Shape']?.id.toString():'');
  const [frameType, setFrameType] = useState<string>(typeof item !== 'undefined' ? item.details['Frame type']?.id.toString():'');
  const [frameMaterials, setFrameMaterials] = useState<string>(typeof item !== 'undefined' ? item.details['Frame materials']?.id.toString():'');
  const [forOption, setForOption] = useState<string>(typeof item !== 'undefined' ? item.details.For?.id.toString():'');

  const frameShapeChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFrameShape(event.target.value);
    setFrameShapeProduct(event.target.value);
  };
  const frameTypeChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFrameType(event.target.value);
    setFrameTypeProduct(event.target.value);
  };
  const frameMaterialsChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFrameMaterials(event.target.value);
    setFrameMaterialsProduct(event.target.value);
  };
  const forChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForOption(event.target.value);
    setForProduct(event.target.value);
  };
  
  const [lensTypes, setLensTypes] = useState<string>(typeof item !== 'undefined' ? item.details['Lens types']?.id.toString():'');
  const lensTypesChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLensTypes(event.target.value);
    setLensTypesProduct(event.target.value);
  };

  const addNewSizes = () => {
    const sizes = [];
    for (let i = 0; i < sizesCount; i++) {
      sizes.push(<SizeSet key={i} size={size} item={item} />);
    }
    return sizes;
  };

  const addLensTypes = () => {
    const lensTypes = [];
    for(let i=0;i<taxonomies![`lens-types`].length;i++)
    {
      if(category === 'sunglasses')
      {
        if(taxonomies![`lens-types`][i].name === 'Gradient' || taxonomies![`lens-types`][i].name === 'Polarized' || taxonomies![`lens-types`][i].name === 'Mirror' || taxonomies![`lens-types`][i].name === 'Uniform color')
        {
          lensTypes.push(<MenuItem key={i} value={taxonomies![`lens-types`][i].taxonomy_relationship_id}>
          {taxonomies![`lens-types`][i].name}
           </MenuItem>)
        }
      }
     else if(category === 'computer')
      {
        if(taxonomies![`lens-types`][i].name === 'Blue light filter')
        {
          lensTypes.push(<MenuItem key={i} value={taxonomies![`lens-types`][i].taxonomy_relationship_id}>
          {taxonomies![`lens-types`][i].name}
           </MenuItem>)
        }
      }
      else{
        lensTypes.push(<MenuItem key={i} value={taxonomies![`lens-types`][i].taxonomy_relationship_id}>
          {taxonomies![`lens-types`][i].name}
           </MenuItem>)
      }
    }
    return lensTypes;
  };

  const addForTypes = () => {
    const forTypes = [];
    for(let i=0;i<taxonomies!.for.length;i++)
    {
        if(taxonomies!.for[i].name === 'Women' || taxonomies!.for[i].name === 'Men' || taxonomies!.for[i].name === 'Unisex' || taxonomies!.for[i].name === 'Children')
        {
          forTypes.push(<MenuItem key={i} value={taxonomies!.for[i].taxonomy_relationship_id}>
          {taxonomies!.for[i].name}
           </MenuItem>)
        }
    }
    return forTypes;
  };

  useEffect(()=>{
    if(typeof item !== 'undefined')
    {
      setFrameShapeProduct(frameShape);
      setFrameTypeProduct(frameType);
      setFrameMaterialsProduct(frameMaterials);
      setForProduct(forOption);
      setLensTypesProduct(lensTypes);
    }
  },[])
  const { t } = useTranslation();

  return (
  <div className={classes.productInfo}>      
    <div className={classes.shape}>
      <Typography color="textPrimary" variant={'body1'}>
        {t('FRAME_SHAPE')}*
      </Typography>
      <Typography color="textPrimary" variant={'body1'}>
        {t('FRAME_TYPE')}*
      </Typography>
      <Typography color="textPrimary" variant={'body1'}>
        {t('FRAME_MATERIALS')}*
      </Typography>
      <TextField
        className={classes.input}
        select={true}
        value={frameShape}
        onChange={frameShapeChangeHandler}
        error={frameShape === '' && buttonPressed===true}
      >
        {taxonomies[`frame-shape`].map((option, index) => (
          <MenuItem key={index} value={option.taxonomy_relationship_id}>
            {option.name}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        className={classes.input}
        select={true}
        value={frameType}
        onChange={frameTypeChangeHandler}
        error={frameType === '' && buttonPressed===true}
      >
        {taxonomies[`frame-type`].map((option, index) => (
          <MenuItem key={index} value={option.taxonomy_relationship_id}>
            {option.name}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        className={classes.input}
        select={true}
        value={frameMaterials}
        onChange={frameMaterialsChangeHandler}
        error={frameMaterials === '' && buttonPressed===true}
      >
        {taxonomies[`frame-materials`].map((option, index) => (
          <MenuItem key={index} value={option.taxonomy_relationship_id}>
            {option.name}
          </MenuItem>
        ))}
      </TextField>
      <Typography color="textPrimary" variant={'body1'}>
        {t('FOR')}*
      </Typography>
      {category === 'sunglasses' || category === 'computer' ?
      <Typography color="textPrimary" variant={'body1'}>
      {t('LENS_TYPES')}*
      </Typography> : <div/> }
      <div/>
      <TextField
        className={classes.input}
        select={true}
        value={forOption}
        onChange={forChangeHandler}
        error={forOption === '' && buttonPressed===true}
      >
        {/* {taxonomies.for.map((option, index) => (
          <MenuItem key={index} value={option.taxonomy_relationship_id}>
            {option.name}
          </MenuItem>
        ))} */}
        {addForTypes()}
      </TextField>
      {category === 'sunglasses' || category === 'computer' ? 
      <TextField
      className={classes.input}
      select={true}
      value={lensTypes}
      onChange={lensTypesChangeHandler}
      error={lensTypes === '' && buttonPressed===true}
    >
      {/* {taxonomies![`lens-types`].map((option, index) => (
        <MenuItem key={index} value={option.taxonomy_relationship_id}>
          {option.name}
        </MenuItem>
      ))} */}
      {addLensTypes()}
    </TextField> : <div />}
      
      <div />
    </div>
    <div className={classes.whiteLine} />
    <Typography className={classes.size} color="textPrimary" variant={'body1'}>
      {t('SIZE')}
    </Typography>

    <div className={classes.shape}>
      {addNewSizes()}
    </div>
    <div className={classes.whiteLine} />
  </div>
  );
};

const useStyles = makeStyles(theme => ({
  bold: {
    fontWeight: 700,
  },
  productInfo: {
    padding: `0px ${theme.spacing(3)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
    background: theme.palette.primary.light,
  },
  shape: {
    display: 'grid',
    gridTemplateColumns: 'minmax(100px, 0.33fr) minmax(100px, 0.33fr) minmax(100px, 0.33fr)',
    columnGap: theme.spacing(4),
  },
  input: {
    display: 'grid',
    backgroundColor: theme.palette.background.default,
    borderRadius: '5px',
    height: '34px',
    marginBottom: theme.spacing(3),
  },
  whiteLine: {
    height: '2px',
    backgroundColor: theme.palette.background.default,
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4),
  },
  checkbox: {
    margin: '0',
  },
  size: {
    paddingBottom: theme.spacing(2.5),
  },
  hover: {
    cursor: 'pointer',
  },
}));

export default ShapeAndSize;
