import { Button, InputAdornment, makeStyles, TextField, Typography } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { Autocomplete, AutocompleteRenderInputParams } from '@material-ui/lab';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Filters, { ParentPage } from '../../components/Filters';
import Header from '../../components/Header';
import { Status } from '../../components/OrderManagement/OrderStatusDialog';
import { setOrderBy, setOrderProductCategory, showSnack } from '../../state/actions';
import { getAllOrders, searchOrder } from '../../state/commands/order';
import { InitialState, OrdersData } from '../../state/types';
import { PATHS } from '../../utils/appPaths';
import browserHistory from '../../utils/browserhistory';
import { MetaLinks, OrderItem, OrdersDetails, ProductCategory, SnackbarVariant } from '../../utils/constants';
import MenuItemDropDownOrders from './MenuItemDropDownOrders';

const OrdersContainer: FC<{}> = () => {
  const classes = useStyles();

  const [searchTerm,setSearchTerm] = useState<string>('');
  const [searchOptions] = useState<string[]>([]);
  const [ordersData,setOrdersData] = useState<OrdersData[]>();
  const orderBy = useSelector((state: InitialState) => state.orderBy);
  const productCategory = useSelector((state: InitialState) => state.productCategory);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [page,setPage] = useState<number>(1);
  const { t } = useTranslation();

  useEffect(()=>{
    getOrders();
  },[])

  useEffect(()=>{
    getOrders();
  },[orderBy,productCategory,page])

  const getOrders = async () => {
    try {
      const response = await getAllOrders(productCategory,orderBy,page);
      setOrdersData(response.data);
    } catch(e) {
      showSnack(e.message, SnackbarVariant.error);
    }
  };

  useEffect(()=>{
    generateItems();
  },[ordersData])

  const generateItems = () => {
    if(typeof ordersData!== 'undefined')
    {
      let localItems: OrdersDetails[] = [];
      for(let i=0;i<ordersData.length;i++)
      {
        let aux = {
          product: 'Order',
          nrOrder: '#'+ordersData[i].id,
          status:  '',//ordersData[i].status,
          data: ordersData[i].updated_at,
          // discount: '+ Reducere',
          price:  ordersData[i].total+' LEI',
        }
        if(ordersData[i].status===Status.INITIATED)
        {
          aux.status=t('INITIATED');
        }
        if(ordersData[i].status===Status.PAYMENT_REQUIRED)
        {
          aux.status=t('PAYMENT_REQUIRED');
        }
        if(ordersData[i].status===Status.IN_DELIVERY)
        {
          aux.status=t('IN_DELIVERY');
        }
        if(ordersData[i].status===Status.CANCELED)
        {
          aux.status=t('CANCELED');
        }
        if(ordersData[i].status===Status.COMPLETED)
        {
          aux.status=t('COMPLETED');
        }
        if(ordersData[i].status===Status.PAID)
        {
          aux.status=t('PAID');
        }
        if(ordersData[i].status===Status.PAYMENT_FAILED)
        {
          aux.status=t('PAYMENT_FAILED');
        }
        if(ordersData[i].status===Status.DELIVERED)
        {
          aux.status=t('DELIVERED');
        }
        localItems.push(aux)
      }
      setOrdersList(localItems);
    }
  }

  useEffect(()=>{
    getOrders();
  },[refresh])

  const [ordersList,setOrdersList] = useState<OrdersDetails[]>([]);

  const handleProductChange = (event: React.ChangeEvent<{}>, value: string | null) => {
  };

  const handleSearchTermChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(String(e.target.value));
  };

  const searchOrders = async () => {
    searchTerm.replace(' ', '+');
    const res = await searchOrder(searchTerm);
    setOrdersData(res.data);
  };

  // tslint:disable-next-line:no-any
  const searchOrdersKey = (event: any) => {
    if (event.key === 'Enter') {
      searchOrders();
    }
  }

  const renderInput = (params: AutocompleteRenderInputParams) => (
    <TextField
      {...params}
      onChange={handleSearchTermChange}
      className={classes.textFieldSearchBar}
      onKeyPress={searchOrdersKey}
      label={t('SEARCH')}
      fullWidth={true}
      variant="outlined"
      margin="dense"
      value={searchTerm}
      InputProps={{
        ...params.InputProps,
        endAdornment: (
          <InputAdornment id="search-icon" position="end" className={classes.searchIcon}>
            {<SearchIcon className={classes.search} />}
          </InputAdornment>
        ),
      }}
    />
  );

  const navigateTo = (route: string,state: string) => {
    sessionStorage.setItem('nrOrder',state);
    browserHistory.push({
      pathname: route,
      state: state
    });
  };
  
  const searchBar = (
    <div className={classes.searchBar}>
      <div className={classes.inputColumn}>
        <Autocomplete
          freeSolo={true}
          autoComplete={true}
          autoHighlight={true}
          options={searchOptions}
          onChange={handleProductChange}
          renderInput={renderInput}
        />
      </div>
      <Button className={classes.blueButton} onClick={searchOrders} size="large" variant="contained" color="primary">
        <Typography color="textSecondary" variant={'body1'}>
          {t('SEARCH')}
        </Typography>
      </Button>
    </div>
  );

  const contentOfTable = ordersList.map(order => (
    <div className={classes.table} key={order.nrOrder}>
      <div className={classes.cells} onClick={navigateTo.bind(this, PATHS.APP.ORDERS.DETAILS,order.nrOrder)}>
        <Typography color="textPrimary" variant={'body1'} className={classes.cellsContent}>
          {order.product}
        </Typography>
      </div>
      <div className={classes.cells}>
        <Typography color="textPrimary" variant={'body1'} className={classes.cellsContent}>
          {order.nrOrder}
        </Typography>
      </div>
      <div className={classes.cells}>
        <Typography
          style={order.status === 'In asteptare' ? { color: '#D61B2B' } : { color: '#29AF1D' }}
          variant={'body1'}
          className={classes.cellsContent}
        >
          {order.status}
        </Typography>
      </div>
      <div className={classes.cells}>
        <Typography variant={'body1'} className={classes.cellsContent}>
          {order.data}
        </Typography>
      </div>
      <div className={classes.cells}>
        <Typography color="textPrimary" variant={'body1'} className={classes.cellsContent}>
          {order.price}
        </Typography>
      </div>
      <MenuItemDropDownOrders item={order} refresh={refresh} setRefresh={setRefresh}/>
    </div>
  ));

  const orders = (
    <div className={classes.content}>
      <div className={classes.searchDiv}>{searchBar}</div>
      <Filters parentPage={ParentPage.ORDERS} page={page} setPage={setPage}/>
      <div className={classes.table}>
        <div className={classes.headerOfTable}>
          <Typography className={classes.insideHeaderOfTable} variant={'body1'}>
            {t('ORDERED_PRODUCT')}
          </Typography>
        </div>
        <div className={classes.headerOfTable}>
          <Typography className={classes.insideHeaderOfTable} variant={'body1'}>
            {t('ORDER_NR')}
          </Typography>
        </div>
        <div className={classes.headerOfTable}>
          <Typography className={classes.insideHeaderOfTable} variant={'body1'}>
            {t('ORDER_STATUS')}
          </Typography>
        </div>
        <div className={classes.headerOfTable}>
          <Typography className={classes.insideHeaderOfTable} variant={'body1'}>
            {t('DATE')}
          </Typography>
        </div>
        <div className={classes.headerOfTable}>
          <Typography className={classes.insideHeaderOfTable} variant={'body1'}>
            {t('TOTAL_COST_ORDER')}
          </Typography>
        </div>
        <div className={classes.headerOfTable}>
          <Typography className={classes.insideHeaderOfTable} variant={'body1'}>
            {t('ACTIONS')}
          </Typography>
        </div>
      </div>
      {contentOfTable}
    </div>
  );

  return (
    <div className={classes.root}>
      <div>
        <Header tabName={t('ORDER_MANAGEMENT')} />
        {orders}
      </div>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  blueButton: {
    height: '46px',
  },
  searchBar: {
    display: 'grid',
    gridTemplateColumns: '0.9fr 0.1fr',
    gridGap: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  inputColumn: {
    alignSelf: 'center',
  },
  searchIcon: {
    marginRight: '-25px',
  },
  search: {
    margin: '6px',
    height: '24px',
    cursor: 'pointer',
  },
  textFieldSearchBar: {
    marginTop: '6px',
  },
  searchDiv: {
    display: 'grid',
    gridTemplateColumns: '0.6fr',
    marginTop: theme.spacing(2),
  },
  content: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(5),
  },
  table: {
    display: 'grid',
    gridTemplateColumns: '0.25fr 0.20fr 0.20fr 0.15fr 0.20fr 0.20fr 0.20fr',
    gridGap: theme.spacing(0.5),
  },

  headerOfTable: {
    backgroundColor: theme.palette.grey[400],
    height: '53px',
    display: 'flex',
  },
  insideHeaderOfTable: {
    color: '#8C8C8C',
    alignSelf: 'center',
    margin: '0 auto',
  },
  cellsContent: {
    alignSelf: 'center',
    margin: '0 auto',
    fontWeight: 600,
  },
  cells: {
    height: '39px',
    display: 'flex',
  },
}));

export default OrdersContainer;
