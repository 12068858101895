import {
  Button,
  DialogContent,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  createLocation,
  getCities,
  getCounties,
  updateLocation,
} from '../../../state/commands/location';
import { actionType, City, Country, County, Location } from '../../../utils/constants';

interface Props {
  onClose: () => void;
  update: () => void;
  data: Location;
  action: string;
}

const LocationDataDialog: FC<Props> = props => {
  const classes = useStyles();
  const { onClose, update, data, action } = props;
  const [country, setCountry] = React.useState<string>(data.country==='' ? 'Romania' : data.country);
  const [state, setState] = React.useState<string>(data.state);
  const [city, setCity] = React.useState<string>(data.city);
  const [street, setStreet] = React.useState<string>(data.street);
  const [streetNumber, setStreetNumber] = React.useState<string>(data.street_number);
  const [zipcode, setZipcode] = React.useState<string>(data.zipcode);
  const [anotherDetails, setAnotherDetails] = React.useState<string>(data.description);
  const [programCustomerService, setProgramCustomerService] = React.useState<string>(data.scheduler_customer_server);
  const [phoneNumber, setPhoneNumber] = React.useState<string>(data.phone);
  const [email, setEmail] = React.useState<string>(data.email);
  const [allCounties, setAllCounties] = React.useState<County[]>([]);
  const [cities, setCities] = React.useState<City[]>([]);
  const [firstLoading, setFirstLoading] = React.useState<boolean>(true);

  const { t } = useTranslation();

  const [allCountries, setAllCountries] = React.useState<Country[]>([
    {id:1,name:'Romania'},
    {id:2,name:'UK'},
    {id:3,name:'Australia'},
    {id:4,name:'Canada'},
    {id:5,name:'New Zealand'},
    {id:6,name:'USA'},
  ]);


  const getLocations = async () => {
    try {
      const counties = await getCounties();
      const sortedCounties = counties.sort(function (a: County, b: County) {
        if (a.name < b.name) {
          return -1;
        } else if (b.name < a.name) {
          return 1;
        } else {
          return 0;
        }
      });
      setAllCounties(sortedCounties);
    } catch (e) {
      console.error(e.message);
    }
  };

  useEffect(() => {
    getLocations();
  }, []);

  const handleDialogClose = () => {
    onClose();
  };

  const handleStreetChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStreet(String(e.target.value));
  };

  const handleNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStreetNumber(String(e.target.value));
  };
  const handleZipCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setZipcode(String(e.target.value));
  };

  const handleAnotherDetailsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAnotherDetails(String(e.target.value));
  };

  const loadCitiesOfCounty = async (countyId: number) => {
    try {
      const load = await getCities(countyId);
      setCities(load);
    } catch (e) {
      console.error(e.message);
    }
  };

  const handleCountyChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setState(String(event.target.value));
    let index: number;
    for (const element of allCounties) {
      if (element.name === event.target.value) {
        index = element.id;
        loadCitiesOfCounty(index);
        break;
      }
    }
  };

  const handleCountryChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setCountry(String(event.target.value));
    let index: number;
    for (const element of allCountries) {
      if (element.name === event.target.value) {
        index = element.id;
        // loadCountyOfCountry(index);
        // loadCitiesOfCounty(index);
        break;
      }
    }
  };


  const handleCityChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setCity(String(event.target.value));
  };

  const clickUpdateLocationData = async () => {
    const newLocation: Location = {
      id: data.id,
      country: country,
      state,
      city,
      street,
      street_number: streetNumber,
      zipcode,
      description: anotherDetails,
      latitude: '0',
      longitude: '0',
      phone: phoneNumber,
      main: '0',
      scheduler_customer_server: programCustomerService,
      email: email,
    };

    if (action === actionType.insert) {
      try {
        await createLocation(newLocation);
        handleDialogClose();
        update();
      } catch (e) {
        console.error('Nu am putut insera noua locatie!');
      }
    } else if (action === actionType.update) {
      try {
        newLocation.latitude=data.latitude;
        newLocation.longitude=data.longitude;
        await updateLocation(data.id, newLocation);
        handleDialogClose();
        update();
      } catch (e) {
        console.error('Nu am putut modifica locatia!');
      }
    }
  };

  const loadInitialCity = () => {
    if (state !== '') {
      let index: number;
      for (const element of allCounties) {
        if (element.name === state) {
          index = element.id;
          loadCitiesOfCounty(index);
          setFirstLoading(false);
          break;
        }
      }
    }
  };

  const handleProgramCustomerServiceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setProgramCustomerService(String(e.target.value));
  };

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber(String(e.target.value));
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(String(e.target.value));
  };

  const countriesItemsList = allCountries?.map((item: Country) => (
    <MenuItem key={item.id} id={item.name} value={item.name}>
      {item.name}
    </MenuItem>
  ));


  const countiesItemsList = allCounties?.map((item: County) => (
    <MenuItem key={item.id} id={item.name} value={item.name}>
      {item.name}
    </MenuItem>
  ));

  const citiesItemsList = cities?.map((item: City) => (
    <MenuItem key={item.id} id={item.name} value={item.name}>
      {item.name}
    </MenuItem>
  ));

  const updateLocationData = (
    <DialogContent classes={{ root: classes.root }}>
      <div className={classes.paper}>
        <Typography className={classes.bold} variant={'h4'}>
          {t('EDIT_LOCATION')}
        </Typography>
        <div className={classes.modalControls}>

        <Typography variant={'subtitle2'}>{t('COUNTRY')}*</Typography>
          <FormControl variant="filled">
            <InputLabel id="select-county-label">{t('COUNTRY')}</InputLabel>
            <Select labelId="select-county-label" value={country} onChange={handleCountryChange}>
              {countriesItemsList}
            </Select>
          </FormControl>

          <Typography variant={'subtitle2'}>{t('COUNTY')}*</Typography>
          <FormControl variant="filled">
            <InputLabel id="select-county-label">{t('COUNTY')}</InputLabel>
            <Select labelId="select-county-label" value={state} onChange={handleCountyChange}>
              {countiesItemsList}
            </Select>
          </FormControl>
          <Typography variant={'subtitle2'}>{t('TOWN')}*</Typography>
          <FormControl variant="filled">
            <InputLabel id="select-city-label">{t('TOWN')}</InputLabel>
            <Select labelId="select-city-label" value={city} onChange={handleCityChange}>
              {citiesItemsList}
              {firstLoading ? loadInitialCity() : () => {}}
            </Select>
          </FormControl>

          <Typography variant={'subtitle2'}>{t('STREET')}*</Typography>
          <TextField
            hiddenLabel={true}
            variant="filled"
            value={street}
            type={'text'}
            onChange={handleStreetChange}
          />
          <Typography variant={'subtitle2'}>{t('NUMBER')}*</Typography>
          <TextField
            hiddenLabel={true}
            variant="filled"
            value={streetNumber}
            type={'text'}
            onChange={handleNumberChange}
          />
          <Typography variant={'subtitle2'}>{t('ZIP')}*</Typography>
          <TextField
            hiddenLabel={true}
            variant="filled"
            value={zipcode}
            type={'text'}
            onChange={handleZipCodeChange}
          />
          <Typography variant={'subtitle2'}>{t('PROGRAM_CUSTOMER_SERVICE')}*</Typography>
          <TextField
            hiddenLabel={true}
            variant="filled"
            value={programCustomerService}
            type={'text'}
            onChange={handleProgramCustomerServiceChange}
          />
          <Typography variant={'subtitle2'}>{t('PHONE_NUMBER')}*</Typography>
          <TextField
            hiddenLabel={true}
            variant="filled"
            value={phoneNumber}
            type={'text'}
            onChange={handlePhoneNumberChange}
          />
          <Typography variant={'subtitle2'}>{t('EMAIL')}*</Typography>
          <TextField
            hiddenLabel={true}
            variant="filled"
            value={email}
            type={'text'}
            onChange={handleEmailChange}
          />
          <Typography variant={'subtitle2'}>{t('OTHER_DETAILS')}</Typography>
          <TextField
            hiddenLabel={true}
            variant="filled"
            value={anotherDetails}
            type={'text'}
            onChange={handleAnotherDetailsChange}
          />
        </div>
        <Typography className={classes.greyText} variant={'body1'}>
          {t('FIELDS_MANDATORY')}
        </Typography>
        <Button
          className={classes.greenButton}
          size="large"
          color="primary"
          variant="contained"
          onClick={clickUpdateLocationData}
        >
          <Typography color="textSecondary" variant={'body1'}>
            {t('SAVE')}
          </Typography>
        </Button>
      </div>
    </DialogContent>
  );

  return <div>{updateLocationData}</div>;
};

const useStyles = makeStyles(theme => ({
  root: {
    flex: 'unset',
    overflowX: 'hidden',
  },
  paper: {
    width: '100%',
    backgroundColor: theme.palette.background.default,
    display: 'grid',
    paddingLeft: theme.spacing(3.5),
    paddingTop: theme.spacing(3.5),
    gridGap: theme.spacing(3),
    paddingBottom: theme.spacing(3.5),
  },
  bold: {
    fontWeight: 700,
  },
  modalControls: {
    display: 'grid',
    gridTemplateColumns: '0.3fr 0.7fr',
    alignItems: 'center',
    gridGap: theme.spacing(1.25),
    marginRight: theme.spacing(3.5),
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr',
    },
  },
  greyText: {
    color: theme.palette.grey[200],
  },
  greenButton: {
    height: '46px',
    width: '98px',
    color: 'green',
    [theme.breakpoints.down('sm')]: {
      justifySelf: 'end',
      marginRight: theme.spacing(3.5),
    },
  },
  countyAndCity: {
    display: 'grid',
    gridTemplateColumns: '0.5fr 0.5fr',
    gridGap: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      gridGap: theme.spacing(1),
      gridTemplateColumns: '1fr',
    },
  },
}));

export default LocationDataDialog;
