import { Button, DialogContent, makeStyles, TextField, Typography } from '@material-ui/core';
import React, { FC, useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { showSnack } from '../../../state/actions';
import { changeAccountPassword, getCompany, getUser, updateCompanyProfile, updateUserProfile } from '../../../state/commands/user';
import { UserPasswords, UserProfile } from '../../../state/types';
import { emailRegex, SnackbarVariant } from '../../../utils/constants';

enum DIALOG_TYPE {
  NAME_EMAIL = 1,
  PASSWORD = 2,
}

interface Props {
  onClose: () => void;
  setOpticalCabinetName: (opticalCabinetName: string) => void;
  setEmail: (email: string) => void;
  changePassword: boolean;
}

const EditAccountDialog: FC<Props> = props => {
  const { onClose,setOpticalCabinetName,setEmail,changePassword } = props;
  const classes = useStyles();
  const [opticName, setOpticName] = useState<string>('');
  const [opticEmail, setOpticEmail] = useState<string>('');
  const [opticPassword, setOpticPassword] = useState<string>('');
  const [confirmOpticPassword, setConfirmOpticPassword] = useState<string>('');
  const [actualPassword, setActualPassword] = useState<string>('');

  const [dialogType, setDialogType] = useState<DIALOG_TYPE>(DIALOG_TYPE.NAME_EMAIL);
  const { t } = useTranslation();

  useEffect(()=>{
    if(changePassword===true)
    {
      setDialogType(DIALOG_TYPE.PASSWORD);
    }
    else{
      setDialogType(DIALOG_TYPE.NAME_EMAIL);
    }
  },[])

  const handleOpticNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOpticName(String(e.target.value));
  };

  const handleOpticEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOpticEmail(String(e.target.value));
  };

  const handleOpticPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOpticPassword(String(e.target.value));
  };

  const handleUpdateInfo = async () => {
      if(dialogType===DIALOG_TYPE.NAME_EMAIL)
      {
        setOpticalCabinetName(opticName);
        setEmail(opticEmail);
        updateAccountDetails();
      }
    onClose();
  };

  const updateAccountDetails = async () => {
    try {
      if(dialogType===DIALOG_TYPE.NAME_EMAIL)
      {
        const userDetailsResponse = await getUser();
        const userId = userDetailsResponse.data.data.id.toString();
        
        const companyDetailsResponse = await getCompany(userId);
        const tradeRegNr = companyDetailsResponse.data.trade_register_number;
        const companyRegNr = companyDetailsResponse.data.company_register_number;        
        const accountDetails: UserProfile = {
          userId: userId,
          name: opticName,
          email: opticEmail,
          tradeRegNr: tradeRegNr,
          companyRegNr: companyRegNr
        }
        const responseCompany = await updateCompanyProfile(accountDetails);
        const responseUser = await updateUserProfile(accountDetails);
        if(responseCompany.status===200 && responseUser.status===201)
        {
          showSnack('Success', SnackbarVariant.success);
        }
      }
      else{
        const accountPassword: UserPasswords = {
          current_password: actualPassword,
          new_password: opticPassword,
          new_password_confirmation: confirmOpticPassword,
        }
        const response = await changeAccountPassword(accountPassword);
      }
    } catch (e) {
      showSnack(e.message, SnackbarVariant.error);
    }
  };

  const handleConfirmOpticPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmOpticPassword(String(e.target.value));
  };

  const handleActualPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setActualPassword(String(e.target.value));
  };

  const editAccount = (
    <DialogContent classes={{ root: classes.root }}>
      <div className={classes.paper}>
        <Typography className={classes.bold} variant={'h4'}>
          {t('OPTICAL_ACCOUNT_DETAILS')}
        </Typography>
        {dialogType===DIALOG_TYPE.NAME_EMAIL ? <div className={classes.fields}><div className={classes.modalControls}>
          <Typography variant={'subtitle2'}>{t('OPTICAL_CABINET_NAME')}*</Typography>
          <TextField
            hiddenLabel={true}
            variant="filled"
            value={opticName === null ? '' : opticName}
            onChange={handleOpticNameChange}
          />
        </div>
        <div className={classes.modalControls}>
          <Typography variant={'subtitle2'}>{t('EMAIL')}*</Typography>
          <TextField
            hiddenLabel={true}
            variant="filled"
            value={opticEmail}
            onChange={handleOpticEmailChange}
          />
        </div></div> : <div className={classes.fields}>
        <div className={classes.modalControls}>
          <Typography variant={'subtitle2'}>{t('ACTUAL_PASSWORD')}*</Typography>
          <TextField
            hiddenLabel={true}
            variant="filled"
            value={actualPassword}
            type={'password'}
            onChange={handleActualPasswordChange}
          />
        </div>
          <div className={classes.modalControls}>
          <Typography variant={'subtitle2'}>{t('NEW_PASSWORD')}*</Typography>
          <TextField
            hiddenLabel={true}
            variant="filled"
            value={opticPassword}
            type={'password'}
            onChange={handleOpticPasswordChange}
          />
        </div><div className={classes.modalControls}>
          <Typography variant={'subtitle2'}>{t('CONFIRM_PASSWORD')}*</Typography>
          <TextField
            hiddenLabel={true}
            variant="filled"
            value={confirmOpticPassword}
            type={'password'}
            onChange={handleConfirmOpticPasswordChange}
          />
        </div></div>}

        <Typography className={classes.greyText} variant={'body1'}>
          {t('FIELDS_MANDATORY')}
        </Typography>
        <div className={classes.buttons}>
        <Button
          className={classes.greenButton}
          size="large"
          color="primary"
          variant="contained"
          onClick={handleUpdateInfo}
          disabled={dialogType===DIALOG_TYPE.NAME_EMAIL ? !(opticName.length>2 && emailRegex.test(opticEmail) && opticEmail!==''):!(confirmOpticPassword===opticPassword && opticPassword.length>5 && actualPassword!=='')}
        >
          <Typography color="textSecondary" variant={'body1'}>
            {t('SAVE')}
          </Typography>
        </Button>
        </div>
        
      </div>
    </DialogContent>
  );

  return <div>{editAccount}</div>;
};

const useStyles = makeStyles(theme => ({
  root: {
    flex: 'unset',
    overflowX: 'hidden',
  },
  paper: {
    width: '100%',
    backgroundColor: theme.palette.background.default,
    display: 'grid',
    paddingLeft: theme.spacing(3.5),
    paddingTop: theme.spacing(3.5),
    gridGap: theme.spacing(3),
    paddingBottom: theme.spacing(3.5),
  },
  bold: {
    fontWeight: 700,
  },
  modalControls: {
    display: 'grid',
    gridTemplateColumns: '0.3fr 0.65fr 0.05fr',
    alignItems: 'center',
    marginRight: theme.spacing(3.5),
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr',
    },
  },
  birthDate: {
    display: 'grid',
    gridTemplateColumns: '0.2fr 0.2fr 0.3fr',
    gridGap: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      gridGap: theme.spacing(1),
      gridTemplateColumns: '0.33fr 0.33fr 0.33fr',
    },
  },
  greyText: {
    color: theme.palette.grey[200],
  },
  greenButton: {
    height: '46px',
    width: '98px',
    [theme.breakpoints.down('sm')]: {
      justifySelf: 'end',
      marginRight: theme.spacing(3.5),
    },
  },
  accountDataOrPasswordButton: {
    height: '46px',
    width: '98px',
    justifySelf: 'end',
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(3.5),
    },
  },
  buttons: {
    display: 'grid',
    gridTemplateColumns: '0.5fr 0.45fr'
  },
  fields: {
    display: 'grid',
    gridGap: theme.spacing(3),
  }
}));

export default EditAccountDialog;
