import { makeStyles, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { resetProduct, setButtonPressed, showSnack } from '../../state/actions';
import { createProduct, updateProduct } from '../../state/commands/product';
import { createProductInitial } from '../../state/initialStore';
import { InitialState, ProductShow } from '../../state/types';
import { PATHS } from '../../utils/appPaths';
import browserHistory from '../../utils/browserhistory';
import { SnackbarVariant } from '../../utils/constants';

interface Props {
  item?: ProductShow;
}

const PublishOrSave: FC<Props> = props => {
  const { item } = props;
  const classes = useStyles();
  const productStore = useSelector((state: InitialState) => state.product);
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();
  const buttonDisabled = Number(sessionStorage.getItem('pickupPoint'));

  const handleSaveProduct = async () => {
    try {
      setButtonPressed(true);
      const response = await createProduct(productStore);
      if(response.status==='success')
      {
        setButtonPressed(false);
        showSnack(response.status, SnackbarVariant.success);
        await new Promise(r => setTimeout(r, 100));
        switch(location.pathname) { 
          case '/': { 
            history.push(PATHS.APP.ADD_PRODUCT.SUNGLASSES)
            history.push(PATHS.APP.ROOT)
            break; 
         } 
          case '/add-product/sunglasses': { 
             history.push(PATHS.APP.ROOT)
             history.push(PATHS.APP.ADD_PRODUCT.SUNGLASSES)
             break; 
          } 
          case '/add-product/computerglasses': {
            history.push(PATHS.APP.ROOT) 
            history.push(PATHS.APP.ADD_PRODUCT.COMPUTER_GLASSES)
            break; 
          } 
          case '/add-product/eyesightlenses': {
            history.push(PATHS.APP.ROOT) 
            history.push(PATHS.APP.ADD_PRODUCT.EYESIGHT_LENSES)
            break; 
          } 
          case '/add-product/cosmeticlenses': {
            history.push(PATHS.APP.ROOT) 
            history.push(PATHS.APP.ADD_PRODUCT.COSMETIC_LENSES)
             break; 
          }
          case '/add-product/others': {
            history.push(PATHS.APP.ROOT) 
            history.push(PATHS.APP.ADD_PRODUCT.OTHERS)
             break; 
          }  
          
       } 
      }
    } catch (e) {
      showSnack(e.message, SnackbarVariant.error);
    }
  }

  const handleUpdateProduct = async () => {
    if(typeof item !== 'undefined')
    {
      try {
      setButtonPressed(true);
      const response = await updateProduct(productStore,item.general.id.toString());
      if(response.status==='success')
      {
        setButtonPressed(false);
        showSnack(response.status, SnackbarVariant.success);
        resetProduct(createProductInitial);
        browserHistory.push(PATHS.APP.PRODUCTS_MANAGEMENT.ROOT);
      }
      } catch (e) {
        showSnack(e.message, SnackbarVariant.error);
      }
    }
  }

  return (
    <div className={classes.productInfo}>
      <Button className={classes.publishButton} variant="contained" color="primary" onClick={typeof item !== 'undefined' ? handleUpdateProduct : handleSaveProduct} disabled={buttonDisabled!==0 ? false : true}>
        <Typography className={classes.publishText} variant="body1">
          {typeof item !== 'undefined' ? t('UPDATE'):t('PUBLISH')}
        </Typography>
      </Button>
      {/* <Button className={classes.saveButton}>
        <Typography variant="body1" color="textPrimary">
          {t('SAVE_PRODUCT}
        </Typography>
      </Button> */}
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  bold: {
    fontWeight: 700,
  },
  productInfo: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit,minmax(160px,0.2fr))',
    paddingTop: theme.spacing(3.5),
    columnGap: theme.spacing(1),
  },
  publishButton: {
    height: '46px',
  },
  publishText: {
    color: theme.palette.background.default,
  },
  saveButton: {
    backgroundColor: theme.palette.grey[200],
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
    height: '46px',
  },
}));

export default PublishOrSave;
