import { makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { configuratorImprovement, configuratorThickness, LensesImprovementsProductShow, LensesThicknessProductShow } from '../../state/types';

interface Props {
  improvement: configuratorImprovement[];
  thickness: configuratorThickness[];
}

const UpgradesAndThickness: FC<Props> = props => {
  const classes = useStyles();
  const { improvement,thickness } = props;

  const [computerIndex,setComputerIndex] = useState<number>(-1);
  const [impactIndex,setImpactIndex] = useState<number>(-1);
  const [photocromaticIndex,setPhotocromaticIndex] = useState<number>(-1);
  const [photocromaticBrownIndex,setPhotocromaticBrownIndex] = useState<number>(-1);
  const [photocromaticGreyIndex,setPhotocromaticGreyIndex] = useState<number>(-1);
  const [sunWithDioptersIndex,setSunWithDioptersIndex] = useState<number>(-1);
  const [progressiveIndex,setProgressiveIndex] = useState<number>(-1);

  const [standardIndex,setStandardIndex] = useState<number>(-1);
  const [thinIndex,setThinIndex] = useState<number>(-1);
  const [ultraThinIndex,setUltraThinIndex] = useState<number>(-1);
  const [asphericalIndex,setAsphericalIndex] = useState<number>(-1);

  const { t } = useTranslation();

  useEffect(()=>{
    for(let i=0;i<improvement.length;i++)
    {
      if(improvement[i].id===2)
      {
        setComputerIndex(i);
      }
      else if(improvement[i].id===3)
      {
        setImpactIndex(i);
      }
      else if(improvement[i].id===4)
      {
        setPhotocromaticIndex(i);
      }
      else if(improvement[i].id===5)
      {
        setSunWithDioptersIndex(i);
      }
      else if(improvement[i].id===6)
      {
        setProgressiveIndex(i);
      }
      else if(improvement[i].id===8)
      {
        setPhotocromaticBrownIndex(i);
      }
      else if(improvement[i].id===9)
      {
        setPhotocromaticGreyIndex(i);
      }
    }
    for(let i=0;i<thickness.length;i++)
    {
      if(thickness[i].id===1)
      {
        setStandardIndex(i);
      }
      else if(thickness[i].id===2)
      {
        setThinIndex(i);
      }
      else if(thickness[i].id===3)
      {
        setUltraThinIndex(i);
      }
      else if(thickness[i].id===4)
      {
        setAsphericalIndex(i);
      }
    }
  },[])

  return (
    <div className={classes.upgradesAndThickness}>
      <div className={classes.section}>
        <div className={classes.container}>
          <Typography className={classes.title} variant="subtitle2" color="primary">
            {t('LENS_ENHANCEMENTS')}
          </Typography>
        </div>
        <div className={clsx(classes.container, classes.detailsText)}>
          <Typography variant="body1" color="textPrimary">
            {t('COMPUTER')}
          </Typography>
          <Typography variant="body1" color="textPrimary">
            {computerIndex!==-1 ? improvement[computerIndex].price : null}
          </Typography>
          <div className={classes.greyLine} />
          <Typography variant="body1" color="textPrimary">
            {t('IMPACT_RESISTANT')}
          </Typography>
          <Typography variant="body1" color="textPrimary">
          {impactIndex!==-1 ? improvement[impactIndex].price : null}
          </Typography>
          <div className={classes.greyLine} />
          <Typography variant="body1" color="textPrimary">
            {t('PHOTOCHROMATIC')}
          </Typography>
          <div>
            <Typography variant="body1" color="textPrimary">
              {photocromaticIndex!==-1 ? t('INCOLOR')+': '+improvement[photocromaticIndex].price.toString() : null}
            </Typography>
            <Typography variant="body1" color="textPrimary">
              {photocromaticBrownIndex!==-1 ? t('BROWN')+': '+improvement[photocromaticBrownIndex].price.toString() : null}
            </Typography>
            <Typography variant="body1" color="textPrimary">
              {photocromaticGreyIndex!==-1 ? t('GREY')+': '+improvement[photocromaticGreyIndex].price.toString() : null}
            </Typography>
          </div>
          
          <div className={classes.greyLine} />
          <Typography variant="body1" color="textPrimary">
            {t('SUN_DIOPTERS')}
          </Typography>
          <Typography variant="body1" color="textPrimary">
          {sunWithDioptersIndex!==-1 ? improvement[sunWithDioptersIndex].price : null}
          </Typography>
          <div className={classes.greyLine} />
          {/* <Typography variant="body1" color="textPrimary">
            {t('PROGRESSIVE')}
          </Typography>
          <Typography variant="body1" color="textPrimary">
          {progressiveIndex!==-1 ? improvement[progressiveIndex].price : null}
          </Typography> */}
          {/* <div className={classes.greyLine} /> */}
        </div>
      </div>
      <div className={classes.section}>
        <div className={classes.container}>
          <Typography className={classes.title} variant="subtitle2" color="primary">
            {t('LENS_THICKNESS')}
          </Typography>
        </div>
        <div className={clsx(classes.container, classes.detailsText)}>
          <Typography variant="body1" color="textPrimary">
            {t('STANDARD_THICKNESS')}
          </Typography>
          <Typography variant="body1" color="textPrimary">
            {standardIndex!==-1 ? thickness[standardIndex].price : null}
          </Typography>
          <div className={classes.greyLine} />
          <Typography variant="body1" color="textPrimary">
            {t('THIN')}
          </Typography>
          <Typography variant="body1" color="textPrimary">
            {thinIndex!==-1 ? thickness[thinIndex].price : null}
          </Typography>
          <div className={classes.greyLine} />
          <Typography variant="body1" color="textPrimary">
            {t('ULTRA_THIN')}
          </Typography>
          <Typography variant="body1" color="textPrimary">
            {ultraThinIndex!==-1 ? thickness[ultraThinIndex].price : null}
          </Typography>
          <div className={classes.greyLine} />
          {/* <Typography variant="body1" color="textPrimary">
            {t('ASPHERICAL')}
          </Typography>
          <Typography variant="body1" color="textPrimary">
            {asphericalIndex!==-1 ? thickness[asphericalIndex].price : null}
          </Typography>
          <div className={classes.greyLine} /> */}
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  upgradesAndThickness: {
    display: 'grid',
    gridTemplateColumns: '0.5fr 0.5fr',
    columnGap: theme.spacing(2.5),
  },
  section: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    rowGap: theme.spacing(0.5),
  },
  title: {
    fontWeight: 700,
    textDecoration: 'underline',
    padding: `${theme.spacing(2)}px 0px ${theme.spacing(2)}px ${theme.spacing(3.5)}px`,
    maxHeight: '52px',
  },
  container: {
    boxShadow: '0px 3px 14px rgba(0, 0, 0, 0.12)',
    borderRadius: '4px',
  },
  detailsText: {
    display: 'grid',
    height: '243px',
    gridTemplateColumns: '0.5fr 0.5fr',
    padding: `${theme.spacing(3)}px ${theme.spacing(2.5)}px ${theme.spacing(
      3.5,
    )}px ${theme.spacing(2.5)}px`,
    rowGap: theme.spacing(1.5),
  },
  greyLine: {
    height: '1px',
    background: theme.palette.grey[300],
    gridColumnStart: 1,
    gridColumnEnd: 3,
  },
  bold: {
    fontWeight: 600,
  },
}));

export default UpgradesAndThickness;
