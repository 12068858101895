import { Button, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import pen from '../../assets/Pen.png';
import { showSnack } from '../../state/actions';
import { getOrderById } from '../../state/commands/order';
import { OrderCompleteData } from '../../state/types';
import { SnackbarVariant } from '../../utils/constants';


export const ComponentToPrint = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const orderNr = String(sessionStorage.getItem('nrOrder'));
  // @ts-ignore
  const [nrOrder, setNrOrder] = useState<string>(orderNr);

  const [orderData,setOrderData] = useState<OrderCompleteData>();
  const { t } = useTranslation();

  useEffect(()=>{
    getOrder(nrOrder.slice(1,nrOrder.length));
  },[nrOrder])

  const getOrder = async (id: string) => {
    if(id!=='')
    {
      try {
      const response = await getOrderById(id);
      setOrderData(response.data);
       } catch(e) {
        showSnack(e.message, SnackbarVariant.error);
      }
    }
    
  };

const underHeader = (
  <div className={classes.underHeader}>
    <Typography color="textPrimary" variant={'subtitle1'}>
      {t('ORDER_DETAILS') + ' ' + nrOrder}
    </Typography>
    <Typography color="primary" variant={'body1'}>
      {t('PAYMENT_VIA')} {orderData?.general.payment_method}
    </Typography>
  </div>
);

const general = (
  <div className={classes.border}>
    <Typography className={classes.details} color="primary" variant={'subtitle2'}>
      {t('GENERAL')}
    </Typography>
    <div className={classes.centerGrid}>
      <Typography color="textPrimary" variant={'body1'}>
        {t('REGISTRATION_DATE')}
      </Typography>
      <Typography className={classes.bold} color="textPrimary" variant={'body1'}>
        {orderData?.general.created_at}
      </Typography>
    </div>
    <div className={classes.centerGrid}>
      <Typography color="textPrimary" variant={'body1'}>
        {t('USER_CLIENT')}
      </Typography>
      <Typography className={classes.bold} color="textPrimary" variant={'body1'}>
        {orderData?.owner.name}
      </Typography>
    </div>
    <div className={classes.centerGrid}>
      <Typography color="textPrimary" variant={'body1'}>
        {t('EMAIL_ADDRESS')}
      </Typography>
      <Typography className={classes.bold} color="textPrimary" variant={'body1'}>
        {orderData?.owner.email}
      </Typography>
    </div>
  </div>
);

const billing = (
  <div className={classes.border}>
    <Typography className={classes.details} color="primary" variant={'subtitle2'}>
      {t('BILLING')}
    </Typography>
    <div className={classes.centerGrid}>
      <div className={classes.addressAndPen}>
        <Typography color="textPrimary" variant={'body1'}>
          {t('ADDRESS')}
        </Typography>
        <img src={pen} alt="alt" width="12px" height="12px" />
      </div>
      <Typography className={classes.bold} color="textPrimary" variant={'body1'}>
        {orderData?.invoice_address.full_address}
      </Typography>
      <Typography className={classes.bold} color="textPrimary" variant={'body1'}>
        {orderData?.invoice_address.county} , {orderData?.invoice_address.city}
      </Typography>
    </div>
    <div className={classes.centerGrid}>
      <Typography color="textPrimary" variant={'body1'}>
        {t('BILLING_DATE')}
      </Typography>
      <Typography className={classes.bold} color="textPrimary" variant={'body1'}>
        {orderData?.general.created_at}
      </Typography>
    </div>
    <div className={classes.centerGrid}>
      <Typography color="textPrimary" variant={'body1'}>
        {t('PHONE_NUMBER')}
      </Typography>
      <Typography className={classes.bold} color="textPrimary" variant={'body1'}>
        {orderData?.invoice_address.phone}
      </Typography>
    </div>
  </div>
);

const shipping = (
  <div className={classes.border}>
    <Typography className={classes.details} color="primary" variant={'subtitle2'}>
      {t('SHIPPING')}
    </Typography>
    <div className={classes.centerGrid}>
      <div className={classes.addressAndPen}>
        <Typography color="textPrimary" variant={'body1'}>
          {t('ADDRESS')}
        </Typography>
        <img src={pen} alt="alt" width="12px" height="12px" />
      </div>
      <Typography className={classes.bold} color="textPrimary" variant={'body1'}>
        {orderData?.delivery_address.full_address}
      </Typography>
      <Typography className={classes.bold} color="textPrimary" variant={'body1'}>
        {orderData?.delivery_address.county} , {orderData?.delivery_address.city}
      </Typography>
    </div>
  </div>
);

const details = (
  typeof orderData !== 'undefined' ? 
  orderData.products.map((item, index) => (
    <div className={classes.specs} key={index}>
    <div className={classes.inlineText}>
      <Typography color="textPrimary" variant={'body1'}>
        {t('BRAND')}:
      </Typography>
      <Typography color="textPrimary" variant={'body1'}>
        {item.brand}
      </Typography>
    </div>
    <div className={classes.greyLine} />
    <div className={classes.inlineText}>
      <Typography color="textPrimary" variant={'body1'}>
        {t('MODEL')}:
      </Typography>
      <Typography color="textPrimary" variant={'body1'}>
        {item.model}
      </Typography>
    </div>
    <div className={classes.greyLine} />
    <div className={classes.inlineText}>
      <Typography color="textPrimary" variant={'body1'}>
        {t('PRODUCT_CODE')}:
      </Typography>
      <Typography color="textPrimary" variant={'body1'}>
        {item.code}
      </Typography>
    </div>
    <div className={classes.greyLine} />
    <div className={classes.inlineText}>
      <Typography color="textPrimary" variant={'body1'}>
        {t('SIZE')}:
      </Typography>
      <Typography color="textPrimary" variant={'body1'}>
        {item.size.width+'/'+item.size.bridge+'/'+item.size.length}
      </Typography>
    </div>
    <div className={classes.greyLine} />
    <div className={classes.inlineText}>
      <Typography color="textPrimary" variant={'body1'}>
        {t('FOR')}:
      </Typography>
      <Typography color="textPrimary" variant={'body1'}>
        {item.details.For}
      </Typography>
    </div>
    <div className={classes.greyLine} />
    <div className={classes.inlineText}>
      <Typography color="textPrimary" variant={'body1'}>
        {t('FRAME_COLOR')}:
      </Typography>
      <Typography color="textPrimary" variant={'body1'}>
        {item.details['Frame color']}
      </Typography>
    </div>
    <div className={classes.greyLine} />
    <div className={classes.inlineText}>
      <Typography color="textPrimary" variant={'body1'}>
        {t('LENSES_COLOR')}:
      </Typography>
      <Typography color="textPrimary" variant={'body1'}>
        {item.details['Lenses color']}
      </Typography>
    </div>
    <div className={classes.greyLine} />
    <div className={classes.inlineText}>
      <Typography color="textPrimary" variant={'body1'}>
        {t('AVAILABLE_ENHANCEMENTS')}:
      </Typography>
      {item.prescription.lense_improvement?.map((item) => (
        <Typography color="textPrimary" variant={'body1'}>
          {item.name}
        </Typography>
      ))}
    </div>
    <div className={classes.greyLine} />
    <div className={classes.inlineText}>
      <Typography color="textPrimary" variant={'body1'}>
        {t('THICKNESS')}:
      </Typography>
      <Typography color="textPrimary" variant={'body1'}>
        {String(item.prescription.lense_thickness)!=='null' ? item.prescription.lense_thickness[0]?.name : null }
      </Typography>
    </div>
  </div>
  )) : null
);

const titles = (
  <div className={classes.secondRow}>
    <Typography className={classes.detailsWithBorder} color="primary" variant={'subtitle2'}>
      {t('PRODUCTS')}
    </Typography>
    <Typography className={classes.detailsWithBorder} color="primary" variant={'subtitle2'}>
      {t('DETAILS')}
    </Typography>
    <Typography className={classes.detailsWithBorder} color="primary" variant={'subtitle2'}>
      {t('PRESCRIPTION')}
    </Typography>
    <Typography className={classes.detailsWithBorder} color="primary" variant={'subtitle2'}>
      {t('PRICE')}
    </Typography>
  </div>
);

const products = (
  typeof orderData !== 'undefined' ? 
  orderData.products.map((item, index)=> (
  <div className={classes.imageAndText} key={index}>
    <Typography className={classes.bold} color="textPrimary" variant={'subtitle2'}>
      {item.brand+' '+item.model}
    </Typography>
    <img src={item.thumbnail} width={167} height={95} alt="alt" />
    <div className={classes.eyeglassText}>
      <Typography className={classes.bold} color="secondary" variant={'body2'}>
        *Full price / {item.discount}% discount
      </Typography>
    </div>
  </div>
  )) : null
);

const recipe = (
  typeof orderData !== 'undefined' ? 
  orderData.products.map((item, index) => (
  <div className={classes.specs} key={index}>
    <div className={classes.inlineText}>
      <Typography color="textPrimary" variant={'body1'}>
        +/- :
      </Typography>
      <Typography color="textPrimary" variant={'body1'}>
        {item.prescription.lense_type?.name}
      </Typography>
    </div>
    <div className={classes.greyLine} />
    <div className={classes.inlineText}>
      <Typography color="textPrimary" variant={'body1'}>
        {t('OD_SPHERE')}:
      </Typography>
      <Typography color="textPrimary" variant={'body1'}>
        {item.prescription.right_eye_sph}
      </Typography>
    </div>
    <div className={classes.greyLine} />
    <div className={classes.inlineText}>
      <Typography color="textPrimary" variant={'body1'}>
        {t('OS_SPHERE')}:
      </Typography>
      <Typography color="textPrimary" variant={'body1'}>
      {item.prescription.left_eye_sph}
      </Typography>
    </div>
    <div className={classes.greyLine} />
    <div className={classes.inlineText}>
      <Typography color="textPrimary" variant={'body1'}>
        {t('OD_CYL')}:
      </Typography>
      <Typography color="textPrimary" variant={'body1'}>
        {item.prescription.right_eye_cyl}
      </Typography>
    </div>
    <div className={classes.greyLine} />
    <div className={classes.inlineText}>
      <Typography color="textPrimary" variant={'body1'}>
        {t('OS_CYL')}:
      </Typography>
      <Typography color="textPrimary" variant={'body1'}>
        {item.prescription.left_eye_cyl}
      </Typography>
    </div>
    <div className={classes.greyLine} />
    <div className={classes.inlineText}>
      <Typography color="textPrimary" variant={'body1'}>
        {t('OD_AX')}:
      </Typography>
      <Typography color="textPrimary" variant={'body1'}>
        {item.prescription.right_eye_ax}
      </Typography>
    </div>
    <div className={classes.greyLine} />
    <div className={classes.inlineText}>
      <Typography color="textPrimary" variant={'body1'}>
        {t('OS_AX')}:
      </Typography>
      <Typography color="textPrimary" variant={'body1'}>
        {item.prescription.left_eye_ax}
      </Typography>
    </div>
    <div className={classes.greyLine} />
    <div className={classes.inlineText}>
      <Typography color="textPrimary" variant={'body1'}>
        {t('PUPILLARY_DISTANCE')}:
      </Typography>
      <Typography color="textPrimary" variant={'body1'}>
        {item.prescription.dp_type!=='single' ? item.prescription.dp_type+' '+' OD '+item.prescription.dp_right_eye+' , '+' OS '+item.prescription.dp_left_eye : item.prescription.dp_type+' '+item.prescription.dp_both_eye}
      </Typography>
    </div>
    <div className={classes.greyLine} />
  </div>
  )) : null
);

const price = (
  typeof orderData !== 'undefined' ? 
  orderData.products.map((item, index) => (
  <div className={classes.specs} key={index}>
    <Typography className={classes.center} color="textPrimary" variant={'body1'}>
      {item.price+' Lei'}
    </Typography>
  </div>
  )) :  null
);

const totalPrice = (
  <div className={classes.specs}>
    <div className={classes.alignRight}>
      <div className={classes.padding}>
        <Typography className={classes.details} color="primary" variant={'subtitle2'}>
          {t('TOTAL')}
        </Typography>
      </div>
      <Typography className={classes.bold} color="secondary" variant={'body1'}>
        {orderData?.total}
      </Typography>
    </div>
  </div>
);

    return (
      // @ts-ignore
      <div ref={ref} className={classes.root}>
      <div className={classes.content}>
        {underHeader}
        <div className={classes.firstRow}>
          {general}
          {billing}
          {shipping}
        </div>
        {titles}
        <div className={classes.secondRow}>
          <div className={classes.secondRowItem}>
            {products}
          </div>
          <div className={classes.secondRowItem}>
            {details}
          </div>
          <div className={classes.secondRowItem}>
            {recipe}
          </div>
          <div className={classes.secondRowItem}>
            {price}
          </div>
        </div>
        {totalPrice}
      </div>
    </div>
    );
  });

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  content: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  firstRow: {
    display: 'grid',
    gridTemplateColumns: '0.2fr 0.35fr 0.35fr',
  },
  secondRow: {
    display: 'grid',
    gridTemplateColumns: '0.2fr 0.35fr 0.35fr 0.1fr',
  },
  title: {
    height: '52px',
  },
  inlineText: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: `0px ${theme.spacing(4)}px`,
  },
  greyLine: {
    height: '1px',
    width: '100%',
    backgroundColor: theme.palette.grey[300],
  },
  bold: {
    fontWeight: 700,
  },
  imageAndText: {
    display: 'grid',
    justifyItems: 'center',
    alignItems: 'center',
    border: `1px solid ${theme.palette.grey[300]}`,
    [theme.breakpoints.down('md')]: {
      gridRow: '1/2',
    },
  },
  eyeglassText: {
    display: 'grid',
    justifyItems: 'center',
  },
  specs: {
    backgroundColor: theme.palette.background.default,
    border: `1px solid ${theme.palette.grey[300]}`,
  },
  details: {
    display: 'flex',
    justifyContent: 'center',
    padding: `${theme.spacing(2)}px 0px`,
    textDecoration: 'underline',
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  detailsWithBorder: {
    display: 'flex',
    justifyContent: 'center',
    padding: `${theme.spacing(2)}px 0px`,
    textDecoration: 'underline',
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    border: `1px solid ${theme.palette.grey[300]}`,
  },
  justifyLeft: {
    justifyContent: 'left',
  },
  eyeglassPrice: {
    padding: `${theme.spacing(1)}px 0px`,
    fontWeight: 'bold',
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
  },
  centerGrid: {
    display: 'grid',
    justifyItems: 'center',
    paddingBottom: theme.spacing(3),
  },
  border: {
    border: `1px solid ${theme.palette.grey[300]}`,
  },
  underHeader: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  alignRight: {
    display: 'grid',
    justifyItems: 'end',
    paddingRight: theme.spacing(3),
  },
  padding: {
    paddingRight: theme.spacing(2),
  },
  buttons: {
    paddingTop: theme.spacing(2),
    display: 'flex',
    gridGap: theme.spacing(2),
    paddingBottom: theme.spacing(4),
  },
  addressAndPen: {
    display: 'flex',
    gridGap: theme.spacing(0.5),
    alignItems: 'center',
  },
  secondRowItem: {
    display: 'grid',
  },
}));
