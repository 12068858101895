import { Button, DialogContent, makeStyles, TextField, Typography } from '@material-ui/core';
import React, { FC, useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { showSnack } from '../../../state/actions';
import { createBankDetails, getBankDetailsById, updateBankDetailsById } from '../../../state/commands/user';
import { SnackbarVariant } from '../../../utils/constants';

interface Props {
  onClose: () => void;
  setUpdatedBankDetails: (updated: boolean) => void;
  updatedBankDetails: boolean;
  bankAccountId: string;
  companyID: number;
}

const EditBankingDialog: FC<Props> = props => {
  const { onClose,setUpdatedBankDetails,updatedBankDetails,bankAccountId,companyID } = props;
  const classes = useStyles();
  const [opticIBAN, setOpticIBAN] = useState<string>('');
  const [accountName, setAccountName] = useState<string>('');
  const [bankName, setBankName] = useState<string>('');
  const { t } = useTranslation();

  const handleOpticIBANChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOpticIBAN(String(e.target.value));
  };

  const handleAccountNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAccountName(String(e.target.value));
  };

  const handleBankNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBankName(String(e.target.value));
  };

  const handleUpdateProfile = async () => {
    try {
      if(bankAccountId!=='')
      {
        const response = await updateBankDetailsById(bankAccountId,accountName,opticIBAN,bankName);
        if(response.status==='success')
        {
          setUpdatedBankDetails(!updatedBankDetails);
          onClose();
        }
      }
      else
      {
        const response = await createBankDetails(companyID,accountName,opticIBAN,bankName);
        if(response.data.status==='success')
        {
          setUpdatedBankDetails(!updatedBankDetails);
          onClose();
        }
      }
      
    } catch(e) {
      showSnack(e.message, SnackbarVariant.error);
    }
    
  };

  useEffect(()=>{
    if(bankAccountId!=='')
    {
      loadBank();
    }
  },[])

  const loadBank = async () => {
    try {
      const response = await getBankDetailsById(bankAccountId);
      setOpticIBAN(response.data.iban);
      setAccountName(response.data.name);
      setBankName(response.data.bank);
    } catch(e) {
      showSnack(e.message, SnackbarVariant.error);
    }
  };

  const editAccount = (
    <DialogContent classes={{ root: classes.root }}>
      <div className={classes.paper}>
        <Typography className={classes.bold} variant={'h4'}>
          {t('BANK_ACCOUNT_INFO')}
        </Typography>
        <div className={classes.modalControls}>
          <Typography variant={'subtitle2'}>{t('IBAN')}*</Typography>
          <TextField
            hiddenLabel={true}
            variant="filled"
            value={opticIBAN === null ? '' : opticIBAN}
            onChange={handleOpticIBANChange}
          />
        </div>
        <div className={classes.modalControls}>
          <Typography variant={'subtitle2'}>{t('ACCOUNT_HOLDER')}*</Typography>
          <TextField
            hiddenLabel={true}
            variant="filled"
            value={accountName}
            onChange={handleAccountNameChange}
          />
        </div>

        <div className={classes.modalControls}>
          <Typography variant={'subtitle2'}>{t('BANK')}*</Typography>
          <TextField
            hiddenLabel={true}
            variant="filled"
            value={bankName}
            onChange={handleBankNameChange}
          />
        </div>

        <Typography className={classes.greyText} variant={'body1'}>
          {t('FIELDS_MANDATORY')}
        </Typography>
        <Button
          className={classes.greenButton}
          size="large"
          color="primary"
          variant="contained"
          onClick={handleUpdateProfile}
        >
          <Typography color="textSecondary" variant={'body1'}>
            {t('SAVE')}
          </Typography>
        </Button>
      </div>
    </DialogContent>
  );

  return <div>{editAccount}</div>;
};

const useStyles = makeStyles(theme => ({
  root: {
    flex: 'unset',
    overflowX: 'hidden',
  },
  paper: {
    width: '100%',
    backgroundColor: theme.palette.background.default,
    display: 'grid',
    paddingLeft: theme.spacing(3.5),
    paddingTop: theme.spacing(3.5),
    gridGap: theme.spacing(3),
    paddingBottom: theme.spacing(3.5),
  },
  bold: {
    fontWeight: 700,
  },
  modalControls: {
    display: 'grid',
    gridTemplateColumns: '0.3fr 0.65fr 0.05fr',
    alignItems: 'center',
    marginRight: theme.spacing(3.5),
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr',
    },
  },
  birthDate: {
    display: 'grid',
    gridTemplateColumns: '0.2fr 0.2fr 0.3fr',
    gridGap: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      gridGap: theme.spacing(1),
      gridTemplateColumns: '0.33fr 0.33fr 0.33fr',
    },
  },
  greyText: {
    color: theme.palette.grey[200],
  },
  greenButton: {
    height: '46px',
    width: '98px',
    [theme.breakpoints.down('sm')]: {
      justifySelf: 'end',
      marginRight: theme.spacing(3.5),
    },
  },
}));

export default EditBankingDialog;
