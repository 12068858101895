import { makeStyles } from '@material-ui/core';
import { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { SOLUTION_ACCESSORIES_ID } from '../../components/AddProduct/ProductPriceAndDescription';
import Header from '../../components/Header';
import { ProductShow } from '../../state/types';
import { Product, ProductType } from '../../utils/constants';
import AddComputerglassesContainer from '../AddProduct/AddComputerglassesContainer';
import AddCosmeticLensesContainer from '../AddProduct/AddCosmeticLensesContainer';
import AddEyeglassesContainer from '../AddProduct/AddEyeglassesContainer';
import AddEyesightLensesContainer from '../AddProduct/AddEyesightLensesContainer';
import AddOthersContainer from '../AddProduct/AddOthersContainer';
import AddSunglassesContainer from '../AddProduct/AddSunglassesContainer';

const EditProduct: FC<{}> = () => {
  const classes = useStyles();
  const location = useLocation();
  const [product,setProduct]=useState<ProductShow>();

  useEffect(() => {
    const state = location.state as ProductShow
    setProduct(state);
  }, [location]);

  const itemSwitch = (item: ProductShow) => {
    switch (item.category.id) {
      case ProductType.EYEGLASSES:
        return <AddEyeglassesContainer item={item} />;
      case ProductType.SUNGLASSES:
        return <AddSunglassesContainer item={item}/>;
      case ProductType.COMPUTERGLASSES:
        return <AddComputerglassesContainer item={item}/>;
      case ProductType.EYESIGHT_LENSES:
        return <AddEyesightLensesContainer item={item}/>;
      case ProductType.COSMETIC_LENSES:
        return <AddCosmeticLensesContainer item={item}/>;
      case ProductType.OTHER_CATEGORIES:
        return <AddOthersContainer item={item}/>;
      case SOLUTION_ACCESSORIES_ID.GLASSES_MAINTENANCE_SOLUTIONS:
        return <AddOthersContainer item={item}/>;
      case SOLUTION_ACCESSORIES_ID.OPHTALMIC_DROPS:
        return <AddOthersContainer item={item}/>;
      case SOLUTION_ACCESSORIES_ID.LENS_MAINTENANCE_SOLUTIONS:
        return <AddOthersContainer item={item}/>;
      case SOLUTION_ACCESSORIES_ID.LENS_HOLDER:
        return <AddOthersContainer item={item}/>;
      case SOLUTION_ACCESSORIES_ID.CLOTHS:
        return <AddOthersContainer item={item}/>;
      case SOLUTION_ACCESSORIES_ID.GLASSES_CASE:
        return <AddOthersContainer item={item}/>;
      case SOLUTION_ACCESSORIES_ID.OTHER:
        return <AddOthersContainer item={item}/>;
      default:
        break;
    }
  };

  return (
    <div className={classes.root}>
      {typeof product !== 'undefined' ? <div><Header tabName={product?.general.brand+' '+product?.general.model} />
      {itemSwitch(product)}</div>:null}
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
}));

export default EditProduct;
