import { PATHS } from './appPaths';
import i18next from 'i18next';

export interface SideMenuList {
  name: string;
  toolbarItem: string;
  menuItems: { name: string; route: string }[];
}

export const getAxiosConfig = () => {
  const token = String(sessionStorage.getItem('sessionToken'));
  if (token !== 'null') {
    return {
      headers: { Authorization: `Bearer ${token}` },
    };
  }
  return {};
};

export const sideMenuList: SideMenuList[] = [
  {
    name: 'ADD_PRODUCT',
    toolbarItem: PATHS.APP.ROOT,
    menuItems: [
      { name: 'EYEGLASSES', route: PATHS.APP.ROOT },
      { name: 'SUNGLASSES', route: PATHS.APP.ADD_PRODUCT.SUNGLASSES },
      {
        name: 'COMPUTERGLASSES',
        route: PATHS.APP.ADD_PRODUCT.COMPUTER_GLASSES,
      },
      { name: 'EYESIGHT_LENSES', route: PATHS.APP.ADD_PRODUCT.EYESIGHT_LENSES },
      { name: 'COSMETIC_LENSES', route: PATHS.APP.ADD_PRODUCT.COSMETIC_LENSES },
      { name: 'OTHER_CATEGORIES', route: PATHS.APP.ADD_PRODUCT.OTHERS },
    ],
  },
  {
    name: 'PRODUCT_MANAGEMENT',
    toolbarItem: PATHS.APP.PRODUCTS_MANAGEMENT.ROOT,
    menuItems: [],
  },
  {
    name: 'ORDERS',
    toolbarItem: PATHS.APP.ORDERS.ROOT,
    menuItems: [],
  },
  {
    name: 'SETTINGS',
    toolbarItem: PATHS.APP.ACCOUNT.ROOT,
    menuItems: [
      { name: 'ACCOUNT_DATA', route: PATHS.APP.ACCOUNT.ROOT },
      { name: 'LOCATION', route: PATHS.APP.ACCOUNT.LOCATION },
      { name: 'PICKUP_LOCATION', route: PATHS.APP.ACCOUNT.PICKUP_LOCATION },
    ],
  },
];

export interface Taxonomy {
  taxonomy_relationship_id: number;
  taxonomy_slug: string;
  name: string;
}

interface Improvement {
  id: number;
  name: string;
  slug: string;
  description: string;
  icon: string;
  price: number;
}

interface Thickness {
  id: number;
  name: string;
  slug: string;
  description: string;
  icon: string;
  price: number;
  index: number;
}

export interface EyeGlassesInfo {
  brands: Taxonomy[];
  taxonomies: {
    for: Taxonomy[];
    [`frame-shape`]: Taxonomy[]; 
    [`lens-types`]: Taxonomy[]; 
    [`frame-type`]: Taxonomy[]; 
    [`face-shape`]: Taxonomy[]; 
    [`frame-materials`]: Taxonomy[];
    [`frame-color`]: Taxonomy[];
    [`lenses-color`]: Taxonomy[];
    characteristics: Taxonomy[]; 
  };
  lenses: {
    improvements: Improvement[];
    thickness: Thickness[];
    types: Improvement[];
  };
  size: {
    width: string[];
    length: string[];
    bridge: string[];
  };
}


export interface SunGlassesInfo {
  brands: Taxonomy[];
  taxonomies: {
    for: Taxonomy[];
    [`frame-shape`]: Taxonomy[];
    [`lens-types`]: Taxonomy[];
    [`frame-type`]: Taxonomy[];
    [`face-shape`]: Taxonomy[];
    [`frame-materials`]: Taxonomy[];
    [`frame-color`]: Taxonomy[];
    [`lenses-color`]: Taxonomy[];
    characteristics: Taxonomy[];
  };
}

export interface ComputerGlassesInfo {
  brands: Taxonomy[];
  taxonomies: {
    for: Taxonomy[];
    [`frame-shape`]: Taxonomy[];
    [`lens-types`]: Taxonomy[];
    [`frame-type`]: Taxonomy[];
    [`face-shape`]: Taxonomy[];
    [`frame-materials`]: Taxonomy[];
    [`frame-color`]: Taxonomy[];
    characteristics: Taxonomy[];
  };
  lenses: {
    thickness: Thickness[];
    types: Improvement[];
  };
}

export interface EyeSightLensesInfo {
  brands: Taxonomy[];
  taxonomies: {
    for: Taxonomy[];
    [`period-of-use`]: Taxonomy[];
    [`method-of-wearing`]: Taxonomy[];
  };
}

export interface CosmeticLensesInfo {
  brands: Taxonomy[];
  taxonomies: {
    [`lenses-color`]: Taxonomy[];
    [`period-of-use`]: Taxonomy[];
    [`method-of-wearing`]: Taxonomy[];
  };
}

export const initialEyeglassesInfo: EyeGlassesInfo = {
  brands: [],
  taxonomies: {
    for: [],
    [`frame-shape`]: [],
    [`lens-types`]: [],
    [`frame-type`]: [],
    [`face-shape`]: [],
    [`frame-materials`]: [],
    [`frame-color`]: [],
    [`lenses-color`]: [],
    characteristics: [],
  },
  lenses: {
    improvements: [],
    thickness: [],
    types: [],
  },
  size: {
    width: [],
    length: [],
    bridge: [],
  },
};


export const initialSunglassesInfo: SunGlassesInfo = {
  brands: [],
  taxonomies: {
    for: [],
    [`frame-shape`]: [],
    [`lens-types`]: [],
    [`frame-type`]: [],
    [`face-shape`]: [],
    [`frame-materials`]: [],
    [`frame-color`]: [],
    [`lenses-color`]: [],
    characteristics: [],
  },
};


export const initialComputerglassesInfo: ComputerGlassesInfo = {
  brands: [],
  taxonomies: {
    for: [],
    [`frame-shape`]: [],
    [`lens-types`]: [],
    [`frame-type`]: [],
    [`face-shape`]: [],
    [`frame-materials`]: [],
    [`frame-color`]: [],
    characteristics: [],
  },
  lenses: {
    thickness: [],
    types: [],
  },
};

export const initialEyesightlensesInfo: EyeSightLensesInfo = {
  brands: [],
  taxonomies: {
    for: [],
    [`period-of-use`]: [],
    [`method-of-wearing`]: [],
  },
};

export const initialCosmeticlensesInfo: CosmeticLensesInfo = {
  brands: [],
  taxonomies: {
    [`lenses-color`]: [],
    [`period-of-use`]: [],
    [`method-of-wearing`]: [],
  },
};

export interface Location {
  id: number;
  country: string;
  state: string;
  city: string;
  street: string;
  street_number: string;
  zipcode: string;
  description: string;
  latitude: string;
  longitude: string;
  phone: string;
  scheduler_customer_server: string;
  main: string;
  email: string;
}

export interface PickupLocationGet {
  id: number;
  created_at: string;
  updated_at: string;
  automatic_eod: string;
  location_id: number;
  name: string;
  county_id: number;
  county_name: string;
  locality_id: number;
  locality_name: string;
  street_id: number;
  street_name: string;
  building_number: string;
  address_text: string;
  contact_person: string;
  phone_number: string;
  email: string;
  cod_postal: string;
  postal_code: string;
  country_id: number;
  company_id: number;
  user_id: number;
  cargus_id: string;
}

export interface PickupLocationPost {
  email: string;
  name: string;
  county_id: number;
  county_name: string;
  locality_name: string;
  street_name: string;
  building_number: string;
  address_text: string;
  contact_person: string;
  phone_number: string;
  postal_code: string;
  street_id: number;
  locality_id: number;
  location_id: number;
  automatic_eod: string;
  country_id: number;
  company_id: number;
}

export interface County {
  id: number;
  name: string;
  slug: string;
  iso: string;
}

export interface City {
  id: number;
  state_id: number;
  name: string;
  slug: string;
}

export enum actionType {
  insert = 'insert',
  update = 'update',
}

// const orderScreenProduct = {
//   id: 99999,
//   category_id: 99999,
//   owner: '',
//   company: {
//     user_id: 99999,
//     name: '',
//     email: '',
//     slug: '',
//     logo: '',
//     full_logo: '',
//   },
//   group_hash: '',
//   name: '',
//   slug: '',
//   description: '',
//   stock: 99999,
//   size: '',
//   color: '',
//   hexcolor: '',
//   discount: 99999,
//   price: '',
//   price_discounted: '',
//   rating_score: '',
//   count_review: 99999,
//   has_vr: 99999,
//   category: {
//     id: 99999,
//     name: '',
//     slug: '',
//     parent: {
//       id: 99999,
//       name: '',
//       slug: '',
//       parent: '',
//     },
//   },
//   images: [],
//   thumbnail: '/assets/demo-glasses.png',
//   addons: [],
//   tab_description: '',
//   tab_detail: {
//     ['For']: '',
//     ['Frame Shape']: '',
//     ['Lens types']: '',
//     ['Brands']: '',
//     ['Style']: '',
//     ['Frame type']: '',
//     ['Face shape']: '',
//     ['Size']: '',
//     ['Lenses width']: '',
//     ['Temple length']: '',
//     ['Bridge width']: '',
//     ['Frame materials']: '',
//     ['Frame color']: '',
//     ['Lenses color']: '',
//     ['Characteristics']: '',
//     ['Period of use']: '',
//     ['Method of wearing']: '',
//     ['Type']: '',
//   },
//   siblings: {
//     sizes: [],
//     colors: [],
//     hexcolors: {},
//     merged: [],
//   },
//   taxonomies: [],
// };

// export const orderScreenProducts: OrderItem[] = [
//   { product: orderScreenProduct, amount: 1 },
//   { product: orderScreenProduct, amount: 1 },
//   { product: orderScreenProduct, amount: 1 },
// ];

export interface OrderItem {
  product: GlassesShow;
  amount: number;
}

export interface GlassesShow {
  id: number;
  category_id: number;
  owner: string;
  company: {
    user_id: number;
    name: string;
    email: string;
    slug: string;
    logo: string;
    full_logo: string;
  };
  group_hash: string;
  name: string;
  slug: string;
  description: string;
  stock: number;
  size: string;
  color: string;
  hexcolor: string;
  discount: number;
  price: string;
  price_discounted: string;
  rating_score: string;
  count_review: number;
  has_vr: number;
  category: {
    id: number;
    name: string;
    slug: string;
    parent: {
      id: number;
      name: string;
      slug: string;
      parent: string;
    };
  };
  images: GlassesImageShow[];
  thumbnail: string;
  addons: string[];
  tab_description: string;
  tab_detail: {
    ['For']: string;
    ['Frame Shape']: string;
    ['Lens types']: string;
    ['Brands']: string;
    ['Style']: string;
    ['Frame type']: string;
    ['Face shape']: string;
    ['Size']: string;
    ['Lenses width']: string;
    ['Temple length']: string;
    ['Bridge width']: string;
    ['Frame materials']: string;
    ['Frame color']: string;
    ['Lenses color']: string;
    ['Characteristics']: string;
    ['Period of use']: string;
    ['Method of wearing']: string;
    ['Type']: string;
  };
  siblings: {
    sizes: string[];
    colors: string[];
    hexcolors: {
      [key: string]: string;
    };
    merged: GlassesSiblingsMerged[];
  };
  taxonomies: GlassesTaxonomies[];
}

export interface GlassesImageShow {
  id: number;
  name: string;
  alt: string;
  featured: number;
}

export interface GlassesSiblingsMerged {
  id: number;
  name: string;
  slug: string;
  color: string;
  hexcolor: string;
  size: string;
  thumbnail: string;
}
export interface GlassesTaxonomies {
  id: number;
  product_id: number;
  taxonomy_name: string;
  taxonomy_slug: string;
  term_name: string;
  term_slug: string;
  relationship_slug: string;
}

export enum ProductType {
  EYEGLASSES = 1,
  SUNGLASSES = 2,
  COMPUTERGLASSES = 3,
  EYESIGHT_LENSES = 4,
  COSMETIC_LENSES = 5,
  OTHER_CATEGORIES = 6,
}

export interface Product {
  id: number;
  type: ProductType;
  name: string;
  visibility: string;
  stock: number;
  // discount: string;
  data: string;
}

export interface OrdersDetails {
  product: string;
  nrOrder: string;
  status: string;
  data: string;
  // discount: string;
  price: string;
}

export enum ProductCategory {
  EYEGLASSES = '1',
  SUNGLASSES = '2',
  COMPUTER_GLASSES = '3',
  CONTACT_LENSES = '4',
  COSMETIC_LENSES = '5',
  OTHER = '6',
}

export const emailRegex =
  /^[a-zA-Z0-9][a-zA-Z0-9_.-]*(\.[a-zA-Z][a-zA-Z0-9_.-]*)?@[a-z][a-zA-Z-0-9-]*\.[a-z]+(\.[a-z]+)?$/;

export interface MetaLinks {
  current_page: number;
  from: number;
  last_page: number;
  links: {
    url: string;
    label: string;
    active: boolean;
  }[];
  path: string;
  per_page: number;
  to: number;
  total: number
}

export enum SnackbarVariant {
  success = 'success',
  warning = 'warning',
  error = 'error',
  info = 'info',
}

export interface Country {
  id: number;
  name: string;
}

export interface CountyCargus {
  CountyId: number;
  Name: string;
  Abbreviation: string;
}

export interface CityCargus {
  LocalityId: number;
  Name: string;
  ParentId: number;
  ParentName: string;
  ExtraKm: number;
  InNetwork: boolean;
  CountyId: number;
  CountryId: number;
  PostalCode: string;
  MaxHour: string;
  SaturdayDelivery: boolean;
}
