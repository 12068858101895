import { makeStyles, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import bluePenBody from '../../assets/blue-pen-body.png';
import bluePenTop from '../../assets/blue-pen-top.png';
import defaultProfile from '../../assets/default-profile-picture.png';
import whiteEllipse from '../../assets/white-ellipse.png';
import UserAccountDialog, {
  USER_ACCOUNT_DIALOG_TYPE,
} from '../../components/AccountData/AccountDialog/AccountDialog';
import Header from '../../components/Header';
import { showSnack } from '../../state/actions';
import { getBankDetails, getCompany, getUser } from '../../state/commands/user';
import { SnackbarVariant } from '../../utils/constants';

const AccountDataContainer: FC<{}> = () => {
  const classes = useStyles();
  const [userAccountDialogOpened, setUserAccountDialogOpened] = useState(false);
  const [userAccountDialogType, setUserAccountDialogType] = useState<USER_ACCOUNT_DIALOG_TYPE>(
    USER_ACCOUNT_DIALOG_TYPE.EDIT_ACCOUNT,
  );
  const [opticalCabinetName, setOpticalCabinetName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [IBAN, setIBAN] = useState<string>('');
  const [accountHolder, setAccountHolder] = useState<string>('');
  const [bank, setBank] = useState<string>('');
  const [changePassword, setChangePassword] = useState<boolean>(false);
  const [bankAccountId, setBankAccountId] = useState<string>('');
  const { t } = useTranslation();
  const [companyId, setCompanyId] = useState<number>(-1);

  const [updatedBankDetails, setUpdatedBankDetails] = useState<boolean>(false);

  useEffect(()=>{
    loadUser();
    loadBank();
  },[])

  useEffect(()=>{
    loadBank();
  },[updatedBankDetails])

  const loadUser = async () => {
    try {

      const userDetailsResponse = await getUser();
      const userId = userDetailsResponse.data.data.id.toString();
        
      const companyDetailsResponse = await getCompany(userId);
      let name = companyDetailsResponse.data.name.replace(/\+/g,' ')

      setOpticalCabinetName(name);
      setEmail(userDetailsResponse.data.data.email);
      setCompanyId(userDetailsResponse.data.data.id);
    } catch(e) {
      showSnack(e.message, SnackbarVariant.error);
    }
  };

  const loadBank = async () => {
    try {
      const response = await getBankDetails();
      if(response.data.length>0)
      {
        setBankAccountId(response.data[0].id.toString())
        const hiddenCharacter = 'X'
        const iban = response.data[0].iban;
        const hiddenIban = iban.substring(0, 10)+hiddenCharacter.repeat(iban.length-iban.substring(0, 10).length);
        setIBAN(hiddenIban);
        setAccountHolder(response.data[0].name);
        setBank(response.data[0].bank);
      }
      
    } catch(e) {
      showSnack(e.message, SnackbarVariant.error);
    }
  };

  const openUserAccountDialog = (type: USER_ACCOUNT_DIALOG_TYPE) => {
    setUserAccountDialogOpened(true);
    setUserAccountDialogType(type);
  };

  const changeAccountData = () => {
    openUserAccountDialog(USER_ACCOUNT_DIALOG_TYPE.EDIT_ACCOUNT);
  };

  const changeBankingData = () => {
    openUserAccountDialog(USER_ACCOUNT_DIALOG_TYPE.EDIT_BANKING);
  };

  const handleCloseUserAccountDialog = () => {
    setUserAccountDialogOpened(false);
    setChangePassword(false);
  };

  const changeAccountPassword  = () => {
    openUserAccountDialog(USER_ACCOUNT_DIALOG_TYPE.EDIT_ACCOUNT);
    setChangePassword(true);
  };

  return (
    <div className={classes.root}>
      <Header tabName={t('OPTICAL_ACCOUNT_DETAILS')} />
      <div className={classes.info}>
        <div className={classes.accountInfo}>
          <Typography className={classes.bold} variant={'h4'}>
            {t('ACCOUNT_DETAILS')}
          </Typography>
          <div className={classes.userInfo}>
            <div className={classes.image}>
              <img alt={'alt1'} src={defaultProfile} width={90} height={90} />
              <div className={classes.ellipseAndPen}>
                <img alt={'alt1'} src={whiteEllipse} width={24} height={24} />
                <div className={classes.penBody}>
                  <div className={classes.penTop}>
                    <img alt={'alt1'} src={bluePenTop} width={5} height={5} />
                  </div>
                  <img alt={'alt1'} src={bluePenBody} width={12} height={12} />
                </div>
              </div>
            </div>
            <div className={classes.userData}>
              <div className={classes.inlineText}>
                <Typography className={classes.greyText} variant={'subtitle2'}>
                  {t('OPTICAL_CABINET_NAME')}:
                </Typography>
                <div className={classes.userDetails}>
                  <Typography variant={'subtitle2'}>{opticalCabinetName}</Typography>
                </div>
              </div>
              <div className={classes.inlineText}>
                <Typography className={classes.greyText} variant={'subtitle2'}>
                  {t('EMAIL')}:
                </Typography>
                <div className={classes.userDetails}>
                  <Typography variant={'subtitle2'}>{email}</Typography>
                </div>
              </div>
              <div className={classes.inlineText}>
                <Typography className={classes.greyText} variant={'subtitle2'}>
                  {t('PASSWORD')}:
                </Typography>
                <div className={classes.userDetails}>
                  <Typography variant={'subtitle2'}>{'**********'}</Typography>
                </div>
                  <Typography className={classes.changePassword} variant={'body1'} color="primary" onClick={changeAccountPassword}>{t('MODIFY_PASSWORD')}</Typography>
              </div>
            </div>
          </div>
          <div className={classes.line} />
          <Typography
            onClick={changeAccountData}
            color="primary"
            className={classes.editAccountText}
            variant={'body1'}
          >
            {t('MODIFY_ACCOUNT')}
          </Typography>
        </div>

        <div className={classes.accountInfo}>
          <Typography className={classes.bold} variant={'h4'}>
            {t('BANK_ACCOUNT_INFO')}
          </Typography>

          <div className={classes.userData}>
            <div className={classes.inlineText}>
              <Typography className={classes.greyText} variant={'subtitle2'}>
                {t('IBAN')}:
              </Typography>
              <div className={classes.userDetails}>
                <Typography variant={'subtitle2'}>{IBAN}</Typography>
              </div>
            </div>
            <div className={classes.inlineText}>
              <Typography className={classes.greyText} variant={'subtitle2'}>
                {t('ACCOUNT_HOLDER')}:
              </Typography>
              <div className={classes.userDetails}>
                <Typography variant={'subtitle2'}>{accountHolder}</Typography>
              </div>
            </div>
            <div className={classes.inlineText}>
              <Typography className={classes.greyText} variant={'subtitle2'}>
                {t('BANK')}:
              </Typography>
              <div className={classes.userDetails}>
                <Typography variant={'subtitle2'}>{bank}</Typography>
              </div>
            </div>
          </div>

          <div className={classes.line} />
          <Typography
            onClick={changeBankingData}
            color="primary"
            className={classes.editAccountText}
            variant={'body1'}
          >
            {t('EDIT_DETAILS')}
          </Typography>
        </div>
      </div>
      <UserAccountDialog
        open={userAccountDialogOpened}
        onClose={handleCloseUserAccountDialog}
        type={userAccountDialogType}
        setOpticalCabinetName={setOpticalCabinetName}
        setEmail={setEmail}
        changePassword={changePassword}
        setUpdatedBankDetails={setUpdatedBankDetails}
        updatedBankDetails={updatedBankDetails}
        bankAccountId={bankAccountId}
        companyID={companyId}
      />
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  info: {
    display: 'grid',
    gridTemplateRows: '0.5fr 0.5fr',
  },
  accountInfo: {
    display: 'grid',
    gridTemplateRows: '0.2fr 0.5fr 0.1fr 0.2fr',
    boxShadow: '0px 3px 14px rgba(0, 0, 0, 0.12)',
    width: '80%',
    borderRadius: theme.spacing(0.5),
    margin: theme.spacing(2),
    padding: theme.spacing(2),
  },
  bold: {
    fontWeight: 700,
  },
  userInfo: {
    display: 'grid',
    gridTemplateColumns: '0.2fr 0.8fr',
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(2.5),
    },
  },
  image: {
    display: 'grid',
    width: '90px',
    height: '90px',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      width: '60px',
      height: '60px',
    },
  },
  ellipseAndPen: {
    display: 'grid',
    position: 'absolute',
    justifySelf: 'end',
    alignSelf: 'end',
    gridGap: theme.spacing(2.5),
    [theme.breakpoints.down('sm')]: {
      gridGap: theme.spacing(1),
    },
  },
  penTop: {
    display: 'grid',
    position: 'absolute',
    justifySelf: 'end',
  },
  penBody: {
    display: 'grid',
    position: 'absolute',
    justifySelf: 'center',
    alignSelf: 'center',
  },
  userData: {
    display: 'grid',
    gridTemplateRows: '0.25fr 0.25fr 0.25fr',
  },
  greyText: {
    color: theme.palette.grey[200],
  },
  userDetails: {
    paddingLeft: theme.spacing(3.25),
  },
  line: {
    width: '100%',
    height: '1px',
    margin: `${theme.spacing(0.5)}px 0px ${theme.spacing(0.5)}px 0px`,
    backgroundColor: theme.palette.grey[200],
  },
  editAccountText: {
    justifySelf: 'center',
    alignSelf: 'center',
    cursor: 'pointer',
  },
  title: {
    boxShadow: '0px 3px 14px rgba(0, 0, 0, 0.12)',
    borderRadius: theme.spacing(0.5),
  },
  titleText: {
    padding: theme.spacing(2),
  },
  inlineText: {
    display: 'flex',
    flexDirection: 'row',
    padding: '4px 0px',
  },
  changePassword: {
    paddingLeft: theme.spacing(5),
    cursor: 'pointer',
  }
}));

export default AccountDataContainer;
