import { Dialog, makeStyles, Typography } from '@material-ui/core';
import GoogleMap from 'google-map-react';
import React, { FC, useEffect } from 'react';
import Geocode from 'react-geocode';
import { useTranslation } from 'react-i18next';
import { deleteLocation, updateLocation } from '../../state/commands/location';
import { actionType, Location } from '../../utils/constants';
import LocationDialog from './LocationDialog/LocationDialog';

interface Props {
  data: Location;
  update: () => void;
}

const LocationData: FC<Props> = props => {
  const classes = useStyles();
  const { data, update } = props;
  const [locationDialogOpen, setLocationDialogOpen] = React.useState<boolean>(false);
  const [latitude, setLatitude] = React.useState<number>(0);
  const [longitude, setLongitude] = React.useState<number>(0);
  const [forceRefresh, setForceRefresh] = React.useState<number>();

  const { t } = useTranslation();

  const [mapProps, setMapProps] = React.useState({
    center: {
      lat: 0,
      lng: 0,
    },
    zoom: 15,
  });

  Geocode.setApiKey('AIzaSyCXw7orgOAekPHJQDWLDc_LwtGboK1GVkc');
  Geocode.setLanguage('en');
  Geocode.setRegion('ro');
  Geocode.enableDebug();

  useEffect(() => {
    getGeoCodeLocation();
    // eslint-disable-next-line
  }, []);

  const getGeoCodeLocation = () => {
    const completeAddress =
      data.street +
      ' ' +
      data.street_number +
      ' ' +
      data.city +
      ' ' +
      data.state +
      ' ' +
      data.country;

    Geocode.fromAddress(completeAddress).then(
      response => {
        const { lat, lng } = response.results[0].geometry.location;
        setLatitude(lat);
        setLongitude(lng);
        setMapProps({
          ...mapProps,
          center: {
            lat: Number(lat),
            lng: Number(lng),
          },
          zoom: 15,
        });
        setForceRefresh(Math.random());
      },
      error => {
        console.error(error);
      },
    );
  };

  useEffect(()=>{
    if(latitude!==0 && longitude!==0 && data.latitude==='0' && data.longitude==='0')
    {
      updateLatAndLong();
    }
    
  },[latitude,longitude])

  const updateLatAndLong = async () => {
    const newLocation: Location = {
      id: data.id,
      country: data.country,
      state: data.state,
      city: data.city,
      street: data.street,
      street_number: data.street_number,
      zipcode: data.zipcode,
      description: data.description,
      latitude: latitude.toString(),
      longitude: longitude.toString(),
      phone: data.phone,
      main: '0',
      scheduler_customer_server: data.scheduler_customer_server,
      email: data.email,
    };
      try {
        await updateLocation(data.id, newLocation);
        update();
      } catch (e) {
        console.error('Nu am putut actualiza coordonatele!');
      }

  };

  const handleUpdateLocation = () => {
    setLocationDialogOpen(true);
  };

  const closeLocationDialog = () => {
    setLocationDialogOpen(false);
  };

  const handleDeleteLocation = async () => {
    await deleteLocation(data.id);
    update();
  };

  // tslint:disable-next-line:no-any
  const handleGoogleApiLoaded = ({ map, maps }: { map: any; maps: any }) => {
    renderMarkers(map, maps);
  };

  // tslint:disable-next-line:no-any
  const renderMarkers = (map: any, maps: any) => {
    const marker = new maps.Marker({
      position: {
        lat: Number(latitude),
        lng: Number(longitude),
      },
      map,
      title: data.description,
      icon: {
        url: 'https://maps.google.com/mapfiles/ms/icons/blue-dot.png',
      },
    });
    marker.addListener('click', () => {
      map.setZoom(16);
      map.setCenter(marker.getPosition());
    });
    return marker;
  };

  return (
    <div className={classes.root}>
      <div className={classes.topPart}>
        <div className={classes.info}>
          <Typography className={classes.bold} color="textPrimary" variant={'h4'}>
            {t('LOCATION_DETAILS')}
          </Typography>
          <div className={classes.infoColumns}>
            <div className={classes.columns}>
              <div className={classes.rows}>
                <Typography className={classes.grey} color="textPrimary" variant={'subtitle2'}>
                  {t('COUNTY')}
                </Typography>
                <Typography className={classes.bold} color="textPrimary" variant={'subtitle2'}>
                  {data.state}
                </Typography>
              </div>
              <div className={classes.rows}>
                <Typography className={classes.grey} color="textPrimary" variant={'subtitle2'}>
                  {t('TOWN')}
                </Typography>
                <Typography className={classes.bold} color="textPrimary" variant={'subtitle2'}>
                  {data.city}
                </Typography>
              </div>
              <div className={classes.rows}>
                <Typography className={classes.grey} color="textPrimary" variant={'subtitle2'}>
                  {t('STREET')}
                </Typography>
                <Typography className={classes.bold} color="textPrimary" variant={'subtitle2'}>
                  {data.street}
                </Typography>
              </div>
            </div>
            <div className={classes.columns}>
              <div className={classes.rows}>
                <Typography className={classes.grey} color="textPrimary" variant={'subtitle2'}>
                  {t('NUMBER')}
                </Typography>
                <Typography className={classes.bold} color="textPrimary" variant={'subtitle2'}>
                  {data.street_number}
                </Typography>
              </div>
              <div className={classes.rows}>
                <Typography className={classes.grey} color="textPrimary" variant={'subtitle2'}>
                  {t('ZIP')}
                </Typography>
                <Typography className={classes.bold} color="textPrimary" variant={'subtitle2'}>
                  {data.zipcode}
                </Typography>
              </div>
              <div className={classes.rows}>
                <Typography className={classes.grey} color="textPrimary" variant={'subtitle2'}>
                  {t('OTHER_DETAILS')}
                </Typography>
                <Typography className={classes.bold} color="textPrimary" variant={'subtitle2'}>
                  {data.description}
                </Typography>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.map}>
          <GoogleMap
            bootstrapURLKeys={{
              key: 'AIzaSyCXw7orgOAekPHJQDWLDc_LwtGboK1GVkc',
            }}
            key={forceRefresh}
            center={mapProps.center}
            defaultZoom={5}
            zoom={mapProps.zoom}
            yesIWantToUseGoogleMapApiInternals={true}
            onGoogleApiLoaded={handleGoogleApiLoaded}
          />
        </div>
      </div>
      <div className={classes.buttons}>
        <Typography
          color="primary"
          className={classes.pointer}
          variant={'subtitle2'}
          onClick={handleUpdateLocation}
        >
          {t('EDIT_LOCATION')}
        </Typography>
        <div className={classes.end}>
          <Typography
            color="secondary"
            className={classes.pointer}
            variant={'subtitle2'}
            onClick={handleDeleteLocation}
          >
            {t('DELETE_LOCATION')}
          </Typography>
        </div>
      </div>
      <Dialog
        onClose={closeLocationDialog}
        open={locationDialogOpen}
        fullWidth={true}
        maxWidth="md"
      >
        <LocationDialog
          onClose={closeLocationDialog}
          update={update}
          data={data}
          action={actionType.update}
        />
      </Dialog>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: '0px 2px 15px rgba(0, 0, 0, 0.1)',
    marginRight: theme.spacing(6),
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  topPart: {
    display: 'grid',
    gridTemplateColumns: '1fr 0.25fr',
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
  bold: {
    fontWeight: 700,
  },
  info: {
    display: 'grid',
  },
  map: {
    width: '258px',
    height: '159px',
    position: 'relative',
  },
  infoColumns: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 0.5fr))',
  },
  columns: {
    display: 'grid',
  },
  rows: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(100px, 0.5fr))',
  },
  grey: {
    color: 'grey',
  },
  buttons: {
    textAlign: 'center',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
    gridGap: theme.spacing(5),
  },
  pointer: {
    cursor: 'pointer',
  },
  end: {
    justifyContent: 'end',
  },
}));

export default LocationData;
