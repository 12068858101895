import { Dialog } from '@material-ui/core';
import { FC } from 'react';
import EditAccountDialog from './EditAccountDialog';
import EditBankingDialog from './EditBankingDialog';

export enum USER_ACCOUNT_DIALOG_TYPE {
  EDIT_ACCOUNT,
  EDIT_BANKING,
}

interface Props {
  open: boolean;
  type: USER_ACCOUNT_DIALOG_TYPE;
  onClose: () => void;
  setOpticalCabinetName: (opticalCabinetName: string) => void;
  setEmail: (email: string) => void;
  changePassword: boolean;
  setUpdatedBankDetails: (updated: boolean) => void;
  updatedBankDetails: boolean;
  bankAccountId: string;
  companyID: number;
}

const UserAccountDialog: FC<Props> = props => {
  const { onClose, open, type,setOpticalCabinetName,setEmail,changePassword,setUpdatedBankDetails,updatedBankDetails,bankAccountId,companyID } = props;

  return (
    <Dialog onClose={onClose} open={open} fullWidth={true} maxWidth="md">
      {type === USER_ACCOUNT_DIALOG_TYPE.EDIT_ACCOUNT ? (
        <EditAccountDialog onClose={onClose}  setOpticalCabinetName={setOpticalCabinetName} setEmail={setEmail} changePassword={changePassword}/>
      ) : (
        <EditBankingDialog onClose={onClose} setUpdatedBankDetails={setUpdatedBankDetails}  updatedBankDetails={updatedBankDetails} bankAccountId={bankAccountId} companyID={companyID}/>
      )}
    </Dialog>
  );
};

export default UserAccountDialog;
