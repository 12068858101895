import {
  Checkbox,
  FormControlLabel,
  makeStyles,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Colors from '../../components/AddProduct/Colors';
import Add3DImage from '../../components/AddProduct/Images';
import Parameters from '../../components/AddProduct/Parameters';
import PriceAndDescription from '../../components/AddProduct/ProductPriceAndDescription';
import PublishOrSave from '../../components/AddProduct/PublishOrSave';
import Header from '../../components/Header';
import { resetProduct, setButtonPressed, setCategoryProduct, setDioptersProduct, setMethodOfWearingProduct, setPeriodOfUseProduct } from '../../state/actions';
import { getProductInfo } from '../../state/commands/product';
import { createProductInitial } from '../../state/initialStore';
import { InitialState, LenseDiopter, ProductShow, SphCylLense } from '../../state/types';
import { CosmeticLensesInfo, initialCosmeticlensesInfo, Product, ProductCategory, Taxonomy } from '../../utils/constants';

interface Props {
  item?: ProductShow;
}

const AddCosmeticLensesContainer: FC<Props> = props => {
  const { item } = props;
  const classes = useStyles();
  const buttonPressed = useSelector((state: InitialState) => state.buttonPressed);
  const [periodOfUse, setPeriodOfUse] = useState<string>(typeof item !== 'undefined' ? item.details['Period of use']?.id.toString() : '');
  const [methodOfWearing, setMethodOfWearing] = useState<string>(typeof item !== 'undefined' ? item.details['Method of wearing']?.id.toString() : '');
  const { t } = useTranslation();

  const [cosmeticlensesInfo, setCosmeticlensesInfo] = useState<CosmeticLensesInfo>(initialCosmeticlensesInfo);

  useEffect(() => {
    setCategoryProduct(ProductCategory.COSMETIC_LENSES);
    getCosmeticlensesDetails();
  }, []);

  useEffect(()=>{
    if(typeof item === 'undefined')
    {
      resetProduct(createProductInitial);
      setCategoryProduct(ProductCategory.COSMETIC_LENSES);
      setButtonPressed(false);
    }
  },[])

  const getCosmeticlensesDetails = async () => {
    try {
      const newCosmeticlensesInfo = await getProductInfo(ProductCategory.COSMETIC_LENSES);
      setCosmeticlensesInfo(newCosmeticlensesInfo.data);
    } catch (e) {
      console.error(e.message);
    }
  };

  const [index,setIndex] = useState<number>(0);

  useEffect(()=>{
    if(typeof item !== 'undefined')
    {
      for(let i=0;i<item.lenses.diopter.length;i++)
      {
        if(item.lenses.diopter[i].id===2)
        {
          setWithDiopters(true);
          setIndex(i);
        }
        if(item.lenses.diopter[i].id===1)
        {
          setWithoutDiopters(true);
        }
      }
      setPeriodOfUseProduct(periodOfUse);
      setMethodOfWearingProduct(methodOfWearing);
    }
    else {
      setMethodOfWearingProduct('419'); // daily wear
    }
  },[])

  const [withDiopters, setWithDiopters] = React.useState<boolean>(false);
  const [withoutDiopters, setWithoutDiopters] = React.useState<boolean>(false);

  const lenseDiopterStore = useSelector((state: InitialState) => state.product.lense_diopter);

  const toggleWithoutDiopters = () => {
    if(!withoutDiopters===true)
    {
      const lenseDiopter: LenseDiopter[] = [...lenseDiopterStore];
      const sph: SphCylLense[] = [];
      const cyl: SphCylLense[] = [];
      sph[0]={
        min: '0',
        max: '0',
      }
      cyl[0]={
        min: '0',
        max: '0',
      }
      lenseDiopter.push({
        id: '1',
        radius: '0',
        diameter: '0',
        sph: sph,
        cyl: cyl,
      })
      setWithoutDiopters(!withoutDiopters);
      setDioptersProduct(lenseDiopter);
    }
    else{
      const lenseDiopter: LenseDiopter[] = [...lenseDiopterStore];
      const aux: LenseDiopter[] = [];
      for(let i=0;i<lenseDiopter.length;i++)
      {
        if(lenseDiopter[i].id!=='1')
        {
          aux.push(lenseDiopter[i]);
        }
      }
      setWithoutDiopters(!withoutDiopters);
      setDioptersProduct(aux);
    }
  };

  const toggleWithDiopters = () => {
    setWithDiopters(!withDiopters);
    if(withDiopters===true)
    {
      const lenseDiopter: LenseDiopter[] = [...lenseDiopterStore];
      const aux: LenseDiopter[] = [];
      for(let i=0;i<lenseDiopter.length;i++)
      {
        if(lenseDiopter[i].id!=='2')
        {
          aux.push(lenseDiopter[i]);
        }
      }
      setDioptersProduct(aux);
    }
  };

  const periodOfUseChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPeriodOfUse(event.target.value);
    setPeriodOfUseProduct(event.target.value);
  };

  const methodOfWearingChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMethodOfWearing(event.target.value);
    setMethodOfWearingProduct(event.target.value);
  };
  
  const addPeriodOfUse = () => {
    const periodUse = [];
    for(let i=0;i<cosmeticlensesInfo.taxonomies['period-of-use'].length;i++)
    {
      if(cosmeticlensesInfo.taxonomies['period-of-use'][i].name !== 'Annual' && cosmeticlensesInfo.taxonomies['period-of-use'][i].name !== 'Semimonthly' && cosmeticlensesInfo.taxonomies['period-of-use'][i].name !== 'Monthly' && cosmeticlensesInfo.taxonomies['period-of-use'][i].name !== 'Quarterly' && cosmeticlensesInfo.taxonomies['period-of-use'][i].name !== 'Single use')
      {
        periodUse.push(<MenuItem key={i} value={cosmeticlensesInfo.taxonomies['period-of-use'][i].taxonomy_relationship_id}>
        {cosmeticlensesInfo.taxonomies['period-of-use'][i].name}
          </MenuItem>)
      }
    }
    return periodUse;
  };

  useEffect(()=>{
    if(cosmeticlensesInfo.brands.length!==0)
    {
      let auxArray: Taxonomy[]= [...cosmeticlensesInfo.brands];
      let aux;
      let index = -1;
      for(let i=0;i<auxArray.length;i++)
      {
        if(auxArray[i].name==='Altele')
        {
          index = i;
          break;
        }
      }
      if (index > -1) {
        aux = auxArray[index];
        auxArray.splice(index, 1);
        auxArray.push(aux);
        setBrands(auxArray);
      }      
    }
  },[cosmeticlensesInfo])

  const [brands, setBrands] = useState<Taxonomy[]>([]);

  return (
    <div className={classes.root}>
      {typeof item === 'undefined' ? <Header tabName={t('ADD_COSMETIC_LENSES')} /> : null}
      <div className={classes.productInfo}>
        <PriceAndDescription brands={brands} item={item}/>
        <div className={classes.lensesDetails}>
          <Typography color="textPrimary" variant={'body1'}>
            {t('PERIOD_USE')}*
          </Typography>
          {/* <Typography color="textPrimary" variant={'body1'}>
            {t('METHOD_WEARING')}*
          </Typography> */}
          <div/>
          <TextField className={classes.input} select={true} value={periodOfUse} onChange={periodOfUseChangeHandler} error={periodOfUse === '' && buttonPressed===true}>
          {/* {cosmeticlensesInfo.taxonomies['period-of-use'].map((option,index) => (
              <MenuItem key={index} value={option.taxonomy_relationship_id}>
                {option.name}
              </MenuItem>
            ))} */}
            {addPeriodOfUse()}
          </TextField>
          {/* <TextField className={classes.input} select={true} value={methodOfWearing} onChange={methodOfWearingChangeHandler} error={methodOfWearing === '' && buttonPressed===true}>
          {cosmeticlensesInfo.taxonomies['method-of-wearing'].map((option,index) => (
              <MenuItem key={index} value={option.taxonomy_relationship_id}>
                {option.name}
              </MenuItem>
            ))}
          </TextField> */}
        </div>
        <Typography className={classes.colorTitle} color="textPrimary" variant={'body1'}>
          {t('LENSES_COLOR')}*
        </Typography>
        <Colors lensesColor={cosmeticlensesInfo.taxonomies["lenses-color"]} color={item?.color.lense !== null ? item?.color.lense : undefined}/>
        <Add3DImage item={item}/>
        <Typography className={classes.bold} color="textPrimary" variant={'body1'}>
          {t('CHOOSE_PARAMETERS')}
        </Typography>
        {/* <div className={classes.checkboxes}>
          <FormControlLabel
            className={classes.checkbox}
            value="prescription"
            control={<Checkbox color="primary" />}
            label={t('WITHOUT_DIOPTERS')}
            labelPlacement="end"
            checked={withoutDiopters}
            onClick={toggleWithoutDiopters}
          />
          <FormControlLabel
            className={classes.checkbox}
            value="prescription"
            control={<Checkbox color="primary" />}
            label={t('WITH_DIOPTERS')}
            labelPlacement="end"
            checked={withDiopters}
            onClick={toggleWithDiopters}
          />
          <Typography color="textPrimary" variant={'body1'}>
              {t('ONE_OPTION_SHOULD_SELECT')}
          </Typography>
        </div> */}
        <Parameters item={item} category="cosmetic"/>
        <PublishOrSave item={item}/>
      </div>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  productInfo: {
    padding: `${theme.spacing(4)}px ${theme.spacing(2)}px ${theme.spacing(6)}px ${theme.spacing(
      2,
    )}px`,
  },
  lensesDetails: {
    display: 'grid',
    gridTemplateColumns: 'minmax(100px, 0.5fr) minmax(100px, 0.5fr)',
    columnGap: theme.spacing(4),
    padding: `0px ${theme.spacing(3)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
    background: theme.palette.primary.light,
  },
  input: {
    display: 'grid',
    backgroundColor: theme.palette.background.default,
    borderRadius: '5px',
    height: '34px',
    marginBottom: theme.spacing(3),
  },
  colorTitle: {
    background: theme.palette.primary.light,
    paddingLeft: theme.spacing(3),
    paddingBottom: theme.spacing(1),
  },
  bold: {
    fontWeight: 700,
    paddingBottom: theme.spacing(1.5),
    paddingTop: theme.spacing(3),
  },
  checkbox: {
    margin: '0',
  },
  checkboxes: {
    paddingLeft: theme.spacing(2),
    background: theme.palette.primary.light,
    display: 'grid',
    gridTemplateRows: '0.5fr 0.5fr',
  },
}));

export default AddCosmeticLensesContainer;
