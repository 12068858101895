import { makeStyles, Typography } from '@material-ui/core';
import { useEffect } from 'react';
import { useState } from 'react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import Colors from '../../components/AddProduct/Colors';
import Add3DImage from '../../components/AddProduct/Images';
import PrescriptionThickness from '../../components/AddProduct/PrescriptionAndThickness';
import PriceAndDescription from '../../components/AddProduct/ProductPriceAndDescription';
import ShapeAndSize from '../../components/AddProduct/ProductShapeAndSize';
import PublishOrSave from '../../components/AddProduct/PublishOrSave';
import Upgrades from '../../components/AddProduct/Upgrades';
import Header from '../../components/Header';
import { resetProduct, setButtonPressed, setCategoryProduct, setLenseImprovementProduct, setLensesColorProduct, setLenseThicknessProduct } from '../../state/actions';
import { getPickupPoint } from '../../state/commands/location';
import { getProductInfo } from '../../state/commands/product';
import { createProductInitial } from '../../state/initialStore';
import { InitialState, ProductShow, Size } from '../../state/types';
import { EyeGlassesInfo, initialEyeglassesInfo, Product, ProductCategory, Taxonomy } from '../../utils/constants';

interface Props {
  item?: ProductShow;
}

const size: Size = {width: [],length: [],bridge: []};

const AddEyeglassesContainer: FC<Props> = props => {
  const classes = useStyles();
  const { item } = props;
  const [eyeglassesInfo, setEyeglassesInfo] = useState<EyeGlassesInfo>(initialEyeglassesInfo);
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();
  const product = useSelector((state: InitialState) => state.product);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)

    if (queryParams.has('token')) {
      const token = queryParams.get('token');
      if(token!==null)
      {
        sessionStorage.setItem('sessionToken',token);
      }
      queryParams.delete('token')
      history.replace({
        search: queryParams.toString(),
      })
      window.location.reload();
    }
    getUserLocation();
  }, [])

  const getUserLocation = async () => {
    try {
      const pickupPoint = await getPickupPoint();
      sessionStorage.setItem('pickupPoint',pickupPoint.length);
    } catch (e) {
      console.error('Nu am putut incarca locatia de ridicare!');
    }
  };

  useEffect(() => {
    setCategoryProduct(ProductCategory.EYEGLASSES);
    getEyeglassesDetails();
    generateSize();
  }, []);

  useEffect(()=>{
    if(typeof item === 'undefined')
    {
      const lenseThickness = [...product.lense_thickness];
      const lenseImprovement = [...product.lense_improvement];
      console.log(product.lense_thickness)
      resetProduct(createProductInitial);
      setCategoryProduct(ProductCategory.EYEGLASSES);
      setButtonPressed(false);
      setLensesColorProduct('391'); // cristal
      
      setLenseThicknessProduct(lenseThickness);
      setLenseImprovementProduct(lenseImprovement);
    }
  },[])

  const getEyeglassesDetails = async () => {
    try {
      const newEyeglassesInfo = await getProductInfo(ProductCategory.EYEGLASSES);
      setEyeglassesInfo(newEyeglassesInfo.data);
    } catch (e) {
      console.error(e.message);
    }
  };

  useEffect(()=>{
    if(eyeglassesInfo.brands.length!==0)
    {
      let auxArray: Taxonomy[]= [...eyeglassesInfo.brands];
      let aux;
      let index = -1;
      for(let i=0;i<auxArray.length;i++)
      {
        if(auxArray[i].name==='Altele')
        {
          index = i;
          break;
        }
      }
      if (index > -1) {
        aux = auxArray[index];
        auxArray.splice(index, 1);
        auxArray.push(aux);
        setBrands(auxArray);
      }      
    }
  },[eyeglassesInfo])

  const [brands, setBrands] = useState<Taxonomy[]>([]);

  const generateSize = () => {
    for (let i = 35; i <= 70; i+=0.5) {
      size.width.push(i.toString());
    }
    for (let i = 90; i <= 155; i+=0.5) {
      size.length.push(i.toString());
    }
    for (let i = 9; i <= 26; i+=0.5) {
      size.bridge.push(i.toString());
    }
  }

  return (
    <div className={classes.root}>
      {/* {console.log(product)} */}
      {typeof item === 'undefined' ? <Header tabName={t('ADD_EYEGLASSES')} /> : null}
      <div className={classes.productInfo}>
        <PriceAndDescription brands={brands} item={item}/>
        <ShapeAndSize taxonomies={eyeglassesInfo.taxonomies} size={size} item={item}/>
        <Typography className={classes.colorTitle} color="textPrimary" variant={'body1'}>
        {t('FRAME_COLOR')}*
        </Typography>
        <Colors frameColor={eyeglassesInfo.taxonomies['frame-color']} color={item?.color.frame !== null ? item?.color.frame : undefined}/>
        {/* <Typography className={classes.colorTitle} color="textPrimary" variant={'body1'}>
          {t('LENSES_COLOR')}*
        </Typography>
        <Colors lensesColor={eyeglassesInfo.taxonomies["lenses-color"]} color={item?.color.lense !== null ?  item?.color.lense.toLowerCase() : undefined}/> */}
        <Add3DImage item={item}/>
        <PrescriptionThickness thickness={item?.configurator.thickness} type={item?.lenses.type} taxonomies={eyeglassesInfo.taxonomies} item={item}/>
        <Upgrades improvements={item?.configurator.improvement}/>
        <PublishOrSave item={item}/>
      </div>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  productInfo: {
    padding: `${theme.spacing(4)}px ${theme.spacing(2)}px ${theme.spacing(6)}px ${theme.spacing(
      2,
    )}px`,
  },
  colorTitle: {
    background: theme.palette.primary.light,
    paddingLeft: theme.spacing(3),
    paddingBottom: theme.spacing(1),
  },
}));

export default AddEyeglassesContainer;
