import { Button, makeStyles, Typography } from '@material-ui/core';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import Header from '../../components/Header';
import DetailsAndSize from '../../components/ProductDetails/DetailsAndSize';
import GeneralPriceDescriptionColor from '../../components/ProductDetails/GeneralPriceDescriptionColor';
import Images from '../../components/ProductDetails/Images';
import Lenses from '../../components/ProductDetails/Lenses';
import UpgradesAndThickness from '../../components/ProductDetails/UpgradesAndThickness';
import { showSnack } from '../../state/actions';
import { deleteProduct } from '../../state/commands/product';
import { ProductShow } from '../../state/types';
import { PATHS } from '../../utils/appPaths';
import browserHistory from '../../utils/browserhistory';
import { ProductCategory, SnackbarVariant } from '../../utils/constants';

export enum lensesPricingType {
  CLOSEUP = 1,
  DISTANCE = 2,
}

const ProductsDetailsContainer: FC<{}> = () => {
  const classes = useStyles();
  const location = useLocation();
  const [product,setProduct]=useState<ProductShow>();
  const { t } = useTranslation();

    useEffect(() => {
      const state = location.state as ProductShow
      setProduct(state);
    }, [location]);

    const navigateTo = (route: string) => {
      browserHistory.push(route);
    };

    const removeProduct = async () => {
      if(typeof product !== 'undefined')
      {
        try {
        const response = await deleteProduct(product.general.id.toString());
        if(response.status==='success')
        {
          navigateTo(PATHS.APP.PRODUCTS_MANAGEMENT.ROOT);
        }
        } catch(e) {
          showSnack(e.message, SnackbarVariant.error);
        }
      }
      
    };

    const navigateToWithProduct = (route: string,state: ProductShow) => {
      // browserHistory.push(route);
      browserHistory.push({
        pathname: route,
        state: state
      });
    };

  return (
    product!==undefined ? <div className={classes.root}>
    <Header tabName={product.general.brand+' '+product.general.model}/>

    <div className={classes.productInfo}>
      <Typography className={classes.productID} variant="subtitle1">
        {t('PRODUCT_DETAILS')+' '+product.general.id}
      </Typography>
      <GeneralPriceDescriptionColor categoryId={product.category.id} brand={product.general.brand} model={product.general.model} stock={product.general.stoc} fullPrice={product.price.price} discount={product.price.discount} finalPriceAfterDiscount={product.price.price_discounted} textDescription={product.description} frameColor={product.color.frame.length!==0 ? product.color.frame[0].name:''} lenseColor={product.color.lense.length!==0 ? product.color.lense[0].name:''} id={product.general.id}/>
      {product.category.id.toString()===ProductCategory.OTHER ? null :<DetailsAndSize faceShape={product.details['Face shape']?.name} frameShape={product.details['Frame Shape']?.name} lensTypes={product.details['Lens types']?.name} frameType={product.details['Frame type']?.name} frameMaterials={product.details['Frame materials']?.name} characteristics={product.details.Characteristics?.name} For={product.details.For?.name} size={product.size}/>}
      <Images image={product.images}/>
      {/* {product.category.id.toString()===ProductCategory.OTHER ? null :
      <div className={classes.columns}>
        <Lenses type={lensesPricingType.DISTANCE} lenseType={product.lenses.type} configuratorType={product.configurator.type}/>
        <Lenses type={lensesPricingType.CLOSEUP} lenseType={product.lenses.type} configuratorType={product.configurator.type}/>
      </div>} */}
      {product.category.id.toString()===ProductCategory.OTHER ? null :<UpgradesAndThickness improvement={product.configurator.improvement} thickness={product.configurator.thickness}/>}
      <Button className={classes.button} variant="contained" color="primary" onClick={navigateToWithProduct.bind(this, PATHS.APP.PRODUCTS_MANAGEMENT.EDIT_PRODUCT,product)}>
        <Typography variant="body1" color="textSecondary">
          {t('MODIFY_PRODUCT')}
        </Typography>
      </Button>
      <Button className={classes.button} variant="contained" color="secondary" onClick={removeProduct}>
        <Typography variant="body1" color="textSecondary">
          {t('REMOVE_PRODUCT')}
        </Typography>
      </Button>
    </div>
  </div>:null
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  columns: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit,minmax(300px,0.5fr))',
    gridGap: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  productInfo: {
    padding: ` 0px ${theme.spacing(6)}px ${theme.spacing(6)}px ${theme.spacing(6)}px`,
  },
  button: {
    height: '46px',
    width: '184px',
    marginTop: theme.spacing(3.5),
    marginRight: theme.spacing(1.5),
  },
  productID: {
    paddingTop: theme.spacing(2.5),
    paddingBottom: theme.spacing(5),
  },
}));

export default ProductsDetailsContainer;
