import { SnackbarVariant } from "../utils/constants";

export type actionType =
  | SetCreateProduct
  | SetProductBrand
  | SetProductModel
  | SetProductStock
  | SetProductFullPrice
  | SetProductDiscount
  | SetProductDescription
  | SetProductFaceShape
  | SetProductFrameShape
  | SetProductFrameType
  | SetProductFrameMaterials
  | SetProductLensTypes
  | SetProductCharacteristics
  | SetProductFor
  | SetProductSize
  | SetProductFrameColor
  | SetProductLenseColor
  | SetProductLenseImprovements
  | SetProductLenseThickness
  | SetProductLenseType
  | SetProductCategory
  | SetProductImage3D
  | SetProductImages
  | SetProductDiopters
  | SetProductPeriodOfUse
  | SetProductMethodOfWearing
  | OrderBy
  | SetOrderProductCategory
  | ResetProduct
  | ShowSnackbar
  | HideSnackbar
  | SetButtonPressed
  | SetProductCode;
export const SET_CREATE_PRODUCT = 'SET_CREATE_PRODUCT';
export const SET_PRODUCT_BRAND = 'SET_PRODUCT_BRAND';
export const SET_PRODUCT_MODEL = 'SET_PRODUCT_MODEL';
export const SET_PRODUCT_STOCK = 'SET_PRODUCT_STOCK';
export const SET_PRODUCT_FULLPRICE = 'SET_PRODUCT_FULLPRICE';
export const SET_PRODUCT_DISCOUNT = 'SET_PRODUCT_DISCOUNT';
export const SET_PRODUCT_DESCRIPTION = 'SET_PRODUCT_DESCRIPTION';
export const SET_PRODUCT_FACE_SHAPE = 'SET_PRODUCT_FACE_SHAPE';
export const SET_PRODUCT_FRAME_SHAPE = 'SET_PRODUCT_FRAME_SHAPE';
export const SET_PRODUCT_FRAME_TYPE = 'SET_PRODUCT_FRAME_TYPE';
export const SET_PRODUCT_FRAME_MATERIALS = 'SET_PRODUCT_FRAME_MATERIALS';
export const SET_PRODUCT_LENS_TYPES = 'SET_PRODUCT_LENS_TYPES';
export const SET_PRODUCT_CHARACTERISTICS = 'SET_PRODUCT_CHARACTERISTICS';
export const SET_PRODUCT_FOR = 'SET_PRODUCT_FOR';
export const SET_PRODUCT_SIZE = 'SET_PRODUCT_LENSE_WIDTH';
export const SET_PRODUCT_FRAME_COLOR = 'SET_PRODUCT_FRAME_COLOR';
export const SET_PRODUCT_LENSE_COLOR = 'SET_PRODUCT_LENSE_COLOR';
export const SET_PRODUCT_LENSE_IMPROVEMENTS = 'SET_PRODUCT_LENSE_IMPROVEMENTS';
export const SET_PRODUCT_LENSE_THICKNESS = 'SET_PRODUCT_LENSE_THICKNESS';
export const SET_PRODUCT_LENSE_TYPE = 'SET_PRODUCT_LENSE_TYPE';
export const SET_PRODUCT_CATEGORY = 'SET_PRODUCT_CATEGORY';
export const SET_PRODUCT_IMAGE3D = 'SET_PRODUCT_IMAGE3D';
export const SET_PRODUCT_IMAGES= 'SET_PRODUCT_IMAGES';
export const SET_PRODUCT_DIOPTERS= 'SET_PRODUCT_DIOPTERS';
export const SET_PRODUCT_PERIOD_OF_USE= 'SET_PRODUCT_PERIOD_OF_USE';
export const SET_PRODUCT_METHOD_OF_WEARING= 'SET_PRODUCT_METHOD_OF_WEARING';
export const SET_ORDER_BY= 'SET_ORDER_BY';
export const SET_ORDER_PRODUCT_CATEGORY= 'SET_ORDER_PRODUCT_CATEGORY';
export const RESET_PRODUCT= 'RESET_PRODUCT';
export const SHOW_SNACKBAR = 'SHOW_SNACKBAR';
export const HIDE_SNACKBAR = 'HIDE_SNACKBAR';
export const SET_BUTTON_PRESSED = 'SET_BUTTON_PRESSED';
export const SET_PRODUCT_CODE = 'SET_PRODUCT_CODE';

export interface InitialState {
  product: ProductCreate;
  orderBy: string;
  productCategory: string;
  snackbar: {
    open: boolean;
    message: string;
    variant: SnackbarVariant;
  };
  buttonPressed: boolean;
}

interface SetCreateProduct {
    type: typeof SET_CREATE_PRODUCT;
    meta: ProductCreate;
  }

interface SetProductBrand {
    type: typeof SET_PRODUCT_BRAND;
    meta: string;
  }

interface SetProductModel {
    type: typeof SET_PRODUCT_MODEL;
    meta: string;
  }

interface SetProductStock {
    type: typeof SET_PRODUCT_STOCK;
    meta: string;
  }

interface SetProductFullPrice {
    type: typeof SET_PRODUCT_FULLPRICE;
    meta: string;
  } 

interface SetProductDiscount {
    type: typeof SET_PRODUCT_DISCOUNT;
    meta: string;
  } 

interface SetProductDescription {
    type: typeof SET_PRODUCT_DESCRIPTION;
    meta: string;
  }

interface SetProductFaceShape {
    type: typeof SET_PRODUCT_FACE_SHAPE;
    meta: string;
  }

interface SetProductFrameShape {
    type: typeof SET_PRODUCT_FRAME_SHAPE;
    meta: string;
  }

interface SetProductFrameType {
    type: typeof SET_PRODUCT_FRAME_TYPE;
    meta: string;
  }

interface SetProductFrameMaterials {
    type: typeof SET_PRODUCT_FRAME_MATERIALS;
    meta: string;
  }

interface SetProductLensTypes {
    type: typeof SET_PRODUCT_LENS_TYPES;
    meta: string;
  }
 
interface SetProductCharacteristics {
    type: typeof SET_PRODUCT_CHARACTERISTICS;
    meta: string;
  }  

interface SetProductFor {
    type: typeof SET_PRODUCT_FOR;
    meta: string;
  }  

interface SetProductSize {
    type: typeof SET_PRODUCT_SIZE;
    meta: SizeProduct;
  }  

interface SetProductFrameColor {
    type: typeof SET_PRODUCT_FRAME_COLOR;
    meta: string[];
  }  

interface SetProductLenseColor {
    type: typeof SET_PRODUCT_LENSE_COLOR;
    meta: string;
  }  

interface SetProductLenseImprovements {
    type: typeof SET_PRODUCT_LENSE_IMPROVEMENTS;
    meta: LenseImprovement[];
  }  

interface SetProductLenseThickness {
    type: typeof SET_PRODUCT_LENSE_THICKNESS;
    meta: LenseThickness[];
  }  

interface SetProductLenseType {
    type: typeof SET_PRODUCT_LENSE_TYPE;
    meta: LenseType[];
  }  

interface SetProductCategory {
    type: typeof SET_PRODUCT_CATEGORY;
    meta: string;
  }  

interface SetProductImage3D {
    type: typeof SET_PRODUCT_IMAGE3D;
    meta: string;
  }  

interface SetProductImages {
    type: typeof SET_PRODUCT_IMAGES;
    meta: string[];
  }  

interface SetProductDiopters {
    type: typeof SET_PRODUCT_DIOPTERS;
    meta: LenseDiopter[];
  }  

interface SetProductPeriodOfUse {
    type: typeof SET_PRODUCT_PERIOD_OF_USE;
    meta: string;
  }  

interface SetProductMethodOfWearing {
    type: typeof SET_PRODUCT_METHOD_OF_WEARING;
    meta: string;
  }  

interface OrderBy {
    type: typeof SET_ORDER_BY;
    meta: string;
  }  
  
interface SetOrderProductCategory {
    type: typeof SET_ORDER_PRODUCT_CATEGORY;
    meta: string;
  }

interface ResetProduct {
    type: typeof RESET_PRODUCT;
    meta: ProductCreate;
  }
  
interface ShowSnackbar {
    type: typeof SHOW_SNACKBAR;
    meta: {
      message: string;
      variant: SnackbarVariant;
    };
  }
  
interface HideSnackbar {
    type: typeof HIDE_SNACKBAR;
  }

interface SetButtonPressed {
    meta: boolean;
    type: typeof SET_BUTTON_PRESSED;
  }

interface SetProductCode {
  type: typeof SET_PRODUCT_CODE;
  meta: string;
}

  export interface ProductCreate {
    category_id: string;
    brand: string;
    model: string;
    price: string;
    discount: string;
    code: string;
    stock: string;
    size: SizeProduct;
    description: string;
    ['face-shape']: string;
    ['frame-shape']: string;
    ['lens-types']: string;
    ['frame-type']: string;
    ['frame-materials']: string;
    characteristics: string;
    for: string;
    ['frame-color']: string[];
    ['lenses-color']: string;
    images : string[];
    image3d: string;
    lense_improvement: LenseImprovement[];
    lense_thickness: LenseThickness[];
    lense_type: LenseType[];
    lense_diopter: LenseDiopter[];
    ['period-of-use']: string;
    ['method-of-wearing']: string;
  }

  export interface SizeProduct {
      width: string;
      length: string;
      bridge: string;
  }

 export interface Size {
    width: string[];
    length: string[];
    bridge: string[];
  }

export interface LenseImprovement {
      id: string;
      price: string;
      product_depth_id?: number; 
  }

export interface LenseThickness {
    id: string;
    price: string;
}

export interface LenseType {
  id: string;
  price: string;
  sph: SphCyl[];
  cyl: SphCyl[];
}

export interface LenseDiopter {
  id: string;
  radius: string;
  diameter: string;
  price?: string;
  sph?: SphCylLense[];
  cyl?: SphCylLense[];
}

export interface SphCyl
{
  min: string;
  max: string;
  price: string;
}

export interface SphCylLense
{
  min: string;
  max: string;
}

export interface UserProfile
{
  userId: string;
  name: string;
  email: string;
  tradeRegNr: string;
  companyRegNr: string;
}

export interface UserPasswords {
  current_password: string;
  new_password: string;
  new_password_confirmation: string;
}

export interface ProductShow {
  code: string;
  general: GeneralProductShow;
  category: CategoryProductShow;
  price: PriceProductShow;
  description: string;
  color: ColorProductShow;
  details: DetailsProductShow;
  size: SizeProductShow[];
  images: ImagesProductShow[];
  image3d: number;
  thumbnail: string;
  review: ReviewProductShow;
  lenses: LensesProductShow;
  configurator: {
    type: configuratorType[];
    improvement: configuratorImprovement[];
    thickness: configuratorThickness[];
    diopter: configuratorDiopter[];
  };
  updated_at: string;
  created_at: string;
}

export interface GeneralProductShow {
  id: number;
  brand: string;
  model: string;
  slug: string;
  stoc: number;
  code: string;
}

export interface CategoryProductShow {
  id: number;
  name: string;
  slug: string;
  parent: string;
}

export interface PriceProductShow {
  price: string;
  discount: number;
  price_discounted: string;
}

export interface FrameLenseColor {
  id: number;
  name: string;
  slug: string;
}

export interface ColorProductShow {
  frame: FrameLenseColor[];
  lense: FrameLenseColor[];
}

export interface DetailsProductShow {
  Characteristics: IdName;
  ['Face shape']: IdName;
  For: IdName;
  ['Frame materials']: IdName;
  ['Frame Shape']: IdName;
  ['Lens types']: IdName;
  Style: IdName;
  ['Frame type']: IdName;
  ['Period of use']: IdName;
  ['Method of wearing']: IdName;
}

export interface IdName {
  id: number;
  name: string;
}

export interface SizeProductShow {
  id: number;
  width: number;
  length: number;
  bridge: number;
}

export interface ImagesProductShow {
  id: number;
  product_id: number;
  name: string;
  alt: string;
  path: string;
  type: string;
  featured: number;
}

export interface ReviewProductShow {
  rating_score: string;
  count_review: string;
}

export interface LensesProductShow {
  improvements: LensesImprovementsProductShow[];
  thickness: LensesThicknessProductShow[];
  type: LensesTypeProductShow[];
  diopter: LensesDiopterProductShow[];
}

export interface LensesImprovementsProductShow {
  id: number;
  name: string;
  slug: string;
  description: string;
  icon: string;
  price: number;
}

export interface LensesThicknessProductShow {
  id: number;
  name: string;
  slug: string;
  icon: string;
  index: string;
  description: string;
  price: number;
}

export interface LensesTypeProductShow {
  id: number;
  name: string;
  slug: string;
  icon: string;
  description: string;
  price: number;
  lentil_relation_id: number;
  diopters?: LensesTypeDioptersProductShow;
}

export interface LensesTypeDioptersProductShow {
  sph: LensesTypeDioptersSphCylProductShow[];
  cyl: LensesTypeDioptersSphCylProductShow[];
}

export interface LensesTypeDioptersSphCylProductShow {
  id: number;
  product_id: number;
  lentil_relation_id: number;
  type: string;
  min: number;
  max: number;
  price: number;
}

export interface LensesDiopterProductShow {
  id: number;
  name: string;
  slug: string;
  description: string;
  icon: string;
  created_at: string;
  updated_at: string;
  diopter_relation_id: number;
  diopters? : LensesDiopterDioptersProductShow;
}

export interface LensesDiopterSphCylProductShow {
  id: number;
  product_id: number;
  diopter_relation_id: number;
  type: string;
  min: number;
  max: number;
  price: number;
}

export interface LensesDiopterDioptersProductShow {
  sph: LensesDiopterSphCylProductShow[];
  cyl: LensesDiopterSphCylProductShow[];
}


export interface configuratorType {
  id: number;
  name: string;
  lentil_relation_id: string;
  price: number;
  items: {
    sph: configuratorItem[];
    cyl: configuratorItem[];
  };
}

export interface configuratorItem {
  value: number;
  price: number;
}

export interface configuratorImprovement {
  id: number;
  name: string;
  improvement_relation_id: number;
  price: number;
  thickness_id?: number;
  thickness?: string;
}

export interface configuratorThickness {
  id: number;
  name: string;
  thickness_relation_id: number;
  price: number;
}

export interface configuratorDiopter {
  id: number;
  name: string;
  product_diopter_id: number;
  price: number;
  diameter: number;
  radius: number;
  items: {
    cyl: configuratorItem[];
  }
}

export interface OrdersData {
  id: number;
  payment_method: string;
  payment_status: string;
  status: string;
  total: number;
  updated_at: string;
  created_at: string;
}

export interface OrderDataGeneral {
  id: number;
  user_id: number;
  payment_method: string;
  payment_status: string;
  status: string;
  total: number;
  updated_at: string;
  created_at: string;
}

export interface Address {
  id: number;
  user_id: number;
  scope: string;
  name: string;
  phone: string;
  phone2: string;
  county: string;
  city: string;
  full_address: string;
  is_company: number;
  cui: string;
  register_number: string;
  bank: string;
  iban: string;
  updated_at: string;
  created_at: string;
}

export interface OrderProductDetails {
  For: string;
  ['Frame Shape']: string;
  ['Lens types']: string;
  Brands: string;
  Style: string;
  ['Frame type']: string;
  ['Face shape']: string;
  ['Lenses width']: string;
  ['Temple length']: string;
  ['Bridge width']: string;
  ['Frame materials']: string;
  ['Frame color']: string;
  ['Lenses color']: string;
  Characteristics: string;
  ['Period of use']: string;
  ['Method of wearing']: string;
  Type: string;
}

export interface OrderProductPrescription {
  id: number;
  is_photo: string;
  photo_path: string;
  lense_type: {
    name: string;
    price: number;
  };
  lense_improvement: [{
    name: string;
    price: number;
  }];
  lense_thickness: [{
    name: string;
    price: number;
  }];
  lense_diopter: {
    name: string;
    radius: number;
    diameter: number;
    price: number;
  };
  right_eye_sph: string;
  right_eye_cyl: string;
  right_eye_ax: string;
  left_eye_sph: string;
  left_eye_cyl: string;
  left_eye_ax: string;
  dp_type: string;
  dp_left_eye: string;
  dp_right_eye: string;
  dp_both_eye: string;
  sph_price: number;
  cyl_price: number;
}

export interface OrderProduct {
  id: number;
  brand: string;
  model: string;
  code: string;
  category_id: number;
  stock: number;
  size: SizeProduct;
  discount: number;
  price: string;
  price_discounted: string;
  rating_score: string;
  count_review: string;
  has_vr: number;
  color: ColorProductShow;
  thumbnail: string;
  details: OrderProductDetails;
  prescription: OrderProductPrescription;
}

export interface OrderCompleteData {
  general: OrderDataGeneral;
  owner: {
    id: number;
    name: string;
    birthday: string;
    avatar: string;
    phone: string;
    full_avatar: string;
    email: string;
    google_id: string;
    facebook_id: string;
    stripe_id: string;
  }
  invoice_address: Address;
  delivery_address: Address;
  invoice_url: string;
  awb: string;
  products: OrderProduct[];
  total: number;
}

export interface BankDetails {
  id: number;
  company_id: number;
  name: string;
  iban: string;
  bank: string;
  created_at: string;
  updated_at: string;
}

  export const INEXISTENT_ID = '999999999';
  export const INEXISTENT_VALUE = '999999999';