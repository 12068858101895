import {
  Button,
  Checkbox,
  FormControlLabel,
  InputBase,
  makeStyles,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';
import HeightIcon from '@material-ui/icons/Height';
import clsx from 'clsx';
import React, { FC, useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { setLenseThicknessProduct, setLenseTypeProduct, setLensTypesProduct } from '../../state/actions';
import { configuratorThickness, configuratorType, INEXISTENT_ID, INEXISTENT_VALUE, InitialState, LensesTypeProductShow, LenseThickness, LenseType, ProductShow, SphCyl } from '../../state/types';
import { Taxonomy } from '../../utils/constants';

enum Thickness {
  STANDARD = 1,
  THIN = 2,
  ULTRA_THIN = 3,
  ASPHERICAL = 4,
}

enum Lense {
  DISTANCE = 1,
  CLOSE_UP = 2,
  WITHOUT_DIOPTERS = 3,
}

const thicknessChoosen: LenseThickness[] = [];
const lenseTypeChooosen: LenseType[] = [];

interface Props {
  thickness?: configuratorThickness[];
  type?: LensesTypeProductShow[];
  taxonomies?: {
    [`face-shape`]: Taxonomy[];
    [`frame-shape`]: Taxonomy[];
    [`frame-type`]: Taxonomy[];
    [`frame-materials`]: Taxonomy[];
    [`lens-types`]: Taxonomy[];
    characteristics: Taxonomy[];
    for: Taxonomy[];
  };
  item?: ProductShow;
}

const UpgradesPrescriptionThickness: FC<Props> = props => {
  const { thickness, type, taxonomies, item } = props;
  const classes = useStyles();
  const buttonPressed = useSelector((state: InitialState) => state.buttonPressed);
  const [closeup, setCloseup] = useState<boolean>(false);
  const [distance, setDistance] = useState<boolean>(false);
  const [withoutDiopter, setWithoutDiopter] = useState<boolean>(false);
  const [distanceStandardPrice, setDistanceStandardPrice] = useState<string>('0');
  const [distanceSPHRange1, setDistanceSPHRange1] = useState<boolean>(false);
  const [distanceSPHRange2, setDistanceSPHRange2] = useState<boolean>(false);
  const [distanceSPHRange3, setDistanceSPHRange3] = useState<boolean>(false);
  const [distanceSPHRange4, setDistanceSPHRange4] = useState<boolean>(false);
  const [distanceSPHRange5, setDistanceSPHRange5] = useState<boolean>(false);
  const [distanceCYLRange1, setDistanceCYLRange1] = useState<boolean>(false);
  const [distanceCYLRange2, setDistanceCYLRange2] = useState<boolean>(false);

  const [closeupStandardPrice, setCloseupStandardPrice] = useState<string>('0');
  const [closeupSPHRange1, setCloseupSPHRange1] = useState<boolean>(false);
  const [closeupSPHRange2, setCloseupSPHRange2] = useState<boolean>(false);
  const [closeupSPHRange3, setCloseupSPHRange3] = useState<boolean>(false);
  const [closeupSPHRange4, setCloseupSPHRange4] = useState<boolean>(false);
  const [closeupSPHRange5, setCloseupSPHRange5] = useState<boolean>(false);
  const [closeupCYLRange1, setCloseupCYLRange1] = useState<boolean>(false);
  const [closeupCYLRange2, setCloseupCYLRange2] = useState<boolean>(false);

  const [standardThicknes, setStandardThicknes] = useState<boolean>(false);
  const [thin, setThin] = useState<boolean>(false);
  const [ultraThin, setUltraThin] = useState<boolean>(false);
  const [aspherical, setAspherical] = useState<boolean>(false);

  const product = useSelector((state: InitialState) => state.product);
  const [standardThicknesPrice, setStandardThicknesPrice] = useState<string>('');
  const [thinPrice, setThinPrice] = useState<string>('');
  const [ultraThinPrice, setUltraThinPrice] = useState<string>('');
  const [asphericalPrice, setAsphericalPrice] = useState<string>('');
  const { t } = useTranslation();

  useEffect(()=>{
    if(typeof thickness !== 'undefined' && typeof type !== 'undefined')
    {
      for(let i=0;i<thickness.length;i++)
      {
        if(thickness[i].id===Thickness.STANDARD)
        {
          setStandardThicknes(true);
          setStandardThicknesPrice(thickness[i].price.toString())
        }
        if(thickness[i].id===Thickness.THIN)
        {
          setThin(true);
          setThinPrice(thickness[i].price.toString())
        }
        if(thickness[i].id===Thickness.ULTRA_THIN)
        {
          setUltraThin(true);
          setUltraThinPrice(thickness[i].price.toString())
        }
      }

      for(let i=0;i<type.length;i++)
      {
        if(type[i].id===Lense.CLOSE_UP)
        {
          setCloseup(true);          
            // @ts-ignore
            for(let j=0;j<type[i].diopters?.sph.length;j++)
            {
              if(type[i].diopters?.sph[j].min === 12.25)
              {
                setCloseupSPHRange1(true);
              }
              if(type[i].diopters?.sph[j].min === 8.25)
              {
                setCloseupSPHRange2(true);
              }
              if(type[i].diopters?.sph[j].min === 6.25)
              {
                setCloseupSPHRange3(true);
              }
              if(type[i].diopters?.sph[j].min === 4.25)
              {
                setCloseupSPHRange4(true);
              }
              if(type[i].diopters?.sph[j].min === 0.25)
              {
                setCloseupSPHRange5(true);
              }
            }
            // @ts-ignore
            for(let j=0;j<type[i].diopters?.cyl.length;j++)
            {
              if(type[i].diopters?.cyl[j].min === 2.25)
              {
                setCloseupCYLRange1(true);
              }
              if(type[i].diopters?.cyl[j].min === 0.25)
              {
                setCloseupCYLRange2(true);
              }
            }
        }
        if(type[i].id===Lense.DISTANCE)
        {
          setDistance(true);
          // @ts-ignore
          for(let j=0;j<type[i].diopters?.sph.length;j++)
          {
            if(type[i].diopters?.sph[j].min === 12.25)
            {
              setDistanceSPHRange1(true);
            }
            if(type[i].diopters?.sph[j].min === 8.25)
            {
              setDistanceSPHRange2(true);
            }
            if(type[i].diopters?.sph[j].min === 6.25)
            {
              setDistanceSPHRange3(true);
            }
            if(type[i].diopters?.sph[j].min === 4.25)
            {
              setDistanceSPHRange4(true);
            }
            if(type[i].diopters?.sph[j].min === 0.25)
            {
              setDistanceSPHRange5(true);
            }
          }
          // @ts-ignore
          for(let j=0;j<type[i].diopters?.cyl.length;j++)
          {
            if(type[i].diopters?.cyl[j].min === 2.25)
            {
              setDistanceCYLRange1(true);
            }
            if(type[i].diopters?.cyl[j].min === 0.25)
            {
              setDistanceCYLRange2(true);
            }
          }
        }
      }
    }
  },[])

  useEffect(()=>{
    for(let i=0;i<product.lense_thickness.length;i++)
      {
        if(Number(product.lense_thickness[i].id)===Thickness.STANDARD)
        {
          setStandardThicknes(true);
          setStandardThicknesPrice(product.lense_thickness[i].price.toString())
        }
        if(Number(product.lense_thickness[i].id)===Thickness.THIN)
        {
          setThin(true);
          setThinPrice(product.lense_thickness[i].price.toString())
        }
        if(Number(product.lense_thickness[i].id)===Thickness.ULTRA_THIN)
        {
          setUltraThin(true);
          setUltraThinPrice(product.lense_thickness[i].price.toString())
        }
      }
  },[])

  const toggleDistance = () => {
    setDistance(!distance);
  };
  const toggleCloseup = () => {
    setCloseup(!closeup);
  };

  const toggleDistanceSPHRange1 = () => {
    setDistanceSPHRange1(!distanceSPHRange1);
  }

  const toggleDistanceSPHRange2 = () => {
    setDistanceSPHRange2(!distanceSPHRange2);
  }

  const toggleDistanceSPHRange3 = () => {
    setDistanceSPHRange3(!distanceSPHRange3);
  }

  const toggleDistanceSPHRange4 = () => {
    setDistanceSPHRange4(!distanceSPHRange4);
  }

  const toggleDistanceSPHRange5 = () => {
    setDistanceSPHRange5(!distanceSPHRange5);
  }

  const toggleDistanceCYLRange1 = () => {
    setDistanceCYLRange1(!distanceCYLRange1);
  }

  const toggleDistanceCYLRange2 = () => {
    setDistanceCYLRange2(!distanceCYLRange2);
  }

  const toggleCloseupSPHRange1 = () => {
    setCloseupSPHRange1(!closeupSPHRange1);
  }

  const toggleCloseupSPHRange2 = () => {
    setCloseupSPHRange2(!closeupSPHRange2);
  }

  const toggleCloseupSPHRange3 = () => {
    setCloseupSPHRange3(!closeupSPHRange3);
  }

  const toggleCloseupSPHRange4 = () => {
    setCloseupSPHRange4(!closeupSPHRange4);
  }

  const toggleCloseupSPHRange5 = () => {
    setCloseupSPHRange5(!closeupSPHRange5);
  }

  const toggleCloseupCYLRange1 = () => {
    setCloseupCYLRange1(!closeupCYLRange1);
  }

  const toggleCloseupCYLRange2 = () => {
    setCloseupCYLRange2(!closeupCYLRange2);
  }

  const standardThicknessPriceChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if(Number(event.target.value)>=0)
      setStandardThicknesPrice(event.target.value);
  };
  const thinPriceChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if(Number(event.target.value)>=0)
      setThinPrice(event.target.value);
  };
  const ultraThinPriceChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if(Number(event.target.value)>=0)
      setUltraThinPrice(event.target.value);
  };

  const filteredThickness = (thickness: LenseThickness[]) => thickness.filter((item=>{
    return item!==null && item.id!==INEXISTENT_ID
  }));

  useEffect(()=>{
    if((standardThicknes===true && standardThicknesPrice!==''))
    {
      thicknessChoosen[0]={
        id: Thickness.STANDARD.toString(),
        price: standardThicknesPrice,
      };
    }
    if((thin===true && thinPrice!==''))
    {
      thicknessChoosen[1]={
        id: Thickness.THIN.toString(),
        price: thinPrice,
      };
    }
    if((ultraThin===true && ultraThinPrice!==''))
    {
      thicknessChoosen[2]={
        id: Thickness.ULTRA_THIN.toString(),
        price: ultraThinPrice,
      };
    }
    if((aspherical===true && asphericalPrice!==''))
    {
      thicknessChoosen[3]={
        id: Thickness.ASPHERICAL.toString(),
        price: asphericalPrice,
      };
    }
    if(standardThicknes===false)
    {
      thicknessChoosen[0]={
        id: INEXISTENT_ID,
        price: INEXISTENT_VALUE,
      };
    }
    if(thin===false)
    {
      thicknessChoosen[1]={
        id: INEXISTENT_ID,
        price: INEXISTENT_VALUE,
      };
    }
    if(ultraThin===false)
    {
      thicknessChoosen[2]={
        id: INEXISTENT_ID,
        price: INEXISTENT_VALUE,
      };
    }
    if(aspherical===false)
    {
      thicknessChoosen[3]={
        id: INEXISTENT_ID,
        price: INEXISTENT_VALUE,
      };
    }
    setLenseThicknessProduct(filteredThickness(thicknessChoosen));
  },[standardThicknes,thin,ultraThin,aspherical,standardThicknesPrice,thinPrice,ultraThinPrice,asphericalPrice])

  
  const filteredLenseType = (type: LenseType[]) => type.filter((item=>{
    return item!==null && item.id!==INEXISTENT_ID
  }));


  useEffect(()=>{
    if(distance)
    {
      const sph: SphCyl[] = [];
      if(distanceSPHRange1)
      {
        sph.push({min: '12.25',
                  max: '16',
                  price: '0'})
      }
      if(distanceSPHRange2)
      {
        sph.push({min: '8.25',
                  max: '12',
                  price: '0'})
      }
      if(distanceSPHRange3)
      {
        sph.push({min: '6.25',
                  max: '8',
                  price: '0'})
      }
      if(distanceSPHRange4)
      {
        sph.push({min: '4.25',
                  max: '6',
                  price: '0'})
      }
      if(distanceSPHRange5)
      {
        sph.push({min: '0.25',
                  max: '4',
                  price: '0'})
      }
      const cyl: SphCyl[] = [];
      if(distanceCYLRange1)
      {
        cyl.push({min: '2.25',
                  max: '4',
                  price: '0'})
      }
      if(distanceCYLRange2)
      {
        cyl.push({min: '0.25',
                  max: '2',
                  price: '0'})
      }
      lenseTypeChooosen[0]={
      id: Lense.DISTANCE.toString(),
      price: distanceStandardPrice,
      sph: sph,
      cyl: cyl,
      };
    }

    if(closeup)
    {
      const sph: SphCyl[] = [];
      if(closeupSPHRange1)
      {
        sph.push({min: '12.25',
                  max: '16',
                  price: '0'})
      }
      if(closeupSPHRange2)
      {
        sph.push({min: '8.25',
                  max: '12',
                  price: '0'})
      }
      if(closeupSPHRange3)
      {
        sph.push({min: '6.25',
                  max: '8',
                  price: '0'})
      }
      if(closeupSPHRange4)
      {
        sph.push({min: '4.25',
                  max: '6',
                  price: '0'})
      }
      if(closeupSPHRange5)
      {
        sph.push({min: '0.25',
                  max: '4',
                  price: '0'})
      }
      const cyl: SphCyl[] = [];
      if(closeupCYLRange1)
      {
        cyl.push({min: '2.25',
                  max: '4',
                  price: '0'})
      }
      if(closeupCYLRange2)
      {
        cyl.push({min: '0.25',
                  max: '2',
                  price: '0'})
      }
      lenseTypeChooosen[1]={
      id: Lense.CLOSE_UP.toString(),
      price: closeupStandardPrice,
      sph: sph,
      cyl: cyl,
      };
    }
  
    if(distance===false)
    {
      const sph: SphCyl[] = [];
      sph[0]={
        min: '0',
        max: '0',
        price: '0',
      }
      const cyl: SphCyl[] = [];
      cyl[0]={
        min: '0',
        max: '0',
        price: '0',
      }
      lenseTypeChooosen[0]={
        id: INEXISTENT_ID.toString(),
        price: '0',
        sph: sph,
        cyl: cyl,
      };
    }

    if(closeup===false)
    {
      const sph: SphCyl[] = [];
      sph[0]={
        min: '0',
        max: '0',
        price: '0',
      }
      const cyl: SphCyl[] = [];
      cyl[0]={
        min: '0',
        max: '0',
        price: '0',
      }
      lenseTypeChooosen[1]={
        id: INEXISTENT_ID,
        price: '0',
        sph: sph,
        cyl: cyl,
      };
    }
    setLenseTypeProduct(filteredLenseType(lenseTypeChooosen));
  },[distance,distanceSPHRange1,distanceSPHRange2,distanceSPHRange3,distanceSPHRange4,distanceSPHRange5,distanceCYLRange1,distanceCYLRange2,closeup,closeupSPHRange1,closeupSPHRange2,closeupSPHRange3,closeupSPHRange4,closeupSPHRange5,closeupCYLRange1,closeupCYLRange2])

  const [lensTypes, setLensTypes] = useState<string>(typeof item !== 'undefined' ? item.details['Lens types']?.id.toString():'');
  const lensTypesChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLensTypes(event.target.value);
    setLensTypesProduct(event.target.value);
  };

  useEffect(()=>{
    if(typeof item !== 'undefined')
    {
      setLensTypesProduct(lensTypes);
    }
  },[])

  const addLensTypes = () => {
    const lensTypes = [];
    for(let i=0;i<taxonomies![`lens-types`].length;i++)
    {
        if(taxonomies![`lens-types`][i].name === 'Monofocal')
        {
          lensTypes.push(<MenuItem key={i} value={taxonomies![`lens-types`][i].taxonomy_relationship_id}>
          {taxonomies![`lens-types`][i].name}
           </MenuItem>)
        }     
    }
    return lensTypes;
  };

  useEffect(()=>{
      setStandardThicknes(((closeupSPHRange5 && closeupCYLRange2) || (distanceSPHRange5 && distanceCYLRange2)) && (distance || closeup));
      setThin((closeupSPHRange1 || closeupSPHRange2 || closeupSPHRange3 || closeupSPHRange4 || closeupSPHRange5 || closeupCYLRange1 || closeupCYLRange2 || distanceSPHRange1 || distanceSPHRange2 || distanceSPHRange3 || distanceSPHRange4 || distanceSPHRange5 || distanceCYLRange1 || distanceCYLRange2) && (distance || closeup));
      setUltraThin((closeupSPHRange1 || closeupSPHRange2 || closeupSPHRange3 || closeupSPHRange4 || closeupSPHRange5 || closeupCYLRange1 || closeupCYLRange2 || distanceSPHRange1 || distanceSPHRange2 || distanceSPHRange3 || distanceSPHRange4 || distanceSPHRange5 || distanceCYLRange1 || distanceCYLRange2) && (distance || closeup));
  },[distance,distanceSPHRange1,distanceSPHRange2,distanceSPHRange3,distanceSPHRange4,distanceSPHRange5,distanceCYLRange1,distanceCYLRange2,closeup,closeupSPHRange1,closeupSPHRange2,closeupSPHRange3,closeupSPHRange4,closeupSPHRange5,closeupCYLRange1,closeupCYLRange2])

const handleSelectAll = () => {
  setCloseup(true);
  setDistance(true);
  setDistanceSPHRange1(true);
  setDistanceSPHRange2(true);
  setDistanceSPHRange3(true);
  setDistanceSPHRange4(true);
  setDistanceSPHRange5(true);
  setDistanceCYLRange1(true);
  setDistanceCYLRange2(true);
  setCloseupSPHRange1(true);
  setCloseupSPHRange2(true);
  setCloseupSPHRange3(true);
  setCloseupSPHRange4(true);
  setCloseupSPHRange5(true);
  setCloseupCYLRange1(true);
  setCloseupCYLRange2(true);
}

  return (
    <div>
      <Typography
        className={clsx(classes.bold, classes.title)}
        color="textPrimary"
        variant={'body1'}
      >
        {t('AVAILABLE_LENSES')}*
      </Typography>
      <div className={classes.prescription}>
        <div className={classes.twoColumns}>
            <div className={classes.shape}>
            <Typography color="textPrimary" variant={'body1'}>
              {t('LENS_TYPES')}*
            </Typography>
            <TextField
              className={classes.input2}
              select={true}
              value={lensTypes}
              onChange={lensTypesChangeHandler}
              error={lensTypes === '' && buttonPressed===true}
            >
              {addLensTypes()}
            </TextField>
            </div>
            <Button className={classes.checkAllButton} variant="contained" color="primary" onClick={handleSelectAll}>
              {t('SELECT_ALL_OPTIONS')}
            </Button>
        </div>
        
        <FormControlLabel
          className={classes.checkbox}
          value="prescription"
          control={<Checkbox color="primary" />}
          label={t('DISTANCE')}
          labelPlacement="end"
          checked={distance}
          onClick={toggleDistance}
        />
        {distance ? (
          <div className={classes.prescriptionPricing}>
            {/* <Typography color="textPrimary" variant={'body1'}>
              {t('PRICE_DIOPTERS')}
            </Typography> */}
            <div className={classes.sphereAndCyl}>
              <Typography color="textPrimary" variant={'body1'}>
                {t('SPHERE')}
              </Typography>
              <Typography color="textPrimary" variant={'body1'}>
                {t('CYL')}
              </Typography>
              <div className={classes.sphereContainer}>
                <div className={classes.rangeAndPrice}>
                <div>
                    <div className={classes.inlineRange}>
                      {'-12,25'} <HeightIcon className={classes.arrow} />
                      {'-16'}
                    </div>
                    <div className={classes.inlineRange}>
                      {'+12,25'} <HeightIcon className={classes.arrow} />
                      {'+16'}
                    </div>
                  </div>
                  <FormControlLabel
                    className={classes.checkbox}
                    value="prescription"
                    control={<Checkbox color="primary" className={classes.radio}/>}
                    label={t('AVAILABLE')}
                    labelPlacement="end"
                    checked={distanceSPHRange1}
                    onClick={toggleDistanceSPHRange1}
                  />
                  <div>
                    <div className={classes.inlineRange}>
                      {'-8,25'} <HeightIcon className={classes.arrow} />
                      {'-12'}
                    </div>
                    <div className={classes.inlineRange}>
                      {'+8,25'} <HeightIcon className={classes.arrow} />
                      {'+12'}
                    </div>
                  </div>
                  <FormControlLabel
                    className={classes.checkbox}
                    value="prescription"
                    control={<Checkbox color="primary" className={classes.radio}/>}
                    label={t('AVAILABLE')}
                    labelPlacement="end"
                    checked={distanceSPHRange2}
                    onClick={toggleDistanceSPHRange2}
                  />
                  <div>
                    <div className={classes.inlineRange}>
                      {'-6,25'} <HeightIcon className={classes.arrow} />
                      {'-8'}
                    </div>
                    <div className={classes.inlineRange}>
                      {'+6,25'} <HeightIcon className={classes.arrow} />
                      {'+8'}
                    </div>
                  </div>
                  <FormControlLabel
                    className={classes.checkbox}
                    value="prescription"
                    control={<Checkbox color="primary" className={classes.radio}/>}
                    label={t('AVAILABLE')}
                    labelPlacement="end"
                    checked={distanceSPHRange3}
                    onClick={toggleDistanceSPHRange3}
                  />
                  <div>
                    <div className={classes.inlineRange}>
                      {'-4,25'} <HeightIcon className={classes.arrow} />
                      {'-6'}
                    </div>
                    <div className={classes.inlineRange}>
                      {'+4,25'} <HeightIcon className={classes.arrow} />
                      {'+6'}
                    </div>
                  </div>
                  <FormControlLabel
                    className={classes.checkbox}
                    value="prescription"
                    control={<Checkbox color="primary" className={classes.radio}/>}
                    label={t('AVAILABLE')}
                    labelPlacement="end"
                    checked={distanceSPHRange4}
                    onClick={toggleDistanceSPHRange4}
                  />
                  <div>
                    <div className={classes.inlineRange}>
                      {'-0,25'} <HeightIcon className={classes.arrow} />
                      {'-4'}
                    </div>
                    <div className={classes.inlineRange}>
                      {'+0,25'} <HeightIcon className={classes.arrow} />
                      {'+4'}
                    </div>
                  </div>
                  <FormControlLabel
                    className={classes.checkbox}
                    value="prescription"
                    control={<Checkbox color="primary" className={classes.radio}/>}
                    label={t('AVAILABLE')}
                    labelPlacement="end"
                    checked={distanceSPHRange5}
                    onClick={toggleDistanceSPHRange5}
                  />
                </div>
              </div>
              <div className={classes.cylContainer}>
                <div className={classes.rangeAndPrice}>
                  <div>
                    <div className={classes.inlineRange}>
                      {'-2,25'} <HeightIcon className={classes.arrow} />
                      {'-4'}
                    </div>
                    <div className={classes.inlineRange}>
                      {'+2,25'} <HeightIcon className={classes.arrow} />
                      {'+4'}
                    </div>
                  </div>
                  <FormControlLabel
                    className={classes.checkbox}
                    value="prescription"
                    control={<Checkbox color="primary" className={classes.radio}/>}
                    label={t('AVAILABLE')}
                    labelPlacement="end"
                    checked={distanceCYLRange1}
                    onClick={toggleDistanceCYLRange1}
                  />
                  <div>
                    <div className={classes.inlineRange}>
                      {'-0,25'} <HeightIcon className={classes.arrow} />
                      {'-2'}
                    </div>
                    <div className={classes.inlineRange}>
                      {'+0,25'} <HeightIcon className={classes.arrow} />
                      {'+2'}
                    </div>
                  </div>
                  <FormControlLabel
                    className={classes.checkbox}
                    value="prescription"
                    control={<Checkbox color="primary" className={classes.radio}/>}
                    label={t('AVAILABLE')}
                    labelPlacement="end"
                    checked={distanceCYLRange2}
                    onClick={toggleDistanceCYLRange2}
                  />
                </div>
              </div>
            </div>
            {/* <Typography color="textPrimary" variant={'body1'}>
              {t('DIOPTERS_MANDATORY')}
            </Typography> */}
          </div>
        ) : null}
        <FormControlLabel
          className={classes.checkbox}
          value="prescription"
          control={<Checkbox color="primary" />}
          label={t('CLOSE_UP')}
          labelPlacement="end"
          checked={closeup}
          onClick={toggleCloseup}
        />
        {closeup ? (
          <div className={classes.prescriptionPricing}>
            {/* <Typography color="textPrimary" variant={'body1'}>
              {t('PRICE_DIOPTERS')}
            </Typography> */}
            <div className={classes.sphereAndCyl}>
              <Typography color="textPrimary" variant={'body1'}>
                {t('SPHERE')}
              </Typography>
              <Typography color="textPrimary" variant={'body1'}>
                {t('CYL')}
              </Typography>
              <div className={classes.sphereContainer}>
                <div className={classes.rangeAndPrice}>
                <div>
                    <div className={classes.inlineRange}>
                      {'-12,25'} <HeightIcon className={classes.arrow} />
                      {'-16'}
                    </div>
                    <div className={classes.inlineRange}>
                      {'+12,25'} <HeightIcon className={classes.arrow} />
                      {'+16'}
                    </div>
                  </div>
                  <FormControlLabel
                    className={classes.checkbox}
                    value="prescription"
                    control={<Checkbox color="primary" className={classes.radio}/>}
                    label={t('AVAILABLE')}
                    labelPlacement="end"
                    checked={closeupSPHRange1}
                    onClick={toggleCloseupSPHRange1}
                  />
                  <div>
                    <div className={classes.inlineRange}>
                      {'-8,25'} <HeightIcon className={classes.arrow} />
                      {'-12'}
                    </div>
                    <div className={classes.inlineRange}>
                      {'+8,25'} <HeightIcon className={classes.arrow} />
                      {'+12'}
                    </div>
                  </div>
                  <FormControlLabel
                    className={classes.checkbox}
                    value="prescription"
                    control={<Checkbox color="primary" className={classes.radio}/>}
                    label={t('AVAILABLE')}
                    labelPlacement="end"
                    checked={closeupSPHRange2}
                    onClick={toggleCloseupSPHRange2}
                  />
                  <div>
                    <div className={classes.inlineRange}>
                      {'-6,25'} <HeightIcon className={classes.arrow} />
                      {'-8'}
                    </div>
                    <div className={classes.inlineRange}>
                      {'+6,25'} <HeightIcon className={classes.arrow} />
                      {'+8'}
                    </div>
                  </div>
                   <FormControlLabel
                    className={classes.checkbox}
                    value="prescription"
                    control={<Checkbox color="primary" className={classes.radio}/>}
                    label={t('AVAILABLE')}
                    labelPlacement="end"
                    checked={closeupSPHRange3}
                    onClick={toggleCloseupSPHRange3}
                  />
                  <div>
                    <div className={classes.inlineRange}>
                      {'-4,25'} <HeightIcon className={classes.arrow} />
                      {'-6'}
                    </div>
                    <div className={classes.inlineRange}>
                      {'+4,25'} <HeightIcon className={classes.arrow} />
                      {'+6'}
                    </div>
                  </div>
                  <FormControlLabel
                    className={classes.checkbox}
                    value="prescription"
                    control={<Checkbox color="primary" className={classes.radio}/>}
                    label={t('AVAILABLE')}
                    labelPlacement="end"
                    checked={closeupSPHRange4}
                    onClick={toggleCloseupSPHRange4}
                  />
                  <div>
                    <div className={classes.inlineRange}>
                      {'-0,25'} <HeightIcon className={classes.arrow} />
                      {'-4'}
                    </div>
                    <div className={classes.inlineRange}>
                      {'+0,25'} <HeightIcon className={classes.arrow} />
                      {'+4'}
                    </div>
                  </div>
                  <FormControlLabel
                    className={classes.checkbox}
                    value="prescription"
                    control={<Checkbox color="primary" className={classes.radio}/>}
                    label={t('AVAILABLE')}
                    labelPlacement="end"
                    checked={closeupSPHRange5}
                    onClick={toggleCloseupSPHRange5}
                  />
                </div>
              </div>
              <div className={classes.cylContainer}>
                <div className={classes.rangeAndPrice}>
                <div>
                    <div className={classes.inlineRange}>
                      {'-2,25'} <HeightIcon className={classes.arrow} />
                      {'-4'}
                    </div>
                    <div className={classes.inlineRange}>
                      {'+2,25'} <HeightIcon className={classes.arrow} />
                      {'+4'}
                    </div>
                  </div>
                  <FormControlLabel
                    className={classes.checkbox}
                    value="prescription"
                    control={<Checkbox color="primary" className={classes.radio}/>}
                    label={t('AVAILABLE')}
                    labelPlacement="end"
                    checked={closeupCYLRange1}
                    onClick={toggleCloseupCYLRange1}
                  />
                  <div>
                    <div className={classes.inlineRange}>
                      {'-0,25'} <HeightIcon className={classes.arrow} />
                      {'-2'}
                    </div>
                    <div className={classes.inlineRange}>
                      {'+0,25'} <HeightIcon className={classes.arrow} />
                      {'+2'}
                    </div>
                  </div>
                  <FormControlLabel
                    className={classes.checkbox}
                    value="prescription"
                    control={<Checkbox color="primary" className={classes.radio}/>}
                    label={t('AVAILABLE')}
                    labelPlacement="end"
                    checked={closeupCYLRange2}
                    onClick={toggleCloseupCYLRange2}
                  />
                </div>
              </div>
            </div>
            {/* <Typography color="textPrimary" variant={'body1'}>
              {t('DIOPTERS_MANDATORY')}
            </Typography> */}
          </div>
        ) : null}
        <div className={classes.padding}>
        <Typography color="textPrimary" variant={'body1'}>
              {t('ONE_OPTION_SHOULD_SELECT')}
        </Typography>
        </div>
      </div>
      <div>
        <Typography
          className={clsx(classes.bold, classes.title)}
          color="textPrimary"
          variant={'body1'}
        >
          {t('AVAILABLE_THICKNESS')}*
        </Typography>
        <div className={classes.textFields}>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  className={classes.radio}
                  name="standard"
                  color="primary"
                  // onClick={standardThicknessChangeHandler}
                  checked={((closeupSPHRange5 && closeupCYLRange2) || (distanceSPHRange5 && distanceCYLRange2)) && (distance || closeup)}
                />
              }
              label={t('STANDARD_THICKNESS')}
            />
            <TextField
              className={classes.input}
              type="number"
              placeholder={t('PRICE')}
              disabled={!(((closeupSPHRange5 && closeupCYLRange2) || (distanceSPHRange5 && distanceCYLRange2)) && (distance || closeup))}
              value={standardThicknesPrice}
              onChange={standardThicknessPriceChangeHandler}
              error={standardThicknesPrice === '' && buttonPressed===true && standardThicknes}
            />
          </div>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  className={classes.radio}
                  name="thin"
                  color="primary"
                  // onClick={thinChangeHandler}
                  checked={(closeupSPHRange1 || closeupSPHRange2 || closeupSPHRange3 || closeupSPHRange4 || closeupSPHRange5 || closeupCYLRange1 || closeupCYLRange2 || distanceSPHRange1 || distanceSPHRange2 || distanceSPHRange3 || distanceSPHRange4 || distanceSPHRange5 || distanceCYLRange1 || distanceCYLRange2) && (distance || closeup)}
                />
              }
              label={t('THIN')}
            />
            <TextField
              className={classes.input}
              type="number"
              placeholder={t('PRICE')}
              disabled={!((closeupSPHRange1 || closeupSPHRange2 || closeupSPHRange3 || closeupSPHRange4 || closeupSPHRange5 || closeupCYLRange1 || closeupCYLRange2 || distanceSPHRange1 || distanceSPHRange2 || distanceSPHRange3 || distanceSPHRange4 || distanceSPHRange5 || distanceCYLRange1 || distanceCYLRange2) && (distance || closeup))}
              value={thinPrice}
              onChange={thinPriceChangeHandler}
              error={thinPrice === '' && buttonPressed===true && thin}
            />
          </div>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  className={classes.radio}
                  name="ultrathin"
                  color="primary"
                  // onClick={ultraThinChangeHandler}
                  checked={(closeupSPHRange1 || closeupSPHRange2 || closeupSPHRange3 || closeupSPHRange4 || closeupSPHRange5 || closeupCYLRange1 || closeupCYLRange2 || distanceSPHRange1 || distanceSPHRange2 || distanceSPHRange3 || distanceSPHRange4 || distanceSPHRange5 || distanceCYLRange1 || distanceCYLRange2) && (distance || closeup)}
                />
              }
              label={t('ULTRA_THIN')}
            />
            <TextField
              className={classes.input}
              type="number"
              placeholder={t('PRICE')}
              disabled={!((closeupSPHRange1 || closeupSPHRange2 || closeupSPHRange3 || closeupSPHRange4 || closeupSPHRange5 || closeupCYLRange1 || closeupCYLRange2 || distanceSPHRange1 || distanceSPHRange2 || distanceSPHRange3 || distanceSPHRange4 || distanceSPHRange5 || distanceCYLRange1 || distanceCYLRange2) && (distance || closeup))}
              value={ultraThinPrice}
              onChange={ultraThinPriceChangeHandler}
              error={ultraThinPrice === '' && buttonPressed===true && ultraThin}
            />
          </div>
        </div>
        <div className={classes.info}>
          <Typography variant="body1" color="textPrimary">
            {t('THICKNESS_MANDATORY')}
          </Typography>
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  bold: {
    fontWeight: 700,
  },
  title: {
    paddingTop: theme.spacing(3.5),
    paddingBottom: theme.spacing(2),
  },
  radio: {
    color: theme.palette.primary.dark,
  },
  checkbox: {
    margin: '0',
  },
  prescription: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    padding: theme.spacing(3),
    background: theme.palette.primary.light,
  },
  prescriptionPricing: {
    padding: theme.spacing(1.5),
  },
  thickness: {
    display: 'grid',
    gridTemplateColumns: '0.19fr 0.19fr 0.38fr 0.27fr',
    padding: theme.spacing(3),
    background: theme.palette.primary.light,
  },
  input: {
    display: 'grid',
    backgroundColor: theme.palette.background.default,
    borderRadius: '5px',
    height: '34px',
    marginBottom: theme.spacing(3),
    width: '190px',
    '& input[type=number]': {
      '-moz-appearance': 'textfield'
    },
    '& input[type=number]::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0
    },
    '& input[type=number]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0
    }
  },
  sphereAndCyl: {
    display: 'grid',
    gridTemplateColumns: '0.2fr 0.2fr',
    gridGap: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  sphereContainer: {
    width: '230px',
    height: '285px',
    background: theme.palette.background.default,
    borderRadius: '5px',
  },
  cylContainer: {
    width: '230px',
    height: '120px',
    background: theme.palette.background.default,
    borderRadius: '5px',
  },
  rangeAndPrice: {
    display: 'grid',
    gridTemplateColumns: '0.5fr 0.5fr',
    rowGap: theme.spacing(1),
    padding: theme.spacing(1),
  },
  inlineRange: {
    display: 'grid',
    gridTemplateColumns: '0.4fr 0.2fr 0.4fr',
    alignItems: 'center',
  },
  arrow: {
    transform: 'rotate(90deg)',
  },
  priceInput: {
    width: '70px',
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: '3px',
    textAlign: 'center',
    '& input[type=number]': {
      '-moz-appearance': 'textfield'
    },
    '& input[type=number]::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0
    },
    '& input[type=number]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0
    }
  },
  tallPriceInput: {
    height: '43px',
  },
  textFields: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit,minmax(170px,0.3fr))',
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    background: theme.palette.primary.light,
    gridGap: theme.spacing(3),
  },
  info: {
    padding: `0px ${theme.spacing(3)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
    background: theme.palette.primary.light,
  },
  priceInputError:{
    width: '70px',
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: '3px',
    textAlign: 'center',
    '& input[type=number]': {
      '-moz-appearance': 'textfield'
    },
    '& input[type=number]::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0
    },
    '& input[type=number]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0
    }
  },
  padding: {
    paddingTop: `${theme.spacing(1)}px`
  },
  shape: {
    display: 'grid',
    gridTemplateColumns: 'minmax(100px, 1fr)',
    columnGap: theme.spacing(4),
    marginLeft: '11px'
  },
  input2: {
    display: 'grid',
    backgroundColor: theme.palette.background.default,
    borderRadius: '5px',
    height: '34px',
    marginBottom: theme.spacing(3),
  },
  twoColumns: {
    display: 'grid',
    gridGap: theme.spacing(2),
    gridTemplateColumns: '0.33fr 0.2fr'
  },
  checkAllButton: {
    height: '46px',
    marginTop: theme.spacing(1.25),
  }
}));

export default UpgradesPrescriptionThickness;