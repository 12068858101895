import { makeStyles, Typography } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import addPicture from '../../assets/add-picture.png';
import checkPicture from '../../assets/check-picture.png';
import { setImage3DProduct, setImagesProduct, showSnack } from '../../state/actions';
import { destroyImageFile, uploadImageFile } from '../../state/commands/product';
import { INEXISTENT_ID, ProductShow } from '../../state/types';
import { SnackbarVariant } from '../../utils/constants';

const PRODUCT_IMAGE = 'PRODUCT_IMAGE';
const IMAGE_3D = 'IMAGE_3D';

interface Props {
  item?: ProductShow;
  category?: string;
}

const Images: FC<Props> = props => {
  const { item,category } = props;
  const classes = useStyles();
  const [imagesCount, setImagesCount] = useState<number>(1);
  const [imageList,setImageList] = useState<string[]>([]);
  const [imageListId, setImageListId] = useState<string[]>([]);
  const [image3DId, setImage3DId] = useState<string>(INEXISTENT_ID);
  const [imageType, setImageType] = useState<string>(PRODUCT_IMAGE);

  const [dummyState,setDummyState]=useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(()=>{
    if(typeof item !== 'undefined' && item.images.length>0)
    {
      setImagesCount(item.images.length);
      const newImageList:string[]=[];
      const newImageListId:string[]=[];
      for(let i=0;i<item.images.length;i++)
      {
        newImageList.push(item.images[i].name)
        newImageListId.push(item.images[i].id.toString())
      }
      setImageList(newImageList)
      setImageListId(newImageListId)
      if(item.image3d!==0)
      {
        setImage3DId(item.image3d.toString())
      }
    }
  },[])

  const getFileFromInput = (file: File): Promise<any> => {
    return new Promise(function (resolve, reject) {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = function () { resolve(reader.result); };
      reader.readAsBinaryString(file); // here the file can be read in different way Text, DataUrl, ArrayBuffer
    });
  }

  const manageUploadedFile  = (binary: String, file: File) => {
    // console.log(`The file size is ${binary.length}`);
    // console.log(`The file name is ${file.name}`);
    uploadFile(file);
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
      Array.prototype.slice.call(event.currentTarget.files).forEach(file => {
            getFileFromInput(file)
                .then((binary) => {
                    manageUploadedFile(binary, file);
                }).catch((reason) => {
                    showSnack(`Error during upload ${reason}`, SnackbarVariant.error);
                    event.target.value = ''; // to allow upload of same file if error occurs
                });
        });
  }

  const uploadFile = async (file: File) => {
    try {
      const response = await uploadImageFile(file);
      if(imageType===PRODUCT_IMAGE)
      {
        imageList.push(response.data.name);
        imageListId.push(response.data.id);
        setImagesProduct(imageListId);
      }
      else{
        setImage3DProduct(response.data.id);
        setImage3DId(response.data.id);
      }
      setDummyState(!dummyState);
    } catch (e) {
      showSnack(e.message, SnackbarVariant.error);
    }
  };

  const deleteImg = (pictureIndex: number,imageType: string) => {
    if(imageType===PRODUCT_IMAGE)
    {
      let auxImageListId = [...imageListId];
      const imageToDeleteId = auxImageListId[pictureIndex]
      destroyImage(imageToDeleteId);
      auxImageListId.splice(pictureIndex, 1);
      setImageListId(auxImageListId); 

      let auxImageList = [...imageList];
      auxImageList.splice(pictureIndex, 1);
      setImageList(auxImageList);
      if(imagesCount>1)
      {
        setImagesCount(imagesCount - 1);
      }
    }
    else{
      const imageToDeleteId = image3DId;
      destroyImage(imageToDeleteId);
      setImage3DId(INEXISTENT_ID);
    }    
  }

  const destroyImage = async (imageId: string) => {
    try {
      const response = await destroyImageFile(imageId);
    } catch (e) {
      showSnack(e.message, SnackbarVariant.error);
    }
  };

  const addNewImages = () => {
    const images = [];
    for (let i = 0; i < imagesCount; i++) {
      images.push(
        <React.Fragment key={i}>
          {console.log(dummyState)}
          <div className={classes.imageAndDelete}>
            <div className={classes.imageSlot}>
              <input
                accept="image/*"
                className={classes.input}
                id="image-file"
                type="file"
                onChange={handleFileChange}
              />
              <label htmlFor="image-file" onClick={setImgType.bind(this,PRODUCT_IMAGE)}>
                <img className={imageList[i]===undefined ? classes.addPicture:classes.fitImage} src={imageList[i]!==undefined ? imageList[i] : addPicture} alt={'add'} />
              </label>
            </div>
              <label className={classes.deleteButton} onClick={deleteImg.bind(this,i,PRODUCT_IMAGE)}>
                <Typography
                  className={classes.hover}
                  color="secondary"
                  variant={'body1'}
                >
                  {imageList[i]!==undefined ? t('DELETE') : null}
                </Typography>
              </label>
          </div>
        </React.Fragment>,
      );
    }
    return images;
  };
  const inscreaseImagesCount = () => {
    setImagesCount(imagesCount + 1);
  };

  const setImgType = (type: string) => {
    setImageType(type)
  }

  return (
    <div className={typeof category !== 'undefined' ? classes.productInfo2 : classes.productInfo}>
      <div className={classes.whiteLine} />
      <Typography color="textPrimary" variant={'body1'}>
        {t('PRODUCT_IMAGES')}
      </Typography>
      <div className={classes.images}>{addNewImages()}</div>
      <Typography
        onClick={inscreaseImagesCount}
        className={classes.addImage}
        color="primary"
        variant={'body1'}
      >
        {t('ADD_IMAGE')}
      </Typography>
      <Typography color="textPrimary" variant={'body1'}>
        {t('IMAGE_3D')}
      </Typography>
      <div className={classes.images}>
       <div className={classes.imageAndDelete}>
        <div className={classes.imageSlot}>
          <input
              accept="image/*"
              className={classes.input}
              id="3d-image-file"
              type="file"
              onChange={handleFileChange}
            />
            <label htmlFor="3d-image-file" onClick={setImgType.bind(this,IMAGE_3D)}>
              <img className={classes.addPicture} src={image3DId!==INEXISTENT_ID ?  checkPicture : addPicture} alt={'add'} />
            </label>
        </div>
        <label className={classes.deleteButton} onClick={deleteImg.bind(this,0,IMAGE_3D)}>
                <Typography
                  className={classes.hover}
                  color="secondary"
                  variant={'body1'}
                >
                  {image3DId!==INEXISTENT_ID  ? t('DELETE') : null}
                </Typography>
              </label>
       </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  bold: {
    fontWeight: 700,
  },
  productInfo: {
    padding: theme.spacing(3),
    background: theme.palette.primary.light,
  },
  productInfo2: {
    padding: theme.spacing(3),
    background: theme.palette.primary.light,
    marginTop: -theme.spacing(12.5)
  },
  images: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit,minmax(300px,0.33fr))',
    alignItems: 'center',
    gridGap: theme.spacing(3.5),
  },
  imageSlot: {
    backgroundColor: theme.palette.background.default,
    width: '300px',
    height: '164px',
    marginTop: theme.spacing(1),
    display: 'grid',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden'
  },
  addPicture: {
    position: 'relative',
    cursor: 'pointer',
  },
  hover: {
    cursor: 'pointer',
  },
  whiteLine: {
    height: '2px',
    backgroundColor: theme.palette.background.default,
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4),
  },
  addImage: {
    fontWeight: 700,
    cursor: 'pointer',
    marginBottom: theme.spacing(3.5),
    marginTop: theme.spacing(5),
    width: '125px',
  },
  input: {
    display: 'none',
  },
  fitImage: {
    // maxWidth: '100%',
    // height:'auto',
    // width: '100%'
    maxWidth: '100%',
    maxHeight: '100%'
  },
  imageAndDelete: {
    display: 'grid',
    width: '300px',
    gridTemplateRows: '0.9fr 0.1fr'
  },
  deleteButton: {
    justifySelf: 'end',
  }
}));

export default Images;
