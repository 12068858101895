import {
  Button,
  FormControl,
  makeStyles,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import arrow from '../assets/Arrow.png';
import { setOrderBy, setOrderProductCategory, showSnack } from '../state/actions';
import { getAllOrders } from '../state/commands/order';
import { deleteProduct, getAllProducts } from '../state/commands/product';
import { InitialState } from '../state/types';
import { MetaLinks, ProductCategory, SnackbarVariant } from '../utils/constants';

interface Props {
  parentPage: string;
  page: number;
  setPage: (newPage: number) => void;
  idsToDelete?: number[];
  setIdsToDelete?: (newIds: number[]) => void;
  setRefresh?: (refresh: boolean) => void;
}

export enum ParentPage {
  PRODUCTS = 'PRODUCTS',
  ORDERS = 'ORDERS',
}

const Filters: FC<Props> = props => {
  const { parentPage,page,setPage,idsToDelete,setIdsToDelete,setRefresh } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const orderBy = useSelector((state: InitialState) => state.orderBy);
  const productCategory = useSelector((state: InitialState) => state.productCategory);
  const [actionType, setActionType] = React.useState<string>(t('DELETE'));
  const [sortingType, setSortingType] = React.useState<string>(t('DATE_NEWEST'));
  const [itemType, setItemType] = React.useState<string>(t('EYEGLASSES'));
  const [totalNumber,setTotalNumber] = React.useState<number>(40);
  const [meta,setMeta] = useState<MetaLinks>();

  useEffect(()=>{
    if(parentPage===ParentPage.PRODUCTS)
    {
      getProductsMeta();
    }
    else{
      getOrdersMeta();
    }
    if(productCategory===ProductCategory.EYEGLASSES)
    {
      setItemType(t('EYEGLASSES'))
    }
    if(productCategory===ProductCategory.SUNGLASSES)
    {
      setItemType(t('SUNGLASSES'))
    }
    if(productCategory===ProductCategory.COMPUTER_GLASSES)
    {
      setItemType(t('COMPUTERGLASSES'))
    }
    if(productCategory===ProductCategory.CONTACT_LENSES)
    {
      setItemType(t('EYESIGHT_LENSES'))
    }
    if(productCategory===ProductCategory.COSMETIC_LENSES)
    {
      setItemType(t('COSMETIC_LENSES'))
    }
    if(orderBy==='created_at desc')
    {
      setSortingType(t('DATE_NEWEST'))
    }
    if(orderBy==='created_at asc')
    {
      setSortingType(t('DATE_OLDEST'))
    }
    if(orderBy==='price asc')
    {
      setSortingType(t('CHEAPEST'))
    }
    if(orderBy==='price desc')
    {
      setSortingType(t('PRICIEST'))
    }
  },[orderBy,productCategory,page])

  const getProductsMeta = async () => {
    try {
      const response = await getAllProducts(productCategory,orderBy,page);
      setMeta(response.meta);
    } catch(e) {
      showSnack(e.message, SnackbarVariant.error);
    }
  };

  const getOrdersMeta = async () => {
    try {
      const response = await getAllOrders(productCategory,orderBy,page);
      setMeta(response.meta);
    } catch(e) {
      showSnack(e.message, SnackbarVariant.error);
    }
  };

  useEffect(()=>{
    if(typeof meta !== 'undefined')
    {
      setTotalNumber(meta.total)
    }
  },[meta])

  const handleDropDown1Change = (event: React.ChangeEvent<{ value: unknown }>) => {
    setActionType(String(event.target.value));
  };
  const handleDropDown2Change = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSortingType(String(event.target.value));
    if(String(event.target.value)===t('DATE_NEWEST'))
    {
      setOrderBy('created_at desc')
    }
    if(String(event.target.value)===t('DATE_OLDEST'))
    {
      setOrderBy('created_at asc')
    }
    if(String(event.target.value)===t('CHEAPEST'))
    {
      setOrderBy('price asc')
    }
    if(String(event.target.value)===t('PRICIEST'))
    {
      setOrderBy('price desc')
    }
    
  };
  const handleDropDown3Change = (event: React.ChangeEvent<{ value: unknown }>) => {
    setItemType(String(event.target.value));
    if(String(event.target.value)===t('EYEGLASSES'))
    {
      setOrderProductCategory(ProductCategory.EYEGLASSES)
    }
    if(String(event.target.value)===t('SUNGLASSES'))
    {
      setOrderProductCategory(ProductCategory.SUNGLASSES)
    }
    if(String(event.target.value)===t('COMPUTERGLASSES'))
    {
      setOrderProductCategory(ProductCategory.COMPUTER_GLASSES)
    }
    if(String(event.target.value)===t('EYESIGHT_LENSES'))
    {
      setOrderProductCategory(ProductCategory.CONTACT_LENSES)
    }
    if(String(event.target.value)===t('COSMETIC_LENSES'))
    {
      setOrderProductCategory(ProductCategory.COSMETIC_LENSES)
    }
    if(String(event.target.value)===t('COSMETIC_LENSES'))
    {
      setOrderProductCategory(ProductCategory.COSMETIC_LENSES)
    }
    if(String(event.target.value)===t('OTHER_CATEGORIES'))
    {
      setOrderProductCategory(ProductCategory.OTHER)
    }
    if(String(event.target.value)===t('ALL'))
    {
      setOrderProductCategory('')
    }
  };

  const handleClickNextPage = () => {
    setPage(page + 1);
  };

  const handleClickPrevPage = () => {
    setPage(page - 1);
  };

  let counter = 0;
  const handleClickApply = () => {
    if(typeof idsToDelete !== 'undefined')
    {
      if(idsToDelete.length>0)
      {
        removeProduct();
      }
    }
  };

  const removeProduct = async () => {
    for(let i=0;i<idsToDelete!.length;i++)
        {
          try {
            const response = await deleteProduct(idsToDelete![i].toString());
            if(response.status==='success')
            {
              counter++;
            }
            } catch(e) {
              showSnack(e.message, SnackbarVariant.error);
            }
        }
        if(counter===idsToDelete!.length)
        {
          if(typeof setRefresh !== 'undefined' && typeof setIdsToDelete !== 'undefined')
          {
            setRefresh(true);
            setIdsToDelete([]);
          }
        }
  };

  return (
    <div className={classes.root}>
      {parentPage === ParentPage.PRODUCTS ?
      <div className={classes.dropDownAndButton}>
        <FormControl variant="filled" hiddenLabel={true}>
          <Select
            value={actionType}
            onChange={handleDropDown1Change}
            className={classes.formControl}
          >
            <MenuItem value={t('DELETE')}>{t('DELETE')}</MenuItem>
          </Select>
        </FormControl>
        <Button className={classes.blueButton} size="large" variant="contained" color="primary" onClick={handleClickApply}>
          <Typography color="textSecondary" variant={'body1'}>
            {t('APPLY')}
          </Typography>
        </Button>
      </div> : null }
      <div>
        <Typography className={classes.lightGrey} color="textSecondary" variant={'body1'}>
          {t('ORDER_BY')}
        </Typography>
        <FormControl variant="filled" className={classes.formControl} hiddenLabel={true}>
          <Select
            value={sortingType}
            onChange={handleDropDown2Change}
            className={classes.formControl}
          >
            <MenuItem value={t('DATE_NEWEST')}>{t('DATE_NEWEST')}</MenuItem>
            <MenuItem value={t('DATE_OLDEST')}>{t('DATE_OLDEST')}</MenuItem>
            <MenuItem value={t('CHEAPEST')}>{t('CHEAPEST')}</MenuItem>
            <MenuItem value={t('PRICIEST')}>{t('PRICIEST')}</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div>
        <Typography className={classes.lightGrey} color="textSecondary" variant={'body1'}>
          {t('PRODUCT_CATEGORY')}
        </Typography>
        <FormControl variant="filled" className={classes.formControl} hiddenLabel={true}>
          <Select
            value={itemType}
            onChange={handleDropDown3Change}
            className={classes.formControl}
          >
            <MenuItem value={t('EYEGLASSES')}>{t('EYEGLASSES')}</MenuItem>
            <MenuItem value={t('SUNGLASSES')}>{t('SUNGLASSES')}</MenuItem>
            <MenuItem value={t('COMPUTERGLASSES')}>{t('COMPUTERGLASSES')}</MenuItem>
            <MenuItem value={t('EYESIGHT_LENSES')}>{t('EYESIGHT_LENSES')}</MenuItem>
            <MenuItem value={t('COSMETIC_LENSES')}>{t('COSMETIC_LENSES')}</MenuItem>
            <MenuItem value={t('OTHER_CATEGORIES')}>{t('OTHER_CATEGORIES')}</MenuItem>
            <MenuItem value={t('ALL')}>{t('ALL')}</MenuItem>
          </Select>
        </FormControl>
      </div>
      {parentPage === ParentPage.ORDERS ? <div/> : null}
      <div className={classes.pages}>
        <div className={classes.numbersPerPage}>
          <Typography className={classes.bold} color="textPrimary" variant={'subtitle2'}>
            {page > 1 ? (page-1)*10+1:page*10} - {page > 1 && page*10 > totalNumber ? totalNumber : page*10}
          </Typography>
          <Typography color="textPrimary" variant={'subtitle2'}>
            of
          </Typography>
          <Typography className={classes.bold} color="textPrimary" variant={'subtitle2'}>
            {totalNumber}
          </Typography>
        </div>
        {page === 1 ? null : (
          <div className={classes.square} onClick={handleClickPrevPage}>
            <img src={arrow} alt="alt" className={classes.prevArrow} />
          </div>
        )}
        {page * 10 >= totalNumber ? null : (
          <div className={classes.square} onClick={handleClickNextPage}>
            <img src={arrow} alt="alt" className={classes.nextArrow} />
          </div>
        )}
      </div>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 0.25fr))',
    gridGap: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  dropDownAndButton: {
    display: 'grid',
    gridTemplateColumns: '1fr 0.25fr',
    gridGap: '10px',
    alignItems: 'end',
  },
  blueButton: {
    width: '78px',
    height: '46px',
  },
  lightGrey: {
    color: '#595959',
  },
  formControl: {
    height: '44px',
    width: '100%',
  },
  bold: {
    fontWeight: 700,
  },
  numbersPerPage: {
    display: 'grid',
    gridTemplateColumns: '55px 20px 30px',
    alignItems: 'end',
    paddingBottom: theme.spacing(1),
  },
  pages: {
    display: 'grid',
    marginLeft: theme.spacing(3),
    gridTemplateColumns: '110px 55px 55px',
  },
  square: {
    width: '46px',
    height: '46px',
    boxShadow: '0px 2px 15px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    alignSelf: 'end',
    cursor: 'pointer',
  },
  prevArrow: {
    alignSelf: 'center',
    margin: '0 auto',
    width: '20%',
    transform: 'rotate(180deg)',
  },
  nextArrow: {
    alignSelf: 'center',
    margin: '0 auto',
    width: '20%',
  },
}));

export default Filters;
