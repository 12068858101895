import { MuiThemeProvider } from '@material-ui/core';
import * as React from 'react';
import { Suspense } from 'react';
import { Provider } from 'react-redux';
import AppContainer from './AppContainer';
import { store } from './state/store';
import theme from './theme';
import './i18n';

function App() {
  return (
    <Suspense fallback="loading">
      <Provider store={store}>
        <MuiThemeProvider theme={theme}>
          <AppContainer />
        </MuiThemeProvider>
      </Provider>
    </Suspense>
    
  );
}

export default App;
