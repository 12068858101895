import { makeStyles, Popover } from '@material-ui/core';
import React, { FC } from 'react';

interface Props {
  opened: boolean;
  anchorEl: HTMLElement | null;
  close: () => void;
}

const CustomPopover: FC<Props> = props => {
  const classes = useStyles();
  const { opened, anchorEl, close } = props;

  return (
    <Popover
      open={opened}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      onClose={close}
    >
      <div onMouseLeave={close} className={classes.menu}>
        {props.children}
      </div>
    </Popover>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    display: 'grid',
  },
  menu: {
    display: 'grid',
    gridGap: theme.spacing(1),
    padding: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(1),
  },
}));

export default CustomPopover;
