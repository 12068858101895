import {
  Button,
  Dialog,
  DialogContent,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { FC } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { showSnack } from '../../state/actions';
import { updateProductStock } from '../../state/commands/product';
import { ProductShow } from '../../state/types';
import { SnackbarVariant } from '../../utils/constants';

interface Props {
  onClose: () => void;
  open: boolean;
  stock: number;
  id: number;
  products: ProductShow[];
  setProducts: (products: ProductShow[]) => void;
  index: number;
}

const StockQuantityDialog: FC<Props> = props => {
  const { onClose, open, stock,id,products,setProducts,index } = props;
  const classes = useStyles();
  const [itemQuantity, setItemQuantity] = useState<string>('');
  const { t } = useTranslation();

  const handleItemQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setItemQuantity(String(e.target.value));
  };

  const handleUpdateStock = async () => {
    try {
      const response = await updateProductStock(id.toString(),itemQuantity);
      if(response.status==='success')
      {
        let newArray = [...products];
        newArray[index]=response.data;
        setProducts(newArray)
      }
    } catch(e) {
      showSnack(e.message, SnackbarVariant.error);
    }
    
    onClose();
  };

  const editAccount = (
    <Dialog onClose={onClose} open={open} fullWidth={true} maxWidth="md">
      <DialogContent classes={{ root: classes.root }}>
        <div className={classes.paper}>
          <Typography className={classes.bold} variant={'h4'}>
            {t('MODIFY_STOCK')}
          </Typography>
          <div className={classes.modalControls}>
            <Typography variant={'subtitle2'}>{t('NUMBER_PRODUCTS_STOCK')}</Typography>
            <TextField
              hiddenLabel={true}
              variant="filled"
              value={itemQuantity === null ? '' : itemQuantity}
              placeholder={String(stock)}
              onChange={handleItemQuantityChange}
            />
          </div>
          <Button
            className={classes.greenButton}
            size="large"
            color="primary"
            variant="contained"
            onClick={handleUpdateStock}
          >
            <Typography color="textSecondary" variant={'body1'}>
              {t('SAVE')}
            </Typography>
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );

  return <div>{editAccount}</div>;
};

const useStyles = makeStyles(theme => ({
  root: {
    flex: 'unset',
    overflowX: 'hidden',
  },
  paper: {
    width: '100%',
    backgroundColor: theme.palette.background.default,
    display: 'grid',
    paddingLeft: theme.spacing(3.5),
    paddingTop: theme.spacing(3.5),
    gridGap: theme.spacing(3),
    paddingBottom: theme.spacing(3.5),
  },
  bold: {
    fontWeight: 700,
  },
  modalControls: {
    display: 'grid',
    gridTemplateColumns: '0.4fr 0.7fr 0.05fr ',
    alignItems: 'center',
    marginRight: theme.spacing(3.5),
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr',
    },
  },
  birthDate: {
    display: 'grid',
    gridTemplateColumns: '0.2fr 0.2fr 0.3fr',
    gridGap: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      gridGap: theme.spacing(1),
      gridTemplateColumns: '0.33fr 0.33fr 0.33fr',
    },
  },
  greyText: {
    color: theme.palette.grey[200],
  },
  greenButton: {
    height: '46px',
    width: '98px',
    [theme.breakpoints.down('sm')]: {
      justifySelf: 'end',
      marginRight: theme.spacing(3.5),
    },
  },
}));

export default StockQuantityDialog;
