import { Button, makeStyles, Menu, MenuItem } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import clsx from 'clsx';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import OrderStatusDialog from '../../components/OrderManagement/OrderStatusDialog';
import { OrdersDetails } from '../../utils/constants';

interface Props {
  item: OrdersDetails;
  refresh: boolean;
  setRefresh: (newRefresh: boolean) => void;
}

const MenuItemDropDownOrders: FC<Props> = props => {
  const classes = useStyles();
  const { item,refresh,setRefresh } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [orderStatusDialogOpened, setOrderStatusDialogOpened] = useState<boolean>(false);
  const handleCloseDropdown = () => {
    setAnchorEl(null);
  };

  const handleCloseDialog = () => {
    setOrderStatusDialogOpened(false);
  };
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const openChangeOrderStatusDialog = () => {
    setOrderStatusDialogOpened(true);
    handleCloseDropdown();
  };

  const { t } = useTranslation();

  return (
    <div className={clsx(classes.cells, classes.dropdownButton)}>
      <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
        <ArrowDropDownIcon />
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted={true}
        open={Boolean(anchorEl)}
        onClose={handleCloseDropdown}
      >
        <MenuItem onClick={openChangeOrderStatusDialog.bind(this)}>
          {t('CHANGE_ORDER_STATUS')}
        </MenuItem>
      </Menu>
      {orderStatusDialogOpened ? (
        <OrderStatusDialog
          onClose={handleCloseDialog}
          open={orderStatusDialogOpened}
          item={item}
          refresh={refresh}
          setRefresh={setRefresh}
        />
      ) : null}
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  cells: {
    height: '39px',
    display: 'flex',
  },
  dropdownButton: {
    display: 'grid',
    justifyItems: 'center',
  },
}));

export default MenuItemDropDownOrders;
