import { SnackbarVariant } from "../utils/constants";
import { createProductInitial } from "./initialStore";
import { actionType, InitialState, SET_CREATE_PRODUCT, SET_PRODUCT_BRAND, SET_PRODUCT_CHARACTERISTICS, SET_PRODUCT_DESCRIPTION, SET_PRODUCT_DISCOUNT, SET_PRODUCT_FACE_SHAPE, SET_PRODUCT_FOR, SET_PRODUCT_FRAME_MATERIALS, SET_PRODUCT_FRAME_SHAPE, SET_PRODUCT_FRAME_TYPE, SET_PRODUCT_FULLPRICE, SET_PRODUCT_SIZE, SET_PRODUCT_LENS_TYPES, SET_PRODUCT_MODEL, SET_PRODUCT_STOCK, SET_PRODUCT_FRAME_COLOR, SET_PRODUCT_LENSE_COLOR, SET_PRODUCT_LENSE_IMPROVEMENTS, SET_PRODUCT_LENSE_THICKNESS, SET_PRODUCT_LENSE_TYPE, SET_PRODUCT_CATEGORY, SET_PRODUCT_IMAGE3D, SET_PRODUCT_IMAGES, SET_PRODUCT_DIOPTERS, SET_PRODUCT_PERIOD_OF_USE, SET_PRODUCT_METHOD_OF_WEARING, SET_ORDER_BY, SET_ORDER_PRODUCT_CATEGORY, RESET_PRODUCT, SHOW_SNACKBAR, HIDE_SNACKBAR, SET_BUTTON_PRESSED, SET_PRODUCT_CODE } from "./types";

export const initialState: InitialState = {
  product: createProductInitial,
  orderBy: 'created_at desc',
  productCategory: '1',
  snackbar: {
    open: false,
    message: '',
    variant: SnackbarVariant.error,
  },
  buttonPressed: false,
};

export const reducer = (state = initialState, action: actionType): InitialState => {
  switch (action.type) {
    case SET_CREATE_PRODUCT: {
      return {
        ...state,
        ...action.meta,
      };
    }
    case SET_PRODUCT_BRAND: {
      return {
        ...state,
        product: {
          ...state.product,
          brand: action.meta,
        },
      };
    }
    case SET_PRODUCT_MODEL: {
      return {
        ...state,
        product: {
          ...state.product,
          model: action.meta,
        },
      };
    }
    case SET_PRODUCT_STOCK: {
      return {
        ...state,
        product: {
          ...state.product,
          stock: action.meta,
        },
      };
    }
    case SET_PRODUCT_FULLPRICE: {
      return {
        ...state,
        product: {
          ...state.product,
          price: action.meta,
        },
      };
    }
    case SET_PRODUCT_DISCOUNT: {
      return {
        ...state,
        product: {
          ...state.product,
          discount: action.meta,
        },
      };
    }
    case SET_PRODUCT_DESCRIPTION: {
      return {
        ...state,
        product: {
          ...state.product,
          description: action.meta,
        },
      };
    }
    case SET_PRODUCT_FACE_SHAPE: {
      return {
        ...state,
        product: {
          ...state.product,
          ['face-shape']: action.meta,
        },
      };
    }
    case SET_PRODUCT_FRAME_SHAPE: {
      return {
        ...state,
        product: {
          ...state.product,
          ['frame-shape']: action.meta,
        },
      };
    }
    case SET_PRODUCT_FRAME_TYPE: {
      return {
        ...state,
        product: {
          ...state.product,
          ['frame-type']: action.meta,
        },
      };
    }
    case SET_PRODUCT_FRAME_MATERIALS: {
      return {
        ...state,
        product: {
          ...state.product,
          ['frame-materials']: action.meta,
        },
      };
    }
    case SET_PRODUCT_LENS_TYPES: {
      return {
        ...state,
        product: {
          ...state.product,
          ['lens-types']: action.meta,
        },
      };
    }
    case SET_PRODUCT_CHARACTERISTICS: {
      return {
        ...state,
        product: {
          ...state.product,
          characteristics: action.meta,
        },
      };
    }
    case SET_PRODUCT_FOR: {
      return {
        ...state,
        product: {
          ...state.product,
          for: action.meta,
        },
      };
    }
    case SET_PRODUCT_SIZE: {
      return {
        ...state,
        product: {
          ...state.product,
          size: action.meta,
        },
      };
    }
    case SET_PRODUCT_FRAME_COLOR: {
      return {
        ...state,
        product: {
          ...state.product,
          ['frame-color']: action.meta,
        },
      };
    }
    case SET_PRODUCT_LENSE_COLOR: {
      return {
        ...state,
        product: {
          ...state.product,
          ['lenses-color']: action.meta,
        },
      };
    }
    case SET_PRODUCT_LENSE_IMPROVEMENTS: {
      return {
        ...state,
        product: {
          ...state.product,
          lense_improvement: action.meta,
        },
      };
    }
    case SET_PRODUCT_LENSE_THICKNESS: {
      return {
        ...state,
        product: {
          ...state.product,
          lense_thickness: action.meta,
        },
      };
    }
    case SET_PRODUCT_LENSE_TYPE: {
      return {
        ...state,
        product: {
          ...state.product,
          lense_type: action.meta,
        },
      };
    }
    case SET_PRODUCT_CATEGORY: {
      return {
        ...state,
        product: {
          ...state.product,
          category_id: action.meta,
        },
      };
    }
    case SET_PRODUCT_IMAGE3D: {
      return {
        ...state,
        product: {
          ...state.product,
          image3d: action.meta,
        },
      };
    }
    case SET_PRODUCT_IMAGES: {
      return {
        ...state,
        product: {
          ...state.product,
          images: action.meta,
        },
      };
    }
    case SET_PRODUCT_DIOPTERS: {
      return {
        ...state,
        product: {
          ...state.product,
          lense_diopter: action.meta,
        },
      };
    }
    case SET_PRODUCT_PERIOD_OF_USE: {
      return {
        ...state,
        product: {
          ...state.product,
          ['period-of-use']: action.meta,
        },
      };
    }
    case SET_PRODUCT_METHOD_OF_WEARING: {
      return {
        ...state,
        product: {
          ...state.product,
          ['method-of-wearing']: action.meta,
        },
      };
    }
    case SET_ORDER_BY: {
      return {
        ...state,
        orderBy: action.meta,
      };
    }
    case SET_ORDER_PRODUCT_CATEGORY: {
      return {
        ...state,
        productCategory: action.meta,
      };
    }
    case RESET_PRODUCT: {
      return {
        ...state,
        product: action.meta
      };
    }
    case SHOW_SNACKBAR: {
      return {
        ...state,
        snackbar: {
          ...state.snackbar,
          message: action.meta.message,
          variant: action.meta.variant,
          open: true,
        },
      };
    }
    case HIDE_SNACKBAR: {
      return {
        ...state,
        snackbar: {
          ...state.snackbar,
          open: false,
        },
      };
    }
    case SET_BUTTON_PRESSED: {
      return {
        ...state,
        buttonPressed: action.meta
      };
    }
    case SET_PRODUCT_CODE: {
      return {
        ...state,
        product: {
          ...state.product,
          code: action.meta,
        },
      };
    }
    
    default:
      return state;
  }
};
