import axios from 'axios';
import { env } from '../../config';
import { getAxiosConfig, ProductCategory } from '../../utils/constants';
import { LenseDiopter, ProductCreate } from '../types';
// const env =  'https://api.eyes-aid.com/'

const GET_EYEGLASSES_INFO = `${env}api/dashboard/create-product?category_id=`;
const UPLOAD_PRODUCT_IMAGE = `${env}api/dashboard/product/image`;
const DESTROY_PRODUCT_IMAGE = `${env}api/dashboard/product/image/`;
const CREATE_PRODUCT = `${env}api/dashboard/product`;
const SHOW_ALL_PRODUCTS = `${env}api/dashboard/product`;
const DELETE_PRODUCT = `${env}api/dashboard/product/`;
const UPDATE_PRODUCT = `${env}api/dashboard/product/`;
const UPDATE_PRODUCT_STOCK = `${env}api/dashboard/product/stock/`;

export const getProductInfo = async (categoryId : string) => {
  return axios
    .get(GET_EYEGLASSES_INFO+categoryId, getAxiosConfig())
    .then(res => {
      return res.data;
    })
    .catch(err => {
      throw new Error(err.message);
    });
};

export const uploadImageFile = async (file: File) => {
  let bodyFormData = new FormData();
  bodyFormData.append('image', file); 
  return axios
    .post(UPLOAD_PRODUCT_IMAGE, bodyFormData, getAxiosConfig())
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw new Error(err.message);
    });
};

export const destroyImageFile = async (imageId: string) => {
  return axios
    .delete(DESTROY_PRODUCT_IMAGE+imageId, getAxiosConfig())
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw new Error(err.message);
    });
};

const filteredLenseDiopter = (diopters: LenseDiopter[]) => diopters.filter((item=>{
  return item!==null
}));


export const createProduct = async (product: ProductCreate) => {
  let bodyParams;
  if(product.category_id===ProductCategory.EYEGLASSES)
  {
    bodyParams = {
      category_id: product.category_id,
      brands: product.brand,
      model: product.model,
      code: product.code,
      price: product.price,
      discount: product.discount,
      stock: product.stock,
      size: product.size,
      description: product.description,
      ['face-shape']: product['face-shape'],
      ['frame-shape']: product['frame-shape'],
      ['lens-types']: product['lens-types'],
      ['frame-type']: product['frame-type'],
      ['frame-materials']: product['frame-materials'],
      characteristics: product.characteristics,
      for: product.for,
      ['frame-color']: product['frame-color'],
      // ['lenses-color']: product['lenses-color'],
      images : product.images,
      image3d: product.image3d,
      lense_improvement: product.lense_improvement,
      lense_thickness: product.lense_thickness,
      lense_type: product.lense_type,
    };
  }
  else if(product.category_id===ProductCategory.SUNGLASSES)
  {
    bodyParams = {
      category_id: product.category_id,
      brands: product.brand,
      model: product.model,
      code: product.code,
      price: product.price,
      discount: product.discount,
      stock: product.stock,
      size: product.size,
      description: product.description,
      ['face-shape']: product['face-shape'],
      ['frame-shape']: product['frame-shape'],
      ['lens-types']: product['lens-types'],
      ['frame-type']: product['frame-type'],
      ['frame-materials']: product['frame-materials'],
      characteristics: product.characteristics,
      for: product.for,
      ['frame-color']: product['frame-color'],
      ['lenses-color']: product['lenses-color'],
      images : product.images,
      image3d: product.image3d,
    };
  }
  else if(product.category_id===ProductCategory.COMPUTER_GLASSES)
  {
    bodyParams = {
      category_id: product.category_id,
      brands: product.brand,
      model: product.model,
      code: product.code,
      price: product.price,
      discount: product.discount,
      stock: product.stock,
      size: product.size,
      description: product.description,
      ['face-shape']: product['face-shape'],
      ['frame-shape']: product['frame-shape'],
      ['lens-types']: product['lens-types'],
      ['frame-type']: product['frame-type'],
      ['frame-materials']: product['frame-materials'],
      characteristics: product.characteristics,
      for: product.for,
      ['frame-color']: product['frame-color'],
      images : product.images,
      image3d: product.image3d,
      lense_thickness: product.lense_thickness,
      lense_type: product.lense_type,
    };
  }
  else if(product.category_id===ProductCategory.CONTACT_LENSES)
  {
    bodyParams = {
      category_id: product.category_id,
      brands: product.brand,
      model: product.model,
      code: product.code,
      price: product.price,
      discount: product.discount,
      stock: product.stock,
      description: product.description,
      for: product.for,
      ['period-of-use']: product['period-of-use'],
      ['method-of-wearing']: product['method-of-wearing'],
      images : product.images,
      image3d: product.image3d,
      lense_diopter: filteredLenseDiopter(product.lense_diopter),
    };
  }
  else if(product.category_id===ProductCategory.COSMETIC_LENSES)
  {
    

    bodyParams = {
      category_id: product.category_id,
      brands: product.brand,
      model: product.model,
      code: product.code,
      price: product.price,
      discount: product.discount,
      stock: product.stock,
      description: product.description,
      ['period-of-use']: product['period-of-use'],
      // ['method-of-wearing']: product['method-of-wearing'],
      ['lenses-color']: product['lenses-color'],
      images : product.images,
      image3d: product.image3d,
      lense_diopter: filteredLenseDiopter(product.lense_diopter),
    };
    
  }
  else if(Number(product.category_id)>=Number(ProductCategory.OTHER))
  {
    bodyParams = {
      category_id: product.category_id,
      brands: product.brand,
      // model: product.model,
      code: product.code,
      price: product.price,
      discount: product.discount,
      stock: product.stock,
      description: product.description,
      images : product.images,
      image3d: product.image3d,
    };
  }
  else{
    bodyParams = {};
  }
  // console.log(bodyParams);
  return axios
    .post(CREATE_PRODUCT, bodyParams, getAxiosConfig())
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw new Error(err.message);
    });
};



export const getAllProducts = async (productCategory: string ,orderBy: string,page?: number) => {
  const actualPage = page ? page : 1;
  if(productCategory!=='')
  {
    return axios
    .get(SHOW_ALL_PRODUCTS+'?category_id='+productCategory+'&order_by='+orderBy.substr(0,orderBy.indexOf(' '))+'&order_type='+orderBy.substr(orderBy.indexOf(' ')+1)+'&page='+actualPage.toString(), getAxiosConfig())
    .then(res => {
      return res.data;
    })
    .catch(err => {
      throw new Error(err.message);
    });
  }
  else {
    return axios
    .get(SHOW_ALL_PRODUCTS+'?order_by='+orderBy.substr(0,orderBy.indexOf(' '))+'&order_type='+orderBy.substr(orderBy.indexOf(' ')+1)+'&page='+actualPage.toString(), getAxiosConfig())
    .then(res => {
      return res.data;
    })
    .catch(err => {
      throw new Error(err.message);
    });
  }
  
};


export const deleteProduct = async (productId: string) => {
  return axios
    .delete(DELETE_PRODUCT+productId, getAxiosConfig())
    .then(res => {
      return res.data;
    })
    .catch(err => {
      throw new Error(err.message);
    });
};


export const updateProduct = async (product: ProductCreate,productId: string) => {
  let bodyParams;
  if(product.category_id===ProductCategory.EYEGLASSES)
  {
    bodyParams = {
      category_id: product.category_id,
      brands: product.brand,
      model: product.model,
      code: product.code,
      price: product.price,
      discount: product.discount,
      stock: product.stock,
      size: product.size,
      description: product.description,
      ['face-shape']: product['face-shape'],
      ['frame-shape']: product['frame-shape'],
      ['lens-types']: product['lens-types'],
      ['frame-type']: product['frame-type'],
      ['frame-materials']: product['frame-materials'],
      characteristics: product.characteristics,
      for: product.for,
      ['frame-color']: product['frame-color'],
      // ['lenses-color']: product['lenses-color'],
      images : product.images,
      image3d: product.image3d,
      lense_improvement: product.lense_improvement,
      lense_thickness: product.lense_thickness,
      lense_type: product.lense_type,
    };
  }
  else if(product.category_id===ProductCategory.SUNGLASSES)
  {
    bodyParams = {
      category_id: product.category_id,
      brands: product.brand,
      model: product.model,
      code: product.code,
      price: product.price,
      discount: product.discount,
      stock: product.stock,
      size: product.size,
      description: product.description,
      ['face-shape']: product['face-shape'],
      ['frame-shape']: product['frame-shape'],
      ['lens-types']: product['lens-types'],
      ['frame-type']: product['frame-type'],
      ['frame-materials']: product['frame-materials'],
      characteristics: product.characteristics,
      for: product.for,
      ['frame-color']: product['frame-color'],
      ['lenses-color']: product['lenses-color'],
      images : product.images,
      image3d: product.image3d,
    };
  }
  else if(product.category_id===ProductCategory.COMPUTER_GLASSES)
  {
    bodyParams = {
      category_id: product.category_id,
      brands: product.brand,
      model: product.model,
      code: product.code,
      price: product.price,
      discount: product.discount,
      stock: product.stock,
      size: product.size,
      description: product.description,
      ['face-shape']: product['face-shape'],
      ['frame-shape']: product['frame-shape'],
      ['lens-types']: product['lens-types'],
      ['frame-type']: product['frame-type'],
      ['frame-materials']: product['frame-materials'],
      characteristics: product.characteristics,
      for: product.for,
      ['frame-color']: product['frame-color'],
      images : product.images,
      image3d: product.image3d,
      lense_thickness: product.lense_thickness,
      lense_type: product.lense_type,
    };
  }
  else if(product.category_id===ProductCategory.CONTACT_LENSES)
  {
    bodyParams = {
      category_id: product.category_id,
      brands: product.brand,
      model: product.model,
      code: product.code,
      price: product.price,
      discount: product.discount,
      stock: product.stock,
      description: product.description,
      for: product.for,
      ['period-of-use']: product['period-of-use'],
      ['method-of-wearing']: product['method-of-wearing'],
      images : product.images,
      image3d: product.image3d,
      lense_diopter: filteredLenseDiopter(product.lense_diopter),
    };
  }
  else if(product.category_id===ProductCategory.COSMETIC_LENSES)
  {
    bodyParams = {
      category_id: product.category_id,
      brands: product.brand,
      model: product.model,
      code: product.code,
      price: product.price,
      discount: product.discount,
      stock: product.stock,
      description: product.description,
      ['period-of-use']: product['period-of-use'],
      // ['method-of-wearing']: product['method-of-wearing'],
      ['lenses-color']: product['lenses-color'],
      images : product.images,
      image3d: product.image3d,
      lense_diopter: filteredLenseDiopter(product.lense_diopter),
    };
    
  }
  else if(Number(product.category_id)>=Number(ProductCategory.OTHER))
  {
    bodyParams = {
      brands: product.brand,
      category_id: product.category_id,
      // model: product.model,
      code: product.code,
      price: product.price,
      discount: product.discount,
      stock: product.stock,
      description: product.description,
      images : product.images,
      image3d: product.image3d,
    };
  }
  else{
    bodyParams = {};
  }
  // console.log(bodyParams);
  return axios
    .put(UPDATE_PRODUCT+productId, bodyParams, getAxiosConfig())
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw new Error(err.message);
    });
};

export const updateProductStock = async (productId: string,stock: string) => {
  const bodyParams = {
    stock: stock
  };
  return axios
    .post(UPDATE_PRODUCT_STOCK+productId, bodyParams, getAxiosConfig())
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw new Error(err.message);
    });
};

export const searchProducts = async (searchTerm: string,categoryId: string) => {
  return axios
    .get(SHOW_ALL_PRODUCTS+'?category_id='+categoryId+'&model='+searchTerm, getAxiosConfig())
    .then(res => {
      if(res.data.data.length!==0)
      {
        return res.data;
      }
    })
    .catch(err => {
      throw new Error(err.message);
    });
};

export const searchProductsCode = async (searchTerm: string,categoryId: string) => {
  return axios
    .get(SHOW_ALL_PRODUCTS+'?category_id='+categoryId+'&code='+searchTerm, getAxiosConfig())
    .then(res => {
      if(res.data.data.length!==0)
      {
        return res.data;
      }
    })
    .catch(err => {
      throw new Error(err.message);
    });
};