import {
  Button,
  DialogContent,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { showSnack } from '../../../state/actions';
import {
  getCitiesCargus,
  getCountiesCargus,
  getPickupPoint,
  postPickupPoint,
  updatePickupPoint,
} from '../../../state/commands/location';
import { getCompany, getUser } from '../../../state/commands/user';
import {
  actionType,
  CityCargus,
  Country,
  County,
  CountyCargus,
  PickupLocationGet,
  PickupLocationPost,
  SnackbarVariant,
} from '../../../utils/constants';

interface Props {
  onClose: () => void;
  data: PickupLocationPost | PickupLocationGet;
  action: string;
}

const PickupLocationDialog: FC<Props> = props => {
  const classes = useStyles();
  const { onClose, data, action } = props;
  const [country, setCountry] = React.useState<string>(data.country_id === 1 ? 'Romania' : '');
  const [state, setState] = React.useState<string>(data.county_name);
  const [stateId, setStateId] = React.useState<number>(-1);
  const [city, setCity] = React.useState<string>(data.locality_name);
  const [street, setStreet] = React.useState<string>(data.street_name);
  const [streetNumber, setStreetNumber] = React.useState<string>(data.building_number);
  const [zipcode, setZipcode] = React.useState<string>(data.postal_code);
  const [contactPerson, setContactPerson] = React.useState<string>(data.contact_person);
  const [phoneNumber, setPhoneNumber] = React.useState<string>(data.phone_number);
  const [email, setEmail] = React.useState<string>(data.email);
  const [name, setName] = React.useState<string>(data.name);
  const [eod, setEod] = React.useState<string>(data.automatic_eod);
  const [allCounties, setAllCounties] = React.useState<CountyCargus[]>([]);
  const [cities, setCities] = React.useState<CityCargus[]>([]);
  const [firstLoading, setFirstLoading] = React.useState<boolean>(true);
  const [companyId, setCompanyId] = React.useState<number>(-1);

  const { t } = useTranslation();

  const [allCountries, setAllCountries] = React.useState<Country[]>([
    { id: 1, name: 'Romania' },
    { id: 2, name: 'UK' },
    { id: 3, name: 'Australia' },
    { id: 4, name: 'Canada' },
    { id: 5, name: 'New Zealand' },
    { id: 6, name: 'USA' },
  ]);

  useEffect(() => {
    loadUser();
  }, []);

  const loadUser = async () => {
    try {
      const userDetailsResponse = await getUser();
      const companyDetailsResponse = await getCompany(
        userDetailsResponse.data.data.id.toString()
      );
      setCompanyId(companyDetailsResponse.data.id);
    } catch (e) {
      showSnack(e.message, SnackbarVariant.error);
    }
  };

  const getLocations = async () => {
    try {
      const counties = await getCountiesCargus();
      const sortedCounties = counties.sort(function (a: County, b: County) {
        if (a.name < b.name) {
          return -1;
        } else if (b.name < a.name) {
          return 1;
        } else {
          return 0;
        }
      });
      setAllCounties(sortedCounties);
    } catch (e) {
      console.error(e.message);
    }
  };

  useEffect(() => {
    getLocations();
  }, []);

  const handleDialogClose = () => {
    onClose();
  };

  const handleStreetChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStreet(String(e.target.value));
  };

  const handleNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStreetNumber(String(e.target.value));
  };
  const handleZipCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setZipcode(String(e.target.value));
  };

  const handleContactPersonChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setContactPerson(String(e.target.value));
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(String(e.target.value));
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(String(e.target.value));
  };
  const handleEODChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEod(String(e.target.value));
  };

  const loadCitiesOfCounty = async (countyId: number) => {
    try {
      const load = await getCitiesCargus(countyId);
      setCities(load);
    } catch (e) {
      console.error(e.message);
    }
  };

  const handleCountyChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setState(String(event.target.value));
    let index: number;
    for (const element of allCounties) {
      if (element.Name === event.target.value) {
        index = element.CountyId;
        setStateId(index);
        loadCitiesOfCounty(index);
        break;
      }
    }
  };

  const handleCountryChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setCountry(String(event.target.value));
    let index: number;
    for (const element of allCountries) {
      if (element.name === event.target.value) {
        index = element.id;
        // loadCountyOfCountry(index);
        // loadCitiesOfCounty(index);
        break;
      }
    }
  };

  const getUserLocation = async () => {
    try {
      const pickupPoint = await getPickupPoint();
      sessionStorage.setItem('pickupPoint', pickupPoint.length);
    } catch (e) {
      console.error('Nu am putut incarca locatia de ridicare!');
    }
  };

  const handleCityChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setCity(String(event.target.value));
  };

  const clickUpdateLocationData = async () => {
    const newLocation: PickupLocationPost = {
      email: email,
      name: name,
      county_id: stateId,
      county_name: state,
      locality_name: city,
      street_name: street,
      building_number: streetNumber,
      address_text: street + ' ' + streetNumber,
      contact_person: contactPerson,
      phone_number: phoneNumber,
      postal_code: zipcode,
      street_id: 0,
      locality_id: 0,
      location_id: 0,
      automatic_eod: eod,
      country_id: 1, // romania
      company_id: companyId,
    };

    if (action === actionType.insert) {
      try {
        await postPickupPoint(newLocation);
        handleDialogClose();
        getUserLocation();
        window.location.reload();
      } catch (e) {
        console.error('Nu am putut insera noua locatie!');
      }
    } else if (action === actionType.update) {
      try {
        const pickupPoint = await getPickupPoint();
        await updatePickupPoint(pickupPoint[0].id.toString(),newLocation);
        handleDialogClose();
        window.location.reload();
      } catch (e) {
        console.error('Nu am putut modifica locatia!');
      }
    }
  };

  const loadInitialCity = () => {
    if (state !== '') {
      let index: number;
      for (const element of allCounties) {
        if (element.Name === state) {
          index = element.CountyId;
          loadCitiesOfCounty(index);
          setFirstLoading(false);
          break;
        }
      }
    }
  };

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber(String(e.target.value));
  };

  const countriesItemsList = allCountries?.map((item: Country) => (
    <MenuItem key={item.id} id={item.name} value={item.name}>
      {item.name}
    </MenuItem>
  ));

  const countiesItemsList = allCounties?.map((item: CountyCargus) => (
    <MenuItem key={item.CountyId} id={item.Name} value={item.Name}>
      {item.Name}
    </MenuItem>
  ));

  const citiesItemsList = cities?.map((item: CityCargus) => (
    <MenuItem key={item.LocalityId} id={item.Name} value={item.Name}>
      {item.Name}
    </MenuItem>
  ));

  const updateLocationData = (
    <DialogContent classes={{ root: classes.root }}>
      <div className={classes.paper}>
        <Typography className={classes.bold} variant={'h4'}>
          {t('EDIT_LOCATION')}
        </Typography>
        <div className={classes.modalControls}>
          {/* <Typography variant={'subtitle2'}>{t('COUNTRY')}*</Typography>
            <FormControl variant="filled">
              <InputLabel id="select-county-label">{t('COUNTRY')}</InputLabel>
              <Select labelId="select-county-label" value={country} onChange={handleCountryChange}>
                {countriesItemsList}
              </Select>
            </FormControl> */}

          <Typography variant={'subtitle2'}>{t('COUNTY')}*</Typography>
          <FormControl variant="filled">
            <InputLabel id="select-county-label">{t('COUNTY')}</InputLabel>
            <Select labelId="select-county-label" value={state} onChange={handleCountyChange}>
              {countiesItemsList}
            </Select>
          </FormControl>
          <Typography variant={'subtitle2'}>{t('TOWN')}*</Typography>
          <FormControl variant="filled">
            <InputLabel id="select-city-label">{t('TOWN')}</InputLabel>
            <Select labelId="select-city-label" value={city} onChange={handleCityChange}>
              {citiesItemsList}
              {firstLoading ? loadInitialCity() : () => {}}
            </Select>
          </FormControl>

          <Typography variant={'subtitle2'}>{t('STREET')}*</Typography>
          <TextField
            hiddenLabel={true}
            variant="filled"
            value={street}
            type={'text'}
            onChange={handleStreetChange}
          />
          <Typography variant={'subtitle2'}>{t('NUMBER')}*</Typography>
          <TextField
            hiddenLabel={true}
            variant="filled"
            value={streetNumber}
            type={'text'}
            onChange={handleNumberChange}
          />
          <Typography variant={'subtitle2'}>{t('ZIP')}*</Typography>
          <TextField
            hiddenLabel={true}
            variant="filled"
            value={zipcode}
            type={'text'}
            onChange={handleZipCodeChange}
          />
          <Typography variant={'subtitle2'}>{t('CONTACT_PERSON')}*</Typography>
          <TextField
            hiddenLabel={true}
            variant="filled"
            value={contactPerson}
            type={'text'}
            onChange={handleContactPersonChange}
          />
          <Typography variant={'subtitle2'}>{t('PHONE_NUMBER')}*</Typography>
          <TextField
            hiddenLabel={true}
            variant="filled"
            value={phoneNumber}
            type={'text'}
            onChange={handlePhoneNumberChange}
          />
          <Typography variant={'subtitle2'}>{t('EMAIL')}*</Typography>
          <TextField
            hiddenLabel={true}
            variant="filled"
            value={email}
            type={'text'}
            onChange={handleEmailChange}
          />
          <Typography variant={'subtitle2'}>{t('NAME')}*</Typography>
          <TextField
            hiddenLabel={true}
            variant="filled"
            value={name}
            type={'text'}
            onChange={handleNameChange}
          />
          <Typography variant={'subtitle2'}>{t('END_OF_DAY')}*</Typography>
          <TextField
            hiddenLabel={true}
            variant="filled"
            value={eod}
            type={'text'}
            onChange={handleEODChange}
          />
        </div>
        <Typography className={classes.greyText} variant={'body1'}>
          {t('FIELDS_MANDATORY')}
        </Typography>
        <Button
          className={classes.greenButton}
          size="large"
          color="primary"
          variant="contained"
          onClick={clickUpdateLocationData}
        >
          <Typography color="textSecondary" variant={'body1'}>
            {t('SAVE')}
          </Typography>
        </Button>
      </div>
    </DialogContent>
  );

  return <div>{updateLocationData}</div>;
};

const useStyles = makeStyles(theme => ({
  root: {
    flex: 'unset',
    overflowX: 'hidden',
  },
  paper: {
    width: '100%',
    backgroundColor: theme.palette.background.default,
    display: 'grid',
    paddingLeft: theme.spacing(3.5),
    paddingTop: theme.spacing(3.5),
    gridGap: theme.spacing(3),
    paddingBottom: theme.spacing(3.5),
  },
  bold: {
    fontWeight: 700,
  },
  modalControls: {
    display: 'grid',
    gridTemplateColumns: '0.3fr 0.7fr',
    alignItems: 'center',
    gridGap: theme.spacing(1.25),
    marginRight: theme.spacing(3.5),
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr',
    },
  },
  greyText: {
    color: theme.palette.grey[200],
  },
  greenButton: {
    height: '46px',
    width: '98px',
    color: 'green',
    [theme.breakpoints.down('sm')]: {
      justifySelf: 'end',
      marginRight: theme.spacing(3.5),
    },
  },
  countyAndCity: {
    display: 'grid',
    gridTemplateColumns: '0.5fr 0.5fr',
    gridGap: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      gridGap: theme.spacing(1),
      gridTemplateColumns: '1fr',
    },
  },
}));

export default PickupLocationDialog;
