import { makeStyles, Typography } from '@material-ui/core';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ProductCategory } from '../../utils/constants';

interface Props {
  brand: string;
  model: string;
  stock: number;
  fullPrice: string;
  discount: number;
  finalPriceAfterDiscount: string;
  textDescription: string;
  frameColor: string;
  lenseColor: string;
  id: number;
  categoryId: number;
}

const GeneralPriceDescriptionColor: FC<Props> = props => {
  const classes = useStyles();
  const { brand, model,stock,fullPrice,discount,finalPriceAfterDiscount,textDescription,frameColor,lenseColor,id,categoryId } = props;
  const { t } = useTranslation();

  const general = (
    <div className={classes.specs}>
      <Typography className={classes.detailsWithBorder} color="primary" variant={'subtitle2'}>
        {t('GENERAL')}
      </Typography>
      {categoryId.toString()===ProductCategory.OTHER ? null : <div className={classes.inlineText}>
        <Typography color="textPrimary" variant={'subtitle2'}>
          {t('BRAND')}:
        </Typography>
        <Typography color="textPrimary" variant={'subtitle2'}>
          {brand}
        </Typography>
      </div> }
      {categoryId.toString()===ProductCategory.OTHER ? null : <div className={classes.greyLine} />}
      <div className={classes.inlineText}>
        <Typography color="textPrimary" variant={'subtitle2'}>
          {t('MODEL')}:
        </Typography>
        <Typography color="textPrimary" variant={'subtitle2'}>
          {model}
        </Typography>
      </div>
      <div className={classes.greyLine} />
      <div className={classes.inlineText}>
        <Typography color="textPrimary" variant={'subtitle2'}>
          {t('PRODUCT_CODE')}:
        </Typography>
        <Typography color="textPrimary" variant={'subtitle2'}>
          {id}
        </Typography>
      </div>
      <div className={classes.greyLine} />
      <div className={classes.inlineText}>
        <Typography color="textPrimary" variant={'subtitle2'}>
          {t('IN_STOCK')}:
        </Typography>
        <Typography color="textPrimary" variant={'subtitle2'}>
          {stock}
        </Typography>
      </div>
      <div className={classes.greyLine} />
    </div>
  );

  const price = (
    <div className={classes.specs}>
      <Typography className={classes.detailsWithBorder} color="primary" variant={'subtitle2'}>
        {t('PRICE')}
      </Typography>
      <div className={classes.inlineText}>
        <Typography color="textPrimary" variant={'subtitle2'}>
          {t('FULL_PRICE')}:
        </Typography>
        <Typography color="textPrimary" variant={'subtitle2'}>
          {fullPrice}
        </Typography>
      </div>
      <div className={classes.greyLine} />
      <div className={classes.inlineText}>
        <Typography color="textPrimary" variant={'subtitle2'}>
          {t('DISCOUNT')}:
        </Typography>
        <Typography color="textPrimary" variant={'subtitle2'}>
          {discount}%
        </Typography>
      </div>
      <div className={classes.greyLine} />
      <div className={classes.inlineText}>
        <Typography color="textPrimary" variant={'subtitle2'}>
          {t('FINAL_PRICE')}:
        </Typography>
        <Typography color="textPrimary" variant={'subtitle2'}>
          {finalPriceAfterDiscount}
        </Typography>
      </div>
      <div className={classes.greyLine} />
    </div>
  );

  const description = (
    <div className={classes.specsDescription}>
      <Typography className={classes.detailsWithBorder} color="primary" variant={'subtitle2'}>
        {t('DESCRIPTION')}
      </Typography>
      <Typography color="textPrimary" variant={'body1'}>
        {textDescription}
      </Typography>
    </div>
  );

  const colors = (
    <div className={classes.specs}>
      <Typography className={classes.detailsWithBorder} color="primary" variant={'subtitle2'}>
        {t('COLOR')}
      </Typography>
      <div className={classes.inlineText}>
        <Typography color="textPrimary" variant={'subtitle2'}>
          {t('FRAME_COLOR')}:
        </Typography>
        <Typography color="textPrimary" variant={'subtitle2'}>
          {frameColor}
        </Typography>
      </div>
      <div className={classes.greyLine} />
      <div className={classes.inlineText}>
        <Typography color="textPrimary" variant={'subtitle2'}>
          {t('LENSES_COLOR')}
        </Typography>
        <Typography color="textPrimary" variant={'subtitle2'}>
          {lenseColor}
        </Typography>
      </div>
      <div className={classes.greyLine} />
    </div>
  );

  return (
    <div className={classes.root}>
      <div className={classes.columns}>
        <div>{general}</div>
        <div>{price}</div>
      </div>
      <div className={classes.columns}>
        <div>{description}</div>
        {categoryId.toString()===ProductCategory.OTHER ? null :<div>{colors}</div>}
      </div>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  columns: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit,minmax(300px,0.5fr))',
    gridGap: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  greyLine: {
    height: '1px',
    backgroundColor: theme.palette.grey[300],
    marginBottom: theme.spacing(1),
  },
  inlineText: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  specs: {
    backgroundColor: theme.palette.background.default,
    boxShadow: '0px 2px 15px rgba(0, 0, 0, 0.1)',
    padding: theme.spacing(2.5),
    height: '170px',
  },
  detailsWithBorder: {
    display: 'flex',
    paddingBottom: theme.spacing(2),
    textDecoration: 'underline',
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  specsDescription: {
    backgroundColor: theme.palette.background.default,
    boxShadow: '0px 2px 15px rgba(0, 0, 0, 0.1)',
    padding: theme.spacing(2.5),
    height: '170px',
    overflow: 'scroll',
  },
}));

export default GeneralPriceDescriptionColor;
