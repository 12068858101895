import { Button, InputAdornment, makeStyles, TextField, Typography } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import SearchIcon from '@material-ui/icons/Search';
import { Autocomplete, AutocompleteRenderInputParams } from '@material-ui/lab';
import clsx from 'clsx';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Filters, { ParentPage } from '../../components/Filters';
import Header from '../../components/Header';
import { setOrderBy, setOrderProductCategory, showSnack } from '../../state/actions';
import { getAllProducts, searchProducts, searchProductsCode } from '../../state/commands/product';
import { InitialState, ProductShow } from '../../state/types';
import { PATHS } from '../../utils/appPaths';
import browserHistory from '../../utils/browserhistory';
import { MetaLinks, Product, ProductCategory, ProductType, SnackbarVariant } from '../../utils/constants';
import MenuItemDropDown from './MenuItemDropDown';

const ProductsManagementContainer: FC<{}> = () => {
  const classes = useStyles();

  const [searchTerm, setSearchTerm] = useState<string>('');
  const [searchOptions] = useState<string[]>([]);
  const [products,setProducts] = useState<ProductShow[]>();
  const orderBy = useSelector((state: InitialState) => state.orderBy);
  const productCategory = useSelector((state: InitialState) => state.productCategory);
  const [page,setPage] = useState<number>(1);
  const { t } = useTranslation();

  useEffect(()=>{
    getProducts();
  },[])

  useEffect(()=>{
    getProducts();
  },[orderBy,productCategory,page])

  const getProducts = async () => {
    try {
      const response = await getAllProducts(productCategory,orderBy,page);
      setProducts(response.data);
    } catch(e) {
      showSnack(e.message, SnackbarVariant.error);
    }
  };

  let [items,setItems] = useState<Product[]>([]);

  useEffect(()=>{
    generateItems();
  },[products])

  const generateItems = () => {
    if(typeof products!== 'undefined')
    {
      let localItems: Product[] = [];
      for(let i=0;i<products.length;i++)
      {
        let type;
        if(products[i].category.id===1)
        {
          type = ProductType.EYEGLASSES;
        }
        else if(products[i].category.id===2)
        {
          type = ProductType.SUNGLASSES;
        }
        else if(products[i].category.id===3)
        {
          type = ProductType.COMPUTERGLASSES;
        }
        else if(products[i].category.id===4)
        {
          type = ProductType.EYESIGHT_LENSES;
        }
        else if(products[i].category.id===5)
        {
          type = ProductType.COSMETIC_LENSES;
        }
        else{
          type = ProductType.OTHER_CATEGORIES;
        }
        let aux = {
          id: products[i].general.id,
          type: type,
          name: products[i].general.brand!==null ? products[i].general.brand+' '+products[i].general.model: '' +' '+products[i].general.model,
          visibility: 'Publicat',
          stock: products[i].general.stoc,
          data: products[i].updated_at,
        }
        localItems.push(aux)
      }
      setItems(localItems);
    }
  }

  const handleProductChange = (event: React.ChangeEvent<{}>, value: string | null) => {
  };

  const handleSearchTermChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(String(e.target.value));
  };

  const searchProduct = async () => {
    searchTerm.replace(' ', '+');
    const res = await searchProducts(searchTerm,productCategory);
    if(typeof res !== 'undefined')
    {
      setProducts(res.data);
    }
    else{
      const resCode = await searchProductsCode(searchTerm,productCategory);
      if(typeof resCode !== 'undefined')
      {
        setProducts(resCode.data);
      }
    }
  };

  // tslint:disable-next-line:no-any
  const searchProductKey = (event: any) => {
    if (event.key === 'Enter') {
      searchProduct();
    }
  }

  const renderInput = (params: AutocompleteRenderInputParams) => (
    <TextField
      {...params}
      onChange={handleSearchTermChange}
      className={classes.textFieldSearchBar}
      onKeyPress={searchProductKey}
      label={t('SEARCH')}
      fullWidth={true}
      variant="outlined"
      margin="dense"
      value={searchTerm}
      InputProps={{
        ...params.InputProps,
        endAdornment: (
          <InputAdornment id="search-icon" position="end" className={classes.searchIcon}>
            {<SearchIcon className={classes.search} />}
          </InputAdornment>
        ),
      }}
    />
  );
  const navigateTo = (route: string,state: ProductShow) => {
    browserHistory.push({
      pathname: route,
      state: state
    });
  };
  
  const navigateToNewProduct = () => {
    browserHistory.push(PATHS.APP.ADD_PRODUCT.ROOT);
  }

  const addProductAndSearchBar = (
    <div className={classes.addProductAndSearchBar}>
      <Button className={classes.greenButton} onClick={navigateToNewProduct} size="large" variant="contained" color="primary">
        <Typography color="textSecondary" variant={'body1'}>
          {t('NEW_PRODUCT')}
        </Typography>
      </Button>
      <div className={classes.searchBar}>
        <div className={classes.inputColumn}>
          <Autocomplete
            freeSolo={true}
            autoComplete={true}
            autoHighlight={true}
            options={searchOptions}
            onChange={handleProductChange}
            renderInput={renderInput}
          />
        </div>
        <Button className={classes.blueButton} onClick={searchProduct} size="large" variant="contained" color="primary">
          <Typography color="textSecondary" variant={'body1'}>
            {t('SEARCH')}
          </Typography>
        </Button>
      </div>
    </div>
  );

  const [idsToDelete,setIdsToDelete] = useState<number[]>([]);
  const [refresh,setRefresh] = useState<boolean>(false);
  const handleCheckboxClick = (id: number) => {
    const aux = [...idsToDelete];
    if(!aux.includes(id))
    {
      aux.push(id);
      setIdsToDelete(aux);
    }
    else{
      setIdsToDelete(filteredId(aux,id));
    }
  };

  const filteredId = (ids: number[],id: number) => ids.filter((item=>{
    return item!==id
  }));

  useEffect(()=>{
    if(idsToDelete.length===0 && refresh === true)
    {
      setRefresh(false);
      getProducts();
    }
  },[idsToDelete])

  const contentOfTable = items.map((item, index) => (
    <div className={classes.table} key={index}>
      <div
        className={classes.cells}
      >
        <Checkbox
          classes={{ root: classes.checkbox }}
          color="primary"
          inputProps={{ 'aria-label': 'secondary checkbox' }}
          onClick={handleCheckboxClick.bind(this,item.id)}
        />
        <Typography color="textPrimary" variant={'body1'} className={classes.firstCell} onClick={typeof products!=='undefined' ? navigateTo.bind(this, PATHS.APP.PRODUCTS_MANAGEMENT.DETAILS,products[index]):undefined}>
          {item.name}
        </Typography>
      </div>
      <div className={classes.cells}>
        <Typography color="textPrimary" variant={'body1'} className={classes.cellsContent}>
          {item.visibility}
        </Typography>
      </div>
      <div className={classes.cells}>
        <Typography color="textPrimary" variant={'body1'} className={classes.cellsContent}>
          {item.data}
        </Typography>
      </div>
      <div className={classes.cells}>
        <Typography
          style={item.stock > 5 ? { color: '#29AF1D' } : { color: '#D61B2B' }}
          variant={'body1'}
          className={classes.cellsContent}
        >
          {`${t('IN_STOCK')} (` + item.stock + ')'}
        </Typography>
      </div>
      {typeof products!== 'undefined' ? <MenuItemDropDown item={item} fullItem={products[index]} products={products} setProducts={setProducts} index={index}/> : null}
     
    </div>
  ));

  return (
    <div className={classes.root}>
      <Header tabName={t('PRODUCT_MANAGEMENT')} />
      <div className={classes.content}>
        {addProductAndSearchBar}
        <Filters parentPage={ParentPage.PRODUCTS} page={page} setPage={setPage} idsToDelete={idsToDelete} setIdsToDelete={setIdsToDelete} setRefresh={setRefresh}/>
        <div className={classes.table}>
          <div className={classes.headerOfTable}>
            <Typography className={classes.insideHeaderOfTable} variant={'body1'}>
              {t('PRODUCT_NAME')}
            </Typography>
          </div>
          <div className={classes.headerOfTable}>
            <Typography className={classes.insideHeaderOfTable} variant={'body1'}>
              {t('VISIBILITY')}
            </Typography>
          </div>
          <div className={classes.headerOfTable}>
            <Typography className={classes.insideHeaderOfTable} variant={'body1'}>
              {t('DATE')}
            </Typography>
          </div>
          <div className={classes.headerOfTable}>
            <Typography className={classes.insideHeaderOfTable} variant={'body1'}>
              {t('PRODUCT_STATUS')}
            </Typography>
          </div>
          <div className={classes.headerOfTable}>
            <Typography className={classes.insideHeaderOfTable} variant={'body1'}>
              {t('ACTIONS')}
            </Typography>
          </div>
        </div>
        {contentOfTable}
      </div>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  content: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(5),
  },
  addProductAndSearchBar: {
    display: 'grid',
    gridTemplateColumns: '0.35fr 0.65fr',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    marginRight: theme.spacing(9),
  },
  greenButton: {
    height: '46px',
    width: '155px',
    backgroundColor: '#29AF1D',
  },
  blueButton: {
    height: '46px',
  },
  searchBar: {
    display: 'grid',
    gridTemplateColumns: '0.9fr 0.1fr',
    gridGap: theme.spacing(2),
  },
  inputColumn: {
    alignSelf: 'center',
  },
  searchIcon: {
    marginRight: '-25px',
  },
  search: {
    margin: '6px',
    height: '24px',
    cursor: 'pointer',
  },
  textFieldSearchBar: {
    marginTop: '6px',
  },
  table: {
    display: 'grid',
    gridTemplateColumns: '0.2fr 0.20fr 0.15fr 0.20fr 0.15fr 0.1fr',
    gridGap: theme.spacing(0.5),
  },

  headerOfTable: {
    backgroundColor: theme.palette.grey[400],
    height: '53px',
    display: 'flex',
  },
  insideHeaderOfTable: {
    color: '#8C8C8C',
    alignSelf: 'center',
    margin: '0 auto',
  },
  cellsContent: {
    alignSelf: 'center',
    margin: '0 auto',
    fontWeight: 600,
  },
  cells: {
    height: '39px',
    display: 'flex',
  },
  checkbox: {
    color: theme.palette.grey[100],
  },
  firstCell: {
    alignSelf: 'center',
  },
  discount: {
    color: '#FF6B00',
  },
  dropdownButton: {
    display: 'grid',
    justifyItems: 'center',
  },
}));

export default ProductsManagementContainer;
