import { makeStyles, Typography } from '@material-ui/core';
import CancelSharpIcon from '@material-ui/icons/CancelSharp';
import { useState } from 'react';
import { useCallback } from 'react';
import { useEffect } from 'react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import white from '../../assets/alb.png';
import blue from '../../assets/albastru.png';
import silver from '../../assets/argintiu.png';
import golden from '../../assets/auriu.png';
import beige from '../../assets/bej.png';
import colorOutline from '../../assets/colorOutline.png';
import crystal from '../../assets/cristal.png';
import yellow from '../../assets/galben.png';
import grey from '../../assets/gri.png';
import havana from '../../assets/havana.png';
import brown from '../../assets/maro.png';
import multicolor from '../../assets/multicolor.png';
import black from '../../assets/negru.png';
import orange from '../../assets/portocaliu.png';
import red from '../../assets/rosu.png';
import pink from '../../assets/roz.png';
import green from '../../assets/verde.png';
import violet from '../../assets/violet.png';
import burgundy from '../../assets/visiniu.png';
import turquoise from '../../assets/turcoaz.png';
import specialEffects from '../../assets/efecte-speciale.png';
import goldMirror from '../../assets/auriu-oglinda.png';
import transparent from '../../assets/transparent.png';
import smoke from '../../assets/smoke.png';
import mauve from '../../assets/mov.png';
import { setFrameColorProduct, setLensesColorProduct } from '../../state/actions';
import { FrameLenseColor, InitialState } from '../../state/types';
import { Taxonomy } from '../../utils/constants';

interface Props {
  frameColor?: Taxonomy[];
  lensesColor?: Taxonomy[];
  color?: FrameLenseColor[] | string;
  col?: string[];
}

const Colors: FC<Props> = props => {
  const classes = useStyles();
  const { frameColor, lensesColor, color, col } = props;
  const buttonPressed = useSelector((state: InitialState) => state.buttonPressed);
  const [colorSelected, setColorSelected] = useState<string[]>([]);
  const [availableColors, setAvailableColors] = useState<string[]>([]);
  const { t } = useTranslation();

  useEffect(()=>{
    if(typeof color === 'object')
    {
      let aux = [];
      for(let i=0;i<color.length;i++)
      {
        aux.push(color[i].name.toLowerCase())
      }
      setColorSelected(aux);
    }
  },[])

  useEffect(()=>{
    let id = [];
    if(frameColor)
    {
      for(let i=0;i<frameColor.length;i++)
      {
        for(let j=0;j<colorSelected.length;j++)
        {
          if(frameColor[i].name.toLowerCase()===colorSelected[j])
          {
            id.push(frameColor[i].taxonomy_relationship_id.toString());
            break;
          }
        }
      }
      if(id.length!==0)
      {
        setFrameColorProduct(id);
      }
    }
    if(lensesColor)
    {
      for(let i=0;i<lensesColor.length;i++)
      {
        for(let j=0;j<colorSelected.length;j++)
        {
          if(lensesColor[i].name.toLowerCase()===colorSelected[j])
          {
            id.push(lensesColor[i].taxonomy_relationship_id);
            break;
          }
        }
      }
      if(id!==undefined)
      {
      setLensesColorProduct(id.toString());
      }
    }
  },[frameColor,lensesColor])

  const getAvailableColors = useCallback(() => {
    const newAvailableColors: string[] = [];
    if (frameColor) {
      frameColor.forEach(element => {
        newAvailableColors.push(element.name);
      });
    } else if (lensesColor) {
      lensesColor.forEach(element => {
        newAvailableColors.push(element.name);
      });
    }
    setAvailableColors(newAvailableColors);
  }, [frameColor, lensesColor]);

  useEffect(() => {
    getAvailableColors();
  }, [getAvailableColors]);

  const changeColorHandler = (newColor: string) => {
    if(frameColor)
    {
      let newColors = [...colorSelected];
        if(colorSelected.length<3)
        {
          newColors.push(newColor);
          setColorSelected(newColors);
        }
        else{
          newColors.pop();
          newColors.push(newColor);
          setColorSelected(newColors);
        }
        let id = [];
        for(let i=0;i<frameColor.length;i++)
        {
          for(let j=0;j<newColors.length;j++)
          {
            if(frameColor[i].name.toLocaleLowerCase()===newColors[j])
            {
              id.push(frameColor[i].taxonomy_relationship_id.toString());
            }
          }
        }
      if(id.length!==0)
      {
        setFrameColorProduct(id);
      }
    }
    if(lensesColor)
    {
      if(colorSelected.length<1)
        {
          let newColors = [...colorSelected];
          newColors.push(newColor);
          setColorSelected(newColors);
        }
        else{
          let newColors = [...colorSelected];
          newColors.pop();
          newColors.push(newColor);
          setColorSelected(newColors);
        }
    }
    
    let id;
    if(lensesColor)
    {
      for(let i=0;i<lensesColor.length;i++)
      {
        if(lensesColor[i].name.toLowerCase()===newColor)
        {
          id=lensesColor[i].taxonomy_relationship_id;
          break;
        }
      }
      if(id!==undefined)
      {
        setLensesColorProduct(id.toString());
      }
    }
  };

  const removeColor = (color: String) => {
    let oldColors = [...colorSelected];
    let newColors: string[] = [];
    for(let i=0;i<oldColors.length;i++)
    {
      if(oldColors[i]!==color)
      {
        newColors.push(oldColors[i]);
      }
    }
    setColorSelected(newColors);
  };
  

  return (
    <div className={classes.productInfo}>
      <div className={classes.colorsAndNames}>
        <div className={classes.colors}>
          {availableColors.includes('Black') ? (
            <div className={classes.outlineAndImage}>
              {colorSelected[0] === 'black' || colorSelected[1] === 'black' || colorSelected[2] === 'black' ? (
                <img src={colorOutline} className={classes.outline} alt={'outline'} />
              ) : null}
              <img
                src={black}
                alt={'black'}
                className={classes.color}
                onClick={changeColorHandler.bind(this, 'black')}
              />
            </div>
          ) : null}
          {availableColors.includes('Gray') ? (
            <div className={classes.outlineAndImage}>
              {colorSelected[0] === 'gray' || colorSelected[1] === 'gray' || colorSelected[2] === 'gray' ? (
                <img src={colorOutline} className={classes.outline} alt={'outline'} />
              ) : null}
              <img
                src={grey}
                alt={'gray'}
                className={classes.color}
                onClick={changeColorHandler.bind(this, 'gray')}
              />
            </div>
          ) : null}

          {availableColors.includes('Silver') ? (
            <div className={classes.outlineAndImage}>
              {colorSelected[0] === 'silver' || colorSelected[1] === 'silver' || colorSelected[2] === 'silver' ? (
                <img src={colorOutline} className={classes.outline} alt={'outline'} />
              ) : null}
              <img
                src={silver}
                alt={'silver'}
                className={classes.color}
                onClick={changeColorHandler.bind(this, 'silver')}
              />
            </div>
          ) : null}

          {availableColors.includes('Golden') ? (
            <div className={classes.outlineAndImage}>
              {colorSelected[0] === 'golden' || colorSelected[1] === 'golden' || colorSelected[2] === 'golden' ? (
                <img src={colorOutline} className={classes.outline} alt={'outline'} />
              ) : null}
              <img
                src={golden}
                alt={'golden'}
                className={classes.color}
                onClick={changeColorHandler.bind(this, 'golden')}
              />
            </div>
          ) : null}
          {availableColors.includes('Gold') ? (
            <div className={classes.outlineAndImage}>
              {colorSelected[0] === 'gold' || colorSelected[1] === 'gold' || colorSelected[2] === 'gold' ? (
                <img src={colorOutline} className={classes.outline} alt={'outline'} />
              ) : null}
              <img
                src={golden}
                alt={'gold'}
                className={classes.color}
                onClick={changeColorHandler.bind(this, 'gold')}
              />
            </div>
          ) : null}

          {availableColors.includes('Brown') ? (
            <div className={classes.outlineAndImage}>
              {colorSelected[0] === 'brown' || colorSelected[1] === 'brown' || colorSelected[2] === 'brown' ? (
                <img src={colorOutline} className={classes.outline} alt={'outline'} />
              ) : null}
              <img
                src={brown}
                alt={'brown'}
                className={classes.color}
                onClick={changeColorHandler.bind(this, 'brown')}
              />
            </div>
          ) : null}
          {availableColors.includes('Beige') ? (
            <div className={classes.outlineAndImage}>
              {colorSelected[0] === 'beige' || colorSelected[1] === 'beige' || colorSelected[2] === 'beige' ? (
                <img src={colorOutline} className={classes.outline} alt={'outline'} />
              ) : null}
              <img
                src={beige}
                alt={'beige'}
                className={classes.color}
                onClick={changeColorHandler.bind(this, 'beige')}
              />
            </div>
          ) : null}

          {availableColors.includes('Green') ? (
            <div className={classes.outlineAndImage}>
              {colorSelected[0] === 'green' || colorSelected[1] === 'green' || colorSelected[2] === 'green' ? (
                <img src={colorOutline} className={classes.outline} alt={'outline'} />
              ) : null}
              <img
                src={green}
                alt={'green'}
                className={classes.color}
                onClick={changeColorHandler.bind(this, 'green')}
              />
            </div>
          ) : null}
          {availableColors.includes('Blue') ? (
            <div className={classes.outlineAndImage}>
              {colorSelected[0] === 'blue' || colorSelected[1] === 'blue' || colorSelected[2] === 'blue'? (
                <img src={colorOutline} className={classes.outline} alt={'outline'} />
              ) : null}
              <img
                src={blue}
                alt={'blue'}
                className={classes.color}
                onClick={changeColorHandler.bind(this, 'blue')}
              />
            </div>
          ) : null}
          {availableColors.includes('Violet') ? (
            <div className={classes.outlineAndImage}>
              {colorSelected[0] === 'violet' || colorSelected[1] === 'violet' || colorSelected[2] === 'violet' ? (
                <img src={colorOutline} className={classes.outline} alt={'outline'} />
              ) : null}
              <img
                src={violet}
                alt={'violet'}
                className={classes.color}
                onClick={changeColorHandler.bind(this, 'violet')}
              />
            </div>
          ) : null}
          {availableColors.includes('Mauve') ? (
            <div className={classes.outlineAndImage}>
              {colorSelected[0] === 'mauve' || colorSelected[1] === 'mauve' || colorSelected[2] === 'mauve' ? (
                <img src={colorOutline} className={classes.outline} alt={'outline'} />
              ) : null}
              <img
                src={mauve}
                alt={'mauve'}
                className={classes.color}
                onClick={changeColorHandler.bind(this, 'mauve')}
              />
            </div>
          ) : null}
          {availableColors.includes('Pink') ? (
            <div className={classes.outlineAndImage}>
              {colorSelected[0] === 'pink' || colorSelected[1] === 'pink' || colorSelected[2] === 'pink' ? (
                <img src={colorOutline} className={classes.outline} alt={'outline'} />
              ) : null}
              <img
                src={pink}
                alt={'pink'}
                className={classes.color}
                onClick={changeColorHandler.bind(this, 'pink')}
              />
            </div>
          ) : null}
          {availableColors.includes('Red') ? (
            <div className={classes.outlineAndImage}>
              {colorSelected[0] === 'red' || colorSelected[1] === 'red' || colorSelected[2] === 'red'? (
                <img src={colorOutline} className={classes.outline} alt={'outline'} />
              ) : null}
              <img
                src={red}
                alt={'red'}
                className={classes.color}
                onClick={changeColorHandler.bind(this, 'red')}
              />
            </div>
          ) : null}
          {availableColors.includes('Burgundy') ? (
            <div className={classes.outlineAndImage}>
              {colorSelected[0] === 'burgundy' || colorSelected[1] === 'burgundy' || colorSelected[2] === 'burgundy' ? (
                <img src={colorOutline} className={classes.outline} alt={'outline'} />
              ) : null}
              <img
                src={burgundy}
                alt={'burgundy'}
                className={classes.color}
                onClick={changeColorHandler.bind(this, 'burgundy')}
              />
            </div>
          ) : null}
          {availableColors.includes('Cherry') ? (
            <div className={classes.outlineAndImage}>
              {colorSelected[0] === 'cherry' || colorSelected[1] === 'cherry' || colorSelected[2] === 'cherry' ? (
                <img src={colorOutline} className={classes.outline} alt={'outline'} />
              ) : null}
              <img
                src={burgundy}
                alt={'cherry'}
                className={classes.color}
                onClick={changeColorHandler.bind(this, 'cherry')}
              />
            </div>
          ) : null}
          {availableColors.includes('Orange') ? (
            <div className={classes.outlineAndImage}>
              {colorSelected[0] === 'orange' || colorSelected[1] === 'orange' || colorSelected[2] === 'orange' ? (
                <img src={colorOutline} className={classes.outline} alt={'outline'} />
              ) : null}
              <img
                src={orange}
                alt={'orange'}
                className={classes.color}
                onClick={changeColorHandler.bind(this, 'orange')}
              />
            </div>
          ) : null}
          {availableColors.includes('Yellow') ? (
            <div className={classes.outlineAndImage}>
              {colorSelected[0] === 'yellow' || colorSelected[1] === 'yellow' || colorSelected[2] === 'yellow' ? (
                <img src={colorOutline} className={classes.outline} alt={'outline'} />
              ) : null}
              <img
                src={yellow}
                alt={'yellow'}
                className={classes.color}
                onClick={changeColorHandler.bind(this, 'yellow')}
              />
            </div>
          ) : null}
          {availableColors.includes('White') ? (
            <div className={classes.outlineAndImage}>
              {colorSelected[0] === 'white' || colorSelected[1] === 'white' || colorSelected[2] === 'white' ? (
                <img src={colorOutline} className={classes.outline} alt={'outline'} />
              ) : null}
              <img
                src={white}
                alt={'white'}
                className={classes.color}
                onClick={changeColorHandler.bind(this, 'white')}
              />
            </div>
          ) : null}
          {availableColors.includes('Havana') ? (
            <div className={classes.outlineAndImage}>
              {colorSelected[0] === 'havana' || colorSelected[1] === 'havana' || colorSelected[2] === 'havana'? (
                <img src={colorOutline} className={classes.outline} alt={'outline'} />
              ) : null}
              <img
                src={havana}
                alt={'havana'}
                className={classes.color}
                onClick={changeColorHandler.bind(this, 'havana')}
              />
            </div>
          ) : null}
          {availableColors.includes('Cristal') ? (
            <div className={classes.outlineAndImage}>
              {colorSelected[0] === 'cristal' || colorSelected[1] === 'cristal' || colorSelected[2] === 'cristal' ? (
                <img src={colorOutline} className={classes.outline} alt={'outline'} />
              ) : null}
              <img
                src={crystal}
                alt={'cristal'}
                className={classes.color}
                onClick={changeColorHandler.bind(this, 'cristal')}
              />
            </div>
          ) : null}
          {availableColors.includes('Multicolor') ? (
            <div className={classes.outlineAndImage}>
              {colorSelected[0] === 'multicolor' || colorSelected[1] === 'multicolor' || colorSelected[2] === 'multicolor'? (
                <img src={colorOutline} className={classes.outline} alt={'outline'} />
              ) : null}
              <img
                src={multicolor}
                alt={'multicolor'}
                className={classes.color}
                onClick={changeColorHandler.bind(this, 'multicolor')}
              />
            </div>
          ) : null}
          {availableColors.includes('Multicolour') ? (
            <div className={classes.outlineAndImage}>
              {colorSelected[0] === 'multicolour' || colorSelected[1] === 'multicolour' || colorSelected[2] === 'multicolour'? (
                <img src={colorOutline} className={classes.outline} alt={'outline'} />
              ) : null}
              <img
                src={multicolor}
                alt={'multicolour'}
                className={classes.color}
                onClick={changeColorHandler.bind(this, 'multicolour')}
              />
            </div>
          ) : null}
          {availableColors.includes('Turquoise') ? (
            <div className={classes.outlineAndImage}>
              {colorSelected[0] === 'turquoise' || colorSelected[1] === 'turquoise' || colorSelected[2] === 'turquoise'? (
                <img src={colorOutline} className={classes.outline} alt={'outline'} />
              ) : null}
              <img
                src={turquoise}
                alt={'turquoise'}
                className={classes.color}
                onClick={changeColorHandler.bind(this, 'turquoise')}
              />
            </div>
          ) : null}
          {availableColors.includes('Special Effects') ? (
            <div className={classes.outlineAndImage}>
              {colorSelected[0] === 'special-effects' || colorSelected[1] === 'special-effects' || colorSelected[2] === 'special-effects'? (
                <img src={colorOutline} className={classes.outline} alt={'outline'} />
              ) : null}
              <img
                src={specialEffects}
                alt={'special-effects'}
                className={classes.color}
                onClick={changeColorHandler.bind(this, 'special-effects')}
              />
            </div>
          ) : null}
          {availableColors.includes('Gold mirror') ? (
            <div className={classes.outlineAndImage}>
              {colorSelected[0] === 'gold-mirror' || colorSelected[1] === 'gold-mirror' || colorSelected[2] === 'gold-mirror'? (
                <img src={colorOutline} className={classes.outline} alt={'outline'} />
              ) : null}
              <img
                src={goldMirror}
                alt={'gold-mirror'}
                className={classes.color}
                onClick={changeColorHandler.bind(this, 'gold-mirror')}
              />
            </div>
          ) : null}
          {availableColors.includes('Transparent') ? (
            <div className={classes.outlineAndImage}>
              {colorSelected[0] === 'transparent' || colorSelected[1] === 'transparent' || colorSelected[2] === 'transparent'? (
                <img src={colorOutline} className={classes.outline} alt={'outline'} />
              ) : null}
              <img
                src={transparent}
                alt={'transparent'}
                className={classes.color}
                onClick={changeColorHandler.bind(this, 'transparent')}
              />
            </div>
          ) : null}
          {availableColors.includes('Smoke') ? (
            <div className={classes.outlineAndImage}>
              {colorSelected[0] === 'smoke' || colorSelected[1] === 'smoke' || colorSelected[2] === 'smoke'? (
                <img src={colorOutline} className={classes.outline} alt={'outline'} />
              ) : null}
              <img
                src={smoke}
                alt={'smoke'}
                className={classes.color}
                onClick={changeColorHandler.bind(this, 'smoke')}
              />
            </div>
          ) : null}
        </div>
        <div className={classes.colorNames}>
        {colorSelected.map((option,index) => (
           <div className={classes.colorName} key={index}>
             <Typography color="textPrimary" variant={'body1'}>
               {option}
             </Typography>
             <CancelSharpIcon
               className={classes.cursor}
               fontSize="small"
               onClick={removeColor.bind(this,option)}
             />
           </div>
        ))}
        </div>
        {colorSelected.length === 0 && buttonPressed === true ? (
          <div className={classes.colorNames}>
            <div className={classes.colorName}>
              <Typography color="secondary" variant={'body1'}>
                {t('NO_COLOR_SELECTED')}
              </Typography>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  bold: {
    fontWeight: 700,
  },
  productInfo: {
    padding: `0px ${theme.spacing(3)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
    background: theme.palette.primary.light,
  },
  colorsAndNames: {
    backgroundColor: theme.palette.background.default,
    minHeight: '97px',
    columnGap: theme.spacing(2.5),
    padding: theme.spacing(1),
  },
  colors: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit,minmax(20px,0.05fr))',
  },
  outline: {
    position: 'absolute',
  },
  color: {
    position: 'relative',
    top: '20%',
    left: '20%',
    cursor: 'pointer',
  },
  outlineAndImage: {
    height: '30px',
    width: '30px',
  },
  colorName: {
    display: 'grid',
    gridTemplateColumns: '0.6fr 0.2fr',
    justifyContent: 'center',
    alignContent: 'center',
    minWidth: '100px',
    minHeight: '25px',
    backgroundColor: theme.palette.grey[300],
    borderRadius: theme.spacing(2),
  },
  colorNames: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit,minmax(100px,0.05fr))',
    gridGap: theme.spacing(0.5),
    gridRowStart: '2',
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  cursor: {
    cursor: 'pointer',
  },
}));

export default Colors;
