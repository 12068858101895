import { makeStyles, MenuItem, TextField, Typography } from '@material-ui/core';
import { FC, useEffect, useState } from 'react';
import PriceAndDescription from '../../components/AddProduct/ProductPriceAndDescription';
import Parameters from '../../components/AddProduct/Parameters';
import PublishOrSave from '../../components/AddProduct/PublishOrSave';
import Add3DImage from '../../components/AddProduct/Images';
import Header from '../../components/Header';
import { resetProduct, setButtonPressed, setCategoryProduct, setForProduct, setMethodOfWearingProduct, setPeriodOfUseProduct } from '../../state/actions';
import { getProductInfo } from '../../state/commands/product';
import { EyeSightLensesInfo, initialEyesightlensesInfo, Product, ProductCategory, Taxonomy } from '../../utils/constants';
import { InitialState, ProductShow } from '../../state/types';
import { createProductInitial } from '../../state/initialStore';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

interface Props {
  item?: ProductShow;
}

const AddEyesightLensesContainer: FC<Props> = props => {
  const { item } = props;
  const classes = useStyles();
  const buttonPressed = useSelector((state: InitialState) => state.buttonPressed);
  const [forOption, setForOption] = useState<string>(typeof item !== 'undefined' ? item.details.For?.id.toString() : '');
  const [periodOfUse, setPeriodOfUse] = useState<string>(typeof item !== 'undefined' ? item.details['Period of use']?.id.toString() : '');
  const [methodOfWearing, setMethodOfWearing] = useState<string>(typeof item !== 'undefined' ? item.details['Method of wearing']?.id.toString() : '');
  const { t } = useTranslation();

  const [eyesightlensesInfo, setEyesightlensesInfo] = useState<EyeSightLensesInfo>(initialEyesightlensesInfo);

  useEffect(() => {
    setCategoryProduct(ProductCategory.CONTACT_LENSES);
    getEyesightlensesDetails();
  }, []);

  useEffect(()=>{
    if(typeof item === 'undefined')
    {
      resetProduct(createProductInitial);
      setCategoryProduct(ProductCategory.CONTACT_LENSES);
      setButtonPressed(false);
    }
  },[])

  useEffect(()=>{
    if(typeof item !== 'undefined')
    {
      setForProduct(forOption);
      setPeriodOfUseProduct(periodOfUse);
      setMethodOfWearingProduct(methodOfWearing);
    }
  },[])

  const getEyesightlensesDetails = async () => {
    try {
      const newEyesightlensesInfo = await getProductInfo(ProductCategory.CONTACT_LENSES);
      setEyesightlensesInfo(newEyesightlensesInfo.data);
    } catch (e) {
      console.error(e.message);
    }
  };

  const forOptionChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForOption(event.target.value);
    setForProduct(event.target.value);
  };

  const periodOfUseChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPeriodOfUse(event.target.value);
    setPeriodOfUseProduct(event.target.value);
  };

  const methodOfWearingChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMethodOfWearing(event.target.value);
    setMethodOfWearingProduct(event.target.value);
  };

  const addLensTypes = () => {
    const lensTypes = [];
    for(let i=0;i<eyesightlensesInfo.taxonomies.for.length;i++)
    {
      if(eyesightlensesInfo.taxonomies.for[i].name === 'Toric Astigmatism' || eyesightlensesInfo.taxonomies.for[i].name === 'Presbyopia-Multifocal' || eyesightlensesInfo.taxonomies.for[i].name === 'Myopia-Hyperopia')
      {
        lensTypes.push(<MenuItem key={i} value={eyesightlensesInfo.taxonomies.for[i].taxonomy_relationship_id}>
        {eyesightlensesInfo.taxonomies.for[i].name}
          </MenuItem>)
      }
    }
    return lensTypes;
  };

  const addPeriodOfUse = () => {
    const periodUse = [];
    for(let i=0;i<eyesightlensesInfo.taxonomies['period-of-use'].length;i++)
    {
      if(eyesightlensesInfo.taxonomies['period-of-use'][i].name === 'Annual' || eyesightlensesInfo.taxonomies['period-of-use'][i].name === 'Semimonthly' || eyesightlensesInfo.taxonomies['period-of-use'][i].name === 'Monthly' || eyesightlensesInfo.taxonomies['period-of-use'][i].name === 'Quarterly' || eyesightlensesInfo.taxonomies['period-of-use'][i].name === 'Single use')
      {
        periodUse.push(<MenuItem key={i} value={eyesightlensesInfo.taxonomies['period-of-use'][i].taxonomy_relationship_id}>
        {eyesightlensesInfo.taxonomies['period-of-use'][i].name}
          </MenuItem>)
      }
    }
    return periodUse;
  };

  useEffect(()=>{
    if(eyesightlensesInfo.brands.length!==0)
    {
      let auxArray: Taxonomy[]= [...eyesightlensesInfo.brands];
      let aux;
      let index = -1;
      for(let i=0;i<auxArray.length;i++)
      {
        if(auxArray[i].name==='Altele')
        {
          index = i;
          break;
        }
      }
      if (index > -1) {
        aux = auxArray[index];
        auxArray.splice(index, 1);
        auxArray.push(aux);
        setBrands(auxArray);
      }      
    }
  },[eyesightlensesInfo])

  const [brands, setBrands] = useState<Taxonomy[]>([]);

  return (
    <div className={classes.root}>
      {typeof item === 'undefined' ? <Header tabName={t('ADD_LENSES')} /> : null}
      <div className={classes.productInfo}>
        <PriceAndDescription brands={brands} item={item}/>
        <div className={classes.lensesDetails}>
          <Typography color="textPrimary" variant={'body1'}>
          {t('LENS_TYPES')}*
          </Typography>
          <Typography color="textPrimary" variant={'body1'}>
            {t('PERIOD_USE')}*
          </Typography>
          <Typography color="textPrimary" variant={'body1'}>
            {t('METHOD_WEARING')}*
          </Typography>
          <TextField className={classes.input} select={true} value={forOption} onChange={forOptionChangeHandler} error={forOption === '' && buttonPressed===true}>
          {/* {eyesightlensesInfo.taxonomies.for.map((option,index) => (
              <MenuItem key={index} value={option.taxonomy_relationship_id}>
                {option.name}
              </MenuItem>
            ))} */}
            {addLensTypes()}
          </TextField>
          <TextField className={classes.input} select={true} value={periodOfUse} onChange={periodOfUseChangeHandler} error={periodOfUse === '' && buttonPressed===true}>
          {/* {eyesightlensesInfo.taxonomies['period-of-use'].map((option,index) => (
              <MenuItem key={index} value={option.taxonomy_relationship_id}>
                {option.name}
              </MenuItem>
            ))} */}
            {addPeriodOfUse()}
          </TextField>
          <TextField className={classes.input} select={true} value={methodOfWearing} onChange={methodOfWearingChangeHandler} error={methodOfWearing === '' && buttonPressed===true}>
          {eyesightlensesInfo.taxonomies['method-of-wearing'].map((option,index) => (
              <MenuItem key={index} value={option.taxonomy_relationship_id}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <Add3DImage item={item}/>
        <Typography className={classes.bold} color="textPrimary" variant={'body1'}>
          {t('CHOOSE_PARAMETERS')}
        </Typography>
        <Parameters item={item} category="eyesight"/>
        <PublishOrSave item={item}/>
      </div>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  productInfo: {
    padding: `${theme.spacing(4)}px ${theme.spacing(2)}px ${theme.spacing(6)}px ${theme.spacing(
      2,
    )}px`,
  },
  lensesDetails: {
    display: 'grid',
    gridTemplateColumns: 'minmax(100px, 0.33fr) minmax(100px, 0.33fr) minmax(100px, 0.33fr)',
    columnGap: theme.spacing(4),
    padding: `0px ${theme.spacing(3)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
    background: theme.palette.primary.light,
  },
  input: {
    display: 'grid',
    backgroundColor: theme.palette.background.default,
    borderRadius: '5px',
    height: '34px',
    marginBottom: theme.spacing(3),
  },
  bold: {
    fontWeight: 700,
    paddingBottom: theme.spacing(1.5),
    paddingTop: theme.spacing(3),
  },
}));

export default AddEyesightLensesContainer;
