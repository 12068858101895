import { Theme } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CheckCircle from '@material-ui/icons/CheckCircle';
import Error from '@material-ui/icons/Error';
import Info from '@material-ui/icons/Info';
import Warning from '@material-ui/icons/Warning';
import * as React from 'react';
import { FC, useEffect, useState } from 'react';
import { hideSnack } from '../../state/actions';

const variantIcon = {
  success: CheckCircle,
  warning: Warning,
  error: Error,
  info: Info,
};

interface Props {
  message: string;
  variant: keyof typeof variantIcon;
  open: boolean;
  onClose: (event: React.SyntheticEvent | MouseEvent, reason?: string) => void;
}

export const SnackbarMessage: FC<Props> = ({ message, variant, open, onClose }) => {
  const [openedSnackbar, setOpenedSnackbar] = useState<boolean>(false);
  const Icon = variantIcon[variant];
  const classes = useStyles();

  useEffect(() => {
    setOpenedSnackbar(open);
    if (open) {
      setTimeout(() => {
        hideSnack();
      }, 5000);
    }
  }, [open]);

  return (
    <Snackbar
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      open={openedSnackbar}
      autoHideDuration={5000}
    >
      <SnackbarContent
        className={classes[variant]}
        aria-describedby="client-snackbar"
        message={
          <span data-testid="snackbar-content" className={classes.snack}>
            <Icon className={classes.icon} />
            {message}
          </span>
        }
      />
    </Snackbar>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  success: {
    backgroundColor: '#339129', // medium green
  },
  error: {
    backgroundColor: '#b80f0f', // dark red
  },
  info: {
    backgroundColor: '#0077b3', // blue
  },
  warning: {
    backgroundColor: '#e38a17', // orange
  },
  icon: {
    opacity: 0.9,
    fontSize: '20px',
    marginRight: '8px',
  },
  snack: {
    display: 'flex',
    alignItems: 'center',
  },
}));
