import { makeStyles, Typography } from '@material-ui/core';
import { FC } from 'react';
import React from 'react';
import glassesImage from '../../assets/glassesImage.png';
import { ImagesProductShow } from '../../state/types';
import { useTranslation } from 'react-i18next';

interface Props {
  image: ImagesProductShow[];
}

const Images: FC<Props> = props => {
  const classes = useStyles();
  const { image } = props;
  const { t } = useTranslation();

  const images = (
    <div className={classes.specsImage}>
      <Typography className={classes.detailsWithBorder} color="primary" variant={'subtitle2'}>
        {t('IMAGES')}
      </Typography>
      <div className={classes.images}>
      {image.map((item,index)=>(
        <img className={classes.fitImage} src={item.name} alt={"alt-"+index} />
      ))}
        {/* <img src={glassesImage} alt="alt-1" />
        <img src={glassesImage} alt="alt-1" /> */}
      </div>
      <Typography className={classes.bold} color="primary" variant={'body1'}>
        {t('ALL_IMAGES')}
      </Typography>
    </div>
  );

  const image3D = (
    <div className={classes.specs}>
      <Typography className={classes.detailsWithBorder} color="primary" variant={'subtitle2'}>
        {t('IMAGE_3D')}
      </Typography>
      <img src={glassesImage} alt="alt-1" />
    </div>
  );

  return (
    <div className={classes.root}>
      <div className={classes.columns}>
        <div>{images}</div>
        <div>{image3D}</div>
      </div>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  columns: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit,minmax(300px,0.5fr))',
    gridGap: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  greyLine: {
    height: '1px',
    backgroundColor: theme.palette.grey[300],
    marginBottom: theme.spacing(1),
  },
  inlineText: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  specs: {
    backgroundColor: theme.palette.background.default,
    boxShadow: '0px 2px 15px rgba(0, 0, 0, 0.1)',
    padding: theme.spacing(2.5),
    height: '180px',
  },
  detailsWithBorder: {
    display: 'flex',
    paddingBottom: theme.spacing(2),
    textDecoration: 'underline',
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  images: {
    display: 'flex',
    gridGap: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden'
  },
  bold: {
    fontWeight: 600,
  },
  specsImage: {
    backgroundColor: theme.palette.background.default,
    boxShadow: '0px 2px 15px rgba(0, 0, 0, 0.1)',
    padding: theme.spacing(2.5),
  },
  fitImage: {
    // maxWidth: '100%',
    // height:'auto',
    // width: '100%'
    maxWidth: '40%',
    maxHeight: '40%'
  },
}));

export default Images;
