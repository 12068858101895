import { INEXISTENT_ID, INEXISTENT_VALUE, ProductCreate } from "./types";

export const createProductInitial: ProductCreate = {
    category_id: INEXISTENT_ID,
    brand: INEXISTENT_ID,
    model: '',
    price: INEXISTENT_VALUE,
    discount: '0',
    code: '',
    stock: INEXISTENT_VALUE,
    size: {
        width: '',
      length: '',
      bridge: '',
    },
    description: '',
    ['face-shape']: '248',
    ['frame-shape']: INEXISTENT_ID,
    ['lens-types']: INEXISTENT_ID,
    ['frame-type']: INEXISTENT_ID,
    ['frame-materials']: INEXISTENT_ID,
    characteristics: '412',
    for: INEXISTENT_ID,
    ['frame-color']: [],
    ['lenses-color']: INEXISTENT_ID,
    images : [],
    image3d: INEXISTENT_ID,
    lense_improvement: [],
    lense_thickness: [],
    lense_type: [],
    lense_diopter: [],
    ['period-of-use']: '',
    ['method-of-wearing']: '',
};
