import axios from 'axios';
import { env } from '../../config';
import { getAxiosConfig, Location, PickupLocationPost } from '../../utils/constants';
// import { env } from '../../../environments';

// const env =  'https://api.eyes-aid.com/'

const COUNTIES_API = `${env}api/optics/states`;
const OPTICS_API = `${env}api/optics/localize/`;
const CITIES_API = `${env}api/optics/cities-by-state/`;
const USER_OPTICS = `${env}api/dashboard/company-location`;
const PICKUP_POINT_API = `${env}api/dashboard/cargus/pickup`;
const COUNTIES_API_CARGUS = `${env}api/cargus/counties`;
const CITIES_API_CARGUS = `${env}api/cargus/localities/`;


export const getCounties = async () => {
  return axios
    .get(COUNTIES_API)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      throw new Error(err.message);
    });
};

export const getOptics = async (countyName: string) => {
  return axios
    .get(OPTICS_API + countyName)
    .then(res => {
      return res.data.data;
    })
    .catch(err => {
      throw new Error(err.message);
    });
};

export const getCities = async (countyId: number) => {
  return axios
    .get(CITIES_API + countyId)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      throw new Error(err.message);
    });
};

export const getUserOptics = async () => {
  return axios
    .get(USER_OPTICS, getAxiosConfig())
    .then(res => {
      return res;
    })
    .catch(err => {
      throw new Error(err.message);
    });
};

export const createLocation = async (newLocation: Location) => {
  const bodyParams = {
    country: newLocation.country,
    state: newLocation.state,
    city: newLocation.city,
    street: newLocation.street,
    street_number: newLocation.street_number,
    zipcode: newLocation.zipcode,
    phone: newLocation.phone,
    main: newLocation.main,
    scheduler_customer_server: newLocation.scheduler_customer_server,
    description: newLocation.description,
    latitude: newLocation.latitude,
    longitude: newLocation.longitude,
    email: newLocation.email,
  };
  return axios
    .post(USER_OPTICS, bodyParams, getAxiosConfig())
    .then(res => {
      return res;
    })
    .catch(err => {
      throw new Error(err.message);
    });
};

export const updateLocation = async (id: number, newLocation: Location) => {
  const bodyParams = {
    country: newLocation.country,
    state: newLocation.state,
    city: newLocation.city,
    street: newLocation.street,
    street_number: newLocation.street_number,
    zipcode: newLocation.zipcode,
    phone: newLocation.phone,
    main: newLocation.main,
    scheduler_customer_server: newLocation.scheduler_customer_server,
    description: newLocation.description,
    latitude: newLocation.latitude,
    longitude: newLocation.longitude,
    email: newLocation.email,
  };
  return axios
    .put(USER_OPTICS + '/' + id, bodyParams, getAxiosConfig())
    .then(res => {
      return res;
    })
    .catch(err => {
      throw new Error(err.message);
    });
};

export const deleteLocation = async (id: number) => {
  return axios
    .delete(USER_OPTICS + '/' + id, getAxiosConfig())
    .then(res => {
      return res;
    })
    .catch(err => {
      throw new Error(err.message);
    });
};

export const getPickupPoint = async () => {
  return axios
    .get(PICKUP_POINT_API, getAxiosConfig())
    .then(res => {
      return res.data;
    })
    .catch(err => {
      throw new Error(err.message);
    });
};

export const getCountiesCargus = async () => {
  return axios
    .get(COUNTIES_API_CARGUS)
    .then(res => {
      return res.data.data;
    })
    .catch(err => {
      throw new Error(err.message);
    });
};

export const getCitiesCargus = async (countyId: number) => {
  return axios
    .get(CITIES_API_CARGUS + countyId)
    .then(res => {
      return res.data.data;
    })
    .catch(err => {
      throw new Error(err.message);
    });
};

export const postPickupPoint = async (newLocation: PickupLocationPost) => {
  const bodyParams = {
    email: newLocation.email,
    name: newLocation.name,
    county_id: newLocation.county_id,
    county_name: newLocation.county_name,
    locality_name: newLocation.locality_name,
    street_name: newLocation.street_name,
    building_number: newLocation.building_number,
    address_text: newLocation.address_text,
    contact_person: newLocation.contact_person,
    phone_number: newLocation.phone_number,
    postal_code: newLocation.postal_code,
    street_id: newLocation.street_id,
    locality_id: newLocation.locality_id,
    location_id: newLocation.location_id,
    automatic_eod: newLocation.automatic_eod,
    country_id: newLocation.country_id,
    company_id: newLocation.company_id
  };
  return axios
    .post(PICKUP_POINT_API, bodyParams, getAxiosConfig())
    .then(res => {
      return res;
    })
    .catch(err => {
      throw new Error(err.message);
    });
};

export const updatePickupPoint = async (id: string,newLocation: PickupLocationPost) => {
  const bodyParams = {
    email: newLocation.email,
    name: newLocation.name,
    county_id: newLocation.county_id,
    county_name: newLocation.county_name,
    locality_name: newLocation.locality_name,
    street_name: newLocation.street_name,
    building_number: newLocation.building_number,
    address_text: newLocation.address_text,
    contact_person: newLocation.contact_person,
    phone_number: newLocation.phone_number,
    postal_code: newLocation.postal_code,
    street_id: newLocation.street_id,
    locality_id: newLocation.locality_id,
    location_id: newLocation.location_id,
    automatic_eod: newLocation.automatic_eod,
    country_id: newLocation.country_id,
    company_id: newLocation.company_id
  };
  return axios
    .post(PICKUP_POINT_API+'/'+id, bodyParams, getAxiosConfig())
    .then(res => {
      return res;
    })
    .catch(err => {
      throw new Error(err.message);
    });
};