const APP_PATH = '/';

export const PATHS = {
  APP: {
    ROOT: APP_PATH,
    ADD_PRODUCT: {
      ROOT: APP_PATH,
      EYEGLASSES: `${APP_PATH}add-product/eyeglasses`,
      SUNGLASSES: `${APP_PATH}add-product/sunglasses`,
      COMPUTER_GLASSES: `${APP_PATH}add-product/computerglasses`,
      COSMETIC_LENSES: `${APP_PATH}add-product/cosmeticlenses`,
      EYESIGHT_LENSES: `${APP_PATH}add-product/eyesightlenses`,
      OTHERS: `${APP_PATH}add-product/others`,
    },
    PRODUCTS_MANAGEMENT: {
      ROOT: `${APP_PATH}products-management/`,
      EDIT_PRODUCT: `${APP_PATH}products-management/edit-product/`,
      DETAILS: `${APP_PATH}products-management/details`,
    },
    ORDERS: {
      ROOT: `${APP_PATH}orders/`,
      DETAILS: `${APP_PATH}orders/details`,
    },
    ACCOUNT: {
      ROOT: `${APP_PATH}account/`,
      ACCOUNT_DATA: `${APP_PATH}account/account-data`,
      LOCATION: `${APP_PATH}account/location`,
      PICKUP_LOCATION: `${APP_PATH}account/pickup-location`,
    },
  },
};

export const REGEX_ROUTE = {
  PRODUCTS_MANAGEMENT: /^\/products-management\//,
  ORDERS: /^\/orders\//,
  ACCOUNT: /^\/account\//,
};
