import { makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SizeProductShow } from '../../state/types';

interface Props {
  faceShape?: string;
  frameShape?: string;
  lensTypes?: string;
  frameType?: string;
  frameMaterials?: string;
  characteristics?: string;
  For?: string;
  size: SizeProductShow[];
}

const DetailsAndSize: FC<Props> = props => {
  const classes = useStyles();
  const { faceShape,frameShape,lensTypes,frameType,frameMaterials,characteristics,For,size } = props;
  const { t } = useTranslation();

  return (
    <div className={classes.detailsAndSize}>
      <div className={classes.details}>
        <div className={classes.container}>
          <Typography className={classes.title} variant="subtitle2" color="primary">
            {t('DETAILS')}
          </Typography>
        </div>
        <div className={clsx(classes.container, classes.detailsText)}>
          {/* <Typography variant="body1" color="textPrimary">
            {t('FACE_SHAPE')}:
          </Typography>
          <Typography variant="body1" color="textPrimary">
            {faceShape}
          </Typography> */}
          {/* <div className={classes.greyLine} /> */}
          <Typography variant="body1" color="textPrimary">
            {t('FRAME_SHAPE')}:
          </Typography>
          <Typography variant="body1" color="textPrimary">
            {frameShape}
          </Typography>
          <div className={classes.greyLine} />
          <Typography variant="body1" color="textPrimary">
            {t('LENS_TYPES')}:
          </Typography>
          <Typography variant="body1" color="textPrimary">
            {lensTypes}
          </Typography>
          <div className={classes.greyLine} />
          <Typography variant="body1" color="textPrimary">
            {t('FRAME_TYPE')}:
          </Typography>
          <Typography variant="body1" color="textPrimary">
            {frameType}
          </Typography>
          <div className={classes.greyLine} />
          <Typography variant="body1" color="textPrimary">
            {t('FRAME_MATERIALS')}:
          </Typography>
          <Typography variant="body1" color="textPrimary">
            {frameMaterials}
          </Typography>
          <div className={classes.greyLine} />
          {/* <Typography variant="body1" color="textPrimary">
            {t('CHARACTERISTICS')}:
          </Typography>
          <Typography variant="body1" color="textPrimary">
            {characteristics}
          </Typography>
          <div className={classes.greyLine} /> */}
          <Typography variant="body1" color="textPrimary">
            {t('FOR')}:
          </Typography>
          <Typography variant="body1" color="textPrimary">
            {For}
          </Typography>
          <div className={classes.greyLine} />
        </div>
      </div>
      <div className={classes.details}>
        <div className={classes.container}>
          <Typography className={classes.title} variant="subtitle2" color="primary">
            {t('SIZE')}
          </Typography>
        </div>
        <div className={clsx(classes.container, classes.sizes)}>
          {size.map((item,index)=>(
            <div className={classes.titleAndValue} key={index}>
            <Typography className={classes.bold} variant="body1" color="textPrimary">
              {t('SIZE')} {index+1}
            </Typography>
            <div className={classes.greyLine} />
            <div className={classes.sizesText}>
              <Typography className={classes.sizeType} variant="body1" color="textPrimary">
                {t('LENSES_WIDTH')}
              </Typography>
              <Typography className={classes.sizeType} variant="body1" color="textPrimary">
                {t('TEMPLE_LENGTH')}
              </Typography>
              <Typography className={classes.sizeType} variant="body1" color="textPrimary">
                {t('BRIDGE_WIDTH')}
              </Typography>
              <Typography variant="body1" color="textPrimary">
                {item.width+' mm'}
              </Typography>
              <Typography variant="body1" color="textPrimary">
                {item.length+' mm'}
              </Typography>
              <Typography variant="body1" color="textPrimary">
                {item.bridge+' mm'}
              </Typography>
            </div>
          </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  detailsAndSize: {
    display: 'grid',
    gridTemplateColumns: '0.5fr 0.5fr',
    columnGap: theme.spacing(2.5),
    paddingBottom: theme.spacing(3),
  },
  details: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    rowGap: theme.spacing(0.5),
  },
  title: {
    fontWeight: 700,
    textDecoration: 'underline',
    padding: `${theme.spacing(2)}px 0px ${theme.spacing(2)}px ${theme.spacing(3.5)}px`,
    maxHeight: '52px',
  },
  container: {
    boxShadow: '0px 3px 14px rgba(0, 0, 0, 0.12)',
    borderRadius: '4px',
  },
  detailsText: {
    display: 'grid',
    height: '363px',
    gridTemplateColumns: '0.5fr 0.5fr',
    padding: `${theme.spacing(3)}px ${theme.spacing(2.5)}px ${theme.spacing(
      8.5,
    )}px ${theme.spacing(2.5)}px`,
    rowGap: theme.spacing(1.5),
  },
  greyLine: {
    height: '1px',
    background: theme.palette.grey[300],
    gridColumnStart: 1,
    gridColumnEnd: 3,
  },
  bold: {
    fontWeight: 600,
  },
  sizes: {
    display: 'grid',
    height: '363px',
    padding: `${theme.spacing(3)}px ${theme.spacing(2.5)}px ${theme.spacing(
      8.5,
    )}px ${theme.spacing(2.5)}px`,
  },
  sizesText: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 0.33fr)',
  },
  sizeType: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  titleAndValue: {
    paddingBottom: theme.spacing(3),
  },
}));

export default DetailsAndSize;
