import {
  Button,
  Dialog,
  DialogContent,
  makeStyles,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { FC, useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { showSnack } from '../../state/actions';
import { updateOrderStatus } from '../../state/commands/order';
import { OrdersDetails, SnackbarVariant } from '../../utils/constants';

interface Props {
  onClose: () => void;
  open: boolean;
  item: OrdersDetails;
  refresh: boolean;
  setRefresh: (newRefresh: boolean) => void;
}

export enum Status {
  INITIATED = 'initiated',
  PAYMENT_REQUIRED = 'payment_required',
  IN_DELIVERY = 'in_delivery',
  CANCELED = 'canceled',
  COMPLETED = 'completed',
  PAID = 'paid',
  PAYMENT_FAILED = 'payment_failed',
  DELIVERED = 'delivered'
}

const OrderStatusDialog: FC<Props> = props => {
  const { onClose, open, item,refresh,setRefresh } = props;
  const classes = useStyles();
  const [orderStatus, setOrderStatus] = useState<string>(item.status);
  const { t } = useTranslation();

  useEffect(()=>{
    if(item.status===t('INITIATED'))
    {
      setOrderStatus(Status.INITIATED)
    }
    if(item.status===t('PAYMENT_REQUIRED'))
    {
      setOrderStatus(Status.PAYMENT_REQUIRED)
    }
    if(item.status===t('IN_DELIVERY'))
    {
      setOrderStatus(Status.IN_DELIVERY)
    }
    if(item.status===t('CANCELED'))
    {
      setOrderStatus(Status.CANCELED)
    }
    if(item.status===t('COMPLETED'))
    {
      setOrderStatus(Status.COMPLETED)
    }
    if(item.status===t('PAID'))
    {
      setOrderStatus(Status.PAID)
    }
    if(item.status===t('PAYMENT_FAILED'))
    {
      setOrderStatus(Status.PAYMENT_FAILED)
    }
    if(item.status===t('DELIVERED'))
    {
      setOrderStatus(Status.DELIVERED)
    }
  },[item])

  const handleUpdateStatus = async () => {
    try {
      const response = await updateOrderStatus(item.nrOrder.substring(1,item.nrOrder.length),orderStatus);
      setRefresh(!refresh);
    } catch(e) {
      showSnack(e.message, SnackbarVariant.error);
    }
    onClose();
  };

  const orderStatusChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOrderStatus(e.target.value);
  }

  const editAccount = (
    <Dialog onClose={onClose} open={open} fullWidth={true} maxWidth="md">
      <DialogContent classes={{ root: classes.root }}>
        <div className={classes.paper}>
          <Typography className={classes.bold} variant={'h4'}>
            {t('CHANGE_ORDER_STATUS')}
          </Typography>
          <div className={classes.modalControls}>
            <Typography variant={'subtitle2'}>{t('CURRENT_ORDER_STATUS')}</Typography>
            <TextField
            select={true}
            value={orderStatus}
            onChange={orderStatusChangeHandler}
          >
            <MenuItem key={Status.INITIATED} value={Status.INITIATED}>
                {t('INITIATED')}
            </MenuItem>
            <MenuItem key={Status.PAYMENT_REQUIRED} value={Status.PAYMENT_REQUIRED}>
                {t('PAYMENT_REQUIRED')}
            </MenuItem>
            <MenuItem key={Status.IN_DELIVERY} value={Status.IN_DELIVERY}>
                {t('IN_DELIVERY')}
            </MenuItem>
            <MenuItem key={Status.CANCELED} value={Status.CANCELED}>
                {t('CANCELED')}
            </MenuItem>
            <MenuItem key={Status.COMPLETED} value={Status.COMPLETED}>
                {t('COMPLETED')}
            </MenuItem>
            <MenuItem key={Status.PAID} value={Status.PAID}>
                {t('PAID')}
            </MenuItem>
            <MenuItem key={Status.PAYMENT_FAILED} value={Status.PAYMENT_FAILED}>
                {t('PAYMENT_FAILED')}
            </MenuItem>
            <MenuItem key={Status.DELIVERED} value={Status.DELIVERED}>
                {t('DELIVERED')}
            </MenuItem>
          </TextField>
          </div>
          <Button
            className={classes.greenButton}
            size="large"
            color="primary"
            variant="contained"
            onClick={handleUpdateStatus}
          >
            <Typography color="textSecondary" variant={'body1'}>
              {t('SAVE')}
            </Typography>
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );

  return <div>{editAccount}</div>;
};

const useStyles = makeStyles(theme => ({
  root: {
    flex: 'unset',
    overflowX: 'hidden',
  },
  paper: {
    width: '100%',
    backgroundColor: theme.palette.background.default,
    display: 'grid',
    paddingLeft: theme.spacing(3.5),
    paddingTop: theme.spacing(3.5),
    gridGap: theme.spacing(3),
    paddingBottom: theme.spacing(3.5),
  },
  bold: {
    fontWeight: 700,
  },
  modalControls: {
    display: 'grid',
    gridTemplateColumns: '0.4fr 0.7fr 0.05fr ',
    alignItems: 'center',
    marginRight: theme.spacing(3.5),
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr',
    },
  },
  birthDate: {
    display: 'grid',
    gridTemplateColumns: '0.2fr 0.2fr 0.3fr',
    gridGap: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      gridGap: theme.spacing(1),
      gridTemplateColumns: '0.33fr 0.33fr 0.33fr',
    },
  },
  greyText: {
    color: theme.palette.grey[200],
  },
  greenButton: {
    height: '46px',
    width: '98px',
    [theme.breakpoints.down('sm')]: {
      justifySelf: 'end',
      marginRight: theme.spacing(3.5),
    },
  },
}));

export default OrderStatusDialog;
