import { Button, Dialog, makeStyles } from '@material-ui/core';
import { FC, useEffect } from 'react';
import React from 'react';
import Header from '../../components/Header';
import LocationData from '../../components/LocationData/LocationData';
import LocationDialog from '../../components/LocationData/LocationDialog/LocationDialog';
import { getUserOptics } from '../../state/commands/location';
import { actionType, Location } from '../../utils/constants';
import { useTranslation } from 'react-i18next';

const AccountLocationContainer: FC<{}> = () => {
  const classes = useStyles();
  const [locationDialogOpen, setLocationDialogOpen] = React.useState<boolean>(false);
  const location: Location = {
    id: 0,
    country: '',
    state: '',
    city: '',
    street: '',
    street_number: '',
    zipcode: '',
    latitude: '',
    longitude: '',
    description: '',
    phone: '',
    scheduler_customer_server: '',
    main: '',
    email: '',
  };
  const [listOfLocations, setListOfLocations] = React.useState<Location[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    getUserLocation();
  }, []);

  const getUserLocation = async () => {
    try {
      const userLocations = await getUserOptics();
      setListOfLocations(userLocations.data.data);
    } catch (e) {
      console.error('Nu am putut incarca datele!');
    }
  };

  const openLocationModal = () => {
    setLocationDialogOpen(true);
  };
  const closeLocationModal = () => {
    setLocationDialogOpen(false);
  };

  const updateLocations = async () => {
    getUserLocation();
  };

  const locations = listOfLocations.map(singleLocation => {
    return (
      <div key={singleLocation.street}>
        <LocationData data={singleLocation} update={updateLocations} />
      </div>
    );
  });

  const locationContainer = (
    <div className={classes.root}>
      <Header tabName={t('LOCATIONS')} />
      {locations}
      <div className={classes.addButton}>
        <Button onClick={openLocationModal} size="large" variant="contained" color="primary">
          {t('ADD_LOCATION')}
        </Button>
      </div>
    </div>
  );

  return (
    <div className={classes.root}>
      {locationContainer}
      <Dialog
        onClose={closeLocationModal}
        open={locationDialogOpen}
        fullWidth={true}
        maxWidth="md"
      >
        <LocationDialog
          onClose={closeLocationModal}
          update={updateLocations}
          data={location}
          action={actionType.insert}
        />
      </Dialog>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  header: {
    display: 'grid',
    gridTemplateColumns: '1fr 0.3fr',
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    boxShadow: '0px 2px 15px rgba(0, 0, 0, 0.1)',
  },
  profile: {
    display: 'grid',
    gridTemplateColumns: '150px  1fr',
  },
  lightBold: {
    fontWeight: 600,
  },
  addButton: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
  },
}));

export default AccountLocationContainer;
