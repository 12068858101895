import { makeStyles, Typography } from '@material-ui/core';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Colors from '../../components/AddProduct/Colors';
import Images from '../../components/AddProduct/Images';
import PrescriptionAndThickness from '../../components/AddProduct/PrescriptionAndThickness';
import PriceAndDescription from '../../components/AddProduct/ProductPriceAndDescription';
import ShapeAndSize from '../../components/AddProduct/ProductShapeAndSize';
import PublishOrSave from '../../components/AddProduct/PublishOrSave';
import Header from '../../components/Header';
import { resetProduct, setButtonPressed, setCategoryProduct } from '../../state/actions';
import { getProductInfo } from '../../state/commands/product';
import { createProductInitial } from '../../state/initialStore';
import { ProductShow, Size } from '../../state/types';
import { ComputerGlassesInfo, initialComputerglassesInfo, Product, ProductCategory, Taxonomy } from '../../utils/constants';

interface Props {
  item?: ProductShow;
}

const size: Size = {width: [],length: [],bridge: []};

const AddComputerglassesContainer: FC<Props> = props => {
  const classes = useStyles();
  const { item } = props;
  
  const [computerglassesInfo, setComputerglassesInfo] = useState<ComputerGlassesInfo>(initialComputerglassesInfo);
  const { t } = useTranslation();

  useEffect(() => {
    setCategoryProduct(ProductCategory.COMPUTER_GLASSES);
    getComputerglassesDetails();
    generateSize();
  }, []);

  useEffect(()=>{
    if(typeof item === 'undefined')
    {
      resetProduct(createProductInitial);
      setCategoryProduct(ProductCategory.COMPUTER_GLASSES);
      setButtonPressed(false);
    }
  },[])

  const getComputerglassesDetails = async () => {
    try {
      const newComputerglassesInfo = await getProductInfo(ProductCategory.COMPUTER_GLASSES);
      setComputerglassesInfo(newComputerglassesInfo.data);
    } catch (e) {
      console.error(e.message);
    }
  };

  useEffect(()=>{
    if(computerglassesInfo.brands.length!==0)
    {
      let auxArray: Taxonomy[]= [...computerglassesInfo.brands];
      let aux;
      let index = -1;
      for(let i=0;i<auxArray.length;i++)
      {
        if(auxArray[i].name==='Altele')
        {
          index = i;
          break;
        }
      }
      if (index > -1) {
        aux = auxArray[index];
        auxArray.splice(index, 1);
        auxArray.push(aux);
        setBrands(auxArray);
      }      
    }
  },[computerglassesInfo])

  const [brands, setBrands] = useState<Taxonomy[]>([]);

  const generateSize = () => {
    for (let i = 35; i <= 70; i+=0.5) {
      size.width.push(i.toString());
    }
    for (let i = 90; i <= 155; i+=0.5) {
      size.length.push(i.toString());
    }
    for (let i = 9; i <= 26; i+=0.5) {
      size.bridge.push(i.toString());
    }
  }

  return (
    <div className={classes.root}>
      {typeof item === 'undefined' ? <Header tabName={t('ADD_COMPUTER_GLASSES')} /> : null}
      <div className={classes.productInfo}>
        <PriceAndDescription brands={brands} item={item}/>
        <ShapeAndSize taxonomies={computerglassesInfo.taxonomies} size={size} item={item} category="computer"/>
        <Typography className={classes.colorTitle} color="textPrimary" variant={'body1'}>
          {t('FRAME_COLOR')}*
        </Typography>
        <Colors frameColor={computerglassesInfo.taxonomies['frame-color']} color={item?.color.frame !== null ? item?.color.frame : undefined}/>
        <Images item={item}/>
        <PublishOrSave item={item}/>
      </div>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  productInfo: {
    padding: `${theme.spacing(4)}px ${theme.spacing(2)}px ${theme.spacing(6)}px ${theme.spacing(
      2,
    )}px`,
  },
  colorTitle: {
    background: theme.palette.primary.light,
    paddingLeft: theme.spacing(3),
    paddingBottom: theme.spacing(1),
  },
}));

export default AddComputerglassesContainer;
