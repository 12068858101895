import { red } from '@material-ui/core/colors';
import { createTheme } from '@material-ui/core/styles';

// Create a theme instance.
const theme = createTheme({
  palette: {
    primary: {
      dark: '#223875',
      main: '#0056D3',
      light: '#E3F4FA',
      contrastText: '#F1F1F1',
    },
    secondary: {
      main: '#D61B2B',
    },
    grey: {
      100: '#828282', // grey
      200: '#C8C8C8',
      300: '#D3D3D3', // light grey
      400: '#ECECEC', // light light grey
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#ffffff',
    },
    text: {
      primary: '#252E41', // dark blue
      secondary: '#ffffff', // white
    },
  },
  spacing: 8,
  typography: {
    h1: {
      fontSize: '36px',
    },
    h2: {
      fontSize: '30px',
    },
    h3: {
      fontSize: '25px',
    },
    h4: {
      fontSize: '20px',
    },
    subtitle1: {
      fontSize: '18px',
    },
    subtitle2: {
      fontSize: '16px',
    },
    body1: {
      fontSize: '14px',
    },
    body2: {
      fontSize: '12px',
    },
    fontFamily: 'sans-serif',
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 450,
      md: 700,
      lg: 1100,
      xl: 1800,
    },
  },
  overrides: {
    MuiTypography: {
      root: {
        cursor: 'default',
        color: '#252E41',
      },
    },
  },
});

export default theme;
