import { makeStyles, MenuItem, TextField, Typography } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Autocomplete, AutocompleteRenderInputParams } from '@material-ui/lab';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { FC } from 'react';
import { Taxonomy } from '../../utils/constants';
import { setBrandProduct, setCategoryProduct, setCodeProduct, setDescriptionProduct, setDiscountProduct, setFullPriceProduct, setModelProduct, setStockProduct } from '../../state/actions';
import { InitialState, ProductShow } from '../../state/types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

interface Props {
  brands: Taxonomy[];
  item?: ProductShow;
  category?: string;
}

export enum SOLUTION_ACCESSORIES_ID {
  OPHTALMIC_DROPS = 8,
  LENS_MAINTENANCE_SOLUTIONS = 9,
  LENS_HOLDER = 10,
  GLASSES_MAINTENANCE_SOLUTIONS = 7,
  CLOTHS = 11,
  GLASSES_CASE = 12,
  OTHER = 13
}

const PriceAndDescription: FC<Props> = props => {
  const classes = useStyles();
  const { brands,item,category } = props;
  const buttonPressed = useSelector((state: InitialState) => state.buttonPressed);
  const renderInput = (params: AutocompleteRenderInputParams) => (
    <TextField {...params} variant="outlined" error={brand === null && buttonPressed===true} />
  );
  const [brand, setBrand] = useState<Taxonomy | null>(null);
  const [model, setModel] = useState<string>('');
  const [productCode, setProductCode] = useState<string>('');
  const [stockNr, setStockNr] = useState<string>('');
  const [fullPrice, setFullPrice] = useState<string>('');
  const [discount, setDiscount] = useState<string>('0');
  const priceAfterDiscount = () => {
    if (parseInt(fullPrice, 10) > 0 && parseInt(discount, 10) >= 0) {
      return parseInt(fullPrice, 10) - (parseInt(fullPrice, 10) * parseInt(discount, 10)) / 100;
    } else {
      return 0;
    }
  };
  const [description, setDescription] = useState<string>('');

  const brandChangeHandler = (event: React.ChangeEvent<{}>, newValue: Taxonomy | null) => {
    setBrand(newValue);
    if(newValue!==null)
    {
      setBrandProduct(newValue.taxonomy_relationship_id.toString());
    }
  };
  const modelChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setModel(event.target.value);
    setModelProduct(event.target.value);
  };
  const productCodeChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setProductCode(event.target.value);
    setCodeProduct(event.target.value);
  };
  const stockChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStockNr(event.target.value);
    setStockProduct(event.target.value);
  };
  const fullPriceChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if(Number(event.target.value)>=0)
    {
      setFullPrice(event.target.value);
      setFullPriceProduct(event.target.value);
    }
  };
  const discountChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if(Number(event.target.value)>=0)
    {
      setDiscount(event.target.value);
      setDiscountProduct(event.target.value);
    }
  };
  const descriptionChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDescription(event.target.value);
    setDescriptionProduct(event.target.value);
  };
  const getOptionLabel = (option: Taxonomy) => option.name;

  useEffect(()=>{
    if(typeof item !== 'undefined')
    {
      // setBrand(item.general.brand)
      setProductCode(item.general.code);
      setCodeProduct(item.general.code);
      setModel(item.general.model);
      setModelProduct(item.general.model);
      setStockNr(item.general.stoc.toString());
      setStockProduct(item.general.stoc.toString());
      if(item.price.price.includes('.')===true)
      {
        setFullPrice(item.price.price.replace(',',''));
        setFullPriceProduct(item.price.price.replace(',',''));
      }
      else{
        setFullPrice(item.price.price);
        setFullPriceProduct(item.price.price);
      }
      setDiscount(item.price.discount.toString());
      setDiscountProduct(item.price.discount.toString());
      setDescription(item.description);
      setDescriptionProduct(item.description);

      if(typeof category !== 'undefined')
      {
        setCategoryProduct(String(item.category.id));
        setProdCategory(String(item.category.id));
      }

    }
  },[])

  useEffect(()=>{
    if(typeof item !== 'undefined')
    {
      for(let i=0;i<brands.length;i++)
      {
        if(item.general.brand===brands[i].name)
        {
          setBrand(brands[i]);
          setBrandProduct(brands[i].taxonomy_relationship_id.toString());   
          break;
        }
      }
    }
  },[brands,item])

  const { t } = useTranslation();

  const [prodCategory, setProdCategory] = useState<string>('');

  const handleChangeSolution = (event: React.ChangeEvent<{ value: unknown }>) => {
    setCategoryProduct(String(event.target.value));
    setProdCategory(String(event.target.value));
    setModelProduct('');
  };

  useEffect(()=>{
    if(typeof category!=='undefined')
    {
      setModelProduct(productCode);
    }
  },[productCode])

  return (
    <React.Fragment>
      <div>
        <Typography className={classes.bold} color="textPrimary" variant={'body1'}>
          {t('PRODUCT_DETAILS_FILTERS')}
        </Typography>
      </div>
      <div className={classes.productInfo}>
        <div className={classes.brandAndModel}>
          <Typography color="textPrimary" variant={'body1'}>
            {t('BRAND')}*
          </Typography>
          <Typography color="textPrimary" variant={'body1'}>
            {typeof category!=='undefined' ? t('SOLUTII_ACCESORII'):t('MODEL')}*
          </Typography>
          <Autocomplete
            className={classes.autocomplete}
            id="brands"
            options={brands}
            value={brand}
            onChange={brandChangeHandler}
            style={{ width: 300 }}
            getOptionLabel={getOptionLabel}
            renderInput={renderInput}
          />
          {typeof category!=='undefined' ?
          <TextField
            className={classes.input}
            select
            value={prodCategory}
            onChange={handleChangeSolution}
            error={prodCategory === '' && buttonPressed===true}
          >
          <MenuItem value={SOLUTION_ACCESSORIES_ID.OPHTALMIC_DROPS}>{t('PICATURI_OFTALMICE')}</MenuItem>
          <MenuItem value={SOLUTION_ACCESSORIES_ID.LENS_MAINTENANCE_SOLUTIONS}>{t('SOLUTII_INTRETINERE_LENTILE')}</MenuItem>
          <MenuItem value={SOLUTION_ACCESSORIES_ID.LENS_HOLDER}>{t('SUPORT_LENTILE')}</MenuItem>
          <MenuItem value={SOLUTION_ACCESSORIES_ID.GLASSES_MAINTENANCE_SOLUTIONS}>{t('SOLUTII_INTRETINERE_OCHELARI')}</MenuItem>
          <MenuItem value={SOLUTION_ACCESSORIES_ID.CLOTHS}>{t('LAVETE')}</MenuItem>
          <MenuItem value={SOLUTION_ACCESSORIES_ID.GLASSES_CASE}>{t('TOCURI_OCHELARI')}</MenuItem>
          <MenuItem value={SOLUTION_ACCESSORIES_ID.OTHER}>{t('ALTELE')}</MenuItem>
          </TextField> :
          <TextField className={classes.input} value={model} onChange={modelChangeHandler} error={model === '' && buttonPressed===true} />}
          <div>
            <div className={classes.stock}>
              <Typography color="textPrimary" variant={'body1'}>
                {t('PRODUCT_CODE')}*
              </Typography>
              <TextField
                className={classes.input}
                value={productCode}
                onChange={productCodeChangeHandler}
                error={productCode === '' && buttonPressed===true}
              />
            </div>
            <div className={classes.stock}>
              <Typography color="textPrimary" variant={'body1'}>
                {t('NUMBER_STOCK')}*
              </Typography>
              <TextField
                className={classes.input}
                value={stockNr}
                onChange={stockChangeHandler}
                error={stockNr === '' && buttonPressed===true}
              />
            </div>
          </div>
          
        </div>
        <div className={classes.whiteLine} />
        <div className={classes.pricing}>
          <Typography color="textPrimary" variant={'body1'}>
            {t('FULL_PRICE')}*
          </Typography>
          <Typography color="textPrimary" variant={'body1'}>
            {t('DISCOUNT_PRICE')}
          </Typography>
          <Typography color="textPrimary" variant={'body1'}>
            {t('FINAL_PRICE')}
          </Typography>
          <TextField
            className={classes.input}
            type="number"
            value={fullPrice}
            onChange={fullPriceChangeHandler}
            error={fullPrice === '' && buttonPressed===true}
          />
          <TextField
            className={classes.input}
            type="number"
            value={discount}
            onChange={discountChangeHandler}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Typography color="textPrimary" variant={'body1'}>
                    %
                  </Typography>
                </InputAdornment>
              ),
            }}
          />
          <TextField className={classes.input} value={priceAfterDiscount()} />
        </div>
        <div className={classes.description}>
          <div>
            <Typography color="textPrimary" variant={'body1'}>
              {t('DESCRIPTION')}*
            </Typography>
            <TextField
              className={classes.multiLineInput}
              multiline={true}
              rows={4}
              variant="outlined"
              value={description}
              onChange={descriptionChangeHandler}
              error={description === '' && buttonPressed===true}
            />
          </div>
        </div>
        <div className={classes.whiteLine} />
      </div>
    </React.Fragment>
  );
};

const useStyles = makeStyles(theme => ({
  bold: {
    fontWeight: 700,
  },
  productInfo: {
    padding: theme.spacing(3),
    background: theme.palette.primary.light,
  },
  brandAndModel: {
    display: 'grid',
    width: '90%',
    gridTemplateColumns: '0.5fr 0.5fr',
    columnGap: theme.spacing(2),
  },
  input: {
    display: 'grid',
    backgroundColor: theme.palette.background.default,
    borderRadius: '5px',
    height: '34px',
  },
  multiLineInput: {
    width: '90%',
    backgroundColor: theme.palette.background.default,
  },
  soldAndDelivered: {
    display: 'grid',
    gridTemplateColumns: 'minmax(120px, 0.25fr) minmax(100px, 0.25fr) minmax(10px, 0.25fr) ',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
  },
  stock: {
    display: 'grid',
    gridTemplateColumns: 'minmax(180px, 0.25fr) minmax(100px, 0.25fr)',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
  },
  whiteLine: {
    height: '2px',
    backgroundColor: theme.palette.background.default,
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4),
  },
  pricing: {
    display: 'grid',
    gridTemplateColumns: 'minmax(100px, 0.2fr) minmax(120px, 0.2fr) minmax(160px, 0.2fr)',
    columnGap: theme.spacing(9.5),
    paddingBottom: theme.spacing(3.5),
    '& input[type=number]': {
      '-moz-appearance': 'textfield'
    },
    '& input[type=number]::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0
    },
    '& input[type=number]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0
    }
  },
  description: {
    display: 'grid',
    rowGap: theme.spacing(4),
  },
  autocomplete: {
    background: theme.palette.background.default,
  },
}));

export default PriceAndDescription;
